import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { DoctorInstructionType } from "../models/Types";
import "../../assets/styles/DoctorInstruction.scss";
import { checkUserId } from "../../services/AuthService";
import { USER_TYPE } from "../../services/AppLocalHelper";

interface Props
  extends RouteComponentProps<{
    patientID: string;
    studentID: string;
    facultyID: string;
    startDate: string;
  }> { }
interface State {
  doctorInstructions: DoctorInstructionType[];
  isStudent: boolean;
}

class DoctorInstruction extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID === "supervisor-faculty" ? this.props.match.params.facultyID : this.props.match.params.studentID;
    if (!checkUserId(userId, this.props.match.params.studentID === "supervisor-faculty" ? USER_TYPE.TEACHER : USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }

    this.state = {
      doctorInstructions: [],
      isStudent: true,
    };
    this.checkStudent = this.checkStudent.bind(this)
  }

  public componentDidMount() {
    this.checkStudent()
    // console.log('facultyID', this.props.match.params.facultyID);
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/doctor_instruction?patient_id=${this.props.match.params.patientID}&student_id=${this.props.match.params.studentID}&faculty_id=${this.props.match.params.facultyID}&start_date=${this.props.match.params.startDate}`;

    fetch(url, { mode: "cors" })
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            doctorInstructions: result
          });
        },
        error => {
          console.log("API Error");
        }
      );
  }

  public checkStudent() {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/control/is_student?student_id=${this.props.match.params.studentID}&faculty_id=${this.props.match.params.facultyID}`;

    fetch(url, { mode: "cors" })
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isStudent: result
          });
        },
        error => {
          console.log("API Error");
        }
      );
  }

  public render() {

    const doctorInstructionsRows = (this.state.isStudent ? this.state.doctorInstructions.filter((item) => item.visibleFlag === false) : this.state.doctorInstructions)
      .map((item, i) => {
        return (
          <tr key={i}>
            <td id="date" className="text-nowrap">
              {item.date}
            </td>
            <td id="instruction" className="text-nowrap">
              {item.instruction === "&emsp;" ? "" : item.instruction}
            </td>
            <td>
              <pre className="text-left p-2">{item.detail}</pre>
            </td>
          </tr>
        );
      })

    return (
      <Row className="ml-4">
        <Col md={2} />
        <Col md={8}>
          <h2 className="mb-4">医師の指示</h2>
          <Table
            className="doctor_instruction"

            bordered
            hover
            size="sm"
          >
            <thead className="thead-dark">
              <tr>
                <th id="date">指示開始日</th>
                <th id="instruction">指示</th>
                <th id="detail">詳細内容</th>
              </tr>
            </thead>
            <tbody>

              {doctorInstructionsRows}
            </tbody>
          </Table>
        </Col>
        <Col md={2} />
      </Row>
    );
  }
}

export default DoctorInstruction;
