import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { UniversityList } from "../../models/Types";

interface Props extends RouteComponentProps<{ facultyID: string }> {}
interface State {
  universityList: UniversityList[];
}

class InternUniversityListForSelfEvaluation extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      universityList: [],
    };

    this.onInternUniversityListForSelfEvaluationRowClicked = this.onInternUniversityListForSelfEvaluationRowClicked.bind(
      this
    );
  }

  public onInternUniversityListForSelfEvaluationRowClicked(
    university: string,
    startDate: string
  ) {
    this.props.history.push(
      `/faculty/student_list_self_evaluation/${university}/${startDate}/${this.props.match.params.facultyID}`
    );
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info/intern_university_list?faculty_id=${this.props.match.params.facultyID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          universityList: result,
        });
      })
      .catch(console.error);
  }

  public render() {
    const universityRow = this.state.universityList.map((item, i) => (
      <tr
        key={i}
        onClick={this.onInternUniversityListForSelfEvaluationRowClicked.bind(
          this,
          item.university,
          item.startDate
        )}
      >
        <td>{i + 1}</td>
        <td>{item.university}</td>
        <td>{item.startDate}</td>
        <td>{item.endDate}</td>
        <td>{item.studentNum}</td>
      </tr>
    ));
    return (
      <Container>
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <h2 className="mt-3 mb-3">実習施設一覧 (自己評価)</h2>
            <Table
              id="index_faculty_student_list"
              className="ml-5 mr-5"
              bordered
              hover
            >
              <thead className="thead-dark">
                <tr>
                  <th>#</th>
                  <th>施設名</th>
                  <th>実習開始日</th>
                  <th>実習終了日</th>
                  <th>学生数</th>
                </tr>
              </thead>
              <tbody>{universityRow}</tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(InternUniversityListForSelfEvaluation);
