import React, { Component } from "react";
// import SortableTree, { addNodeUnderParent, removeNodeAtPath } from '../src';
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
} from "react-sortable-tree";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Alert from "@material-ui/lab/Alert";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import { LoadingSpriner } from "../common/CircularSpinner";
import styled from "styled-components";
import "react-sortable-tree/style.css";
import { UniversityList } from "../models/Types";
// In your own app, you would need to use import styles once in the app
// import 'react-sortable-tree/styles.css';

interface Props {
  university: string;
  startDate: string;
  endDate: string;
  isLoading: boolean;
  selfEvaluation: UniversityList;
}
interface State {
  treeData: any;
  isFetchAPI: boolean;
  successMsg: boolean;
  error: boolean;
  addAsFirstChild: any;
}

// export default class SortableTreeComponent Component<Props, State> {

class SortableTreeComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      treeData: [
        {
          name: "",
          expanded: true,
        },
      ],
      addAsFirstChild: false,
      isFetchAPI: false,
      successMsg: false,
      error: false,
    };
  }

  componentDidMount() {
    const { university, startDate, endDate } = this.props;

    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation/settings/fetch-list?univesity_id=${university}&start_date=${startDate}&end_date=${endDate}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        if (result.length > 0) {
          this.setState({
            treeData: result,
          });
        } else {
          this.setState({
            treeData: [
              {
                name: "",
                expanded: true,
              },
            ],
          });
        }
      })
      .catch(console.error);
  }

  componentDidUpdate(nextProps: any) {
    if (this.props.isLoading !== nextProps.isLoading) {
      this.fetchAPI();
    }
  }

  fetchAPI = () => {
    const { university, startDate, endDate } = this.props;
    this.setState({
      isFetchAPI: true,
    });
    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation/settings/fetch-list?univesity_id=${university}&start_date=${startDate}&end_date=${endDate}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          treeData:
            result.length > 0
              ? result
              : [
                  {
                    name: "",
                    expanded: true,
                  },
                ],
          isFetchAPI: false,
        });
      })
      .catch(console.error);
  };

  // Construct flat array from tree of objects
  flatten = (children: any, parent: any) => {
    return Array.prototype.concat.apply(
      children.map((x: any, i: number) => {
        const parentItem = `item_${parent}_${i + 1}`;
        const indexItem = parentItem.padEnd(12, "_0");
        return {
          [indexItem]: x.name,
        };
      }),
      children.map((x: any, i: any) => {
        return this.flatten(
          this.extractChildren(x) || [],
          `${parent}_${i + 1}`
        );
      })
    );
  };

  extractChildren = (x: { children: any }) => x.children;

  onSubmit = () => {
    let result: any = [];
    this.setState({
      isFetchAPI: true,
    });
    this.state.treeData.forEach((item1: any, index: number) => {
      const parentItem = { [`item_${index + 1}_0_0`]: item1.name };
      if (item1?.children) {
        const mappingChildren = this.flatten(
          this.extractChildren(item1),
          index + 1
        )
          .concat(parentItem)
          .map((x) => delete x.children && x)
          .map((x) => delete x.expanded && x);
        result = result.concat(mappingChildren);
      } else {
        result = result.concat(parentItem);
      }
    });

    const { selfEvaluation } = this.props;

    const newData = {
      univesity_id: selfEvaluation.university,
      start_date: selfEvaluation.startDate,
      end_date: selfEvaluation.endDate,
      self_evaluation: Object.assign({}, ...result),
    };

    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation_item/create-or-update`;
    const method = "POST";
    const mode = "cors";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(newData);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.status !== "fail") {
          this.setSuccessMsg();
        } else {
          this.setError();
        }
      })
      .catch(() => {
        this.setError();
      });
  };

  setSuccessMsg = () => {
    this.setState({
      successMsg: true,
      isFetchAPI: false,
    });
    setTimeout(() => {
      this.setState({
        successMsg: false,
      });
    }, 3000);
  };

  setError = () => {
    this.setState({
      error: true,
      isFetchAPI: false,
    });
    setTimeout(() => {
      this.setState({
        error: false,
      });
    }, 3000);
  };

  render() {
    return (
      <div>
        {this.state.isFetchAPI && <LoadingSpriner />}
        <StyledSortableTree
          className="mb-5"
          treeData={this.state.treeData}
          canDrag={false}
          isVirtualized={false}
          canDrop={() => false}
          rowHeight={130}
          onChange={(treeData) => this.setState({ treeData })}
          generateNodeProps={({ node, path }) => {
            let titleNode;
            if (path.length == 1) {
              titleNode = <div>大項目</div>;
            } else if (path.length == 2) {
              titleNode = <div>中項目</div>;
            } else {
              titleNode = <div>小項目</div>;
            }
            return {
              buttons: [
                titleNode,
                <div>
                  {path.length < 3 && (
                    <AddCircleOutlinedIcon
                      color="secondary"
                      fontSize="large"
                      onClick={() => {
                        this.setState((state) => ({
                          treeData: addNodeUnderParent({
                            treeData: state.treeData,
                            parentKey: path[path.length - 1],
                            expandParent: true,
                            getNodeKey: ({ treeIndex }) => treeIndex,
                            newNode: {
                              name: "",
                              expanded: false,
                              parentKey: path[path.length - 1],
                            },
                            addAsFirstChild: state.addAsFirstChild,
                          }).treeData,
                        }));
                      }}
                    />
                  )}
                  <RemoveCircleOutlinedIcon
                    color="primary"
                    fontSize="large"
                    onClick={() =>
                      this.setState((state) => ({
                        treeData: removeNodeAtPath({
                          treeData: state.treeData,
                          path,
                          getNodeKey: ({ treeIndex }) => treeIndex,
                        }),
                      }))
                    }
                  />
                </div>,
              ],
              title: (
                <div>
                  <input
                    value={node.name}
                    onChange={(event) => {
                      const name = event.target.value;

                      this.setState((state) => ({
                        treeData: changeNodeAtPath({
                          treeData: state.treeData,
                          path,
                          getNodeKey: ({ treeIndex }) => treeIndex,
                          newNode: { ...node, name },
                        }),
                      }));
                    }}
                  />
                </div>
              ),
            };
          }}
        />
        {this.state.successMsg && (
          <Alert className="mt-3 mb-3 sw-custom-btn">登録完了しました。</Alert>
        )}
        {this.state.error && (
          <Alert severity="error" className="mt-3 mb-3 sw-custom-btn">
            データの登録に失敗しました。
          </Alert>
        )}

        <Button
          className="ml-3 noPrint"
          variant="primary"
          size="lg"
          type="submit"
          onClick={() =>
            this.setState((state) => ({
              treeData: state.treeData.concat({
                name: "",
              }),
            }))
          }
        >
          <AddCircleOutlinedIcon
            color="secondary"
            fontSize="default"
            // onClick={() =>
            //   this.setState((state) => ({
            //     treeData: state.treeData.concat({
            //       name: "",
            //     }),
            //   }))
            // }
          />
          大項目
        </Button>
        <Button
          className="ml-3 noPrint"
          variant="primary"
          size="lg"
          type="submit"
          onClick={this.onSubmit}
        >
          {this.state.isFetchAPI ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            <span>保存</span>
          )}
        </Button>
      </div>
    );
  }
}

const StyledSortableTree = styled(SortableTree)`
  .rst__rowContents {
    padding: 0 0 0 10px;
    display: grid;
    -webkit-box-shadow: none;
    border: none;
  }

  .rst__rowLabel {
    order: 2;
    padding-right: 0px;

    input {
      width: 800px;
      height: 42px;
      border: 1px solid #bbb;
    }

    input:focus-visible,
    input:hover {
      outline: none;
    }
  }
  .rst__rowToolbar {
    order: 1;
    display: grid;
  }
`;

export default React.memo(SortableTreeComponent);
