import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import NursePlanBasicInfo from "./NursePlanBasicInfo";
import NursePlanList from "./NursePlanList";
import "../../../assets/styles/NursePlan.scss";
import { checkUserId } from "../../../services/AuthService";
import { USER_TYPE } from "../../../services/AppLocalHelper";

interface Props extends RouteComponentProps<{ studentID: string }> { }
interface State {
  studentID: string;
  studentName: string;
  internDate: string;
  inChargeDays: number;
  afterSurgeryDays: number;
  facultyID: string;
}

class NursePlanView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID
    if (!checkUserId(userId, USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }

    this.state = {
      studentID: this.props.match.params.studentID,
      studentName: "",
      internDate: "",
      inChargeDays: 1,
      afterSurgeryDays: 0,
      facultyID: ""
    };

    this.createNursePlan = this.createNursePlan.bind(this);
  }

  public createNursePlan() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const date = `${year}-${month}-${day}`;

    const url: string = `/nurse_plan_create/${this.state.studentID}/${date}/${this.state.facultyID}`;
    this.props.history.push(url);
  }
  public componentDidMount() {
    // fetch facultyID
    const url = `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${this.props.match.params.studentID}`;

    fetch(url)
      .then(res => res.json())
      .then(result => {
        this.setState({
          facultyID: result.facultyID
        });
      })
      .catch(console.error);
  }

  public render() {
    return (
      <Row className="mt-5 mb-5">
        <Col md={2} />
        <Col md={8}>
          <h2 className="clor-lightnavi">看護計画</h2>
          <Row>
            <Col md={6}>
              <NursePlanBasicInfo studentID={this.state.studentID} />
            </Col>
            <Col md={6}>
              <NursePlanList
                studentID={this.props.match.params.studentID}
                facultyID={this.state.facultyID}
              />
              <Button
                className="m-5 bg-lightblue"
                size="lg"

                onClick={this.createNursePlan}
              >
                新規作成
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={2} />
      </Row>
    );
  }
}

export default withRouter(NursePlanView);
