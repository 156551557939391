import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { SelfEvaluationTypeKobeJoshiSyoni } from "../../../models/Types";

interface Props extends RouteComponentProps<{ studentID: string }> {}
interface State extends SelfEvaluationTypeKobeJoshiSyoni {
  studentName: string;
  startDate: string;
  endDate: string;
}

class SelfEvaluationTableKobeJoshiBoseiForFaculty extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      studentID: this.props.match.params.studentID,
      studentName: "",
      startDate: "",
      endDate: "",
      item1_1: "",
      item1_2: "",
      item1_3: "",
      item2_1: "",
      item2_2: "",
      item2_3: "",
      item2_4: "",
      item3_1: "",
      item3_2: "",
      item3_3: "",
      item4_1: "",
      item4_2: "",
      item4_3: "",
      item4_4: "",
      item4_5: "",
      item5_1: "",
      item5_2: "",
      item5_3: "",
      item6_1: "",
      item6_2: "",
      item6_3: "",
      item7_1: "",
      item7_2: "",
      item7_3: "",
      item8_1: "",
      item8_2: "",
      item8_3: "",
      item8_4: "",
    };

    this.onClickCancel = this.onClickCancel.bind(this);
  }

  public onClickCancel() {
    this.props.history.goBack();
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation/kobe_joshi/syoni/fetch?student_id=${this.state.studentID}`;

    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          item1_1: result.item1_1,
          item1_2: result.item1_2,
          item1_3: result.item1_3,
          item2_1: result.item2_1,
          item2_2: result.item2_2,
          item2_3: result.item2_3,
          item2_4: result.item2_4,
          item3_1: result.item3_1,
          item3_2: result.item3_2,
          item3_3: result.item3_3,
          item4_1: result.item4_1,
          item4_2: result.item4_2,
          item4_3: result.item4_3,
          item4_4: result.item4_4,
          item4_5: result.item4_5,
          item5_1: result.item5_1,
          item5_2: result.item5_2,
          item5_3: result.item5_3,
          item6_1: result.item6_1,
          item6_2: result.item6_2,
          item6_3: result.item6_3,
          item7_1: result.item7_1,
          item7_2: result.item7_2,
          item7_3: result.item7_3,
          item8_1: result.item8_1,
          item8_2: result.item8_2,
          item8_3: result.item8_3,
          item8_4: result.item8_4,
        });
      })
      .catch(console.error);

    const url2 = `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${this.state.studentID}`;

    fetch(url2)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          studentName: result.studentName,
          startDate: result.startDate,
          endDate: result.endDate,
        });
      })
      .catch(console.error);
  }

  public render() {
    return (
      <Container>
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <h2 className="mb-3">自己評価表 (神戸女子大学 小児実習)</h2>
            <Table
              id="self_evaluation_kansai_kokusai_tougou_student_info"
              bordered
            >
              <tbody>
                <tr>
                  <td className="text-left">
                    学生番号 &nbsp; {this.state.studentID}
                    <br />
                    学生氏名 &nbsp; {this.state.studentName}
                  </td>
                  <td className="text-left">
                    実習場所
                    <br />
                    神戸大学医学部付属病院 Web病棟
                  </td>
                </tr>
                <tr>
                  <td className="text-left">
                    実習期間
                    <br />
                    {this.state.startDate} ~ {this.state.endDate}
                  </td>
                  <td className="text-left">
                    出席状況 -/-日
                    <br />
                    遅刻・早退時間数 -時間
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table id="self_evaluation_kobe_joshi" bordered>
              <thead className="thead-dark">
                <tr>
                  <th>実習目標</th>
                  <th colSpan={2}>&emsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="goal_big">
                    １．子どもを全人的に理解することができる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td className="goal_small">
                        １）子どもの育ってきた過程を踏まえ、成長発達段階（運動機能・生理的・精神的・心理的・社会的）、基本的生活習慣の獲得状況を把握できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ２）子どもの身体状況をアセスメントし、病態および治療の状況、今後の起こりうる問題について理解する。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ３）子どもの生活習慣（入院前、後）に関する情報を得て、社会や家族の中での位置づけや役割を理解できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ２．健康逸脱や療養生活が子どもとその家族におよぼす影響を理解することができる。
                  </td>
                  <td className="goal_small p-0">
                    <tr>
                      <td className="goal_small">
                        １）疾患および治療が子どもにおよぼしている身体的・精神的・社会的影響が理解できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ２）生活環境の変化が子どもや家族におよぼす影響とその要因について理解できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ３）子どもおよび家族がもつ身体的・精神的・社会的問題を明らかにすることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ４）病気や障害をもち生活する子どもの体験を理解できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_4 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_4 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_4 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_4 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ３．子どもが体験している療養生活について理解し、援助することができる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td className="goal_small">
                        １）子どもの健康逸脱に応じた、感染予防の必要性を理解し、援助できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ２）子どもの成長発達・健康逸脱・行動制限に応じた、事故防止の必要性を理解し、援助できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ３）子どもの発達段階および健康逸脱に応じた療養環境についてアセスメントし、必要な援助ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ４．子どもとその家族に対して看護過程を展開することができる。
                  </td>
                  <td className="goal_small p-0">
                    <tr>
                      <td className="goal_small">
                        １）子どもの成長発達、健康逸脱、療養環境などを総合的にアセスメントできる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ２）子どもと家族の全体像を把握し、優先順位を考え看護問題を抽出できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ３）子どもと家族に必要な看護について、優先順位を考慮し、計画立案できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>４）立案した看護計画に基づき援助を実施できる。</td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_4 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_4 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_4 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_4 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ５）実施した援助について評価し、計画の修正ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_5 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_5 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_5 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_5 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ５．小児医療の中での看護師の役割が理解できる。
                  </td>
                  <td className="goal_small p-0">
                    <tr>
                      <td className="goal_small">
                        １）子どもや家族のニーズに合わせた継続的な看護が病棟・外来・地域間でどのように連携し、提供されるべきかについて考えることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item5_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item5_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item5_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item5_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ２）他職種と連携・協働する中で看護の役割について考えることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item5_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item5_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item5_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item5_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item5_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ３）看護チームの中での学生の役割と責任を認識し、報告・連絡・相談を実践できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item5_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item5_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item5_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item5_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item5_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ６．子どもやその家族と援助的人間関係を築くことができる。
                  </td>
                  <td className="goal_small p-0">
                    <tr>
                      <td className="goal_small">
                        １）子どもと家族に対して、相互に理解を深め、援助関係を築く。
                      </td>
                      <td>
                        <Form.Check
                          name="item6_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item6_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item6_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item6_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item6_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ２）援助関係を築き、発展させていく援助者として責任を学ぶ。
                      </td>
                      <td>
                        <Form.Check
                          name="item6_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item6_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item6_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item6_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item6_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ３）援助関係を振り返り、自己理解を深めるとともに、自己の課題を追求する。
                      </td>
                      <td>
                        <Form.Check
                          name="item6_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item6_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item6_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item6_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item6_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ７．看護職として倫理的に判断・行動するための基本的能力を習得することができる。
                  </td>
                  <td className="goal_small p-0">
                    <tr>
                      <td className="goal_small">
                        １）病院において子どもや家族が脅かされやすい状況や条件について理解し、何が倫理的な問題なのかを分析できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item7_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item7_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item7_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item7_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item7_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ２）子どもの尊厳や権利を守るために、周囲の人の力を借りて学生として最善の方法で実践できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item7_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item7_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item7_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item7_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item7_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ３）より良い看護を行うために自分自身の行動や態度を倫理的観点から振り返ることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item7_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item7_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item7_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item7_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item7_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">８．実習参加度</td>
                  <td className="goal_small p-0">
                    <tr>
                      <td className="goal_small">１）意欲、問題解決への努力</td>
                      <td>
                        <Form.Check
                          name="item8_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item8_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item8_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item8_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item8_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>２）カンファレンスでの発言</td>
                      <td>
                        <Form.Check
                          name="item8_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item8_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item8_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item8_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item8_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>３）リーダーシップ・フォロアーシップ</td>
                      <td>
                        <Form.Check
                          name="item8_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item8_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item8_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item8_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item8_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>４）身だしなみ（服装、髪形など）・礼節・言葉遣い</td>
                      <td>
                        <Form.Check
                          name="item8_4"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item8_4 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_4"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item8_4 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_4"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item8_4 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_4"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item8_4 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-4">
          <Button
            className="ml-3"
            variant="outline-secondary"
            size="lg"
            type="submit"
            onClick={this.onClickCancel}
          >
            Back
          </Button>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SelfEvaluationTableKobeJoshiBoseiForFaculty);
