import React from "react";
import Table from "react-bootstrap/Table";
import { StudentInfo } from "../models/Types";

interface Props {
  studentID: string;
}
interface State {
  studentInfo: StudentInfo;
}

class LoginStudentBasicInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      studentInfo: {
        studentID: "",
        studentName: "",
        university: "",
        internPlace: "",
        startDate: "",
        endDate: "",
        internDay: 0,
        nurseName: "",
        patientInfos:"",
        overviewMaxDay: ""
      }
    };
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${this.props.studentID}`;
    fetch(url)
      .then(res => res.json())
      .then(result => {
        this.setState({ studentInfo: result });
      })
      .catch(console.error);
  }

  public render() {
    return (
      <Table id="index_student" className="m-5" bordered>
        <tbody>
          <tr>
            <td className="left">学籍番号</td>
            <td className="right">{this.state.studentInfo.studentID}</td>
          </tr>
          <tr>
            <td>氏名</td>
            <td>{this.state.studentInfo.studentName}</td>
          </tr>
          <tr>
            <td>所属施設</td>
            <td>{this.state.studentInfo.university}</td>
          </tr>
          <tr>
            <td>実習場所</td>
            <td>{this.state.studentInfo.internPlace}</td>
          </tr>
          <tr>
            <td>担当患者</td>
            <td>{this.state.studentInfo.patientInfos}</td>
          </tr>
          <tr>
            <td>患者の入院日数</td>
            <td>{this.state.studentInfo.overviewMaxDay}</td>
          </tr>
          <tr>
            <td>実習期間</td>
            <td>
              {this.state.studentInfo.startDate}〜
              {this.state.studentInfo.endDate}
            </td>
          </tr>
          <tr>
            <td>実習日</td>
            <td>{this.state.studentInfo.internDay}日目</td>
          </tr>
          <tr>
            <td>担当看護師</td>
            <td>{this.state.studentInfo.nurseName}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default LoginStudentBasicInfo;
