import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Alert from "@material-ui/lab/Alert";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import CheckIcon from "@material-ui/icons/Check";
import { LoadingSpriner } from "../../common/CircularSpinner";
import { DatePickerInline } from "../../common/TimePickerComponent";
import CSVReaderImport from "../../common/CSVReaderImport";
import Link from "@material-ui/core/Link";
import { getUserId, USER_TYPE } from "../../../services/AppLocalHelper";
import { checkUserId } from "../../../services/AuthService";
import { useLocation } from "react-router-dom";

interface Props
  extends RouteComponentProps<{
    university: string;
    startDate: string;
    endDate: string;
  }> { }

interface State {
  studentList: {
    studentID: string;
    studentName: string;
    university_name: string;
    university: string;
    startDate: string;
    endDate: string;
    internDays: number;
    id: string;
  }[];
  teacherList: {
    facultyID: string;
    facultyName: string;
    universityName: string;
    affiliation: string;
    isSupervisor: boolean;
  }[];
  universitys: {
    id: number;
    university_id: string;
    university_name: string;
  }[];
  textMessage: {
    errorMsg: string;
    successMsg: string;
  };
  isChecked: string;
  isSearching: boolean;
  isLoading: boolean;
  universitySelected: string;
  startDate: string | null;
  endDate: string | null;
  isImporting: boolean;
  isFacultySupervisor: boolean;
  affiliationFaculty: string;
  userId: string;
}

const ACTION = {
  students: "students",
  teachers: "teachers",
};

/**
 * @author: HKB
 * 利用者一覧
 */
class UserList extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    let userId = getUserId()
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }
    console.log('location:', this.props.location.state)

    this.state = {
      studentList: [],
      teacherList: [],
      universitys: [],
      textMessage: {
        errorMsg: "",
        successMsg: "",
      },
      isChecked: ACTION.students,
      startDate: null,
      endDate: null,
      universitySelected: "",
      isSearching: false,
      isLoading: false,
      isImporting: false,
      isFacultySupervisor: false,
      affiliationFaculty: "",
      userId: userId
    };

    this.onSearch = this.onSearch.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.fetchStudent = this.fetchStudent.bind(this);
    this.onSelectUniversity = this.onSelectUniversity.bind(this);

  

  }

  async componentDidMount() {
    await this.initialData();
    let state = this.props.location.state as any
    console.log('state:', state)
    if (state !== undefined && state.success) {
      this.setMessage("successMsg", "更新しました。");
      this.props.history.replace({ state: {} })
    }

    if (state !== undefined && state.success === false) {
      this.setMessage("errorMsg", "データの登録に失敗しました。");
      this.props.history.replace({ state: {} })
    }
  }

  goToUpdateFaucuty(fauctlyID: string) {
    this.props.history.push(`/faculty/settings/update_fauctly/${fauctlyID}`);

  }
  goToUpdateStudent(studentID: string) {
    this.props.history.push(`/faculty/settings/update_student/${studentID}`);
  }

  async initialData() {
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${this.state.userId}`;
    const response1 = await fetch(url);
    let result = await response1.json();
    this.setState({isFacultySupervisor : result.is_supervisor, affiliationFaculty: result.affiliation_id});
    // fetch(url)
    //   .then((res) => res.json())
    //   .then((result) => {
    //       console.log('getFaculty', result);
     
    //       this.setState({isFacultySupervisor : result.is_supervisor, affiliationFaculty: result.affiliation_id});
    //   })
    //   .catch(console.error);
    this.setState({
      isLoading: true,
    });
    // get university
    const url2 = `${process.env.REACT_APP_API_URL}/api/signup/get_university`;
    const response = await fetch(url2);
    let universitys = await response.json();
    let filter;
    if (universitys && universitys[0]) {
      const affiliationFaculty  = this.state.affiliationFaculty;
      if(!this.state.isFacultySupervisor){
        universitys = universitys.filter(function (el: any) {
          return el.university_id == affiliationFaculty;
        });
      }
      if(universitys != undefined){
        this.setState({
          universitys: universitys,
          universitySelected: universitys[0].university_id,
        });
        filter = `university=${universitys[0].university_id}`;
      }
    }

    await this.fetchStudent(filter);
    await this.fetchTeacher(filter);
  }
  public getFaculty(facultyID: string){
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${facultyID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
          console.log('getFaculty', result);
     
          this.setState({isFacultySupervisor : result.is_supervisor, affiliationFaculty: result.affiliation_id});
      })
      .catch(console.error);
}
  setMessage = (field: string, message: string) => {
    console.log('message:', message)
    this.setState({
      textMessage: { ...this.state.textMessage, [field]: message },
    });
    setTimeout(() => {
      this.setState({
        textMessage: {
          ...this.state.textMessage,
          [field]: "",
        },
      });
    }, 3000);
  };

  onDelete(itemID: string) {
    const confirmDelete = window.confirm("削除してもよろしいですか？");
    let url = `${process.env.REACT_APP_API_URL}/api/student_info/delete`;
    let param = {
      studentID: itemID,
    };
    if (this.state.isChecked === ACTION.teachers) {
      url = `${process.env.REACT_APP_API_URL}/api/faculty_info/delete`;
      param = {
        facultyID: itemID,
      } as any;
    }
    const method = "DELETE";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(param);
    if (confirmDelete) {
      fetch(url, { method, mode, headers, body })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            this.setMessage("successMsg", "削除しました。");
            if (this.state.isChecked === ACTION.students) {
              this.setState({
                studentList: this.state.studentList.filter(
                  (student) => student.studentID !== itemID
                ),
              });
            } else {
              this.setState({
                teacherList: this.state.teacherList.filter(
                  (teacher) => teacher.facultyID !== itemID
                ),
              });
            }
          }
        })
        .catch(console.error);
      return true;
    } else {
      return false;
    }
  }

  onHandleCSV = (data: any, fileInfo: any) => {
    console.log('data:', data)
    if (fileInfo.name) {
      const regex = /^.+\.(csv)$/g;
      const checkType = regex.test(fileInfo.name);
      if (checkType) {
        this.setState({
          isLoading: true,
        });
        this.fetchAPI(data);
      } else {
        this.setMessage("errorMsg", "データの登録に失敗しました。");
      }
    }
  };

  fetchAPI = (data: any) => {
    this.setState({
      isImporting: true,
    });
    const url = this.state.isChecked == ACTION.students ? `${process.env.REACT_APP_API_URL}/api/student_info/import` : `${process.env.REACT_APP_API_URL}/api/faculty_info/import`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    // const body = { data }
    const body = JSON.stringify({ data });
    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          this.setMessage("successMsg", "データ自動更新しました。");
          this.onSearch();
        } else {
          this.setMessage("errorMsg", "データの登録に失敗しました。");
        }

        this.setState({
          isImporting: false,
        });
        this.fetchUniversity();
      })
      .catch((error) => {
        this.setMessage("errorMsg", "データの登録に失敗しました。");
        console.log(error);
        this.setState({
          isImporting: false,
        });
      });
  };

  onSearch() {
    const { isChecked, universitySelected, startDate, endDate } = this.state;
    let filter = `university=${universitySelected}`;
    if (isChecked === ACTION.students) {
      if (endDate && !startDate) {
        this.setMessage("errorMsg", "開始日を空白のままにすることはできません");
      } else if (startDate && endDate && moment(startDate).isAfter(endDate)) {
        this.setMessage(
          "errorMsg",
          "開始日を終了日より大きくすることはできません"
        );
      } else {
        if (startDate) {
          filter = `${filter}&start_date=${moment(startDate).format(
            "YYYY/MM/DD"
          )}`;
        }
        if (startDate && endDate) {
          filter = `${filter}&end_date=${moment(endDate).format("YYYY/MM/DD")}`;
        }
        this.fetchStudent(filter);
      }
    } else {
      this.fetchTeacher(filter);
    }
  }
  fetchUniversity() {
    const url = `${process.env.REACT_APP_API_URL}/api/signup/get_university`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        console.log('fetchUniversity:',result);
        
        this.setState({
          universitys: result
        });
      })
      .catch(() => {
       
      });
  }
  fetchStudent(filter: string | undefined) {
    this.setState({
      isSearching: true,
      isLoading: true,
    });
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info/student_list?${filter}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          studentList: result,
          isSearching: false,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isSearching: false,
          isLoading: false,
        });
      });
  }

  fetchTeacher(filter: string | undefined) {
    this.setState({
      isSearching: true,
      isLoading: true,
    });
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info/list?${filter}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          teacherList: result,
          isSearching: false,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isSearching: false,
          isLoading: false,
        });
      });
  }

  onSelectUniversity(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      universitySelected: e.target.value,
    });
  }

  render() {
    const students = this.state.studentList.map((item, i) => {
      if (item.startDate === null) {
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{item.studentName}</td>
            <td>{item.university_name}</td>
            <td>no data</td>
            <td>no data</td>
          </tr>
        );
      } else {
        const startDate = item.startDate.split("-");
        const endDate = item.endDate.split("-");
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{item.studentID}</td>
            <td>{item.studentName}</td>
            <td>{item.university_name}</td>
            <td>{`${startDate[0]}年${startDate[1]}月${startDate[2]}日`}</td>
            <td>{`${endDate[0]}年${endDate[1]}月${endDate[2]}日`}</td>
            <td>{item.internDays}日目</td>
            <td>
              <Button variant="primary"
                onClick={() => this.goToUpdateStudent(item.id)}
              >編集</Button>

            </td>
            { this.state.isFacultySupervisor &&  <td>
              <Button
                onClick={() => this.onDelete(item.studentID)}
                variant="danger"
              >
                削除
              </Button>
            </td>} 
          </tr>
        );
      }
    });

    const teachers = this.state.teacherList.map((item, i) => {
      return (
        <tr key={i}>
          <td>{i + 1}</td>
          <td>{item.facultyName}</td>
          <td>{item.facultyID}</td>
          <td>{item.universityName}</td>
          <td>{item.isSupervisor ? <CheckIcon /> : ""} </td>
          <td>
            <Button variant="primary"
              onClick={() => this.goToUpdateFaucuty(item.facultyID)}
            >編集</Button>

          </td>
          { this.state.isFacultySupervisor &&     <td>
            <Button
              onClick={() => this.onDelete(item.facultyID)}
              variant="danger"
            >
              削除
            </Button>
          </td>}
        </tr>
      );
    });

    return (
      <div className="m-5">
        <h2>利用者一覧</h2>

        <StyledRow className="mt-5">
          <Col md={3}></Col>
          <Col md={6}>
            <Form.Check
              name="userchecked"
              inline
              type="radio"
              label="学生"
              value={ACTION.students}
              defaultChecked={true}
              onChange={() => this.setState({ isChecked: ACTION.students })}
            />
            <Form.Check
              name="userchecked"
              inline
              type="radio"
              label="教員"
              value={ACTION.teachers}
              onChange={() => this.setState({ isChecked: ACTION.teachers })}
            />
          </Col>
          {this.state.isFacultySupervisor && <Col md={3} className="d-flex">
            <Button
              onClick={() =>
                this.state.isChecked === ACTION.students
                  ? this.props.history.push("/signup/student")
                  : this.props.history.push("/signup/faculty")
              }
              variant="primary"
            >
              新規作成
            </Button>
          </Col>
         }
        </StyledRow>
        <StyledRow className="mt-3 d-flex">
          <Col
            md={12}
            className="d-flex justify-content-center align-items-baseline"
          >
            <Form.Label className="font-weight-bold pr-3">所属施設</Form.Label>
            <Form.Control
              className="mr-5"
              as="select"
              style={{ width: "180px" }}
              onChange={this.onSelectUniversity}
            >
              {this.state.universitys.map((university, i) => (
                <option key={i} value={university.university_id}>
                  {university.university_name}
                </option>
              ))}
            </Form.Control>
            {this.state.isChecked === ACTION.students && (
              <>
                <Form.Label className="font-weight-bold pr-3">
                  実習期間
                </Form.Label>
                <DatePickerInline
                  className="pr-2"
                  value={this.state.startDate || null}
                  onChange={(time: any) =>
                    this.setState({
                      startDate: time,
                    })
                  }
                />
                <Form.Label className="font-weight-bold pr-2">～</Form.Label>
                <DatePickerInline
                  className="pr-5"
                  value={this.state.endDate || null}
                  onChange={(time: any) =>
                    this.setState({
                      endDate: time,
                    })
                  }
                />
              </>
            )}
            <Button onClick={this.onSearch} variant="primary">
              {this.state.isSearching ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </>
              ) : (
                <span>反映</span>
              )}
            </Button>
        { this.state.isFacultySupervisor && <>
          { this.state.isImporting ? (
              <>
                <Button variant="primary"
                  className="ml-5"
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>

              </>
            ) : (
              <CSVReaderImport
                handleForce={this.onHandleCSV}
                className="react-csv-input pl-5 ml-5"
              />
            )}
            <Link
              href={this.state.isChecked === ACTION.students
                ? `${process.env.REACT_APP_API_URL}/api/patient_info/csv-sample-download?fileName=学生入力 - サンプル.csv`
                : `${process.env.REACT_APP_API_URL}/api/patient_info/csv-sample-download?fileName=教員入力.csv`
              }
              style={{
                cursor: "pointer",
                color: "#007bff",
                textDecoration: "none",
                marginLeft: "20px",
                marginTop: "10px",
              }}
              color="primary"
            >
              CSVテンプレート
            </Link>
         </>
  }
         
          </Col>
          <Col md={5} style={{ margin: "auto" }}>
            {this.state.textMessage.errorMsg && (
              <Alert className="mt-3" variant="filled" severity="error">
                {this.state.textMessage.errorMsg}
              </Alert>
            )}
            {this.state.textMessage.successMsg && (
              <Alert className="mt-3" variant="filled">
                {this.state.textMessage.successMsg}
              </Alert>
            )}
          </Col>
        </StyledRow>
        <Row className="mt-5 d-flex flex-column align-items-center">
          {this.state.isChecked === ACTION.students ? (
            <Col md={10}>
              <StyledTable>
                <h2 className="mb-5">学生一覧</h2>
                <Table bordered hover>
                  <thead className="thead-dark">
                    <tr>
                      <th>#</th>
                      <th>学籍番号</th>
                      <th>学生氏名</th>
                      <th>所属施設</th>
                      <th>実習開始日</th>
                      <th>実習終了日</th>
                      <th>実習日</th>
                      <th></th>
                      { this.state.isFacultySupervisor &&  <th></th> }
                    </tr>
                  </thead>
                  <tbody>
                    {students.length > 0 ? (
                      students
                    ) : (
                      <tr>
                        <td colSpan={9}>
                          {this.state.isLoading ? (
                            <LoadingSpriner />
                          ) : (
                            "No Data"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </StyledTable>
            </Col>
          ) : (
            <Col md={9}>
              <StyledTable>
                <h2 className="mb-5">教員一覧</h2>
                <Table bordered hover>
                  <thead className="thead-dark">
                    <tr>
                      <th>#</th>
                      <th>教員氏名</th>
                      <th>教員</th>
                      <th>所属</th>
                      <th style={{ width: "8%" }}>実習担当</th>
                      <th></th>
                      { this.state.isFacultySupervisor &&  <th></th> }
                    </tr>
                  </thead>
                  <tbody>
                    {teachers.length > 0 ? (
                      teachers
                    ) : (
                      <tr>
                        <td colSpan={9}>
                          {this.state.isLoading ? (
                            <LoadingSpriner />
                          ) : (
                            "No Data"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </StyledTable>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

const StyledRow = styled(Row)`
  input[type="radio"] {
    transform: scale(1.5);
    margin-right: 10px;
  }
  .form-check {
    margin-right: 20px;
  }
`;

const StyledTable = styled.div``;

export default withRouter(UserList);
