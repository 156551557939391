import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import SignUpBox from "../login/SignUpBox";
import "../../assets/styles/IndexFaculty.scss";
import { checkUserId } from "../../services/AuthService";
import { USER_TYPE } from "../../services/AppLocalHelper";

interface Props extends RouteComponentProps<{ facultyID: string }> { }
interface State {
  isSupervisor: boolean;
  affiliation: string;
  facultyID: string;
}

class SettingMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.facultyID
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }

    this.signUpStudent = this.signUpStudent.bind(this);
    this.signUpFaculty = this.signUpFaculty.bind(this);
    this.goToInternUniversityList = this.goToInternUniversityList.bind(this);
    this.goToALlHospitalRoom = this.goToALlHospitalRoom.bind(this);
  }
  public goToALlHospitalRoom() {
    this.props.history.push(`/all_hospital_rooms/supervisor-faculty?facultyID=${this.props.match.params.facultyID}&type=setting`);
  }
  public signUpStudent() {
    this.props.history.push("/signup/student");
  }

  public signUpFaculty() {
    this.props.history.push("/signup/faculty");
  }

  public goToInternUniversityList(type: String) {
    this.props.history.push(`/faculty/intern_university_list/${this.props.match.params.facultyID}?type=${type}`);
  }

  public render() {
    return (
      <Container className="mt-5 mb-5">
        <h2>設定</h2>
        <Row>
          <Col md={12}>
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-info"
              onClick={this.goToALlHospitalRoom}
            >
              患者管理
            </Button>
            {/* HKB 4/5/2021 */}
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-success"
              onClick={() =>
                this.props.history.push("/faculty/settings/manager-overviews")
              }
            >
              オーバービュー種別設定
            </Button>
            {/* <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-danger"
              onClick={this.signUpStudent}
            >
              学生
            </Button>
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-danger"
              onClick={this.signUpFaculty}
            >
              教員
            </Button> */}
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-primary"
              onClick={() =>
                this.props.history.push("/faculty/settings/test_data_items")
              }
            >
              検査データ項目設定
            </Button>
			<Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-danger"
              onClick={() =>
                this.props.history.push("/faculty/settings/user_list")
              }
            >
              利用者管理
            </Button>
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-warning"
              onClick={() => this.goToInternUniversityList('setting')}
            >
              実習グループ
              <br />管理
            </Button>
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-dark"
              onClick={() =>
                this.props.history.push("/faculty/settings/link_infos")
              }
            >
              リンク設定
            </Button>

          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SettingMenu);
