import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import IndexStudentBasicInfo from "../IndexStudentBasicInfo";
import ActionPlanRowForFaculty from "./ActionPlanRowForFaculty";
import NursingActivityRowForFaculty from "./NursingActivityRowForFaculty";
import "../../../assets/styles/NursingPlanForFaculty.scss";
import { NursingPlan, NursingPlanFacultyComment } from "../../models/Types";
import { checkUserId } from "../../../services/AuthService";
import { USER_TYPE } from "../../../services/AppLocalHelper";

interface Props
  extends RouteComponentProps<{
    facultyID: string;
    studentID: string;
    date: string;
  }> {}
interface State extends NursingPlan, NursingPlanFacultyComment {}

class NursePlanInputsForFaculty extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.facultyID
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }
    
    this.state = {
      facultyID: this.props.match.params.facultyID,
      nursingGoalComment: "",
      nursingProblemComment: "",
      actionPlanComment: "",
      activityComment: "",
      reviewComment: "",
      overallComment: "",
      studentID: this.props.match.params.studentID,
      studentName: "",
      patientInfos: "",
      overviewMaxDay: "",
      trainingDays: "",
      createdAt: "",
      updatedAt: "",
      date: this.props.match.params.date,
      nursingGoal: "",
      nursingProblem: "",
      actionPlans: [
        {
          index: 0,
          startHour: undefined,
          startMinute: undefined,
          endHour: undefined,
          endMinute: undefined,
          plan: undefined,
        },
      ],
      nursingActivity: [
        {
          index: 0,
          activity: undefined,
          result: undefined,
          evaluation: undefined,
        },
      ],
      review: "",
    };

    this.onNursingGoalCommentChanged = this.onNursingGoalCommentChanged.bind(
      this
    );
    this.onNursingProblemCommentChanged = this.onNursingProblemCommentChanged.bind(
      this
    );
    this.onActionPlanCommentChanged = this.onActionPlanCommentChanged.bind(
      this
    );
    this.onNursingActivityCommentChanged = this.onNursingActivityCommentChanged.bind(
      this
    );
    this.onReviewCommentChanged = this.onReviewCommentChanged.bind(this);
    this.onOverallCommentChanged = this.onOverallCommentChanged.bind(this);
    this.onSubmitNursingPlanComment = this.onSubmitNursingPlanComment.bind(
      this
    );
    this.onClickCancel = this.onClickCancel.bind(this);
  }

  public onNursingGoalCommentChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const nursingGoalComment: string = e.target.value;
    this.setState({ nursingGoalComment: nursingGoalComment });
  }
  public onNursingProblemCommentChanged(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const nursingProblemComment: string = e.target.value;
    this.setState({ nursingProblemComment: nursingProblemComment });
  }

  public onActionPlanCommentChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const actionPlanComment: string = e.target.value;
    this.setState({ actionPlanComment: actionPlanComment });
  }

  public onNursingActivityCommentChanged(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const newActivityComment: string = e.target.value;
    this.setState({ activityComment: newActivityComment });
  }

  public onReviewCommentChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const reviewComment: string = e.target.value;
    this.setState({ reviewComment: reviewComment });
  }

  public onOverallCommentChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const overallComment: string = e.target.value;
    this.setState({ overallComment: overallComment });
  }

  public onSubmitNursingPlanComment(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    console.log(this.state.activityComment);
    const newNursingPlanComment = {
      nursing_plan_faculty_comment: {
        faculty_id: this.state.facultyID,
        student_id: this.state.studentID,
        date: this.state.date,
        nursing_goal_comment: this.state.nursingGoalComment,
        nursing_problem_comment: this.state.nursingProblemComment,
        action_plan_comment: this.state.actionPlanComment,
        activity_comment: this.state.activityComment,
        review_comment: this.state.reviewComment,
        overall_comment: this.state.overallComment,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info/nursing_plan_comment/save`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(newNursingPlanComment);

    fetch(url, { method, headers, body })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.status === "success") {
          this.props.history.goBack();
        } else {
          console.log("API Error");
        }
      })
      .catch(console.error);
  }

  public componentDidMount() {
    let student_ID = this.props.match.params.studentID.trim()
    const url = `${process.env.REACT_APP_API_URL}/api/nursing_plan/fetch_data?student_id=${student_ID}&date=${this.props.match.params.date}`;

    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          studentName: result.student_name,
          createdAt: result.created_at,
          updatedAt: result.updated_at,
          nursingGoal: result.nursing_goal,
          nursingProblem: result.nursing_problem,
          actionPlans: result.action_plans,
          nursingActivity: result.nursing_activity,
          review: result.review,
        });
      })
      .catch(console.error);

    const url2 = `${process.env.REACT_APP_API_URL}/api/faculty_info/nursing_plan_comment/fetch?faculty_id=${this.state.facultyID}&student_id=${student_ID}&date=${this.state.date}`;

    fetch(url2)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          nursingGoalComment: result.nursingGoalComment,
          nursingProblemComment: result.nursingProblemComment,
          actionPlanComment: result.actionPlanComment,
          activityComment: result.activityComment,
          reviewComment: result.reviewComment,
          overallComment: result.overallComment,
        });
      })
      .catch(console.error);
  }

  public onClickCancel() {
    this.props.history.goBack();
  }

  public render() {
    const ActionPlans = this.state.actionPlans.map((item, i) => (
      <ActionPlanRowForFaculty
        key={i}
        index={item.index}
        startHour={item.startHour}
        startMinute={item.startMinute}
        endHour={item.endHour}
        endMinute={item.endMinute}
        plan={item.plan}
      />
    ));

    const NursingActivitys = this.state.nursingActivity.map((item, i) => (
      <NursingActivityRowForFaculty
        key={i}
        activity={item.activity}
        // result={item.result}
        evaluation={item.evaluation}
      />
    ));

    return (
      <Row>
        <Col md={2} />
        <Col md={8}>
          <h2>看護計画</h2>
          <div>
            <IndexStudentBasicInfo
              studentID={this.props.match.params.studentID}
            />
            <Table id="nursing_plan_basic_info" className="m-5" bordered>
              <thead className="thead-dark">
                <tr>
                  <th>学籍番号</th>
                  <th>学生氏名</th>
                  <th>学生作成日時</th>
                  <th>学生更新日時</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.props.match.params.studentID}</td>
                  <td>{this.state.studentName}</td>
                  <td>{this.state.createdAt}</td>
                  <td>{this.state.updatedAt}</td>
                </tr>
              </tbody>
            </Table>
            <Form className="nurse_plan m-5 text-left">
              <div className="mb-3">
                <h3 className="mb-3">1. 今日の看護目標</h3>
                <pre className="student_input text-muted p-3 mb-4">
                  {this.state.nursingGoal}
                </pre>
              </div>
              <Form.Group className="mb-5">
                <Form.Label>
                  <h5>コメント</h5>
                </Form.Label>
                <Form.Control
                  className="textarea_large"
                  as="textarea"
                  rows={5}
                  defaultValue={this.state.nursingGoalComment}
                  onChange={this.onNursingGoalCommentChanged}
                />
              </Form.Group>
              <div className="mb-3">
                <h3 className="mb-3">2. 看護問題</h3>
                <pre className="student_input text-muted p-3 mb-4">
                  {this.state.nursingProblem}
                </pre>
              </div>
              <Form.Group>
                <Form.Label>
                  <h5>コメント</h5>
                </Form.Label>
                <Form.Control
                  className="textarea_large"
                  as="textarea"
                  rows={5}
                  defaultValue={this.state.nursingProblemComment}
                  onChange={this.onNursingProblemCommentChanged}
                />
              </Form.Group>
              <h3 className="mt-5 mb-3">3. 行動計画</h3>
              <div className="p-3 student_input text-muted">{ActionPlans}</div>
              <Form.Group className="mt-3 mb-5">
                <Form.Label>
                  <h5>コメント</h5>
                </Form.Label>
                <Form.Control
                  className="textarea_large"
                  as="textarea"
                  rows={5}
                  defaultValue={this.state.actionPlanComment}
                  onChange={this.onActionPlanCommentChanged}
                />
              </Form.Group>
              <Form.Group className="mt-5 mb-3">
                <Form.Label className="mb-3">
                  <h3>4. 実施する看護活動と評価</h3>
                </Form.Label>
                <Row>
                  <Col>
                    <h5 className="text-center">実施する看護活動</h5>
                  </Col>
                  {/* <Col>
                    <h5 className="text-center">結果</h5>
                  </Col> */}
                  <Col>
                    <h5 className="text-center">評価</h5>
                  </Col>
                </Row>
                {NursingActivitys}
              </Form.Group>
              <Form.Group className="mb-5">
                <Form.Label>
                  <h5>コメント</h5>
                </Form.Label>
                <Form.Control
                  className="textarea_large"
                  as="textarea"
                  rows={5}
                  defaultValue={this.state.activityComment}
                  onChange={this.onNursingActivityCommentChanged}
                />
              </Form.Group>
              <div className="mb-3">
                <h3 className="mb-3">5. 今日の振り返り</h3>
                <pre className="student_input text-muted p-3">
                  {this.state.review}
                </pre>
              </div>
              <Form.Group className="mb-5">
                <Form.Label>
                  <h5>コメント</h5>
                </Form.Label>
                <Form.Control
                  className="textarea_large"
                  as="textarea"
                  rows={5}
                  defaultValue={this.state.reviewComment}
                  onChange={this.onReviewCommentChanged}
                />
              </Form.Group>
              <Form.Group className="mb-5">
                <Form.Label>
                  <h5>総合コメント</h5>
                </Form.Label>
                <Form.Control
                  className="textarea_large"
                  as="textarea"
                  rows={5}
                  defaultValue={this.state.overallComment}
                  onChange={this.onOverallCommentChanged}
                />
              </Form.Group>
              <Button
                variant="primary"
                size="lg"
                type="submit"
                onClick={this.onSubmitNursingPlanComment}
              >
                保存
              </Button>
              <Button
                className="ml-3"
                variant="outline-secondary"
                size="lg"
                type="button"
                onClick={this.onClickCancel}
              >
                戻る
              </Button>
            </Form>
          </div>
        </Col>
        <Col md={2} />
      </Row>
    );
  }
}

export default withRouter(NursePlanInputsForFaculty);
