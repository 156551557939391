import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { NursingDiagnosisPatientType } from "../models/Types";
import { ButtonSpriner, LoadingSpriner } from "../common/CircularSpinner";
import NurseDiagnosisPatients from "../patient_info/nurse_diagnosis/NurseDiagnosisPatients";
import { onKeyPress, ACTION } from "../common/function";
import styled from "styled-components";
import "../../assets/styles/NursingDiagnosis.scss";
import _ from "lodash";

interface Props
  extends RouteComponentProps<{
    patientID: string;
  }> {}
interface State {
  patientID: string;
  nursingDiagnosisArray: NursingDiagnosisPatientType[];
  formValues: NursingDiagnosisPatientType;
  apiStatus: string;
  isEdit: boolean;
  successMsg: string;
  isLoading: boolean;
  errorMsg: string;
}

class CreateNursingDiagnosis extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      patientID: this.props.match.params.patientID,
      nursingDiagnosisArray: [],
      formValues: {
        date: "",
        status: "選択",
        diagnosisName: "",
        o: "",
        t: "",
        p: "",
      },
      apiStatus: "",
      successMsg: "",
      isLoading: false,
      isEdit: false,
      errorMsg: "",
    };

    this.onSubmitNursingDiagnosis = this.onSubmitNursingDiagnosis.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onMouseClickUp = this.onMouseClickUp.bind(this);
    this.onMouseClickDown = this.onMouseClickDown.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { location } = this.props;
    const {
      data: { patientID },
    } = location.state as any;
    this.setState({
      isLoading: true,
    });

    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/nursing_diagnosis/fetch_list?patient_id=${patientID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          this.setState({
            isLoading: false,
            nursingDiagnosisArray: result,
          });
        }
      })
      .catch(console.error);
  };

  // set field for event onchange input
  setField = (field: string, value: string) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [field]: value,
      },
    });
  };

  setLoading = (isLoading: boolean) => {
    this.setState({
      isLoading,
    });
  };

  // edit medical record
  setFormValues = (nursingDiagnosisID: number) => {
    const nursingDiagnosis = this.state.nursingDiagnosisArray.find(
      (nursingDiagnos) =>
        nursingDiagnos.nursingDiagnosisID === nursingDiagnosisID
    ) as any;

    const mappingData = {
      patientID: nursingDiagnosis.patientID,
      nursingDiagnosisID: nursingDiagnosis.nursingDiagnosisID,
      date: nursingDiagnosis.date_converted as any,
      status: nursingDiagnosis.status,
      diagnosisName: nursingDiagnosis.diagnosisName,
      o: nursingDiagnosis.o,
      t: nursingDiagnosis.t,
      p: nursingDiagnosis.p,
    };
    this.setState({
      formValues: mappingData,
      isEdit: true,
    });
    window.scrollTo(0, 0);
  };

  setNursingDiagnosis = (
    nursingDiagnosisID: number,
    action: string,
    visibleFlag: boolean = false
  ) => {
    const { nursingDiagnosisArray } = this.state;
    let nurseDiagnosisNew;
    switch (action) {
      case ACTION.DELETE:
        nurseDiagnosisNew = nursingDiagnosisArray.filter(
          (item) => item.nursingDiagnosisID !== nursingDiagnosisID
        );
        break;
      case ACTION.VISIBLE:
        nurseDiagnosisNew = nursingDiagnosisArray.map((nursingDiagnosis) => {
          const visibleByID =
            nursingDiagnosis.nursingDiagnosisID === nursingDiagnosisID
              ? visibleFlag
              : nursingDiagnosis.visibleFlag;
          return { ...nursingDiagnosis, visibleFlag: visibleByID };
        });
        break;
      case ACTION.UPDATE:
        nurseDiagnosisNew = nursingDiagnosisArray.map((nursingDiagnosis) => {
          const newData =
            nursingDiagnosis.nursingDiagnosisID === nursingDiagnosisID
              ? this.state.formValues
              : {};

          console.log(newData, "newData");

          return {
            ...nursingDiagnosis,
            ...newData,
          };
        });
        break;

      default:
        break;
    }

    if (nurseDiagnosisNew) {
      this.setState({
        nursingDiagnosisArray: nurseDiagnosisNew as any,
      });
    }
  };

  onVisibleMedicalRecord = (
    nursingDiagnosisID: number,
    visibleFlag: boolean
  ) => {
    const nursingDiagnosis = this.state.nursingDiagnosisArray.find(
      (nursingDiagnos) =>
        nursingDiagnos.nursingDiagnosisID === nursingDiagnosisID
    ) as any;

    const params = {
      nursingDiagnosisID: nursingDiagnosis.nursingDiagnosisID,
      patient_id: nursingDiagnosis.patientID,
      visibleFlag: visibleFlag,
    };
    const route = "patient_info/nursing_diagnosis/visible";
    this.fetchAPI(params, route, "", ACTION.VISIBLE);
  };

  setSuccessMessage = (successMsg: string) => {
    this.setState({
      successMsg,
    });
    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 3000);
  };

  setErrorMessage = (errorMsg: string) => {
    this.setState({
      errorMsg,
    });
    setTimeout(() => {
      this.setState({
        errorMsg: "",
      });
    }, 3000);
  };

  public onClickCancel() {
    this.props.history.goBack();
  }

  onSubmitNursingDiagnosis = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    const { location } = this.props;
    const {
      data: { patientID },
    } = location.state as any;
    const nursingDiagnosisArray = {
      patient_id: patientID,
      nursing_diagnosis_array: [
        {
          ...this.state.formValues,
          diagnosis_name: this.state.formValues.diagnosisName,
        },
      ],
    };

    const successMsg = this.state.formValues.nursingDiagnosisID
      ? "登録完了しました。"
      : "更新しました。";

    this.fetchAPI(
      nursingDiagnosisArray,
      "patient_info/nursing_diagnosis/create_or_update",
      successMsg,
      this.state.formValues.nursingDiagnosisID ? ACTION.UPDATE : ACTION.CREATE
    );
  };

  fetchAPI = (
    nursingDiagnosis: any,
    route: string,
    successMsg: string,
    action: string
  ) => {
    const url = `${process.env.REACT_APP_API_URL}/api/${route}`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(nursingDiagnosis);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);

        if (result.success) {
          this.setState({
            isLoading: false,
          });
          this.fetchData();
          // switch (action) {
          //   case ACTION.CREATE:
          //     this.fetchData();
          //     break;
          //   case ACTION.VISIBLE:
          //     this.setNursingDiagnosis(
          //       nursingDiagnosis.nursingDiagnosisID,
          //       action,
          //       nursingDiagnosis.visibleFlag
          //     );
          //     break;

          //   default:
          //     this.setNursingDiagnosis(
          //       nursingDiagnosis.nursing_diagnosis_array.nursingDiagnosisID,
          //       action,
          //       nursingDiagnosis.visibleFlag
          //     );
          //     break;
          // }
          this.setSuccessMessage(successMsg);
          this.onResetForm();
        }
      })
      .catch((error) => {
        console.log(error);

        let errorText = "登録に失敗しました";
        if (action === ACTION.UPDATE) {
          errorText = "アップデートに失敗しました";
        }
        this.setErrorMessage(errorText);
      });
  };

  public swapArrayLocs = (arr: any, index1: number, index2: number) => {
    [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
  };

  public onMouseClickUp(index: number) {
    let nursingDiagnosisArray = [...this.state.nursingDiagnosisArray];
    this.swapArrayLocs(nursingDiagnosisArray, index - 1, index);
    this.setState({ nursingDiagnosisArray });
    this.onUpdate(nursingDiagnosisArray);
  }
  public onMouseClickDown(index: number) {
    let nursingDiagnosisArray = [...this.state.nursingDiagnosisArray];
    this.swapArrayLocs(nursingDiagnosisArray, index + 1, index);
    this.setState({ nursingDiagnosisArray });
    this.onUpdate(nursingDiagnosisArray);
  }
  
  onUpdate = (
    nursingDiagnosis: any,
  ) => {
      console.log('nursingDiagnosis', nursingDiagnosis);
      const successMsg = "更新しました。";
      const data = {
        items: nursingDiagnosis
      }
      this.fetchAPI(
        data,
        "patient_info/nursing_diagnosis/update_order_display",
        successMsg,
        ACTION.UPDATE
      );
  };
  onResetForm = () => {
    this.setState({
      isEdit: false,
      formValues: {
        date: "",
        status: "選択",
        diagnosisName: "",
        o: "",
        t: "",
        p: "",
      },
    });
  };

  public render() {
    const { formValues } = this.state;
    const { location } = this.props;
    const {
      data: { patientID },
    } = location.state as any;

    const nursingDiagnosisRow = (
      <tr>
        <td className="d-flex b-none" style={{ border: "none" }}>
          <Form.Control
            className="m-0"
            required
            // disabled={this.state.isEdit}
            type="text"
            value={formValues.date || ""}
            onChange={(e) => this.setField("date", e.target.value)}
            onKeyPress={onKeyPress}
          />
          <p style={{ wordBreak: "keep-all" }}>日目</p>
          {/* <Form.Row>
            <Col md={8}>
              <Form.Control
                className="m-0"
                required
                disabled={this.state.isEdit}
                type="text"
                value={formValues.date || ""}
                onChange={(e) => this.setField("date", e.target.value)}
                onKeyPress={onKeyPress}
              />
            </Col>
            <Col
              md={4}
              className="d-flex justify-content-center align-items-center"
            >
              日目
            </Col>
          </Form.Row> */}
        </td>
        <td>
          <Form.Control
            as="select"
            style={{ width: "100px" }}
            value={formValues.status || "選択"}
            onChange={(e) => this.setField("status", e.target.value)}
          >
            <option>選択</option>
            <option>未評価</option>
            <option>達成</option>
            <option>解決</option>
          </Form.Control>
        </td>
        <td id="nursing_diagnosis" className="p-0">
          <div className="text-left sw-padding-15">
            <Form.Row>
              <Col
                md={2}
                className="d-flex justify-content-center align-items-center"
              >
                診断名：
              </Col>
              <Col>
                <Form.Control
                  className="m-0"
                  required
                  style={{ whiteSpace: "pre-line" }}
                  as="textarea"
                  value={formValues.diagnosisName || ""}
                  onChange={(e) =>
                    this.setField("diagnosisName", e.target.value)
                  }
                />
              </Col>
            </Form.Row>
          </div>
          <div className="text-left sw-padding-15">
            <Form.Row>
              <Col
                md={2}
                className="d-flex justify-content-center align-items-center"
              >
                O：
              </Col>
              <Col>
                <Form.Control
                  className="m-0"
                  style={{ whiteSpace: "pre-line" }}
                  as="textarea"
                  value={formValues.o || ""}
                  onChange={(e) => this.setField("o", e.target.value)}
                />
              </Col>
            </Form.Row>
          </div>
          <div className="text-left sw-padding-15">
            <Form.Row>
              <Col
                md={2}
                className="d-flex justify-content-center align-items-center"
              >
                T：
              </Col>
              <Col>
                <Form.Control
                  className="m-0"
                  style={{ whiteSpace: "pre-line" }}
                  as="textarea"
                  value={formValues.t || ""}
                  onChange={(e) => this.setField("t", e.target.value)}
                />
              </Col>
            </Form.Row>
          </div>
          <div className="text-left sw-padding-15">
            <Form.Row>
              <Col
                md={2}
                className="d-flex justify-content-center align-items-center"
              >
                E：
              </Col>
              <Col>
                <Form.Control
                  className="m-0"
                  style={{ whiteSpace: "pre-line" }}
                  as="textarea"
                  value={formValues.p || ""}
                  onChange={(e) => this.setField("p", e.target.value)}
                />
              </Col>
            </Form.Row>
          </div>
        </td>
      </tr>
    );

    return (
      <Container className="mt-5 mb-5">
        <h2>看護診断入力</h2>
        <Form onSubmit={this.onSubmitNursingDiagnosis} className="mb-5">
          <Row>
            <Col md={10}>
              <div>
                <Table id="nursing_diagnosis" className="m-5" bordered>
                  <thead className="thead-dark">
                    <tr>
                      <th id="date">診断年月日</th>
                      <th id="status">状況</th>
                      <th id="diagnosis" colSpan={2}>
                        看護診断/共同問題
                      </th>
                    </tr>
                  </thead>
                  <tbody>{nursingDiagnosisRow}</tbody>
                </Table>
              </div>
              <StyledButton>
                <ButtonSpriner isLoading={this.state.isLoading} label="登録" />
                <Button variant="outline-primary" onClick={this.onResetForm}>
                  クリア
                </Button>
              </StyledButton>
              {this.state.successMsg && (
                <Row className="ml-5">
                  <Col md={6} className="mt-3" style={{ margin: "auto" }}>
                    <Alert className="mt-3" variant="success">
                      {this.state.successMsg}
                    </Alert>
                  </Col>
                </Row>
              )}
              {this.state.errorMsg && (
                <Row className="ml-5">
                  <Col md={6} className="mt-3" style={{ margin: "auto" }}>
                    <Alert className="mt-3" variant="danger">
                      {this.state.errorMsg}
                    </Alert>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
        {this.state.isLoading && <LoadingSpriner />}
        <NurseDiagnosisPatients
          patientID={patientID}
          nursingDiagnosis={this.state.nursingDiagnosisArray}
          onMouseClickUp={this.onMouseClickUp}
          onMouseClickDown={this.onMouseClickDown}
          setFormValues={this.setFormValues}
          setNursingDiagnosis={this.setNursingDiagnosis}
          setSuccessMessage={this.setSuccessMessage}
          onVisibleMedicalRecord={this.onVisibleMedicalRecord}
          setLoading={this.setLoading}
          fetchData={this.fetchData}
        />
      </Container>
    );
  }
}

const StyledButton = styled.div`
  margin-left: 20%;
  .btn {
    width: 125px;
    margin-right: 20px;
  }
`;

export default withRouter(CreateNursingDiagnosis);
