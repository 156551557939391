import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { NurseInfoType } from "../models/Types";
import "../../assets/styles/NurseInfo.scss";
import { checkUserId } from "../../services/AuthService";
import { USER_TYPE } from "../../services/AppLocalHelper";
// import Image from "react-bootstrap/Image";
// import family_map from "../../assets/images/photo.png";

interface Props
  extends RouteComponentProps<{
    patientID: string;
    studentID: string;
    facultyID: string;
  }> { }
interface State {
  nurseInfo: NurseInfoType;
}

class NurseInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID === "supervisor-faculty" ? this.props.match.params.facultyID : this.props.match.params.studentID;
    if (!checkUserId(userId, this.props.match.params.studentID === "supervisor-faculty" ? USER_TYPE.TEACHER : USER_TYPE.STUDENT)) {
      this.props.history.push('/')

    }
    this.state = {
      nurseInfo: {
        health: "",
        self_awarness: "",
        nutrition: "",
        role: "",
        excretion: "",
        sex: "",
        activity: "",
        stress: "",
        cognition: "",
        value: "",
        sleep: "",
        other: ""
      }
    };
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/nursing_info?patient_id=${this.props.match.params.patientID}`;

    fetch(url, { mode: "cors" })
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            nurseInfo: result
          });
        },
        error => {
          console.log("Error: loadAllStudentTableItems");
        }
      );
  }

  public render() {
    return (
      <Container fluid>
        <Row className="mb-4">
          <Col>
            <h2>看護情報</h2>
          </Col>
        </Row>
        <Row className="ml-5">
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">健康知覚・健康管理</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">{this.state.nurseInfo.health}</pre>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">自己認識・自己概念</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">
                  {this.state.nurseInfo.self_awarness}
                </pre>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">栄養・代謝</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">
                  {this.state.nurseInfo.nutrition}
                </pre>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">役割・関係</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">{this.state.nurseInfo.role}</pre>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">排泄</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">
                  {this.state.nurseInfo.excretion}
                </pre>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">性・生殖</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">{this.state.nurseInfo.sex}</pre>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">活動・運動</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">{this.state.nurseInfo.activity}</pre>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">コーピング・ストレス耐性</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">{this.state.nurseInfo.stress}</pre>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">認知・知覚</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">
                  {this.state.nurseInfo.cognition}
                </pre>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">価値・信念</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">{this.state.nurseInfo.value}</pre>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">睡眠・休息</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">{this.state.nurseInfo.sleep}</pre>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="nurse_info_card mr-3 mb-5">
            <Card.Header as="h5">その他</Card.Header>
            <Card.Body>
              <Card.Text>
                <pre className="text-left">{this.state.nurseInfo.other}</pre>
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    );
  }
}

export default NurseInfo;
