import { clearUserId, getUserId, getUserType, USER_TYPE } from "./AppLocalHelper";

export function checkUserId(userId: string, currentType: string): boolean {
    console.log('currentType:', currentType)
    let localUser = getUserId()
    let userType = getUserType()
    
    if (userType !== "" && userType !== undefined && userId !== "" && userId !== undefined) {
        if (userType === currentType && userId === localUser) {
            return true
        }
    }
    // clearUserId()
    return false
}