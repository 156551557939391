import React, { useState } from "react";
import {
  isEmpty as _isEmpty,
  cloneDeep as _cloneDeep,
  isString as _isString,
} from "lodash";
import useDebounce from "../../common/useDebounce";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Link from "@material-ui/core/Link";
import { TimePicker } from "../../common/TimePickerComponent";
import moment from "moment";
import styled from "styled-components";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Popover from "react-bootstrap/Popover";

interface Props {
  dataCol: any;
  setDataCol: Function;
  setIsAutoSave: Function;
  setIsDeleteSuccess: Function;
  loadOverview: Function;
  patientID: string;
}
interface FormValuesType {
  [key: string]: {
    mr_time: string;
    mr_value: string;
  };
}
interface DateTimeType {
  [key: string]: {
    mr_date: number;
    new_time: any;
  };
}

type DataColType = {
  isFilter: boolean;
  mr_date: number;
  mr_date_time: string;
  mr_time: any;
  mr_value: any;
};
function replaceAll(str: string, find: string, replace: string) {
  if(str == null){
    return 'time_'
  }
  return str.replace(new RegExp(find, 'g'), replace);
}
const OverviewTableCol: React.FunctionComponent<Props> = ({
  dataCol,
  setDataCol,
  setIsAutoSave,
  setIsDeleteSuccess,
  loadOverview,
  patientID,
}) => {
  const [dateTime, setDateTime] = useState<DateTimeType>({} as any);
  const [formValues, setFormValues] = useState<any>({});
  const [isDirty, setIsDirty] = useState({});
  const debouncedSearchTerm = useDebounce(isDirty, 3000);
  // Here's where the API call happens
  // We use useEffect since this is an asynchronous action
  React.useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      
      if (debouncedSearchTerm && !_isEmpty(formValues)) {
        const newData = {
          dateTime,
          formValues,
          patientID,
        };

        const url = `${process.env.REACT_APP_API_URL}/api/settings/overview_data/create_or_update`;
        const mode = "cors";
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const body = JSON.stringify(newData);
        fetch(url, { method: "POST", mode, headers, body })
          .then((res) => res.json())
          .then((result) => {
            if (result.success) {
              setIsAutoSave();
            }
          })
          .catch(console.error);
      }
    },
    // This is the useEffect input array
    // Our useEffect function will only execute if this value changes ...
    // ... and thanks to our hook it will only change if the original ...
    // value (searchTerm) hasn't changed for more than 500ms.
    [debouncedSearchTerm]
  );

  const addNewCol = (mrDate: number) => {
    const newData = dataCol.map((items: any) => {
      if (items.mr_date === mrDate) {
        const newCols: any = {};
        const mrValue = items.mr_value.map((value: any) => {
          const newPdata = _cloneDeep(value.pdata);
          const time =  moment().format("HH:mm:ss");
          const id =  replaceAll(time,':','_') +'__'+items.mr_date+ '_'+value.base_type_id+'_id_null';
          newCols[id] = {
            mr_date: items.mr_date,
            mr_time: time,
            mr_value: "",
            type_id: value.base_type_id,
          };
          newPdata.push({
            id: id,
            mr_date: items.mr_date,
            mr_time: time,
            mr_value: "",
            type_id: value.base_type_id,
          });

          return {
            ...value,
            pdata: newPdata,
          };
        });

        setFormValues(newCols);

        const cloneMrTime = _cloneDeep(items.mr_time);
        cloneMrTime.push({ hour: "", mr_time: moment().format("HH:mm:ss") });
        setDateTime({
          [moment().format("HH:mm:ss")]: {
            mr_date: items.mr_date,
            new_time: moment().format("HH:mm:ss"),
          },
        });

        return {
          ...items,
          mr_time: cloneMrTime,
          mr_value: mrValue,
        };
      } else {
        return items;
      }
    });

    setIsDirty(newData);
    setDataCol(newData);
  };

  return dataCol.map((colItem: DataColType) => {
    let colSpan = 1;
    let mr_date = 0;
    let isFilter = false;
    if (colItem) {
      colSpan = colItem.mr_time.length;
      mr_date = colItem.mr_date;
      isFilter = colItem.isFilter;
    }

    return (
      <Table
        className="settings__overview patient_info_overview_right float-left"
        id="overview301"
        bordered
        hover
        size="sm"
        key={mr_date}
      >
        <thead>
          <tr>
            {isFilter && (
              <th colSpan={colSpan}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <span>{mr_date} 日目</span>
                  <Link
                    style={{
                      cursor: "pointer",
                      color: "#007bff",
                      textDecoration: "none",
                    }}
                    color="primary"
                    onClick={() => addNewCol(mr_date)}
                  >
                    時間追加
                  </Link>
                </div>
              </th>
            )}
          </tr>
          {colItem && (
            <ColTime
              dataCol={colItem}
              setDateTime={setDateTime}
              dateTime={dateTime}
              setIsDirty={setIsDirty}
            />
          )}
        </thead>
        <tbody>
          {colItem && (
            <ColData
              dataCol={colItem}
              setFormValues={setFormValues}
              loadOverview={loadOverview}
              formValues={formValues}
              setIsDirty={setIsDirty}
              patientID={patientID}
              setIsDeleteSuccess={setIsDeleteSuccess}
            />
          )}
        </tbody>
      </Table>
    );
  });
};

const ColTime = ({
  dataCol,
  dateTime,
  setDateTime,
  setIsDirty,
}: any): JSX.Element => {
  const [initDateTime, setInitDateTime] = useState<DateTimeType>({} as any);

  React.useEffect(() => {
    const dataClone = _cloneDeep(initDateTime);
    dataCol.mr_time.map((time: any) => {
      dataClone[time.mr_time] = {
        mr_date: dataCol.mr_date,
        new_time: moment(time.mr_time, "HH:mm:ss"),
      };
    });

    setInitDateTime(dataClone);
  }, []);

  // Validate "hh:mm" format
  const validHHMMstring = (str: string) =>
    /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);

  const onChange = (mrTime: string, mrDate: number, value: string) => {
    const time = moment(value).format("HH:mm:ss");
    const dataClone = _cloneDeep(dateTime);
    const newTime = mrTime || time;
    dataClone[newTime] = {
      mr_date: mrDate,
      new_time: time,
    };
    const initDateTimeClone = _cloneDeep(initDateTime);

    initDateTimeClone[newTime] = {
      mr_date: mrDate,
      new_time: value,
    };
    if (validHHMMstring(moment(value).format("HH:mm"))) {
      setDateTime(dataClone);
      setInitDateTime(initDateTimeClone);
      setIsDirty(dataClone);

    }
  };

  if (!dataCol) return <></>;

  return (
    <tr>
      {dataCol.mr_time.sort(function (a: any, b: any) {
        return (a.mr_time == null || b.mr_time == null) || parseInt(a.mr_time.toString().replace(":00:00", "")) - parseInt(b.mr_time.toString().replace(":00:00", ""))
      }).map((item: any) => {
        if (dataCol.isFilter) {
          return (
            <th key={`date_${item.hour}`}>
              <StyleTimePicker
                value={
                  initDateTime[item.mr_time] &&
                  initDateTime[item.mr_time].new_time
                }
                onChange={(time: any) =>
                  onChange(item.mr_time, dataCol.mr_date, time)
                }
              />
            </th>
          );
        }
      })}
    </tr>
  );
};

const ColData = ({
  dataCol,
  loadOverview,
  formValues,
  setFormValues,
  setIsDirty,
  patientID,
  setIsDeleteSuccess,
}: any): JSX.Element => {
  const [initialValues, setInitialValues] = useState<FormValuesType>({});

  React.useEffect(() => {
    if (_isEmpty(formValues)) {
      const mr_time = dataCol.mr_time;
      const mr_date_root = dataCol.mr_date;
      let result: any = {};
      dataCol.mr_value.map((item: any) => {
        const pdata = JSON.parse(JSON.stringify(item.pdata)).sort(function (a: any, b: any) {
          return (a.mr_time == null || b.mr_time == null) || parseInt(a.mr_time.toString().replace(":00:00", "")) - parseInt(b.mr_time.toString().replace(":00:00", ""))
        });
        const elements = pdata.splice(0, mr_time.length);
      
        elements.map((element: any) => {
          result = {
            ...result,
            [replaceAll(element.mr_time,':','_') +'__'+mr_date_root+ '_'+item.base_type_id+'_id_'+element.id]: {
              mr_value: element.mr_value,
              mr_time: element.mr_time,
            },
          };
        });
      });

      setInitialValues(result);
    }
  }, []);

  const onChange = (id: any, mr_time: string, mr_date: number, type_id: number, value: string) => {
    const formValuesClone = _cloneDeep(formValues);
    const initialClone = _cloneDeep(initialValues);

    if (_isString(id) && id.includes("add")) {
      formValuesClone[id] = {
        ...formValuesClone[id],
        mr_date,
        type_id,
        mr_time,
        mr_value: value,
      };
    } else {
      formValuesClone[id] = {
        ...formValuesClone[id],
        mr_date,
        type_id,
        mr_time,
        mr_value: value,
      };
    }

    initialClone[id] = {
      mr_time,
      mr_value: value,
    };

    setIsDirty(formValuesClone);
    setFormValues(formValuesClone);
    setInitialValues(initialClone);
  };

  const actionDelete = (mr_date: any, mr_time: string, patient_id: string) => {
    const confirmDelete = window.confirm("削除してもよろしいですか？");
    const url = `${process.env.REACT_APP_API_URL}/api/settings/overview_data/delete`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({
      mr_date,
      mr_time,
      patientID,
    });

    if (confirmDelete) {
      fetch(url, { method: "DELETE", mode: "cors", headers, body })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            loadOverview();
            setIsDeleteSuccess();
          }
        })
        .catch(console.error);
      return true;
    } else {
      return false;
    }

  };

  const getElement = () => {
    let result: any[] = [];

    const mr_time = dataCol.mr_time;
    const mr_date_root = dataCol.mr_date;
    
    dataCol.mr_value.map((item: any) => {
      if (item.isDisplay) {
        const pdata = JSON.parse(JSON.stringify(item.pdata)).sort(function (a: any, b: any) {
          return (a.mr_time == null || b.mr_time == null) || parseInt(a.mr_time.replace(":00:00", "")) - parseInt(b.mr_time.replace(":00:00", ""))
        })
          ;
        const tmpRowCount = Math.ceil(pdata.length / mr_time.length);

        for (let i = 0; i < tmpRowCount; i++) {
          // tổng số cột
          // nếu độ dài của mảng arrTemp = tổng số cột thì next row mới
          let arrData: any = [];
          
          const element = pdata.splice(0, mr_time.length);
          for (let j = 0; j < element.length; j++) {
            const x = element[j];
            // console.log('mr_time[]', mr_time[j].mr_time);
            const id = x.id == null || x.id.toString().includes("_id_") ? 'null' : x.id;
            
            const mr_time_root = mr_time[j].mr_time;
            const test_key = replaceAll(mr_time_root,':','_') +'__'+mr_date_root+ '_'+item.base_type_id+'_id_'+id;
            arrData.push(
              <td key={`td_${test_key}`}>
                <Input
                  key={test_key}
                  name={`item_${test_key}`}
                  type="text"
                  style={{
                    minWidth:
                      x.mr_value?.length > 0 ? x.mr_value.length * 8 : 80,
                  }}
                  value={
                    initialValues[test_key] && initialValues[test_key].mr_value !== "-" && initialValues[test_key].mr_value != null
                      ? initialValues[test_key].mr_value
                      : x.mr_value
                  }
                  onChange={(e: any) =>
                    onChange(test_key, mr_time_root, mr_date_root, item.base_type_id, e.target.value)
                  }
                />
              </td>
            );
          }
          // row
          result.push(
            <tr key={`tr_${item.base_type_id}`}>
              {
                // col in row
                arrData.map((item: any) => item)
              }
            </tr>
          );
        }
      }
    });

    result.push(
      <tr key={Math.random()}>
        {dataCol.mr_time.map((item: any) => {
          if (dataCol.isFilter) {
            return (
              <th key={Math.random()}>
                <Link
                  style={{
                    cursor: "pointer",
                    color: "#007bff",
                    textDecoration: "none",
                  }}
                  color="primary"
                  onClick={() =>
                    actionDelete(dataCol.mr_date, item.mr_time, patientID)
                  }
                >
                  列削除
                </Link>
              </th>
            );
          }
        })}
      </tr>
    );

    return result;
  };

  if (!dataCol) <></>;

  return <>{getElement()}</>;
};

const Input = styled(Form.Control)``;
const StyleTimePicker = styled(TimePicker)`
  .MuiSvgIcon-root {
    display: none;
  }

  .MuiInputAdornment-positionEnd {
    display: none;
  }
`;

export default React.memo(OverviewTableCol);