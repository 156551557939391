import React from "react";
import Table from "react-bootstrap/Table";

interface Props {
  studentID: string;
}
interface State {
  studentID: string;
  studentName: string;
  internDuration: string;
  internDay: string;
  inChargeDays: number;
  afterSurgeryDays: number;
  patientInfos: string;
  overviewMaxDay: string;
}

class NursePlanBasicInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      studentID: this.props.studentID,
      studentName: "",
      internDuration: "",
      internDay: "",
      inChargeDays: 1,
      afterSurgeryDays: 0,
      patientInfos: "",
      overviewMaxDay: ""
    };
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${this.props.studentID}`;

    fetch(url)
      .then(res => res.json())
      .then(result => {
        const internDuration = `${result.startDate}〜${result.endDate}`;
        this.setState({
          studentID: result.studentID,
          studentName: result.studentName,
          internDuration: internDuration,
          internDay: result.internDay,
          patientInfos: result.patientInfos,
          overviewMaxDay: result.overviewMaxDay
        });
      })
      .catch(console.error);
  }

  public render() {
    return (
      <Table id="nurse_plan" className="m-5" bordered>
        <tbody>
          <tr>
            <td>学籍番号</td>
            <td>{this.state.studentID}</td>
          </tr>
          <tr>
            <td>氏名</td>
            <td>{this.state.studentName}</td>
          </tr>
          <tr>
            <td>担当患者</td>
            <td>{this.state.patientInfos}</td>
          </tr>
          <tr>
            <td>患者の入院日数</td>
            <td>{this.state.overviewMaxDay}</td>
          </tr>
          <tr>
            <td>実習期間</td>
            <td>{this.state.internDuration}</td>
          </tr>
          <tr>
            <td>実習日</td>
            <td>{this.state.internDay}日目</td>
          </tr>
          <tr>
            <td>受け持ち</td>
            <td>{this.state.internDay}日目</td>
          </tr>
          <tr>
            <td>術後</td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default NursePlanBasicInfo;
