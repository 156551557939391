import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import PDFViews from "./PDFViews";
import { checkUserId } from "../../../services/AuthService";
import { USER_TYPE } from "../../../services/AppLocalHelper";

interface Props extends RouteComponentProps<{
  studentID: string,
  facultyID: string,
  // linkId: string
}> { }
interface State {
  fileUploadStatus: string;
  uploadedFileName: string;
  fileUploadNum: number;
}

class ChartView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.facultyID
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }

    let { location } = this.props;
    console.log('location:', location)
    let data = location.state as any
    this.state = {
      fileUploadStatus: "",
      uploadedFileName: "",
      fileUploadNum: 0,
    };

    this.onFileUploadChanged = this.onFileUploadChanged.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
  }

  public onFileUploadChanged(status: string) {
    this.setState((prevState: State) => {
      return {
        fileUploadStatus: status,
        fileUploadNum: prevState.fileUploadNum + 1,
      };
    });
  }

  public onFileUploaded(uploadedFileName: string) {
    this.setState({ uploadedFileName: uploadedFileName });
  }

  public render() {
    return (
      <Row className="mt-5 mb-5">
        <Col md={1} />
        <Col md={10}>
          <h2 className="mb-5">関連図</h2>
          <PDFViews
            studentID={this.props.match.params.studentID}
            fileUploadNum={this.state.fileUploadNum}
          />
        </Col>
        <Col md={1} />
      </Row>
    );
  }
}

export default withRouter(ChartView);
