import React from "react";
import { TestDataItemRecordType } from "../../models/Types";

interface Props {
  testData: TestDataItemRecordType,
  itemId: number
}
interface State {}

class TestDataRecord extends React.Component<Props, State> {
  public render() {
    const itemInfo = this.props.testData.results.filter((r)=>{
        return r.item_id == this.props.itemId;
      });
    let item_name = itemInfo != null && itemInfo.length > 0 ? itemInfo[0].result : '-';
    item_name = item_name == null || item_name == '' ?   '-' : item_name;
    return (
      <span>{item_name}</span>
    );
  }
}

export default TestDataRecord;
