import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { ButtonSpriner } from "../common/CircularSpinner";
import ConfirmDialog from "../common/ConfirmDialog";
import { HospitalRoomType } from "../models/Types";
import "../../assets/styles/HospitalRoom.scss";
import { getUserId, USER_TYPE } from "../../services/AppLocalHelper";
import { checkUserId } from "../../services/AuthService";
interface Props extends RouteComponentProps {
  university: any;
  currentFilter: string | number;
}
interface State {
  hospitalRooms: HospitalRoomType[];
  patients: string[];
  isLoading: boolean;
  dialogOpen: boolean;
}

class HospitalRoom extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let userId = getUserId();
    this.getFaculty(userId);
    this.state = {
      hospitalRooms: [],
      patients: [],
      isLoading: false,
      dialogOpen: false,
    };
  }
  
  componentDidMount() {


    const { university } = this.props;
    const url2 = `${process.env.REACT_APP_API_URL}/api/patient_info/get_interns_patient?univercity_id=${university.university}&start_date=${university.startDate}&end_date=${university.endDate}`;
    fetch(url2)
      .then((res) => res.json())
      .then((result) => {
        if (result !== null) {
          this.setState({
            patients: result,
          });
        }
      })
      .catch(console.error);
  }
  public getFaculty(facultyID: string){
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${facultyID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
          console.log('getFaculty', result);
          this.getRooms(result);
         // this.setState({isFacultySupervisor : result.is_supervisor, affiliationFaculty: result.affiliation});
      })
      .catch(console.error);
}
public getRooms(faculty: any){
  const url = `${process.env.REACT_APP_API_URL}/api/patient_info/${this.props.currentFilter}?student_id=supervisor-faculty&university=null&start_date=null&end_date=null`;
  fetch(url)
    .then((res) => res.json())
    .then((result) => {
      if (result !== null) {
        if(faculty.is_supervisor){
          this.setState({
            hospitalRooms: result,
          });

        }else{
          result = result.filter(function (el: any) {
            return el.affiliation == faculty.affiliation_id || el.affiliation == "COMMON";
          });
          this.setState({
            hospitalRooms: result,
          });
        }
        
      }
    })
    .catch(console.error);
}
  onCloseModal = () => {
    this.props.history.goBack();
    this.setState({
      dialogOpen: false,
    });
  };

  onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    let checkList = [...this.state.patients];
    if (isChecked) {
      if (!checkList.includes(e.target.value)) checkList.push(e.target.value);
    } else {
      checkList = checkList.filter((check) => check !== e.target.value);
    }

    this.setState({
      patients: checkList,
    });
  };

  onSubmitHospitalRoom = () => {
    this.setState({
      isLoading: true,
    });
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/create_intern_patient`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const { university } = this.props;
    const data = {
      university_id: university.university,
      start_date: university.startDate,
      end_date: university.endDate,
      patients: this.state.patients,
    };
    const body = JSON.stringify(data);

    fetch(url, { method, headers, body })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          isLoading: false,
        });
        if (result.success) {
          this.setState({
            dialogOpen: true,
          });
        } else {
          console.log("API Error");
        }
      })
      .catch(console.error);
  };

  render() {
    console.log(this.props.currentFilter, 'this.props.currentFilter');

    const listHospital = this.props.currentFilter === "all" ? this.state.hospitalRooms : this.state.hospitalRooms.filter(item => Number(item.roomName) >= Number(this.props.currentFilter) && Number(item.roomName) < Number(this.props.currentFilter) + 100)
    const hospitalRooms = listHospital.map((item, i) => {
      return (
        <StyledHospotalRoom key={i} className="float-left m-3 ml-3">
          <Form.Group>
            <Form.Check
              type="checkbox"
              checked={this.state.patients.includes(item.patientID)}
              value={item.patientID}
              onChange={this.onChangeCheckbox}
            />
          </Form.Group>

          <Table bordered id={"hospital_room"}>
            <tbody>
              <tr>
                <td>病室</td>
                <td>{item.roomName}</td>
              </tr>
              <tr>
                <td>診療科</td>
                <td>{item.department}</td>
              </tr>
              <tr>
                <td>患者氏名</td>
                <td>{item.patientName}</td>
              </tr>
            </tbody>
          </Table>
        </StyledHospotalRoom>
      );
    });

    return (
      <Row>
        <Col md={12}>{hospitalRooms}</Col>
        <Col md={12}>
          {this.state.hospitalRooms.length > 0 && (
            <StyledButton onClick={this.onSubmitHospitalRoom}>
              <ButtonSpriner isLoading={this.state.isLoading} label="保存" />
            </StyledButton>
          )}
        </Col>
        <ConfirmDialog
          dialogOpen={this.state.dialogOpen}
          onCloseModal={this.onCloseModal}
        />
      </Row>
    );
  }
}

const StyledHospotalRoom = styled.div`
  display: flex;

  .form-group {
    display: flex;
    align-items: center;
    padding-right: 20px;
  }

  input[type="checkbox"] {
    transform: scale(2);
  }
`;

const StyledButton = styled.div`
  .btn {
    width: 125px;
    margin-top: 50px;
  }
`;

export default withRouter(HospitalRoom);
