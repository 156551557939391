import React from "react";
import Table from "react-bootstrap/Table";
import { FacultyInfo } from "../models/Types";

interface Props {
  facultyID: string;
  fetchFacultyInfo: (
    isSupervisor: boolean,
    isSubSupervisor: boolean,
    affiliation: string,
    facultyID: string
  ) => void;
}
interface State extends FacultyInfo {}

class IndexFacultyBasicInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      facultyID: "",
      facultyName: "",
      affiliation: "",
      isSupervisor: false,
      isSubSupervisor: false,
    };
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${this.props.facultyID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.props.fetchFacultyInfo(
          result.is_supervisor,
          result.is_subsupervisor,
          result.affiliation,
					result.facultyID
        );
        this.setState({
          facultyID: result.faculty_id,
          facultyName: result.faculty_name,
          affiliation: result.affiliation,
          isSupervisor: result.is_supervisor,
          isSubSupervisor:  result.is_subsupervisor,
        });
      })
      .catch(console.error);
  }

  public render() {
    return (
      <Table id="index_faculty" className="mt-5" bordered>
        <tbody>
          <tr>
            <td className="left">教員ID</td>
            <td className="right">{this.state.facultyID}</td>
          </tr>
          <tr>
            <td>氏名</td>
            <td>{this.state.facultyName}</td>
          </tr>
          <tr>
            <td>所属</td>
            <td>{this.state.affiliation}</td>
          </tr>
          <tr>
            <td>実習担当</td>
            <td>{this.state.isSupervisor ? "あり" : "なし"}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default IndexFacultyBasicInfo;
