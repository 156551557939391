import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Table from "react-bootstrap/Table";

interface Props
  extends RouteComponentProps<{
    university: string;
    startDate: string;
    endDate: string;
    facultyID: string;
  }> { }
interface State {
  studentList: {
    studentID: string;
    studentName: string;
    university_name: string;
    university: string;
    startDate: string;
    endDate: string;
  }[];
}

class StudentList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      studentList: [],
    };

    this.onStudentListRowClicked = this.onStudentListRowClicked.bind(this);
  }

  public onStudentListRowClicked(studentID: string) {
    this.props.history.push(
      `/faculty/self_evaluation_for_faculty/${studentID}/${this.props.match.params.facultyID}`
    );
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info/student_list?university=${this.props.match.params.university}&start_date=${this.props.match.params.startDate}&end_date=${this.props.match.params.endDate}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          studentList: result,
        });
      })
      .catch(console.error);
  }

  public render() {
    const faculty_id = this.props.match.params.facultyID;

    const students = this.state.studentList.map((item, i) => {
      if (item.startDate === null || item.endDate === null) {
        return (
          <tr
            key={i}
            onClick={this.onStudentListRowClicked.bind(this, item.studentID)}
          >
            <td>{i + 1}</td>
            <td>{item.studentName}</td>
            <td>{item.university_name}</td>
            <td>no data</td>
            <td>no data</td>
          </tr>
        );
      } else {
        const startDate = item.startDate.split("-");
        const endDate = item.endDate.split("-");
        return (
          <tr
            key={i}
            onClick={this.onStudentListRowClicked.bind(this, item.studentID)}
          >
            <td>{i + 1}</td>
            <td>{item.studentName}</td>
            <td>{item.university_name}</td>
            <td>{`${startDate[0]}年${startDate[1]}月${startDate[2]}日`}</td>
            <td>{`${endDate[0]}年${endDate[1]}月${endDate[2]}日`}</td>
          </tr>
        );
      }
    });
    return (
      <div className="m-5 d-flex flex-column align-items-center">
        <h2 className="mb-3">学生一覧</h2>
        <Table id="index_faculty_student_list" className="" bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>学生氏名</th>
              <th>所属施設</th>
              <th>実習開始日</th>
              <th>実習終了日</th>
            </tr>
          </thead>
          <tbody>{students}</tbody>
        </Table>
      </div>
    );
  }
}

export default withRouter(StudentList);
