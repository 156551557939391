import { TransferWithinAStationSharp } from "@material-ui/icons";
import React from "react";
import { getTitleMedicalRecord } from "../../common/function";
import styled from "styled-components";
import { Button, Table } from "react-bootstrap";
import { MedicalRecordData } from "../../models/Types";

interface Props {
  medicalRecordData: MedicalRecordData;
  isShowAction?: boolean;
  isSuccess?: string;
  setSuccessMessage?: any;
  setMedicalRecords?: any;
  setFormValues?: any;
  onVisibleMedicalRecord?: any;
}

interface State {
  openModal: boolean;
}

class MedicalRecordTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  onDelete = () => {
    const confirmDelete = window.confirm("削除してもよろしいですか？");
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/medical_record/delete`;
    const method = "DELETE";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify({
      medicalRecordsId: this.props.medicalRecordData.medicalRecordId,
    });
    if (confirmDelete) {
      fetch(url, { method, mode, headers, body })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            this.props.setSuccessMessage("削除しました。");
            this.props.setMedicalRecords(
              this.props.medicalRecordData.medicalRecordId,
              "DELETE"
            );
          }
        })
        .catch(console.error);
      return true;
    } else {
      return false;
    }
  };

  onUpdate = () => {
    console.log(this.props.medicalRecordData, 'this.props.medicalRecordData');
    
    this.props.setFormValues(this.props.medicalRecordData.medicalRecordId);
  };

  onVisible = () => {
    this.props.onVisibleMedicalRecord(
      this.props.medicalRecordData.medicalRecordId,
      !this.props.medicalRecordData.visibleFlag
    );
  };

  public render() {
    const actionItem = [
      {
        label: this.props.medicalRecordData.visibleFlag ? "表示" : "非表示",
        action: this.onVisible,
      },
      {
        label: "編集",
        action: this.onUpdate,
      },
      {
        label: "削除",
        action: this.onDelete,
      },
    ];

    const actionHTML = actionItem.map((item) => (
      <Button onClick={item.action} key={item.label}>
        {item.label}
      </Button>
    ));

    return (
      <StyledTable
        bordered
        is-visible={this.props.medicalRecordData.visibleFlag ? 1 : 0}
      >
        <tbody>
          <tr>
            <td className="title text-left" colSpan={2}>
              <StyledHeader>
                <StyledDateTime>
                  {getTitleMedicalRecord(this.props.medicalRecordData)}
                </StyledDateTime>
                <StyledAction>
                  {this.props.isShowAction && actionHTML}
                </StyledAction>
              </StyledHeader>
            </td>
          </tr>
          <tr>
            <td className="left">
              {this.props.medicalRecordData.subTitle === "&emsp;"
                ? ""
                : this.props.medicalRecordData.subTitle}
            </td>
            <td className="text-left">
              <span>
                <pre>{this.props.medicalRecordData.message}</pre>
              </span>
            </td>
          </tr>
        </tbody>
      </StyledTable>
    );
  }
}

const StyledTable = styled(Table)`
  background-color: ${(props) => props["is-visible"] && "darkgray"};
`;
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledDateTime = styled.div``;
const StyledAction = styled.div`
  button {
    font-size: 1em;
    text-align: left;
    color: blue;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    margin-left: 10px;
  }

  button:hover,
  button:focus {
    color: blue;
    background: none;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background: none;
    color: blue;
  }
`;
export default MedicalRecordTable;
