import React, { Component } from 'react';
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

interface Props {
  dataCol: any
}

class OverviewTableCol extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.colTime = this.colTime.bind(this);
    this.colData = this.colData.bind(this);

  }

  colTime() {
    if (!this.props.dataCol) return;
    return (
      <tr key={Math.random()}>
        {
          this.props.dataCol.mr_time.map((item: any) => {
            return (
              <th key={Math.random()}>{item.hour}</th>
            )
          })
        }
      </tr>
    );
  }

  colData() {
    if (!this.props.dataCol) return;
    let result: any[] = [];

    const mr_time = this.props.dataCol.mr_time;
    this.props.dataCol.mr_value.map((item: any) => {
      if (item.pdata[0] !== undefined && item.pdata[0].visible_flag !== undefined && parseInt(item.pdata[0].visible_flag) === 0) {
        let tmpColCount = 0;
        // tổng số cột
        // nếu độ dài của mảng arrTemp = tổng số cột thì next row mới

        let arrData: any[] = [];
        // const pdata = item.pdata.sort((a:any,b:any) => a.id - b.id);
        const pdata = item.pdata.sort(function (a: any, b: any) {
          return (a.mr_time == null || b.mr_time == null) || parseInt(a.mr_time.replace(":00:00", "")) - parseInt(b.mr_time.replace(":00:00", ""))
        });
        const tmpRowCount = Math.ceil(pdata.length / mr_time.length);
        // console.log(tmpRowCount);

        for (let i = 0; i < tmpRowCount; i++) {
          const element = pdata.splice(0, mr_time.length);
          // console.log(element, pdata);

          arrData = [];

          for (let i = 0; i < element.length; i++) {
            const x = element[i];
            if (x.type_id == 70) {
              // console.log(x.mr_time);                
            }
            arrData.push(
              <td key={Math.random()}>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover id={`time-${i}`}>
                      <Popover.Title as="h3">{item.nameb + (item.namec ? '(' + item.namec + ')' : '')}</Popover.Title>
                      <Popover.Content>{x.mr_value}</Popover.Content>
                    </Popover>
                  }
                >
                  <span className="overflow" data-id={x.id} >{x.mr_value || '-'}</span>
                </OverlayTrigger>
              </td>
            );
          }

          // row
          result.push(
            <tr key={Math.random()}>
              {
                // col in row
                arrData.map(item => item)
              }
            </tr>
          );
        }
      }

    })

    return result;
  }

  formatDate(dt: string) {
    if (!dt) return;
    var d = new Date(dt.replace(/-/g, '/'));
    var lang = 'en',
      year = d.toLocaleString(lang, { year: 'numeric' }),
      month = ("0" + d.toLocaleString(lang, { month: 'numeric' })).slice(-2),
      day = ("0" + d.toLocaleString(lang, { day: 'numeric' })).slice(-2),
      dayName = d.toLocaleString(lang, { weekday: 'long' });

    console.log(
      `${year}年 ${month}月 ${day}日 ${dayName}`
    );
    return `${year}年 ${month}月 ${day}日`;
  }

  render() {
    let colSpan = 1;
    let mr_date = 0;
    let mr_date_time = '';
    // const { mr_time, mr_date } = this.props.dataCol;
    if (this.props.dataCol) {
      colSpan = this.props.dataCol.mr_time.length;
      mr_date = this.props.dataCol.mr_date;
      mr_date_time = this.props.dataCol.mr_date_time;
    }

    return (
      <Table
        className="patient__overview patient_info_overview_right float-left"
        id="overview301"
        bordered
        hover
        size="sm"
      >
        <thead>
          <tr>
            <th colSpan={colSpan}>{this.formatDate(mr_date_time)}</th>
          </tr>
          <tr>
            <th colSpan={colSpan}>{mr_date} 日目</th>
          </tr>
          {this.colTime()}
        </thead>
        <tbody>


          {this.colData()}
        </tbody>
      </Table>
    );
  }
}

export default OverviewTableCol;