import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

interface Props {
  value: string | null;
  onChange: Function;
  className?: any;
  required?: boolean;
}

export const TimePicker = ({ value, onChange, className, required }: Props) => {

  const [openModal, setOpenModal] = React.useState(false);

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <KeyboardTimePicker
        className={className}
        ampm={false}
        // open={openModal}
        onOpen={() => setOpenModal(true)}
        onClose={() => setOpenModal(false)}
        autoOk
        variant="inline"
        label="HH:mm"
        value={value}
        onChange={(time) => onChange(moment(time))}
        mask="__:__"
        invalidDateMessage={"無効な時間形式"}
        required={required}
      />
    </MuiPickersUtilsProvider>
  );
};

export const DatePickerInline = ({ value, onChange, className }: Props) => {
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <KeyboardDatePicker
        className={className}
        disableToolbar
        variant="inline"
        format="YYYY/MM/DD"
        margin="normal"
        label="年/月/日"
        value={value}
        onChange={(time) => onChange(moment(time))}
        mask="____/__/__"
        invalidDateMessage={"無効な時間形式"}
      />
    </MuiPickersUtilsProvider>
  );
};
