import React from "react";
import Row from "react-bootstrap/Row";
import path from "path";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Document, Page, pdfjs } from "react-pdf";
import { FileListType, ChartCommentInfoType } from "../../models/Types";
import "../../../assets/styles/PDFView.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  studentID: string;
  fileUploadNum: number;
}

interface State {
  fileUploadNum: number;
  numPages: number;
  pageNumber: number;
  fileList: FileListType[];
  chartCommentInfo: ChartCommentInfoType[];
  fileIndex: number;
  updateState: string;
  showAlert: boolean;
  isNotes: boolean;
  isConfirm2Disabled: boolean;
  fileName: string;
}

class PDFViews extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      numPages: 4,
      pageNumber: 1,
      fileUploadNum: this.props.fileUploadNum,
      fileList: [],
      chartCommentInfo: [],
      fileIndex: 0,
      updateState: "",
      showAlert: true,
      isNotes: false,
      fileName: '',
      isConfirm2Disabled: false,
    };

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.onClickFileListRow = this.onClickFileListRow.bind(this);
    this.onChartCommentChanged = this.onChartCommentChanged.bind(this);
    this.onSaveChartComment = this.onSaveChartComment.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
  }

  public onDocumentLoadSuccess() {
    this.setState({ numPages: 4 });
  }

  public onClickFileListRow(i: number,file_name: string) {
    console.log(file_name)
    this.setState({ fileIndex: i ,fileName: file_name});
    // console.log(this.state.fileIndex);
  }

  public onChartCommentChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const newComment: string = e.target.value;

    this.setState((prevState: State) => {
      let newChartCommentInfo: ChartCommentInfoType[] =
        prevState.chartCommentInfo;

      newChartCommentInfo[this.state.fileIndex]["comment"] = newComment;

      return { chartCommentInfo: newChartCommentInfo };
    });
  }

  public onSaveChartComment(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const chartCommentInfo = {
      student_id: this.props.studentID,
      chart_comment_info: this.state.chartCommentInfo,
    };

    const body = JSON.stringify(chartCommentInfo);
    const url = `${process.env.REACT_APP_API_URL}/api/chart_pdf/comment_save`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    fetch(url, { method, headers, body })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.status === "success") {
          this.setState({ updateState: "success", showAlert: true });
          console.log("API success!!");
        } else {
          this.setState({ updateState: "error" });
          console.log("API Error");
        }
      })
      .catch(console.error);
  }

  public onCloseAlert() {
    this.setState({ showAlert: false });
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/chart_pdf/file_list?student_id=${this.props.studentID}`;

    fetch(url, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          const isChecked: boolean = result.length === 0 ? true : false;
          let index_file: string = result.length > 0 ? result[0]['file_name'] : '';
          this.setState({
            fileList: result,
            isNotes: isChecked,
            isConfirm2Disabled: isChecked,
            fileName: index_file,
          });
        },
        (error) => {
          console.log("API Error");
        }
      );

    const url2 = `${process.env.REACT_APP_API_URL}/api/chart_pdf/comment_list?student_id=${this.props.studentID}`;

    fetch(url2, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            chartCommentInfo: result,
          });
        },
        (error) => {
          console.log("API Error");
        }
      );
  }

  public render() {
    // console.log(this.state.fileList);

    const fileListRow = this.state.fileList.map((item:any , i) => {
      // console.log(item["filePath"]);
      const filePath = path.basename(item["filePath"]);

      return (
        <tr
          key={i}
          className={i === this.state.fileIndex ? "table-active" : ""}
          onClick={this.onClickFileListRow.bind(this, i,  item.file_name)}
        >
          <td>{filePath}</td>
          <td>
            <a href={item["filePath"]} download={filePath}>
              DLリンク
            </a>
          </td>
          <td>{item['timestamp']}</td>
        </tr>
      );
    });

    return (
      <Row className="mt-5 mb-5">
         <Col md={8} className="ovf-scroll">
          <div>
            {this.state.fileList.length === 0 && (
              <p>ファイルがまだありません</p>
            )}
            {this.state.fileList.length > 0 && (
              <Document
                // file={this.state.fileList[this.state.fileIndex]["filePath"]}
                file={'/chart_pdf/'+this.props.studentID+'/'+this.state.fileName}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={this.state.pageNumber} />
              </Document>
            )}
          </div>
        </Col>
        <Col md={4}>
          <Table id="chart_pdf_list" bordered hover>
            <thead>
              <th>PDF</th>
              <th>リンク</th>
              <th>提出日</th>
            </thead>
            <tbody>{fileListRow}</tbody>
          </Table>

          <Form.Group className="mt-5">
            <Form.Label>
              <h5>教員コメント</h5>
            </Form.Label>
            <Form.Control
              className=""
              as="textarea"
              rows={5}
              value={
                this.state.chartCommentInfo.length > 0
                  ? this.state.chartCommentInfo[this.state.fileIndex]["comment"]
                  : "なし"
              }
              onChange={this.onChartCommentChanged}
              disabled={this.state.isConfirm2Disabled}
            />
            <Button
              className="mt-4"
              variant="primary"
              size="lg"
              onClick={this.onSaveChartComment}
              disabled={this.state.isNotes}
            >
              保存
            </Button>
            {this.state.updateState === "success" && (
              <Alert
                variant={"success"}
                className="mt-3"
                dismissible
                show={this.state.showAlert}
                onClose={this.onCloseAlert}
              >
                コメントを保存しました
              </Alert>
            )}
            {this.state.updateState === "error" && (
              <Alert
                variant={"danger"}
                className="mt-3"
                dismissible
                show={this.state.showAlert}
                onClose={this.onCloseAlert}
              >
                コメントを保存に失敗しました
              </Alert>
            )}
          </Form.Group>
        </Col>
      </Row>
    );
  }
}

export default PDFViews;
