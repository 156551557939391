import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import IndexStudentBasicInfo from "../../IndexStudentBasicInfo";
import SelfEvaluationTableKansaiKokusaiKyusei from "./SelfEvaluationTableKansaiKokusaiKyusei";
import SelfEvaluationTableKansaiKokusaiTougou from "./SelfEvaluationTableKansaiKokusaiTougou";
import "../../../../assets/styles/SelfEvaluation.scss";

interface Props extends RouteComponentProps<{ studentID: string }> {}
interface State {}

class SelfEvaluationViewKansaiKokusai extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.goToSelfEvaluationTableKansaiKokusaiKyusei = this.goToSelfEvaluationTableKansaiKokusaiKyusei.bind(
      this
    );

    this.goToSelfEvaluationTableKansaiKokusaiTougou = this.goToSelfEvaluationTableKansaiKokusaiTougou.bind(
      this
    );
  }

  public goToSelfEvaluationTableKansaiKokusaiKyusei() {
    this.props.history.push(
      `/self_evaluation_kansai_kokusai_kyusei/${this.props.match.params.studentID}`
    );
  }

  public goToSelfEvaluationTableKansaiKokusaiTougou() {
    this.props.history.push(
      `/self_evaluation_kansai_kokusai_tougou/${this.props.match.params.studentID}`
    );
  }

  public render() {
    return (
      <Container className="mt-5 mb-5">
        <h2>自己評価の選択</h2>
        <Row>
          <Col>
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-info"
              onClick={this.goToSelfEvaluationTableKansaiKokusaiKyusei}
            >
              急性
            </Button>
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-info"
              onClick={this.goToSelfEvaluationTableKansaiKokusaiTougou}
            >
              統合
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SelfEvaluationViewKansaiKokusai);
