import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { SignupFacultyInfo } from "../models/Types";
import "../../assets/styles/SWCustom.scss";
import SignUpUniversitySelect from './forms/SignUpUniversitySelect';
import SignUpInternPlace from './forms/SignUpInternPlace';
import { getUserId, USER_TYPE } from "../../services/AppLocalHelper";
import { checkUserId } from "../../services/AuthService";

interface Props extends RouteComponentProps { }
interface State extends SignupFacultyInfo {
  facultyIdStatus: string;
  isShow: string;
  isFacultySupervisor: boolean;
}

class SignUpFaculty extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let newPass = this.makePassword(6);
    let userId = getUserId()
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }
    this.getFaculty(userId);
    this.state = {
      facultyID: "",
      facultyName: "",
      password: newPass,
      password2: newPass,
      passwordStatus: "",
      affiliation: "",
      isSupervisor: false,
      isSubSupervisor: false,
      internPlace: "",
      registrationKey: "",
      signupStatus: "",
      isShow: "password",
      facultyIdStatus: "",
      isFacultySupervisor: false,
    };

    this.onFacultyIDChanged = this.onFacultyIDChanged.bind(this);
    this.onFacultyNameChanged = this.onFacultyNameChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onPassword2Changed = this.onPassword2Changed.bind(this);
    this.onAffiliationChanged = this.onAffiliationChanged.bind(this);
    this.onIsSupervisorChanged = this.onIsSupervisorChanged.bind(this);
    this.onRegistrationKeyChanged = this.onRegistrationKeyChanged.bind(this);
    this.signUpFaculty = this.signUpFaculty.bind(this);
    this.onInternPlaceChanged = this.onInternPlaceChanged.bind(this);
    this.getLastRecord = this.getLastRecord.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.makePassword = this.makePassword.bind(this);
  }

  public onChangeType() {
    let currentType: string = "";
    this.state.isShow == "password" ? currentType = "text" : currentType = "password"


    this.setState({
      isShow: currentType
    })
  }
  public getFaculty(facultyID: string){
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${facultyID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
          console.log('getFaculty', result);
          this.setState({isFacultySupervisor : result.is_supervisor});
      })
      .catch(console.error);
}
  public makePassword(length: any) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
  }

  public onFacultyIDChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const facultyID = e.target.value;
    this.setState({ facultyID: facultyID });
  }

  public onFacultyNameChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const facultyName = e.target.value;
    this.setState({ facultyName: facultyName });
  }

  public onPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const password = e.target.value;
    this.setState({ password: password });
  }

  public onPassword2Changed(e: React.ChangeEvent<HTMLInputElement>) {
    const password2 = e.target.value;
    this.setState({ password2: password2 });
  }

  public onAffiliationChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const affiliation = e.target.value;
    this.setState({ affiliation: affiliation });
  }

  public onIsSupervisorChanged(e: React.ChangeEvent<HTMLInputElement>) {
   
    if(e.target.checked){
      this.setState({ isSupervisor: e.target.checked,isSubSupervisor: !e.target.checked });
    }else{
      this.setState({ isSupervisor: e.target.checked });
    }
}
  public onIsSubSupervisorChanged(e: React.ChangeEvent<HTMLInputElement>) {
    if(e.target.checked){
      this.setState({ isSubSupervisor: e.target.checked, isSupervisor: !e.target.checked });
    }else{
      this.setState({ isSubSupervisor: e.target.checked});
    }
  }

  public onRegistrationKeyChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const registrationKey = e.target.value;
    this.setState({ registrationKey: registrationKey });
  }
  public onInternPlaceChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const internPlace: string = e.target.value;
    this.setState({ internPlace: internPlace });
  }

  public getLastRecord() {
    const url = `${process.env.REACT_APP_API_URL}/api/signup/get_last_id`;

    fetch(url, { mode: "cors" })
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isSupervisor: result.faculty[0]['is_supervisor'],
            affiliation: result.faculty[0]['affiliation'],
            internPlace: result.faculty[0]['intern_place'],
          });
        },
        error => {
          console.log("API Error");
        }
      );
  }

  public componentDidMount() {
    this.getLastRecord()
  }

  public signUpFaculty(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    let format = /[ !　@#$%^&*()+=\[\]{};':"\\|,<>\/?]+/;

    if (format.test(this.state.facultyID)) {
      this.setState({ facultyIdStatus: "fail" });

    } else {
      if (this.state.password === this.state.password2) {
        const signupFacultyInfo = {
          faculty_info: {
            faculty_id: this.state.facultyID,
            faculty_name: this.state.facultyName,
            password: this.state.password,
            affiliation: this.state.affiliation,
            is_supervisor: this.state.isSupervisor,
            is_subsupervisor:   this.state.isSubSupervisor,
            intern_place: this.state.internPlace,
            registration_key: this.state.registrationKey,
          },
        };
        const url = `${process.env.REACT_APP_API_URL}/api/signup_faculty`;
        const method = "POST";
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const body = JSON.stringify(signupFacultyInfo);

        fetch(url, { method, headers, body })
          .then((res) => res.json())
          .then((result) => {
            console.log(result);
            if (result.status === "success") {
              this.props.history.push(`/index/faculty/${this.state.facultyID}`);
            } else {
              this.setState({ signupStatus: result.status });
            }
          })
          .catch(console.error);
      } else {
        this.setState({ passwordStatus: "fail" });
      }
    }
  }

  public render() {
    return (
      <div className="mt-5 mb-5">
        <h2 className="mb-5">教員登録</h2>
        <Form className="mt-4" onSubmit={this.signUpFaculty} autoComplete="off">
          <Form.Group as={Row} controlId="formBasicEmail">
            <Form.Label column md={4} className="font-weight-bold text-right">
              教員ID
            </Form.Label>
            <Col md={6}>
              <Form.Control
                required
                type="text"
                placeholder="Enter your faculty ID"
                autoComplete="new-ID"
                onChange={this.onFacultyIDChanged}
              />
              <Form.Text className="text-muted text-left">
                &nbsp;半角英数字で入力
              </Form.Text>
              {this.state.facultyIdStatus === "fail" && (
                <Alert className="" variant="danger">
                  /\%$#@は使用できません
                </Alert>
              )}

            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formBasicEmail" className="mb-5">
            <Form.Label column md={4} className="font-weight-bold text-right">
              氏名
            </Form.Label>
            <Col md={6}>
              <Form.Control
                required
                type="text"
                placeholder="Enter your name"
                autoComplete="new-name"
                onChange={this.onFacultyNameChanged}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formBasicEmail">
            <Form.Label column md={4} className="font-weight-bold text-right">
              パスワード
            </Form.Label>
            <Col md={6}>
              <Form.Control
                required
                type={this.state.isShow}
                placeholder="Enter password"
                autoComplete="new-password"
                onChange={this.onPasswordChanged}
                value={this.state.password}
              />
              <Form.Text className="text-muted text-left">
                &nbsp;半角英数字で入力
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formBasicEmail">
            <Form.Label column md={4} className="font-weight-bold text-right">
              パスワード（再入力）
            </Form.Label>
            <Col md={6}>
              <Form.Control
                required
                type={this.state.isShow}
                placeholder="Enter password"
                autoComplete="new-password"
                onChange={this.onPassword2Changed}
                value={this.state.password2}
              />
              <Form.Text className="text-muted text-left">
                &nbsp;半角英数字で入力
              </Form.Text>
              {this.state.passwordStatus === "fail" && (
                <Alert className="" variant="danger">
                  パスワードが一致しません
                </Alert>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="" className="mb-5">
            <Form.Label column md={4} className="font-weight-bold text-right clor-lightblue">

            </Form.Label>
            <Col md={6}>
              <input
                type="checkbox"
                className="check-show-password"
                // type="password"
                // placeholder="Enter password"
                onClick={this.onChangeType}
              />
              <Form.Text className="text-muted text-left m-0">
                パスワード表示
              </Form.Text>
              <div className="clear"></div>
            </Col>

          </Form.Group>


          <Form.Group as={Row}>
            <Form.Label column md={4} className="font-weight-bold text-right">
              所属
            </Form.Label>
            <Col md={6}>
              <SignUpUniversitySelect value={this.state.affiliation} onItemSelect={this.onAffiliationChanged} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="fg-6">
            <Form.Label column md={4} className="font-weight-bold text-right">
              実習場所
            </Form.Label>
            <Col md={6}>
              <SignUpInternPlace value={this.state.internPlace} onItemSelect={this.onInternPlaceChanged} />
            </Col>
          </Form.Group>
          {/* <Form.Group as={Row} className="mt-4 mb-4">
            <Form.Label column md={4} className="font-weight-bold text-right">
              実習担当
            </Form.Label>
            <Col md={6}>
              <Form.Check
                type="checkbox"
                label="看護実習で学生を受けもつ"
                className="text-left mt-1"
                checked={this.state.isSupervisor}

                onChange={this.onIsSupervisorChanged}
              />
            </Col>
          </Form.Group> */}
          <Form.Group as={Row} className="mt-4 mb-4">
              <Form.Label column md={4} className="font-weight-bold text-right">
              管理者権限 
              </Form.Label>
              <Col md={6}>
                  <Form.Check
                      type="checkbox"
                      label=" システム管理者"
                      className="text-left mt-1"
                      checked={this.state.isSupervisor}
                      disabled={!this.state.isFacultySupervisor}
                      onChange={this.onIsSupervisorChanged.bind(this)}
                  />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-4 mb-4">
                <Form.Label column md={4} className="font-weight-bold text-right">
                  
                </Form.Label>
                <Col md={6}>
                    <Form.Check
                        type="checkbox"
                        label="施設管理者"
                        className="text-left mt-1"
                        checked={this.state.isSubSupervisor}
                        onChange={this.onIsSubSupervisorChanged.bind(this)}
                    />
                </Col>
            </Form.Group>
          {/* <Form.Group as={Row} className="mb-5">
            <Form.Label column md={4} className="font-weight-bold text-right">
              登録キー
            </Form.Label>
            <Col md={6}>
              <Form.Control
                required
                type=""
                placeholder="Enter registration key"
                onChange={this.onRegistrationKeyChanged}
              />
              <Form.Text className="text-muted text-left">
                &nbsp;半角英数字で入力
              </Form.Text>
            </Col>
          </Form.Group> */}
          <Row>
            <Col md={2} />
            <Col md={8}>
              <Button className="d-none" type="submit" disabled>
                disabled enter
              </Button>
              <Button className="mt-5 sw-custom-btn" variant="primary" type="submit" block>
                登録
              </Button>
              {this.state.signupStatus === "faculty exists" && (
                <Alert className="mt-3" variant="danger">
                  教員IDが登録済みです
                </Alert>
              )}
              {this.state.signupStatus ===
                "supervisor registration key incorrect" && (
                  <Alert className="mt-3" variant="danger">
                    実習担当教員の登録キーが間違っています
                  </Alert>
                )}
              {this.state.signupStatus ===
                "faculty registration key incorrect" && (
                  <Alert className="mt-3" variant="danger">
                    一般教員の登録キーが間違っています
                  </Alert>
                )}
            </Col>
            <Col md={2} />
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(SignUpFaculty);
