import React, { useEffect } from 'react';
import { useHomePath } from './../../helpers/HomePathContext';

const AutoHomePath = (props: any) => {
    const { setHomePath } = useHomePath()
    useEffect(() => {
        setHomePath(props.pathname || "/")
        localStorage.setItem("homepath", props.pathname)
    }, []);

    return (
        null
    );
}

export default AutoHomePath;
