import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import SurgeryRecord from "./surgery_record/SurgeryRecord";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "../../assets/styles/PatientInfoItems.scss";

import PatientInfoControl503 from './../faculty_view/patient_info_control/PatientInfoControl503';
import { checkUserId } from "../../services/AuthService";
import { USER_TYPE } from "../../services/AppLocalHelper";


interface Props
  extends RouteComponentProps<{
    patientID: string;
    studentID: string;
    facultyID: string;
    startDate: string;
    university: string;
    endDate: string;
  }> { }
// SW add 2021/3/1
interface State {
  isSupervisor: boolean;
  affiliation: string;
  facultyID: string;
  record_type: number;
}

class PatientInfoButtons extends React.Component<Props, State> {

  private faculty_id: string | null;
  private type: string | null;

  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID === "supervisor-faculty" ? this.props.match.params.facultyID : this.props.match.params.studentID;
    if (!checkUserId(userId, this.props.match.params.studentID === "supervisor-faculty" ? USER_TYPE.TEACHER : USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }

    this.onClickButton = this.onClickButton.bind(this);
    this.goToSurgeryRecord = this.goToSurgeryRecord.bind(this);
    this.goToJosanRecord = this.goToJosanRecord.bind(this);
    // SW add 2021/3/1
    this.state = { isSupervisor: false, affiliation: "", facultyID: "", record_type: 0 };
    this.fetchFacultyInfo = this.fetchFacultyInfo.bind(this);
    // this.getType = this.getType.bind(this);


    const query = new URLSearchParams(this.props.location.search);
    this.faculty_id = query.get('facultyID');
    this.type = query.get("type");
  }
  // SW add 2021/3/1
  public fetchFacultyInfo(
    isSupervisor: boolean,
    affiliation: string,
    facultyID: string
  ) {
    this.setState({
      isSupervisor: isSupervisor,
      affiliation: affiliation,
      facultyID: facultyID,
    });
  }



  onClickButton = (itemUrl: string, e: React.MouseEvent<HTMLElement>) => {

    // SW add 2021/3/3
    const type = this.type;
    if (type == "setting") {
      switch (itemUrl) {
        case "overview":
          this.props.history.push("/create-new/patient-information-overview", {
            patientID: this.props.match.params.patientID,
            facultyID: this.faculty_id,
            studentID: this.props.match.params.studentID,
          });
          break;
        case "medical_records":
          this.props.history.push(
            "/create-new/patient-information-medical-records",
            {
              data: {
                patientID: this.props.match.params.patientID,
                facultyID: this.faculty_id,
                studentID: this.props.match.params.studentID,
              },
            }
          );
          break;
        case "test_data":
          this.props.history.push("/create-new/patient-information-test-data", {
            patientID: this.props.match.params.patientID,
            facultyID: this.faculty_id,
            studentID: this.props.match.params.studentID,
          });
          break;
        case "test_data_manager":
          this.props.history.push("/create-new/manager-test-data-items", {
            patientID: this.props.match.params.patientID,
            facultyID: this.faculty_id,
            studentID: this.props.match.params.studentID,
          });
          break;
        case "nurse_info":
          this.props.history.push("/create-new/nursing-information", {
            data: {
              patientID: this.props.match.params.patientID,
              studentID: this.props.match.params.studentID,
            },
          });
          break;
        case "nurse_diagnosis":
          this.props.history.push("/create-new/nursing-diagnosis", {
            data: {
              patientID: this.props.match.params.patientID,
              facultyID: this.faculty_id,
              studentID: this.props.match.params.studentID,
            },
          });
          break;
        case "doctor_instruction":
          this.props.history.push(
            "/create-new/patient-information-doctor-instruction",
            {
              data: {
                patientID: this.props.match.params.patientID,
                facultyID: this.faculty_id,
                studentID: this.props.match.params.studentID,
              },
            }
          );
          break;
          case "pdf-manager":
            this.props.history.push(
              `/create-new/patient-pdf-manager`,
              {
                data: {
                  patientID: this.props.match.params.patientID,
                  facultyID: this.faculty_id,
                  studentID: this.props.match.params.studentID,
                },
              }
            )
            break;
  
        default:
          break;
      }
    } else {
      if (itemUrl === "overview") {
        const patient_id_num = this.props.match.params.patientID.replace(
          "patient",
          ""
        );
        const url = `/patient_info/${this.props.match.params.patientID}/${this.props.match.params.studentID}/${this.props.match.params.facultyID}/${this.props.match.params.startDate}/${this.props.match.params.endDate}/${this.props.match.params.university}/over_view/${itemUrl}${patient_id_num}`;
        this.props.history.push(url);
      } else if (itemUrl === "test_data") {
        const patient_id_num = this.props.match.params.patientID.replace(
          "patient",
          ""
        );
        const url = `/patient_info/${this.props.match.params.patientID}/${this.props.match.params.studentID}/${this.props.match.params.facultyID}/${this.props.match.params.startDate}/${this.props.match.params.endDate}/${this.props.match.params.university}/${itemUrl}${patient_id_num}`;
        this.props.history.push(url);
      }  else if (itemUrl === "pdf-manager") {
        this.props.history.push(`/patient_info/${this.props.match.params.patientID}/pdf-manager`)
      }
      else {
        this.props.history.push(
          `/patient_info/${this.props.match.params.patientID}/${this.props.match.params.studentID}/${this.props.match.params.facultyID}/${this.props.match.params.startDate}/${this.props.match.params.endDate}/${this.props.match.params.university}/${itemUrl}`
        );
      }
    }

  };

  buttons = [
    {
      itemName: "オーバービュー",
      itemUrl: "overview",
    },
    {
      itemName: "診療記録",
      itemUrl: "medical_records",
    },
    {
      itemName: "検査データ",
      itemUrl: "test_data",
    },
    {
      itemName: "看護情報",
      itemUrl: "nurse_info",
    },
    {
      itemName: "看護診断",
      itemUrl: "nurse_diagnosis",
    },
    {
      itemName: "医師の指示",
      itemUrl: "doctor_instruction",
    },
    {
      itemName: "記録PDF",
      itemUrl: "pdf-manager",
    },
    // {
    //   itemName: "タ項目設定",
    //   itemUrl: "test_data_manager",
    // },
  ];

  public goToSurgeryRecord() {
    const type = this.type;
    if (type == 'setting') {
      this.props.history.push(
        `/create-new/patient-information-surgery-record`
      );
    } else {
      this.props.history.push(
        `/patient_info/${this.props.match.params.patientID}/${this.props.match.params.studentID}/${this.props.match.params.facultyID}/${this.props.match.params.startDate}/${this.props.match.params.endDate}/${this.props.match.params.university}/surgery_record/all`
      );
    }

  }

  public goToJosanRecord() {
    const type = this.type;
    if (type == 'setting') {
      this.props.history.push(
        `/create-new/patient-information-midwifery-record`
      );
    } else {
      this.props.history.push(
        `/patient_info/${this.props.match.params.patientID}/${this.props.match.params.studentID}/${this.props.match.params.facultyID}/${this.props.match.params.startDate}/${this.props.match.params.endDate}/${this.props.match.params.university}/josan_record/all`
      );
    }
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/get_record_type?patient_id=${this.props.match.params.patientID}`;

    fetch(url, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            record_type: result
          });
        },
        (error) => {
          console.log("Error: ");
        }
      );
  }

  public renderButtons() {

    const buttons = this.buttons.map((item, i) => (
      <Button
        key={i}
        className="student_info_item mr-4 font-weight-bold"
        variant="outline-secondary"
        onClick={this.onClickButton.bind(this, item.itemUrl)}
      >
        {item.itemName}
      </Button>
    ));

    const overview = buttons[0];
    const medicalRecords = buttons[1];
    const testData = buttons[2];
    const nurseInfo = buttons[3];
    const nurseDiagnosis = buttons[4];
    const doctorInstruction = buttons[5];
    const testDataManager = buttons[6];
    const pdfManager = buttons[6];
    const recordType = this.state.record_type;
    const actionType = new URLSearchParams(this.props.location.search).get('type');

    if (this.state.record_type === 1) {
      return (
        <div>
          <Row className="mb-5">
            <Col md={2} />
            <Col md={8}>
              {overview}
              {medicalRecords}
              {testData}
            </Col>
            <Col md={2} />
          </Row>
          <Row>
            <Col md={2} />
            <Col md={8} className="">
              {nurseInfo}
              {nurseDiagnosis}
              {doctorInstruction}
              {actionType && actionType !== 'setting' && (
                <Button
                  className="student_info_item mr-4 font-weight-bold"
                  variant="outline-secondary"
                  onClick={this.goToSurgeryRecord}
                >
                  手術記録
                </Button>
              )}
              {actionType && actionType === 'setting' && pdfManager}
              <br />
            </Col>
            <Col md={2} />
          </Row>
        </div>
      );
    } else if (this.state.record_type === 2) {
      return (
        <div>
          <Row className="mb-5">
            <Col md={2} />
            <Col md={8}>
              {overview}
              {medicalRecords}
              {testData}
              {/* {testDataManager} */}
            </Col>
            <Col md={2} />
          </Row>
          <Row>
            <Col md={2} />
            <Col md={8} className="">
              {nurseInfo}
              {nurseDiagnosis}
              {doctorInstruction}
              {actionType && actionType !== 'setting' && (
                <Button
                className="student_info_item mr-4 font-weight-bold"
                variant="outline-secondary"
                onClick={this.goToJosanRecord}
              >
                  助産記録
              </Button>
              )}
              {actionType && actionType === 'setting' && pdfManager}
              <br />
            </Col>
            <Col md={2} />
          </Row>
        </div>
      );
    } else {
      return (
        <div>
          <Row className="mb-5">
            <Col md={2} />
            <Col md={8}>
              {overview}
              {medicalRecords}
              {testData}
            </Col>
            <Col md={2} />
          </Row>
          <Row>
            <Col md={2} />
            <Col md={8} className="">
              {nurseInfo}
              {nurseDiagnosis}
              {doctorInstruction}
              {actionType && actionType === 'setting' && pdfManager}
              <br />
            </Col>
            <Col md={2} />
          </Row>
        </div>
      );
    }
  }

  public render() {
    const isRenderAdditionalCol: Boolean = this.type === "setting";
    const isStudent: Boolean = this.props.match.params.studentID === "supervisor-faculty";

    return (
      <div>
        {this.renderButtons()}
        {!isRenderAdditionalCol && (
          isStudent && (
            <>
              <Row className="d-flex justify-content-center">
                <PatientInfoControl503
                  facultyID={this.props.match.params.facultyID}
                  patientID={this.props.match.params.patientID}
                  university={this.props.match.params.university}
                  startDate={this.props.match.params.startDate}
                  endDate={this.props.match.params.endDate}
                />
              </Row>
            </>
          )

        )}

      </div>
    )
  }

}

export default withRouter(PatientInfoButtons);
