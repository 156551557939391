import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import { NursingDiagnosisType, NursingCommentInfoType } from "../../models/Types";
import "../../../assets/styles/NursingDiagnosis.scss";
import "../../../assets/styles/SWCustom.scss";
import { checkUserId } from "../../../services/AuthService";
import { getUserId, USER_TYPE } from "../../../services/AppLocalHelper";
// import { AnyAaaaRecord } from "node:dns";

interface Props
  extends RouteComponentProps<{
    studentID: string;
  }> { }

interface State {
  studentID: string;
  studentName: string;
  university: string;
  startDate: string;
  nursingDiagnosisArray: NursingDiagnosisType[];
  apiStatus: string;
  // SW add 2021/3/8
  nursingCommentInfo: string;
  fileIndex: number;
  updateState: string;
  showAlert: boolean;
  patientInfos: string;
  overviewMaxDay: string;
  trainingDays: string;
}

class NursingDiagnosisFaculty extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let { location } = this.props;
    console.log('location:', location)
    let data = location.state as any;

    this.state = {
      studentID: this.props.match.params.studentID,
      studentName: "",
      university: "",
      startDate: "",
      nursingDiagnosisArray: [],
      apiStatus: "",
      nursingCommentInfo: '',
      fileIndex: 0,
      updateState: "",
      showAlert: true,
      patientInfos: "",
      overviewMaxDay:"",
      trainingDays: "",
    };
    this.onSaveNursingComment = this.onSaveNursingComment.bind(this);
    this.getFacultyID = this.getFacultyID.bind(this);
    this.onNursingCommentChanged = this.onNursingCommentChanged.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);

    let userId = this.getFacultyID() == null ? getUserId() : this.getFacultyID()
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }

  }

  public onNursingCommentChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const newComment: string = e.target.value;
    console.log("🚀 ~ file: NursingDiagnosisFaculty.tsx ~ line 59 ~ NursingDiagnosisFaculty ~ onNursingCommentChanged ~ newComment", newComment)

    this.setState({
      nursingCommentInfo: newComment
    });
  }

  public getFacultyID() {
    const query = new URLSearchParams(this.props.location.search)
    const facultyID = query.get("facultyID")
    return facultyID;
  }

  public onSaveNursingComment(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const nursingComment = {
      student_id: this.props.match.params.studentID,
      faculty_id: this.getFacultyID(),
      nursing_comment_info: this.state.nursingCommentInfo
    };

    const body = JSON.stringify(nursingComment);
    const url = `${process.env.REACT_APP_API_URL}/api/nuring_diagnosis_faculty_comment/comment_save`;
    const method = 'POST';
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    fetch(url, { method, headers, body })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.status === "success") {
          this.setState({ updateState: "success", showAlert: true });
          console.log("API success!!");
        } else {
          this.setState({ updateState: "error" });
          console.log("API Error");
        }
      })
      .catch(console.error);

  }

  public onCloseAlert() {
    this.setState({ showAlert: false });
  }

  public onClickCancel() {
    this.props.history.goBack();
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${this.props.match.params.studentID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          studentName: result.studentName,
          university: result.university,
          startDate: result.startDate,
          patientInfos: result.patientInfos,
          overviewMaxDay: result.overviewMaxDay,
          trainingDays: result.internDay,
        });
      })
      .catch(console.error);

    const url2 = `${process.env.REACT_APP_API_URL}/api/nursing_diagnosis/student/fetch_list?student_id=${this.props.match.params.studentID}`;
    fetch(url2)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          nursingDiagnosisArray: result,
        });
      })
      .catch(console.error);

    const url3 = `${process.env.REACT_APP_API_URL}/api/nursing_diagnosis/faculty/comment_list?student_id=${this.props.match.params.studentID}&faculty_id=${this.getFacultyID()}`;
    fetch(url3, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            nursingCommentInfo: result,
          });
        },
        (error) => {
          console.log("API Error");
        }
      );
  }

  public render() {
    const nursingDiagnosisRow = this.state.nursingDiagnosisArray
      .filter((item) => item.deleted === false)
      .map((item, i) => (
        <tr key={i}>
          <td>{`No.${i + 1}`}</td>
          <td>{item.date}</td>
          <td>{item.status}</td>
          <td id="nursing_diagnosis" className="p-0">
          <div>
              <Form.Row>
                <div className="fl-left w-10"> 診断名： </div>
                <div className="fl-left w-75 text-ip">
                <pre className="text-left">
                  {item.diagnosisName}
                </pre>
                </div>
              </Form.Row>
            </div>
            <div>
              <Form.Row>
                <div className="fl-left w-10"> O： </div>
                <div className="fl-left w-75 text-ip">
                <pre className="text-left">
                  {item.o == null ? '' : item.o}
                </pre>
                </div>
              </Form.Row>
            </div>
            <div>
              <Form.Row>
                <div className="fl-left w-10"> T： </div>
                <div className="fl-left w-75 text-ip">
                <pre className="text-left">
                  {item.t == null ? '' : item.t}
                </pre>               
                </div>
              </Form.Row>
            </div>
            <div>
              <Form.Row>
                <div className="fl-left w-10"> E： </div>
                <div className="fl-left w-75 text-ip">
                <pre className="text-left">
                  {item.e == null ? '' : item.e}
                </pre>
                </div>
              </Form.Row>
            </div>
          </td>
        </tr>
      ));

    return (
      <Row>
        <Col md={2} />
        <Col md={8}>
          <div>
            <Table id="nursing_plan_basic_info" className="m-5" bordered>
              <thead className="thead-dark">
                <tr>
                  <th>学籍番号</th>
                  <th>学生氏名</th>
                  <th>施設名</th>
                  <th>実習開始日</th>
                  <th>担当患者</th>
                  <th>患者の入院日数</th>
                  <th>実習日</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.props.match.params.studentID}</td>
                  <td>{this.state.studentName}</td>
                  <td>{this.state.university}</td>
                  <td>{this.state.startDate}</td>
                  <td>{this.state.patientInfos}</td>
                  <td>{this.state.overviewMaxDay}</td>
                  <td>{this.state.trainingDays} 日目</td>
                </tr>
              </tbody>
            </Table>
            <Table id="nursing_diagnosis" className="m-5" bordered>
              <thead className="thead-dark">
                <tr>
                  <th id="number">No</th>
                  <th id="date">診断年月日</th>
                  <th id="status">状況</th>
                  <th id="diagnosis">看護診断/共同問題</th>
                </tr>
              </thead>
              <tbody>{nursingDiagnosisRow}</tbody>
            </Table>
            <Form.Group className="m-5 sw-title-left">
              <h5 className="sw-title-left">教員コメント</h5>
              <Form.Control className="" as="textarea" rows={5}
                value={this.state.nursingCommentInfo}
                onChange={this.onNursingCommentChanged}
              />
              <Button className="mt-4" variant="primary" size="lg"
                onClick={this.onSaveNursingComment}
              >
                保存
              </Button>
              <Button
                className="ml-3 mt-4"
                variant="outline-secondary"
                size="lg"
                type="button"
                onClick={this.onClickCancel}
              >
                戻る
              </Button>
              {this.state.updateState === "success" && (
                <Alert
                  variant={"success"}
                  className="mt-3"
                  dismissible
                  show={this.state.showAlert}
                  onClose={this.onCloseAlert}
                >
                  コメントを保存しました
                </Alert>
              )}
              {this.state.updateState === "error" && (
                <Alert
                  variant={"danger"}
                  className="mt-3"
                  dismissible
                  show={this.state.showAlert}
                  onClose={this.onCloseAlert}
                >
                  コメントを保存に失敗しました
                </Alert>
              )}
            </Form.Group>
          </div>
        </Col>
        <Col md={2} />
      </Row>
    );
  }
}

export default withRouter(NursingDiagnosisFaculty);
