import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { LoginStudentInfo } from "../models/Types";
import { clearUserId, clearUserType, saveUserId, saveUserType, USER_TYPE } from "../../services/AppLocalHelper";

interface Props extends RouteComponentProps { }
interface State extends LoginStudentInfo {
  isShow: string;
  facultyIdStatus: string;
}

class LoginStudent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    clearUserType()
    clearUserId()
    this.state = {
      studentID: "",
      password: "",
      loginStatus: "",
      isShow: "password",
      facultyIdStatus: "",
    };

    this.onStudentIDChanged = this.onStudentIDChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.loginStudent = this.loginStudent.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
  }

  public onStudentIDChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const studentID: string = e.target.value;
    this.setState({ studentID: studentID });
  }

  public onPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const password: string = e.target.value;
    this.setState({ password: password });
  }

  public onChangeType() {
    let currentType: string = "";
    this.state.isShow == "password" ? currentType = "text" : currentType = "password"

    this.setState({
      isShow: currentType
    })
  }

  public loginStudent(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    let format = /[!@#$%^&*()+=\[\]{};':"\\|,<>\/?]+/;

    if (format.test(this.state.studentID)) {
      this.setState({ facultyIdStatus: "fail" });
    } else {

      const url = `${process.env.REACT_APP_API_URL}/api/login_student`;
      const loginStudentInfo = {
        student_id: this.state.studentID,
        password: this.state.password,
      };
      const method = "POST";
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const body = JSON.stringify(loginStudentInfo);

      fetch(url, { method, headers, body })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          if (result.login_status === "success") {
            saveUserId(this.state.studentID)
            saveUserType(USER_TYPE.STUDENT)
            if (result.student_name == '' || result.student_name == null) {
              this.props.history.push(`/update_info/${result.student_id}`);
            } else {
              this.props.history.push(`/index/student/${this.state.studentID}`);
            }
          } else {
            this.setState({ loginStatus: result.login_status });
          }
        })
        .catch(console.error);
    }
  }

  public render() {
    return (
      <div className="mt-5 mb-5 p-3">
        <h2 className="mb-5 clor-lightblue">学生ログイン</h2>
        <Form className="mt-4">
          <Form.Group as={Row} controlId="formBasicEmail">
            <Form.Label column md={4} className="font-weight-bold text-right clor-lightblue">
              学籍番号
            </Form.Label>
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder="Enter student ID"
                onChange={this.onStudentIDChanged}
              />
              <Form.Text className="text-muted text-left">
                &nbsp;半角英数字で入力
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formBasicEmail">
            <Form.Label column md={4} className="font-weight-bold text-right clor-lightblue">
              パスワード
            </Form.Label>
            <Col md={6}>
              <Form.Control
                // type="password"
                type={this.state.isShow}
                placeholder="Enter password"
                id="inputPassword"
                onChange={this.onPasswordChanged}
              />
              <Form.Text className="text-muted text-left">
                &nbsp;半角英数字で入力
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formBasicEmail">
            <Form.Label column md={4} className="font-weight-bold text-right clor-lightblue">

            </Form.Label>
            <Col md={6}>
              <input
                type="checkbox"
                className="check-show-password"
                // type="password"
                // placeholder="Enter password"
                onClick={this.onChangeType}
              />
              <Form.Text className="text-muted text-left m-0">
                パスワード表示
              </Form.Text>
              <div className="clear"></div>
            </Col>

          </Form.Group>
          <Row>
            <Col md={1} />
            <Col md={10}>
              <Button
                className="login mb-3 bg-lightblue"

                type="submit"
                block
                onClick={this.loginStudent}
              >
                Login
              </Button>
            </Col>
            <Col md={1} />
          </Row>
        </Form>
        {this.state.loginStatus === "no_user" && (
          <Alert className="" variant="danger">
            学籍番号が間違っています
          </Alert>
        )}
        {this.state.loginStatus === "password_incorrect" && (
          <Alert className="" variant="danger">
            パスワードが間違っています
          </Alert>
        )}
        {this.state.loginStatus === "login_prohibited" && (
          <Alert className="" variant="danger">
            ログイン許可がありません
          </Alert>
        )}
        {/* SW add 2021/3/3 */}
        {this.state.loginStatus === "expired_day" && (
          <Alert className="" variant="danger">
            使用可能期間外です
          </Alert>
        )}
        {this.state.facultyIdStatus === "fail" && (
          <Alert className="" variant="danger">
            /\%$#@は使用できません
          </Alert>
        )}
      </div>
    );
  }
}

export default withRouter(LoginStudent);
