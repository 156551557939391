import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Dropzone from "react-dropzone";
import '../../../../assets/styles/DropzoneBox.scss'
import moment from "moment";


interface Props extends RouteComponentProps {
  onChange: (file: File) => void;
}
interface State { }

class DropzoneBox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onDropFile = this.onDropFile.bind(this);
  }

  public onDropFile(acceptedFiles: File[]) {
    this.props.onChange(acceptedFiles[0]);
  }

  public render() {
    return (
      <Dropzone onDrop={this.onDropFile} accept="application/pdf">
        {({ getRootProps, getInputProps }) => (
          <section id="dropzone_box-patient">
            <div id="dropzone_area-patient" {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="clor-lightnavi">ここにPDFファイルをドラッグ＆ドロップ</p>
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}

export default withRouter(DropzoneBox);
