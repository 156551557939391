import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import IndexStudentBasicInfo from "../student_view/IndexStudentBasicInfo";
// import IndexStudentAttendance from "../student_view/IndexStudentAttendance";
import "../../assets/styles/IndexStudent.scss";
import "../../assets/styles/SWCustom.scss";
import { getUserId, USER_TYPE } from "../../services/AppLocalHelper";
import { checkUserId } from "../../services/AuthService";

interface Props extends RouteComponentProps<{ studentID: string }> {}
interface State {
  nursingPlans: {
    date: string;
    createdAt: string;
    updatedAt: string;
    completed: boolean;
  }[];
}

class IndexStudentForFaculty extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = getUserId()
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }
    
    let { location } = this.props;
    let data = location.state as any
    this.state = {
      nursingPlans: [],
    };

    this.goToNursePlan = this.goToNursePlan.bind(this);
    this.goToSelfEvaluation = this.goToSelfEvaluation.bind(this);
    // SW add 2021/3/3
    this.goToNuringPlans = this.goToNuringPlans.bind(this);
    this.goToChartView = this.goToChartView.bind(this);
    this.goToAllNursingDiagnosisView = this.goToAllNursingDiagnosisView.bind(this);
    this.getFacultyID = this.getFacultyID.bind(this);
  }

  public goToNursePlan() {
    this.props.history.push(`/nurse_plan/${this.props.match.params.studentID}`);
  }

  public goToSelfEvaluation() {
    this.props.history.push(
      `/self_evaluation/${this.props.match.params.studentID}`
    );
  }
  public getFacultyID(){
    const query = new URLSearchParams(this.props.location.search)
    const facultyID = query.get("facultyID")
    return facultyID;
  }
  public componentDidMout() {
    // fetch nursing plan list
    const url = `${process.env.REACT_APP_API_URL}/api/student_info/nursing_plan_list?student_id=${this.props.match.params.studentID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        // this.setState({
        // studentList: result
        // });
      })
      .catch(console.error);
  }

  public goToNuringPlans() {
    const facultyID = this.getFacultyID();
    this.props.history.push(
      `/faculty/all_nursing_plans/${facultyID}?student_id=${this.props.match.params.studentID}`
    );
  }
  public goToChartView() {
    const facultyID = this.getFacultyID();
    // this.props.history.push(
    //   `/chart_view_all/${facultyID}?student_id=${this.props.match.params.studentID}`
    // );

    this.props.history.push(
      `/chart_view_for_faculty/${facultyID}/${this.props.match.params.studentID}`);
  }
  public goToAllNursingDiagnosisView() {
    const facultyID = this.getFacultyID();
    // this.props.history.push(
    //   `/nursing_diagnosis/faculty_all_list/${facultyID}?student_id=${this.props.match.params.studentID}`
    // );
    this.props.history.push(`/nursing_diagnosis/faculty/${this.props.match.params.studentID}?facultyID=${facultyID}`);
  }
  public render() {
    return (
      <div className="mt-5 mb-5 container">
        <h2>学生 Index For Faculty</h2>
        <Row>
          <Col md={5}>
            <IndexStudentBasicInfo
              studentID={this.props.match.params.studentID}
            />
          </Col>
          <Col md={7} className="custom-pad-top">
          <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-danger"
              onClick={this.goToNuringPlans}
            >
              看護計画一覧
            </Button>
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-danger"
              onClick={this.goToChartView}
            >
              関連図
            </Button>
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-danger"
              onClick={this.goToAllNursingDiagnosisView}
            >
              看護診断
            </Button>
               
                
              </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(IndexStudentForFaculty);
