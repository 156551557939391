import React from "react";
import { RouteComponentProps } from "react-router-dom";
import OverviewTableLeft from "./OverviewTableLeft";
import OverviewTableData from "./OverviewTableData";
import "../../../../assets/styles/Overview.scss";
import "../../../../assets/styles/SWCustom.scss";
import {
  PatientInfoOverview301Col,
  PatientInfoOverview301Row,
} from "../../../models/Types";
import OverviewTableRow from "./OverviewTableRow";
import OverviewTableCol from "./OverviewTableCol";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Line } from 'react-chartjs-2';
import Table from "react-bootstrap/Table";
import { checkUserId } from "../../../../services/AuthService";
import { USER_TYPE } from "../../../../services/AppLocalHelper";

interface Props
  extends RouteComponentProps<{
    patientID: string;
    studentID: string;
    facultyID: string;
    startDate: string;
  }> { }
interface State {
  dataRow: any;
  dataCol: [];
  chartData: {};
  patientInfoOverview: {
    col: PatientInfoOverview301Row[];
    row: PatientInfoOverview301Row[];
  };
}

const myImageClose = new Image(25, 35);
myImageClose.src = "/icon-close.png"; // "https://cdn.icon-icons.com/icons2/1674/PNG/512/close_111152.png";

const myImageDown = new Image(15, 25);
myImageDown.src = "/icon-arrow-down.png"; // "https://cdn1.iconfinder.com/data/icons/arrows-vol-1-4/24/dropdown_arrow-512.png";

const myImageUp = new Image(25, 35);
myImageUp.src = "/icon-arrow-up.png"; // "https://cdn.iconscout.com/icon/free/png-256/keyboard-arrow-up-1780933-1517436.png";

function randomNumber(min: any, max: any) {
  return Math.random() * (max - min) + min;
}

// const data = {
//   labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
//   datasets: [
//     {
//       label: '体温',
//       data: [Math.floor(randomNumber(30, 38)), Math.floor(randomNumber(30, 38)), Math.floor(randomNumber(30, 38)), Math.floor(randomNumber(30, 38)), Math.floor(randomNumber(30, 38)), Math.floor(randomNumber(30, 38)), Math.floor(randomNumber(30, 38)), Math.floor(randomNumber(30, 38)), Math.floor(randomNumber(30, 38)), Math.floor(randomNumber(30, 38)), Math.random(), Math.random()],
//       fill: false,
//       backgroundColor: 'black',
//       borderColor: 'black',
//       yAxisID: 'y-axis-0',
//       pointStyle: 'triangle',
//       borderDash: [5, 15],
//       pointBorderWidth: 5
//     },
//     {
//       label: '脈拍',
//       data: [Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180))],
//       fill: false,
//       backgroundColor: 'red',
//       borderColor: 'red',
//       yAxisID: 'y-axis-0',
//       pointStyle: 'circle',
//       pointBorderWidth: 5
//     },
//     {
//       label: '呼吸数',
//       data: [Math.floor(randomNumber(0, 40)), Math.floor(randomNumber(0, 40)), Math.floor(randomNumber(0, 40)), Math.floor(randomNumber(0, 40)), Math.floor(randomNumber(0, 40)), Math.floor(randomNumber(0, 40)), Math.floor(randomNumber(0, 40)), Math.floor(randomNumber(0, 40)), Math.floor(randomNumber(0, 40)), Math.floor(randomNumber(0, 40)), Math.floor(randomNumber(0, 40)), Math.floor(randomNumber(0, 40))],
//       fill: false,
//       backgroundColor: 'rgb(54, 162, 235)',
//       borderColor: 'rgba(54, 162, 235, 0.2)',
//       yAxisID: 'y-axis-0',
//       pointStyle: myImageClose,
//       showLine: false
//     },
//     {
//       label: '収縮期血圧',
//       data: [Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180))],
//       fill: false,
//       backgroundColor: 'green',
//       borderColor: 'green',
//       yAxisID: 'y-axis-0',
//       pointStyle: myImageDown,
//       showLine: false
//     },
//     {
//       label: '拡張期血圧',
//       data: [Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180)), Math.floor(randomNumber(20, 180))],
//       fill: false,
//       backgroundColor: 'yellow',
//       borderColor: 'yellow',
//       yAxisID: 'y-axis-0',
//       pointStyle: myImageUp,
//       showLine: false
//     },
//   ],
// };

class Overview301 extends React.Component<Props, State> {

  private supportPageOffset: boolean | null;
  private isCSS1Compat: boolean | null;
  private demoItem2: any;

  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID === "supervisor-faculty" ? this.props.match.params.facultyID : this.props.match.params.studentID;
    if (!checkUserId(userId, this.props.match.params.studentID === "supervisor-faculty" ? USER_TYPE.TEACHER : USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }

    this.state = {
      dataRow: null,
      dataCol: [],
      chartData: {},
      patientInfoOverview: {
        col: [],
        row: [],
      },
    };

    this.supportPageOffset = window.pageXOffset !== undefined;
    this.isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
    this.demoItem2 = React.createRef();



    this.chartLabel = this.chartLabel.bind(this);
    this.loadChart = this.loadChart.bind(this);
    this.loadOverview = this.loadOverview.bind(this);
  }

  public loadOverview() {
    fetch(`${process.env.REACT_APP_API_URL}/api/patient_info/get_data_patient?patient_id=${this.props.match.params.patientID}&student_id=${this.props.match.params.studentID}&faculty_id=${this.props.match.params.facultyID}&start_date=${this.props.match.params.startDate}`, { mode: "cors" })
      .then((res) => res.json())
      .then((xhr) => {
        if (xhr.statusCode !== 200) {
          alert(xhr.data);
        } else {
          this.setState({
            dataRow: xhr.data.dataRow,
            dataCol: xhr.data.dataCol
          });
        }
      }, (error) => {
        console.log("Error: ");
      });
  }

  public loadChart() {
    fetch(`${process.env.REACT_APP_API_URL}/api/patient_info/get_data_chart?patient_id=${this.props.match.params.patientID}&student_id=${this.props.match.params.studentID}`, { mode: "cors" })
      .then((res) => res.json())
      .then((xhr) => {
        let xhrData = xhr.data
        // console.log("🚀 ~ file: Overview.tsx ~ line 158 ~ Overview301 ~ .then ~ xhrData", xhrData[14].filter((item) => !!item && item != '-'))
        console.log("🚀 ~ file: Overview.tsx ~ line 158 ~ Overview301 ~ .then ~ xhrData", xhrData[15])
        // console.log("🚀 ~ file: Overview.tsx ~ line 158 ~ Overview301 ~ .then ~ labels", xhr.labels)
        let lineData = {
          labels: xhr.labels,
          datasets: [
            {
              label: '体温',
              data: xhrData[14].map((item: any) => !!item ? item : '-'),
              fill: false,
              backgroundColor: 'black',
              borderColor: 'black',
              yAxisID: 'y',
              pointStyle: 'triangle',
              borderDash: [5, 15],
              pointBorderWidth: 5,
              spanGaps: true
            },
            {
              label: '脈拍',
              data: xhrData[15].map((item: any) => !!item ? item : '-'),
              fill: false,
              backgroundColor: 'red',
              borderColor: 'red',
              yAxisID: 'y1',
              pointStyle: 'circle',
              pointBorderWidth: 5,
              spanGaps: true
            },
            {
              label: '呼吸数',
              data: xhrData[16].map((item: any) => !!item ? item : '-'),
              fill: false,
              backgroundColor: 'rgb(54, 162, 235)',
              borderColor: 'rgba(54, 162, 235, 0.2)',
              yAxisID: 'y2',
              pointStyle: myImageClose,
              showLine: false,
              spanGaps: true
            },
            {
              label: '収縮期血圧',
              data: xhrData[17].map((item: any) => !!item ? item : '-'),
              fill: false,
              backgroundColor: 'green',
              borderColor: 'green',
              yAxisID: 'y3',
              pointStyle: myImageDown,
              showLine: false,
              spanGaps: true
            },
            {
              label: '拡張期血圧',
              data: xhrData[18].map((item: any) => !!item ? item : '-'),
              fill: false,
              backgroundColor: 'yellow',
              borderColor: 'yellow',
              yAxisID: 'y3',
              pointStyle: myImageUp,
              showLine: false,
              spanGaps: true
            },
          ],

        };

        this.setState({
          chartData: lineData
        });

        // console.log("label: ", xhr.labels);
        // console.log("lineData: ", lineData);
      }, (error) => {
        console.log("Error: ");
      });
  }

  chartLabel = () => {
    return (
      <Table
        className="table_chart float-left h-100"
        bordered
        hover
        size="lg"
      >
        <thead>
          <tr>
            <th>体温</th>
            <th>脈拍</th>
            <th>呼吸数</th>
            <th>血圧</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>38</td>
            <td>180</td>
            <td>40</td>
            <td>180</td>
          </tr>
          <tr>
            <td>37</td>
            <td>160</td>
            <td>35</td>
            <td>160</td>
          </tr>
          <tr>
            <td>36</td>
            <td>140</td>
            <td>30</td>
            <td>140</td>
          </tr>
          <tr>
            <td>35</td>
            <td>120</td>
            <td>25</td>
            <td>120</td>
          </tr>
          <tr>
            <td>34</td>
            <td>100</td>
            <td>20</td>
            <td>100</td>
          </tr>
          <tr>
            <td>33</td>
            <td>80</td>
            <td>15</td>
            <td>80</td>
          </tr>
          <tr>
            <td>32</td>
            <td>60</td>
            <td>10</td>
            <td>60</td>
          </tr>
          <tr>
            <td>31</td>
            <td>40</td>
            <td>5</td>
            <td>40</td>
          </tr>
          <tr>
            <td>30</td>
            <td>20</td>
            <td>0</td>
            <td>20</td>
          </tr>
        </tbody>
      </Table>
    );
  }


  options = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      }
    },
    scales: {
      y3: {
        suggestedMin: 20,
        suggestedMax: 180,
        // display: false,
        title: {
          display: false,
          align: 'end',
          text: '収縮期血圧',
          font: {
            size: 24
          }
        }
      },
      y2: {
        suggestedMin: 0,
        suggestedMax: 40,
        // display: false,
        title: {
          display: false,
          align: 'end',
          text: '呼吸数',
          font: {
            size: 24
          }
        }
      },
      y1: {
        suggestedMin: 20,
        suggestedMax: 180,
        type: 'linear',
        // display: false,
        title: {
          display: false,
          align: 'end',
          text: '脈拍',
          font: {
            size: 24
          }
        }
      },
      y: {
        suggestedMin: 30,
        suggestedMax: 38,
        type: 'linear',
        // display: false,
        title: {
          display: false,
          align: 'end',
          text: '体温',
          font: {
            size: 24
          }
        }
      },



      // yAxes: [
      //   {
      //     id: 'y-axis-0',
      //     position: 'left',
      //     ticks: {
      //       display: false
      //     }
      //   },

      // ],
    },
  };

  public componentDidMount() {
    this.loadChart();
    this.loadOverview();
    window.addEventListener('scroll', this.handleScroll, true);
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = () => {
    var x = this.supportPageOffset ? window.pageXOffset : this.isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;
    let y = x - 0;
    this.demoItem2.current.style.left = y + "px";
  }

  public render() {
    // console.log("🚀 ~ file: Overview.tsx ~ line 332 ~ Overview301 ~ render ~ this.state.label", this.state.dataChart)
    // const overviewTableData = this.state.patientInfoOverview[
    //   "col"
    // ].map((item, i) => (
    //   <OverviewTableData
    //     key={i}
    //     overview301Col={item}
    //     overview301Row={this.state.patientInfoOverview["row"][i]}
    //     hospitalizationDay={i + 1}
    //   />
    // ));

    const overviewTableData = this.state.dataCol.map((item, i) => (
      <OverviewTableCol
        key={Math.random()}
        dataCol={item}

      />
    ));

    return (
      <div className="datagrid-viewport">
        <div className="row" style={{ marginBottom: 50 }}>
          <div className="col-md-1">
            {/* {this.chartLabel()} */}
          </div>

          <div className="col-md-11">
            <div className="table__chart_label">
              <Table
                className="table_chart float-left h-100"
                bordered
                hover
                size="sm"
              >
                <thead>
                  <tr>
                    <th>体温</th>
                    <th>脈拍</th>
                    <th>呼吸数</th>
                    <th>血圧</th>
                  </tr>
                </thead>
              </Table>
            </div>
            <Line
              type="line"
              data={this.state.chartData}
              options={this.options}
              className="overview__chart"
            // width={500} height={300}
            />
          </div>
        </div>

        <div id="demoItem2" className='col-label datagrid-pinned-data' ref={this.demoItem2}>
          <OverviewTableRow dataRow={this.state.dataRow} />
        </div>

        <div className="col-data d-flex flex-row x-scrollable-pane">
          {overviewTableData}
        </div>

      </div>



    );
  }

}

export default Overview301;
