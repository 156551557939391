import React from "react";
import Form from "react-bootstrap/Form";
import * as _ from "lodash";

interface Props {
    onItemSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
interface State { data: string[] }

class ListRoomSelect extends React.Component<Props, State> {

    state = { data: [] }

    componentDidMount() {
        const url = `${process.env.REACT_APP_API_URL}/api/patient_info/get_all_room`;
      
        fetch(url)
            .then((res) => res.json())
            .then((result) => {
                const uniqBy = _.uniqBy(
                    result,
                    (item) => {
                      return Math.floor(Number(item) / 100);
                    }
                  );
                  
                const result1 = uniqBy.map((item: any) => (Math.floor(item / 100) * 100).toString());

                this.setState({
                    data: result1,
                });
            })
            .catch(error => {
                console.log("🚀 ~ file: ListRoomSelect.tsx ~ line 22 ~ ListRoomSelect ~ componentDidMount ~ error", error)

            });
    }

    public render() {
        const rooms: string[] = this.state.data;

        const options = rooms.map((item, i) => <option key={i} value={item}>{item}</option>);

        return (
            <Form.Control className="list-room-select" as="select" onChange={this.props.onItemSelect}>
                <option value="all">未選択</option>
                {options}
            </Form.Control>
        );
    }
}

export default ListRoomSelect;
