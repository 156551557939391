import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../../assets/styles/IndexFaculty.scss";
import styled from "styled-components";
import SortableTreeComponent from "../common/SortableTreeComponent";
import { UniversityList } from "../models/Types";
import { checkUserId } from "../../services/AuthService";
import { getUserId, USER_TYPE } from "../../services/AppLocalHelper";
interface Props extends RouteComponentProps<{}> {}
interface State {
  selfEvaluation: UniversityList;
  university: string;
  startDate: string;
  endDate: string;
  dateTimeFilter: any;
  dateTime: any;
  isLoading: boolean;
  isFacultySupervisor: boolean;
  affiliationFaculty: string;
}

class CreateSelfEvaluationItems extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let userId = getUserId();
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }

    this.state = {
      selfEvaluation: {} as UniversityList,
      university: "",
      startDate: "",
      endDate: "",
      dateTime: "",
      isLoading: false,
      dateTimeFilter: [],
      isFacultySupervisor: false,
      affiliationFaculty: '',
    };
    this.getFaculty(userId);
  }

  componentDidMount() {
    const { location } = this.props;
    const {
      university_name,
      university,
      startDate,
      endDate,
      internDays,
      studentNum,
    } = location.state as any;

    this.setState({
      selfEvaluation: {
        university_name,
        university,
        startDate,
        endDate,
        internDays,
        studentNum,
      },
      startDate,
      endDate,
      university,
    });
  }

  onSubmit = () => {
    const { location } = this.props;
    const { universityList } = location.state as any;
    const { dateTime, university } = this.state;

    if (dateTime) {
      const arrDate = dateTime.split("/");

      // const listData = universityList.filter(
      //   (i: any) =>
      //     i.university === university &&
      //     i.startDate === arrDate[0] &&
      //     i.endDate === arrDate[1]
      // );Z

      this.setState({
        startDate: arrDate[0],
        endDate: arrDate[1],
        isLoading: !this.state.isLoading,
      });
    }
  };
  public getFaculty(facultyID: string){
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${facultyID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
          console.log('getFaculty', result);
     
          this.setState({isFacultySupervisor : result.is_supervisor, affiliationFaculty: result.affiliation_id});
      })
      .catch(console.error);
  }
  onChangeUniversity = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { location } = this.props;
    const { universityList } = location.state as any;
    const value = e.target.value;
    const listData = universityList.filter((i: any) => i.university === value);

    this.setState({
      university: e.target.value,
      dateTimeFilter: listData as any,
      dateTime: "",
    });
  };

  onChangeDateTime = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      dateTime: e.target.value,
    });
  };

  public render() {
    const { location } = this.props;
    const { universityList } = location.state as any;
    const { selfEvaluation } = this.state;

    let universitys = universityList.map((o: any) => o.university);
    let filtered = universityList.filter(
      ({ university }: { university: string }, index: any) =>
        !universitys.includes(university, index + 1)
    );
    // d
    if(!this.state.isFacultySupervisor){
      let affiliation = this.state.affiliationFaculty;
      filtered = filtered.filter(function (el: any) {
        return el.university == affiliation;
      });
    }
    return (
      <Container className="mt-5 mb-5">
        <h2>自己評価項目設定</h2>
        <>
          <Table
            id="index_faculty_student_list"
            className="sw-custom-table"
            bordered
            hover
          >
            <thead className="thead-dark">
              <tr>
                <th>施設名</th>
                <th>学生数</th>
                <th>実習開始日</th>
                <th>実習終了日</th>
                <th>実習日</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{selfEvaluation.university_name}</td>
                <td>{selfEvaluation.studentNum}</td>
                <td>{selfEvaluation.startDate}</td>
                <td>{selfEvaluation.endDate}</td>
                <td>{selfEvaluation.internDays}日目</td>
              </tr>
            </tbody>
          </Table>
          <StyledFilter>
            <Form.Label column md={1} className="ml-5">
              引用
            </Form.Label>
            <Form.Control
              className="mr-3"
              style={{ width: "160px" }}
              as="select"
              onChange={this.onChangeUniversity}
            >
              <option value="">施設名</option>
              {filtered.map((u: any, i: number) => (
                <option key={i} value={u.university}>
                  {u.university_name}
                </option>
              ))}
            </Form.Control>
            <Form.Control
              style={{ width: "240px" }}
              as="select"
              value={this.state.dateTime}
              onChange={this.onChangeDateTime}
            >
              <option value="">実習期間</option>
              {this.state.dateTimeFilter.map((u: any, i: number) => (
                <option
                  key={`time_${i}`}
                  value={`${u.startDate}/${u.endDate}`}
                >{`${u.startDate} ~ ${u.endDate}`}</option>
              ))}
            </Form.Control>
            <Button
              className="ml-3 noPrint"
              variant="primary"
              size="sm"
              type="submit"
              onClick={this.onSubmit}
            >
              反映
            </Button>
          </StyledFilter>
          {this.state.university &&
            this.state.startDate &&
            this.state.endDate && (
              <SortableTreeComponent
                university={this.state.university}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                isLoading={this.state.isLoading}
                selfEvaluation={this.state.selfEvaluation}
              />
            )}
        </>
      </Container>
    );
  }
}

const StyledFilter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  width: 850px;
  margin: auto;
`;

export default withRouter(CreateSelfEvaluationItems);
