import React from "react";
import Table from "react-bootstrap/Table";
import { TestDataItemType } from "../../models/Types";
interface Props {
  testDataItem: TestDataItemType[]
}
interface State { }

class TestDataTableLeft extends React.Component<Props, State> {
  public render() {
    return (
      <Table className="test_data_left test_data" bordered hover size="sm">
        <thead>
          <tr><th>検査日</th></tr>
          {/* <tr><th>検査時間</th></tr> */}
        </thead>
        <tbody>
          {this.props.testDataItem.map((post: TestDataItemType) =>
            <tr key={post.id} className={"tr-sw " + (post.is_parent ? 'bg-warning' : '')}>
              <td>{post.name}</td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

export default TestDataTableLeft;
