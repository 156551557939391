import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import { NursingDiagnosisType, NursingCommentInfoType } from "../../models/Types";
import "../../../assets/styles/NursingDiagnosis.scss";
import "../../../assets/styles/SWCustom.scss";
import _ from 'lodash';
import { checkUserId } from "../../../services/AuthService";
import { USER_TYPE } from "../../../services/AppLocalHelper";

interface Props
  extends RouteComponentProps<{
    studentID: string;
  }> { }
interface State {
  studentID: string;
  studentName: string;
  university: string;
  startDate: string;
  nursingDiagnosisArray: NursingDiagnosisType[];
  apiStatus: string;
  nursingCommentInfo: string;
  patientInfos: string;
  overviewMaxDay: string;
  trainingDays: string;
}

class NursingDiagnosisStudent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID
    if (!checkUserId(userId, USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }

    this.state = {
      studentID: this.props.match.params.studentID,
      studentName: "",
      university: "",
      startDate: "",
      nursingDiagnosisArray: [],
      apiStatus: "",
      nursingCommentInfo: '',
      patientInfos: "",
      trainingDays: "",
      overviewMaxDay: "",
    };

    this.onStatusSelect = this.onStatusSelect.bind(this);
    this.onDiagnosisNameChanged = this.onDiagnosisNameChanged.bind(this);
    this.onOChanged = this.onOChanged.bind(this);
    this.onTChanged = this.onTChanged.bind(this);
    this.onEChanged = this.onEChanged.bind(this);
    this.onSubmitNursingDiagnosis = this.onSubmitNursingDiagnosis.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.addNursingDiagnosis = this.addNursingDiagnosis.bind(this);
    this.removeNursingDiagnosis = this.removeNursingDiagnosis.bind(this);
    this.onTempSaveNursingDiagnosis = this.onTempSaveNursingDiagnosis.bind(this);
    this.getFacultyID = this.getFacultyID.bind(this);
    this.onMouseClickUp = this.onMouseClickUp.bind(this);
    this.onMouseClickDown = this.onMouseClickDown.bind(this);
  }

  public onStatusSelect(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    const value: string = e.target.value;
    this.setState((prevState, nextProps) => {
      const newNursingDiagnosisArray = prevState.nursingDiagnosisArray;
      newNursingDiagnosisArray[index].status = value;
      console.log(newNursingDiagnosisArray);
      return { nursingDiagnosisArray: newNursingDiagnosisArray };
    });
  }

  public onDiagnosisNameChanged(
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const value: string = e.target.value;
    const newNursingDiagnosisArray = this.state.nursingDiagnosisArray;
    newNursingDiagnosisArray[index].diagnosisName = value;
    this.setState({
      nursingDiagnosisArray: newNursingDiagnosisArray
    })
  }

  public onOChanged(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    const value: string = e.target.value;
    const newNursingDiagnosisArray = this.state.nursingDiagnosisArray;
    newNursingDiagnosisArray[index].o = value;
    this.setState({
      nursingDiagnosisArray: newNursingDiagnosisArray
    });
  }
  public onTChanged(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    const value: string = e.target.value;
    const newNursingDiagnosisArray = this.state.nursingDiagnosisArray;
    newNursingDiagnosisArray[index].t = value;
    this.setState({
      nursingDiagnosisArray: newNursingDiagnosisArray
    });
  }
  public onEChanged(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    const value: string = e.target.value;
    const newNursingDiagnosisArray = this.state.nursingDiagnosisArray;
    newNursingDiagnosisArray[index].e = value;
    this.setState({
      nursingDiagnosisArray: newNursingDiagnosisArray
    });
  }
  public getFacultyID() {
    const query = new URLSearchParams(this.props.location.search)

    const facultyID = query.get("faculty_id")

    return facultyID;
  }
  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${this.props.match.params.studentID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          studentName: result.studentName,
          university: result.university,
          startDate: result.startDate,
          patientInfos: result.patientInfos,
          trainingDays: result.internDay,
          overviewMaxDay: result.overviewMaxDay
        });
      })
      .catch(console.error);

    const url2 = `${process.env.REACT_APP_API_URL}/api/nursing_diagnosis/student/fetch_list?student_id=${this.props.match.params.studentID}`;
    fetch(url2)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          nursingDiagnosisArray: result,
        });
      })
      .catch(console.error);

    const url3 = `${process.env.REACT_APP_API_URL}/api/nursing_diagnosis/faculty/comment_list?student_id=${this.props.match.params.studentID}&faculty_id=${this.getFacultyID()}`;
    fetch(url3, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            nursingCommentInfo: result,
          });
        },
        (error) => {
          console.log("API Error");
        }
      );
  }

  public onClickCancel() {
    this.props.history.goBack();
  }

  public onTempSaveNursingDiagnosis() {
    const nursingDiagnosisArray = {
      student_id: this.state.studentID,
      nursing_diagnosis_array: this.state.nursingDiagnosisArray,
      isTempoSave: true,
    };
    const url = `${process.env.REACT_APP_API_URL}/api/nursing_diagnosis/student/update`;
    const method = "POST";
    const mode = "cors";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(nursingDiagnosisArray);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          this.props.history.push(`/index/student/${this.props.match.params.studentID}`);
        } else {
          this.setState({ apiStatus: result.status });
        }
      })
      .catch(console.error);
  }

  public onSubmitNursingDiagnosis() {
    const nursingDiagnosisArray = {
      student_id: this.state.studentID,
      nursing_diagnosis_array: this.state.nursingDiagnosisArray,
      isTempoSave: false,
    };
    const url = `${process.env.REACT_APP_API_URL}/api/nursing_diagnosis/student/update`;
    const method = "POST";
    const mode = "cors";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(nursingDiagnosisArray);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          this.props.history.push(`/index/student/${this.props.match.params.studentID}`);
        } else {
          this.setState({ apiStatus: result.status });
        }
      })
      .catch(console.error);
  }

  public addNursingDiagnosis() {
    const itemMaxIndex = _.maxBy(this.state.nursingDiagnosisArray, (item) => item.index);
    const maxIndex = itemMaxIndex?.index ?? 0;
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const today = `${year}年${month}月${day}日`;

    this.setState({
      nursingDiagnosisArray: [...this.state.nursingDiagnosisArray, {
        index: maxIndex + 1,
        date: today,
        status: "",
        diagnosisName: "",
        o: "",
        t: "",
        e: "",
        deleted: false,
      }]
    })
  }

  public swapArrayLocs = (arr: any, index1: number, index2: number) => {
    [arr[index1], arr[index2]] = [arr[index2], arr[index1]]
  }

  public onMouseClickUp(index: number) {
    console.log(index)
    let nursingDiagnosisArray = [...this.state.nursingDiagnosisArray]
    this.swapArrayLocs(nursingDiagnosisArray, index - 1, index)
    this.setState({ nursingDiagnosisArray })
  }
  public onMouseClickDown(index: number) {
    console.log(index)
    let nursingDiagnosisArray = [...this.state.nursingDiagnosisArray]
    this.swapArrayLocs(nursingDiagnosisArray, index + 1, index)
    this.setState({ nursingDiagnosisArray })
  }

  public removeNursingDiagnosis() {
    this.setState((prevState: State, nextProps: Props) => {
      if (prevState.nursingDiagnosisArray.length > 1) {
        const lastIndex = prevState.nursingDiagnosisArray.length - 1;
        const popedNursingActivity = prevState.nursingDiagnosisArray.filter(
          (item, i) => i < lastIndex
        );
        return { nursingDiagnosisArray: popedNursingActivity };
      } else {
        return { nursingDiagnosisArray: prevState.nursingDiagnosisArray };
      }
    });

  }

  public render() {

    const nursingDiagnosisRow = this.state.nursingDiagnosisArray
      .filter((item) => item.deleted === false)
      .map((item, i) => (
        <tr key={item.index}>
          <td className="clor-lightblue">{`No.${i + 1}`}</td>
          <td className="clor-lightblue">{item.date}</td>
          <td>
            <Form.Control
              className=""
              as="select"
              value={item.status}
              onChange={this.onStatusSelect.bind(this, i)}
            >
              <option>選択</option>
              <option>未評価</option>
              <option>達成</option>
              <option>解決</option>
            </Form.Control>
          </td>
          <td id="nursing_diagnosis" className="p-0">
            {/* <tr> */}
            <div className=" text-left sw-padding-15">
              <Form.Row>
                <div className="fl-left w-10 clor-lightblue"> 診断名： </div>

                <div className="fl-left w-65">  <Form.Control
                  className="m-0"
                  required
                  as="textarea" 
                  rows={3}
                  value={item.diagnosisName}
                  onChange={this.onDiagnosisNameChanged.bind(
                    this,
                    i
                  )}
                /> </div>


              </Form.Row>
            </div>
            {/* </tr> */}
            {/* <tr> */}
            <div className=" text-left sw-padding-15">

              <Form.Row>
                <div className="fl-left w-10 clor-lightblue">  O： </div>

                <div className="fl-left w-65">
                  <Form.Control
                    className="m-0 "
                    required
                    as="textarea" 
                    rows={3}
                    value={item.o}
                    onChange={this.onOChanged.bind(this, i)}
                  />
                </div>
              </Form.Row>
            </div>
            {/* </tr> */}
            {/* <tr> */}
            <div className=" text-left sw-padding-15">
              <Form.Row>
                <div className="fl-left w-10 clor-lightblue">T： </div>

                <div className="fl-left w-65">
                  <Form.Control
                    className="m-0 "
                    required
                    as="textarea" 
                    rows={3}
                    value={item.t}
                    onChange={this.onTChanged.bind(this, i)}
                  />
                </div>
              </Form.Row>


            </div>
            {/* </tr>
            <tr> */}
            <div className=" text-left sw-padding-15">
            <Form.Row>
                <div className="fl-left w-10 clor-lightblue">  E： </div>

                <div className="fl-left w-65">
                <Form.Control
                    className="m-0 "
                    required
                    as="textarea" 
                    rows={3}
                    value={item.e}
                    onChange={this.onEChanged.bind(this, i)}
                  />
                </div>
              </Form.Row>


            </div>

          </td>
          <td className="w-100px">
            {i !== 0 && <Button
              className="button-up"
              variant="secondary"
              size="lg"
              type="submit"
              onClick={this.onMouseClickUp.bind(this, i)}
            >
              <i className="far fa-arrow-alt-circle-up"></i>
            </Button>}
            {i !== this.state.nursingDiagnosisArray.length - 1 && <Button

              className="button-down"
              variant="secondary"
              size="lg"
              type="submit"
              onClick={this.onMouseClickDown.bind(this, i)}
            >
              <i className="far fa-arrow-alt-circle-down"></i>
            </Button>}
          </td>


        </tr>


      ));

    return (
      <Row>
        <Col md={2} />
        <Col md={8}>

          <div>
            <Table id="nursing_plan_basic_info" className="m-5" bordered>
              <thead className="thead-dark">
                <tr>
                  <th>学籍番号</th>
                  <th>学生氏名</th>
                  <th>施設名</th>
                  <th>実習開始日</th>
                  <th>担当患者</th>
                  <th>患者の入院日数</th>
                  <th>実習日</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.props.match.params.studentID}</td>
                  <td>{this.state.studentName}</td>
                  <td>{this.state.university}</td>
                  <td>{this.state.startDate}</td>
                  <td>{this.state.patientInfos}</td>
                  <td>{this.state.overviewMaxDay}</td>
                  <td>{this.state.trainingDays} 日目</td>
                </tr>
              </tbody>
            </Table>
            <Table id="nursing_diagnosis" className="m-5" bordered>
              <thead className="thead-dark">
                <tr>
                  <th id="number">No</th>
                  <th id="date">診断年月日</th>
                  <th id="status">状況</th>
                  <th id="diagnosis" colSpan={2}>看護診断/共同問題</th>
                </tr>
              </thead>
              <tbody>{nursingDiagnosisRow}</tbody>
            </Table>
          </div>
          <div className="dflex justify-content-start ml-5 mb-5 noPrint">
            <AddCircleOutlinedIcon
              color="secondary"
              fontSize="large"
              onClick={this.addNursingDiagnosis}
            />
            <RemoveCircleOutlinedIcon
              color="primary"
              fontSize="large"
              onClick={this.removeNursingDiagnosis}
            />
          </div>
          <div className="d-flex justify-content-start ml-5">
            <Button
              className="noPrint bg-lightblue"
              variant="primary"
              size="lg"
              type="submit"
              onClick={this.onTempSaveNursingDiagnosis}
            >
              一時保存
            </Button>
            <Button
              className="ml-3 noPrint bg-lightblue"
              variant="primary"
              size="lg"
              type="submit"
              onClick={this.onSubmitNursingDiagnosis}
            >
              提出
            </Button>
            <Button
              className="ml-3 noPrint clor-lightnavi"
              variant="outline-secondary"
              size="lg"
              type="button"
              onClick={this.onClickCancel}
            >
              戻る
            </Button>
            <Button

              variant="primary"
              size="lg"
              onClick={window.print}
              className="ml-3 noPrint bg-lightblue"
            >
              印刷
            </Button>
          </div>
          <h5 className=" sw-title-left mt-30 ml-5 clor-lightnavi">教員コメント</h5>
          <pre className="comment_faculty mt-3 p-3 ml-5 text-muted sw-title-left">
            {
              this.state.nursingCommentInfo
            }
          </pre>
        </Col>
        <Col md={2} />

      </Row>
    );
  }
}

export default withRouter(NursingDiagnosisStudent);
