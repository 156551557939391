import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { SelfEvaluationType } from "../../models/Types";
import _ from "lodash";
import "../../../assets/styles/SWCustom.scss";
interface Props extends RouteComponentProps {
  studentID: string;
}
interface State extends SelfEvaluationType {
  questions: [];
  answers: any[];
  questionCount: any[];
}

class SelfEvaluationTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      studentID: this.props.studentID,
      questions: [],
      answers: [],
      questionCount: [],
    };

    this.onClickCancel = this.onClickCancel.bind(this);
    this.onSubmitSelfEvaluation = this.onSubmitSelfEvaluation.bind(this);
    this.onChangeItem = this.onChangeItem.bind(this);
    //this.QuestionsData() = this.QuestionsData.bind(this);

    this.loadQuestionCount = this.loadQuestionCount.bind(this);

  }

  public onChangeItem(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const name = e.target.name;
    console.log('Clicked Name', name);
    console.log('Clicked Value', value);
    const item = {
      name: name,
      value: value
    };

    let answers = this.state.answers;
    console.log('answers before', answers);
    let answer = _.find(answers, function (obj) { return obj.name == name })
    console.log('answer', answer);
    if (answer !== undefined) {
      answer.value = value;
    } else {
      answers.push(item);
    }
    console.log('answers after', answers);
    this.setState({ answers: answers });
  }

  public onClickCancel() {
    this.props.history.goBack();
  }

  public onSubmitSelfEvaluation(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation/save`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    console.log(this.state);

    const newSelfEvaluation = {
      self_evaluation: {
        student_id: this.state.studentID,
        answers: this.state.answers
      }
    };
    const body = JSON.stringify(newSelfEvaluation);

    fetch(url, { method, headers, body })
      .then(res => res.json())
      .then(result => {
        console.log(result);
        if (result.status === "success") {
          this.props.history.goBack();
        } else {
          console.log("API Error");
        }
      })
      .catch(console.error);
  }

  public componentDidMount() {
    this.LoadQuestions();
    this.loadQuestionCount();

    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation/fetch?student_id=${this.state.studentID}`;

    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log(result);
        this.setState({
          answers: result
        });
      })
      .catch(console.error);
  }

  QuestionsData() {
    console.log('this.state.questions:', this.state.questions)
    return this.state.questions.map((question: any, index) => {
      // let renderCol1:any[] = [];
      // let renderCol2:any[] = [];
      // let renderCol3:any[] = [];
      let row: any[] = [];
      let i1 = question.col1?.length;
      let i2 = question.col2?.length;
      let i3 = question.col3?.length;

      //Col1
      // let col1Index = 0;
      // let col2Index = 0;
      // let col3Index = 0;
      for (let i = 0; i < i3; i++) {
        let itemName = 'item_' + question.col3[i].index1 + '_' + question.col3[i].index2 + '_' + question.col3[i].index3;
        let itemChk = _.find(this.state.answers, function (obj) { return obj.name == itemName });
        let checkedValued = 'ALL';

        if (itemChk !== undefined) {
          checkedValued = itemChk.value;
        }
        //First row
        if (i == 0) {
          let mergeRow = i3 / i2;
          let iMerge = this.state.questionCount.length;

          for (let ind = 0; ind < iMerge; ind++) {

            if (question.col3[i].index1 === this.state.questionCount[ind].index1 && question.col3[i].index2 == this.state.questionCount[ind].index2) {

              mergeRow = this.state.questionCount[ind].count - 1;
            }
          }

          row.push(
            <tr key={Math.random()}>
              <td className="txt-left width-20" rowSpan={i3}>{question.col1[0].content}</td>
              <td className="txt-left width-20" rowSpan={mergeRow}>{question.col2[0].content}</td>
              <td className="txt-left width-35" >{question.col3[0].content}</td>
              <td>
                <Form.Check
                  name={itemName}
                  inline
                  type="radio"
                  label="A"
                  value="A"
                  checked={checkedValued === "A"}
                  onChange={this.onChangeItem}
                />
              </td>
              <td>
                <Form.Check
                  name={itemName}
                  inline
                  type="radio"
                  label="B"
                  value="B"
                  checked={checkedValued === "B"}
                  onChange={this.onChangeItem}
                />
              </td>
              <td>
                <Form.Check
                  name={itemName}
                  inline
                  type="radio"
                  label="C"
                  value="C"
                  checked={checkedValued === "C"}
                  onChange={this.onChangeItem}
                />
              </td>
              <td>
                <Form.Check
                  name={itemName}
                  inline
                  type="radio"
                  label="D"
                  value="D"
                  checked={checkedValued === "D"}
                  onChange={this.onChangeItem}
                />
              </td>
            </tr>
          );
        } else {
          let Col1;
          let Col2;
          let Col3;
          //col1
          if (i <= i1 - 1) {
            Col1 = <td className="txt-left" >{question.col1[question.col3[i].index1 - 1]?.content}</td>
          }
          //col2

          if (question.col3[i].index3 == 1) {

            let mergeRow = 1;
            let iMerge = this.state.questionCount.length;

            for (let ind = 0; ind < iMerge; ind++) {

              if (question.col3[i].index1 === this.state.questionCount[ind].index1 && question.col3[i].index2 == this.state.questionCount[ind].index2) {

                mergeRow = this.state.questionCount[ind].count - 1;
              }
            }

            Col2 = <td className="txt-left" rowSpan={mergeRow}>{question.col2[question.col3[i].index2 - 1].content}</td>
          }
          //col3
          if (i <= i3 - 1) {
            Col3 = <td className="txt-left" >{question.col3[i].content}</td>
          }

          //Next rows
          row.push(
            <tr key={Math.random()}>
              {Col1}
              {Col2}
              {Col3}
              <td>
                <Form.Check
                  name={itemName}
                  inline
                  type="radio"
                  label="A"
                  value="A"
                  checked={checkedValued === "A"}
                  onChange={this.onChangeItem}
                />
              </td>
              <td>
                <Form.Check
                  name={itemName}
                  inline
                  type="radio"
                  label="B"
                  value="B"
                  checked={checkedValued === "B"}
                  onChange={this.onChangeItem}
                />
              </td>
              <td>
                <Form.Check
                  name={itemName}
                  inline
                  type="radio"
                  label="C"
                  value="C"
                  checked={checkedValued === "C"}
                  onChange={this.onChangeItem}
                />
              </td>
              <td>
                <Form.Check
                  name={itemName}
                  inline
                  type="radio"
                  label="D"
                  value="D"
                  checked={checkedValued === "D"}
                  onChange={this.onChangeItem}
                />
              </td>
            </tr>
          );

        }
      }
      return row
    })
  };

  public LoadQuestions() {
    fetch(`${process.env.REACT_APP_API_URL}/api/self_evaluation/questions?student_id=${this.state.studentID}`, { mode: "cors" })
      .then((res) => res.json())
      .then((xhr) => {
        this.setState({
          questions: xhr.questions
        });

        console.log("questions: ", this.state.questions);
      }, (error) => {
        console.log("Error: ");
      });
  }

  public loadQuestionCount() {
    fetch(`${process.env.REACT_APP_API_URL}/api/self_evaluation/count_question?student_id=${this.state.studentID}`,
      { mode: "cors" })
      .then((res) => res.json())
      .then((xhr) => {
        this.setState({
          questionCount: xhr.result
        });



      }, (error) => {
        console.log("Error: ");
      });
  }

  public render() {
    return (
      <div className="ml-5">
        <Row>
          <Table id="self_evaluation_goal" className="self_evaluation_goal_student" bordered>
            <thead className="bg-lightblue">
              <tr>
                <th>実習目標</th>
                <th colSpan={6}>&emsp;</th>
              </tr>
            </thead>
            <tbody>
              {this.QuestionsData()}
            </tbody>
          </Table>
        </Row>
        <Row className="mt-4">
          <Button
            className="bg-lightblue"
            size="lg"
            type="submit"
            onClick={this.onSubmitSelfEvaluation}
          >
            保存
          </Button>
          <Button
            className="ml-3 clor-lightnavi"
            variant="outline-secondary"
            size="lg"
            type="submit"
            onClick={this.onClickCancel}
          >
            戻る
          </Button>
        </Row>
      </div>
    );
  }
}

export default withRouter(SelfEvaluationTable);
