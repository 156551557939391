import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";
import Alert from "@material-ui/lab/Alert";
import styled from "styled-components";
import { MidwiferyRecordType } from "../models/Types";
import { TimePicker } from "../common/TimePickerComponent";
import {
  onKeyPress,
  convertToHHMM,
  convertHoursTime,
  ACTION,
} from "../common/function";
import "../../assets/styles/IndexFaculty.scss";
import MidwiferyRecordTable from "../patient_info/midwifery-record/MidwiferyRecordTable";
import { ButtonSpriner, LoadingSpriner } from "../common/CircularSpinner";
import "../../assets/styles/IndexFaculty.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props extends RouteComponentProps<{}> { }
interface State {
  midwiferyRecords: MidwiferyRecordType[];
  formValues: MidwiferyRecordType;
  errors: boolean;
  successMsg: string;
  hoursTime: any;
  isLoading: boolean;
  numPages: number;
  filePath: string;
  acceptedFiles: File;
}

class CreatePatientMidwiferyRecord extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      midwiferyRecords: [],
      formValues: {} as MidwiferyRecordType,
      errors: false,
      isLoading: false,
      successMsg: "",
      hoursTime: null,
      numPages: 4,
      filePath: "",
      acceptedFiles: {} as File,
    };
  }

  componentDidMount() {
    this.fetchDataSetting();
  }

  fetchDataSetting = () => {
    const { location } = this.props;
    console.log('location.state:', location.state)
    const { data: { patientID } } = location?.state as any;
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/josan_record/fetch_data_setting?patient_id=${patientID}`;

    fetch(url, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            midwiferyRecords: result,
          });
        },
        (error) => {
          console.log(error, "API Error");
        }
      );
  };

  onDocumentLoadSuccess = () => {
    this.setState({ numPages: 4 });
  };

  onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    const { location } = this.props;
    const { formValues } = this.state;
    const {
      data: { patientID },
    } = location.state as any;

    const convertToMinutes =
      moment(this.state.hoursTime).hour() * 60 * 60 +
      moment(this.state.hoursTime).minute() * 60;

    const hoursTime = this.state.hoursTime ? convertToMinutes : 0;
    const dayTime =
      (formValues.dayTime ? formValues.dayTime : 0) * 24 * 60 * 60;

    const data = {
      fileInfoID: formValues.fileInfoID || "",
      patient_id: patientID,
      recode_type: formValues.recode_type || 1,
      recode_file_name: formValues.recode_file_name,
      recode_date: Number(dayTime) + Number(hoursTime),
    };

    const successMsg = this.state.formValues.fileInfoID
      ? "登録完了しました。"
      : "更新しました。";

    this.fileUpload(
      data,
      "patient_info/josan_record/create_or_update",
      successMsg,
      this.state.formValues.fileInfoID ? ACTION.UPDATE : ACTION.CREATE
    );
  };

  fileUpload = (
    data: any,
    route: string,
    successMsg: string,
    action?: string
  ) => {
    const formData = new FormData();
    if (this.state.acceptedFiles) {
      formData.append("file_uploaded", this.state.acceptedFiles);
    }
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    const url = `${process.env.REACT_APP_API_URL}/api/${route}`;
    const method = "POST";
    const body = formData;

    fetch(url, { method, body })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          isLoading: false,
        });
        if (result.success) {
          if (action === ACTION.CREATE) {
            this.fetchDataSetting();
          }
        }

        this.setSuccessMessage(successMsg);
        this.onResetForm();
      })
      .catch(console.error);
  };

  // edit medical record
  setFormValues = (fileInfoID: number) => {
    console.log(fileInfoID, "fileInfoID");
    const midwiferyRecords = this.state.midwiferyRecords.find(
      (midwiferyRecord) => midwiferyRecord.fileInfoID === fileInfoID
    ) as any;

    const mappingData = {
      fileInfoID: fileInfoID,
      patient_id: midwiferyRecords.patient_id,
      recode_date: midwiferyRecords.recode_date,
      recode_file_name: midwiferyRecords.recode_file_name,
      recode_type: midwiferyRecords.recode_type === "手術" ? 1 : 2,
      dayTime: Math.floor(midwiferyRecords.recode_date / 1440),
      hoursTime: convertToHHMM(midwiferyRecords.recode_date % 1440) as any,
    };

    this.setState({
      hoursTime: convertHoursTime(mappingData.hoursTime as any) as any,
      formValues: mappingData,
      filePath: midwiferyRecords.url,
    });
    window.scrollTo(0, 0);
  };

  setMidwiferyRecords = (fileInfoID: number, action: string, data?: string) => {
    const { midwiferyRecords } = this.state;
    let midwiferyRecordsNew;
    switch (action) {
      case ACTION.DELETE:
        midwiferyRecordsNew = midwiferyRecords.filter(
          (item) => item.fileInfoID !== fileInfoID
        );
        break;
      case ACTION.UPDATE:
        midwiferyRecordsNew = midwiferyRecords.map((doctorInstruction) => {
          const newData =
            doctorInstruction.fileInfoID === fileInfoID
              ? this.state.formValues
              : {};
          return {
            ...doctorInstruction,
            ...newData,
          };
        });
        break;

      default:
        break;
    }

    if (midwiferyRecordsNew) {
      this.setState({
        midwiferyRecords: midwiferyRecordsNew as any,
      });
    }
  };

  setSuccessMessage = (successMsg: string) => {
    this.setState({
      successMsg,
    });
    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 2000);
  };

  onResetForm = () => {
    this.setState({
      hoursTime: null,
      formValues: {} as MidwiferyRecordType,
    });
  };

  // set field for event onchange input
  setField = (field: string, value: any) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [field]: value,
      },
    });
  };
  onSelectFile = (event: any) => {
    document.getElementById('upLoad')?.click()

  }
  handleUploadClick = (event: any) => {
    console.log("CLICK FILE");

    const files = event.target.files;
    files.length > 0 &&
      this.setState({
        acceptedFiles: files[0],
        filePath: URL.createObjectURL(files[0]),
      });
  };

  public render() {
    return (
      <Container fluid>
        <Row>
          <Col md={9} className="mt-5" style={{ margin: "auto" }}>
            <h2>助産記録入力</h2>

            <Form className="mt-5" onSubmit={this.onSubmitForm}>
              <Form.Group as={Row}>
                <Form.Label
                  column
                  md={2}
                  className="font-weight-bold text-left"
                >
                  経過日時:
                </Form.Label>
                <Col md={2} className="d-flex">
                  <Form.Control
                    style={{ width: "80px" }}
                    type="text"
                    required
                    value={this.state.formValues.dayTime || ""}
                    onChange={(e) => this.setField("dayTime", e.target.value)}
                    onKeyPress={onKeyPress}
                  />
                  <span className="ml-1 mt-1">日目</span>
                </Col>
                <Col md={2} style={{ marginTop: "-11px" }}>
                  <TimePicker
                    value={this.state.hoursTime || null}
                    onChange={(time: any) =>
                      this.setState({
                        hoursTime: time,
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label
                  column
                  md={2}
                  className="font-weight-bold text-left"
                >
                  記録名：
                </Form.Label>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    required
                    value={this.state.formValues.recode_file_name || ""}
                    onChange={(e) =>
                      this.setField("recode_file_name", e.target.value)
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label
                  column
                  md={2}
                  className="font-weight-bold text-left"
                >
                  記録名：
                </Form.Label>
                <Col md={2}>
                  <Form.Control
                    as="select"
                    defaultValue={1}
                    value={this.state.formValues.recode_type}
                    onChange={(e) =>
                      this.setField("recode_type", e.target.value)
                    }
                  >
                    <option value={1}>手術</option>
                    <option value={2}>助産</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label
                  column
                  md={2}
                  className="font-weight-bold text-left"
                >
                  アップロードファイル：
                </Form.Label>
                <Col md={2}>
                  <StyleInputContainer>
                    <Button variant="primary"
                      onClick={this.onSelectFile}
                    >
                      <StyledInput
                        id="upLoad"
                        placeholder="kadjaksjdhkjhadjk"
                        accept="application/pdf"
                        type="file"
                        onChange={this.handleUploadClick}
                      />
                      ファイル選択
                    </Button>
                  </StyleInputContainer>
                </Col>
              </Form.Group>
              {this.state.filePath && (
                <StyleDocument
                  file={this.state.filePath}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                >
                  <Page pageNumber={1} />
                </StyleDocument>
              )}
              <StyledButton>
                <ButtonSpriner isLoading={this.state.isLoading} label="登録" />
                <Button variant="outline-primary" onClick={this.onResetForm}>
                  クリア
                </Button>
              </StyledButton>
              {this.state.errors && (
                <Alert className="mt-3" severity="error">
                  医療記録の入力に失敗しました
                </Alert>
              )}
              {this.state.successMsg && (
                <Row className="ml-4">
                  <Col md={9} className="mt-5" style={{ margin: "auto" }}>
                    <Alert className="mt-3">{this.state.successMsg}</Alert>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </Row>

        {this.state.midwiferyRecords.length === 0 ? (
          <LoadingSpriner className="mt-5" />
        ) : (
          <MidwiferyRecordTable
            midwiferyRecords={this.state.midwiferyRecords}
            setSuccessMessage={this.setSuccessMessage}
            setMidwiferyRecords={this.setMidwiferyRecords}
            setFormValues={this.setFormValues}
          />
        )}
      </Container>
    );
  }
}

const StyledButton = styled.div`
  margin-top: 40px;
  .btn {
    width: 125px;
    margin-right: 20px;
  }
`;

const StyleDocument = styled(Document)`
  justify-content: center;
  display: flex;
`;

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
`;

const StyleInputContainer = styled.div`
  float: left;
`;

export default withRouter(CreatePatientMidwiferyRecord);
