import React from "react";
import Table from "react-bootstrap/Table";
import { PatientBasicInfoType } from "../models/Types";
import { withRouter, RouteComponentProps } from "react-router-dom";
import dayjs from "dayjs";

// interface Props {
//   patientID: string;
// }
// interface Props extends RouteComponentProps<{
//   // patientID: string
// }> { patientID: string}
interface Props extends RouteComponentProps {
  patientID: string | null;
  facultyID: string | null;
  studentID: string | null;
  startDate: string | null;
}

interface State extends PatientBasicInfoType {
  // startDate: string | null;
}

class PatientBasicInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      patientName: "",
      birthday: "",
      birthdayYYYYMMDD: "",
      department: "",
      age: undefined,
      gender: "",
      address: "",
      diseaseName: "",
      hospitalAdmissionDate: "",
      hospitalAdmissionDateInterval: "",
      hospitalStayDays: undefined,
      roomName: "",
      // startDate: '',
    };

    this.loadPatientBasicInfo = this.loadPatientBasicInfo.bind(this);
    this.goToCreatePatientInformation = this.goToCreatePatientInformation.bind(
      this
    );
    this.getType = this.getType.bind(this);
  }

  public loadPatientBasicInfo() {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/basic_info?patient_id=${this.props.patientID}&student_id=${this.props.studentID}&faculty_id=${this.props.facultyID}&start_date=${this.props.startDate}`;

    fetch(url, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log('result:', result)
          this.setState({
            patientName: result["patientName"],
            birthday: result["birthday"],
            birthdayYYYYMMDD: result["birthdayYYYYMMDD"],
            age: result["age"],
            gender: result["gender"],
            address: result["address"],
            diseaseName: result["diseaseName"],
            department: result["department"],
            hospitalAdmissionDate: result["hospitalAdmissionDate"],
            hospitalAdmissionDateInterval:
            result["hospitalAdmissionDateInterval"],
            hospitalStayDays: result["hospitalStayDays"],
            roomName: result["roomName"],
          });
          console.log('this.state.birthdayYYYYMMDD:', this.state.birthdayYYYYMMDD)
        },
        (error) => {
          console.log("Error: loadAllStudentTableItems",);
        }
      );

    // const url2 = `${process.env.REACT_APP_API_URL}/api/patient_info/faculty/select_student_id?student_id=${this.props.studentID}&faculty_id=${this.props.facultyID}`;

    // fetch(url2, { mode: "cors" })
    //   .then(res => res.json())
    //   .then(
    //     result => {
    //       this.setState({
    //         startDate: result,

    //       });
    //       console.log("🚀 ~ file: PatientBasicInfo.tsx ~ line 78 ~ PatientBasicInfo ~ loadPatientBasicInfo ~ startDate", result)
    //       console.log("🚀 ~ file: PatientBasicInfo.tsx ~ line 78 ~ PatientBasicInfo ~ loadPatientBasicInfo ~ startDate", this.state.hospitalAdmissionDate)
    //       const hospitalAdmissionDateAdd = dayjs(result, "YYYY年MM月DD日")

    //     },
    //     error => {
    //       console.log("Error: loadAllStudentTableItems");
    //     }
    //   );
  }

  public componentDidMount() {
    this.loadPatientBasicInfo();
  }

  public goToCreatePatientInformation() {
    this.props.history.push("/create-new/basic-patient-information", {
      data: {
        ...this.state,
        patientID: this.props.patientID,
        hospitalAdmissionDate: Math.abs(
          this.state.hospitalAdmissionDateInterval
        ),
        birthday: this.state.birthdayYYYYMMDD,
        department: this.state.department,
      },
      isEdit: true,
    });
  }
  public getType() {
    const query = new URLSearchParams(this.props.location.search);
    const type = query.get("type");
    return type;
  }
  public render() {
    const isType: Boolean = this.getType() === "setting";

    return (
      <div>
        <h2 className="font-weight-bold mb-4">患者情報</h2>
        <Table striped bordered hover>
          <thead className="thead-dark">
            <tr className="text-nowrap">
              <th>患者氏名</th>
              <th>生年月日</th>
              <th>年齢</th>
              <th>性別</th>
              <th>住所</th>
              <th>病名</th>
              <th>入院日</th>
              <th>入院日数</th>
              <th>病室名</th>
            </tr>
          </thead>
          <tbody>
            <tr
              className="text-nowrap"
              onClick={isType ? this.goToCreatePatientInformation : undefined}
            >
              <td>{this.state.patientName}</td>
              <td>{this.state.birthday.substring(5)}</td>
              <td>{this.state.age}</td>
              <td>{this.state.gender}</td>
              <td>{this.state.address}</td>
              <td>{this.state.diseaseName}</td>
              <td>{this.state.hospitalAdmissionDate}</td>
              <td>{this.state.hospitalStayDays}</td>
              <td>{this.state.roomName}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

// export default PatientBasicInfo;
export default withRouter(PatientBasicInfo);
