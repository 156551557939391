import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import Overview from "./overview/Overview";
import "../../assets/styles/IndexFaculty.scss";

interface Props extends RouteComponentProps<{}> {}
interface State {
  formValues: {
    startDate: number | null;
    endDate: number | null;
  };
}

class CreatePatientInformationOverview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { location } = this.props;
    const { patientID, studentID, facultyID } = location.state as any;

    return (
      <Container fluid className="mt-5 mb-5">
        <h2>オーバービュー入力 </h2>
        <Row>
          <Col md={12}>
            <Overview
              patientID={patientID}
              studentID={studentID}
              facultyID={facultyID}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(CreatePatientInformationOverview);
