import React from "react";
import { onKeyPressNumber } from "../../common/function";
import { cloneDeep as _cloneDeep } from "lodash";
import CustomizedDialogs from "../../common/CustomizedDialogs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "@material-ui/lab/Alert";
import TestDataTableLeft from "./TestDataTableLeft";
import TestDataTableData from "./TestDataTableData";
import CSVReaderImport from "../../common/CSVReaderImport";
import { LoadingSpriner } from "../../common/CircularSpinner";
// import { CSVReader } from "react-papaparse"; 
import Link from "@material-ui/core/Link";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import "../../../assets/styles/TestData.scss";
import { TestDataItemType } from "../../models/Types";
import {TestDataItemRecordTypeNew} from "../../models/Types";
// type TestDataItemRecordType = {
//   date: string;
//   date_time_text: string;
//   date_time: string,
//   results: {
//     id: number;
//     display_order: number;
//     item_id: number;
//     parent_id: number;
//     result: string;
//   }[];
//   dialogOpen: boolean;
// };

interface Props {
  patientID: string;
  studentID: string;
  facultyID: string;
}
interface State {
  testDataItems: TestDataItemType[];
  testDataItemParents: TestDataItemType[];
  testDataRecords: TestDataItemRecordTypeNew[];
  testFilter: TestDataItemRecordTypeNew[];
  openModal: boolean;
  isLoading: boolean;
  successMsg: boolean;
  error: boolean;
  formTestData: any;
  newDataUpdate: any;
  formValues: {
    startDate: number | null;
    endDate: number | null;
  };
  message: string | null;
  dialogOpen: boolean;
}

class TestData extends React.Component<Props, State> {
  private supportPageOffset: boolean | null;
  private isCSS1Compat: boolean | null;
  private demoItem2: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      testDataItems: [],
      testDataItemParents: [],
      testDataRecords: [],
      testFilter: [],
      openModal: false,
      isLoading: false,
      successMsg: false,
      error: false,
      formValues: {
        startDate: null,
        endDate: null,
      },
      dialogOpen: false,
      formTestData: {},
      newDataUpdate: {},
      message: ''
    };

    this.supportPageOffset = window.pageXOffset !== undefined;
    this.isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
    this.demoItem2 = React.createRef();
  }

  setTestData = (newData: TestDataItemType[]) => {
    // reload
    this.initData();
    // this.setState({
    //   testDataItems: newData,
    // });
  };

  public componentDidMount() {
    // const url = `${process.env.REACT_APP_API_URL}/api/patient_info/test_data?patient_id=${this.props.patientID}&student_id=${this.props.studentID}&faculty_id=${this.props.facultyID}&start_date=null`;
    // SW change student_id -> create_new_test_data for create
    this.initData();
  }
  initData(){
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/test_data?patient_id=${this.props.patientID}&student_id=create_new_test_data&faculty_id=${this.props.facultyID}&start_date=null`;
    fetch(url, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result) {
            console.log('result:', result)
            let dataItem = [];
            if (result.items.length > 0) {
              dataItem = result.items
                .map((item: any) => {
                  if (!item.is_parent) {
                    return {
                      parent_id: item?.parent_id,
                      display_order: item.order,
                      item_name: "",
                      result: "",
                      id: `add_${item.id}`,
                      item_id: item.id,
                    };
                  }
                })
                .filter((i: any) => i);
            }

            const records =
              result.records.length > 0
                ? result.records
                : [
                  {
                    date: 0,
                    date_time_text: 0,
                    results: dataItem,
                  },
                ];

            const cloneData = _cloneDeep(records);
            const newData = {} as any;

            cloneData.forEach((items: any) => {
              items.results.forEach((r: any) => {
                newData[r.id] = {
                  // date: items.date,
                  item_id: r.item_id,
                  result: r.result,
                };
              });
            });

            this.setState({
              formTestData: newData,
              testDataItems: result.items,
              testDataItemParents: result.parents,
              testDataRecords: records,
              testFilter: records,
            });
          }
        },
        (error) => {
          console.log(error, "Error: loadTestDataTable");
        }
      );

    window.addEventListener("scroll", this.handleScroll, true);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, true);
  }

  handleScroll = () => {
    var x = this.supportPageOffset
      ? window.pageXOffset
      : this.isCSS1Compat
        ? document.documentElement.scrollLeft
        : document.body.scrollLeft;

    let y = x - 0;
    this.demoItem2.current.style.left = y + "px";
  };

  
  addNew = () => {
    const cloneData = _cloneDeep(this.state.formTestData);
    const newDataUpdate = {} as any;
    const dataItem = this.state.testDataItems
      .map((item: any) => {
        if (!item.is_parent) {
          cloneData[`add_${item.id}`] = {
            date: 0,
            item_id: item.id,
            result: "",
          };
          newDataUpdate[`add_${item.id}`] = {
            date: 0,
            item_id: item.id,
            result: "",
          };
          return {
            parent_id: item.parent_id,
            display_order: item.order,
            item_name: "",
            result: "",
            // id: `add_${item.id}`,
            item_id: item.id,
          };
        }
      })
      .filter((i: any) => i);
    const last_record = this.state.testFilter[this.state.testFilter.length-1];
    // add 1 day . 24h
    const date_convert = last_record.date_time.split(":00:00");
    const total_hour = Number(date_convert[0])+ 24;
    const newData = [
      {
        date:  Number(last_record.date) + 1*24*60,
        date_time: total_hour+ ':00:00',
        date_time_text: 0,
        results: dataItem
      },
    ];
    console.log('this.state.testFilter: last:', newData)
    this.setState({
      formTestData: cloneData,
      newDataUpdate: newDataUpdate,
      testFilter: this.state.testFilter.concat(newData as any),
    });
  };

  // set field for event onchange input
  setField = (field: string, value: string) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [field]: value,
      },
    });
  };

  onSearch = () => {
    const startDate = this.state.formValues.startDate || 0;
    const endDate = this.state.formValues.endDate || 365;

    const newData = this.state.testDataRecords.filter(
      (test) =>
        Math.floor(Number(test.date) / 1440) >= Number(startDate) &&
        Math.floor(Number(test.date) / 1440) <= Number(endDate)
    );

    this.setState({
      testFilter: newData,
    });
  };

  setSuccessMsg = () => {
    this.setState({
      successMsg: true,
      isLoading: false,
      dialogOpen: true,
    });
    setTimeout(() => {
      this.setState({
        successMsg: false,
        dialogOpen: false,
      });
    }, 3000);
  };

  setError = () => {
    this.setState({
      error: true,
      isLoading: false,
    });
    setTimeout(() => {
      this.setState({
        error: false,
      });
    }, 3000);
  };

  onHandleCSV = (data: any, fileInfo: any) => {
    console.log('data:', data)
    if (fileInfo.name) {
      const regex = /^.+\.(csv)$/g;
      const checkType = regex.test(fileInfo.name);
      if (checkType) {
        this.setState({
          isLoading: true,
        });
        this.fetchAPI(data);
      } else {
        this.setError();
      }
    }
  };

  fetchAPI = (data: any) => {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/test_data_records/create?mode=csv`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          this.setSuccessMsg();
        } else {
          this.setError();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setError();
      });
  };

  handleClose = () => {
    this.setState({
       openModal: false ,
       dialogOpen: false,
      });
  };

  handleOpen = () => {
    this.setState({ openModal: true });
  };


  public render() {
    return (
      <div className="datagrid-viewport-testdata">
        <StyledBtnModal>
          <CustomizedDialogs
            testDataItems={this.state.testDataItemParents}
            testDataRecords={this.state.testDataRecords}
            setTestData={this.setTestData}
            patientID={this.props.patientID}
          />
        </StyledBtnModal>
        <StyledFormSearch>
          <Col
            md={12}
            style={{ margin: "auto" }}
            className="d-flex justify-content-center align-items-baseline mb-5"
          >
            <Form.Label className="font-weight-bold pr-3">
              表示範囲：
            </Form.Label>
            <Form.Control
              className="m-0"
              required
              type="text"
              onKeyPress={onKeyPressNumber}
              value={this.state.formValues.startDate || ""}
              onChange={(e) => this.setField("startDate", e.target.value)}
            />
            <Form.Label className="font-weight-bold pr-3">日目</Form.Label>
            <Form.Label className="font-weight-bold pr-2">～</Form.Label>
            <Form.Control
              className="m-0"
              required
              type="text"
              onKeyPress={onKeyPressNumber}
              value={this.state.formValues.endDate || ""}
              onChange={(e) => this.setField("endDate", e.target.value)}
            />
            <Form.Label className="font-weight-bold pr-3">日目</Form.Label>
            <Button onClick={this.onSearch} variant="primary">
              <span>反映</span>
            </Button>

            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                marginLeft: "100px",
              }}
            >
              <CSVReaderImport
                handleForce={this.onHandleCSV}
                className="react-csv-input"
              />
              {/* <CSVReader
                onDrop={this.handleOnDrop}
                onError={this.handleOnError}
                noDrag
                addRemoveButton
                onRemoveFile={this.handleOnRemoveFile}
              >
                <span>Click to upload.</span>
              </CSVReader> */}
              <Link
                href={`${process.env.REACT_APP_API_URL}/api/patient_info/csv-sample-download?fileName=[サンプル]検査データ入力.csv`}
                style={{
                  cursor: "pointer",
                  color: "#007bff",
                  textDecoration: "none",
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
                color="primary"
              >
                CSVテンプレート
              </Link>
            </div>
          </Col>
          <Col md={12} className="mb-5 mt-3">
            {this.state.isLoading && <LoadingSpriner />}
            {this.state.successMsg && (
              <Alert className="mt-3 sw-custom-btn">
                データの登録が完了しました。
              </Alert>
            )}
            {this.state.error && (
              <Alert severity="error" className="mt-3 sw-custom-btn">
                データの登録に失敗しました。
              </Alert>
            )}
          </Col>
          <Button onClick={this.addNew} variant="primary" className="mb-2">
            <span>時間追加</span>
          </Button>
        </StyledFormSearch>

        <div
          id="demoItem2"
          className="col-label datagrid-pinned-data"
          ref={this.demoItem2}
        >
          {this.state.testDataItems.length > 0 && (
            <TestDataTableLeft testDataItem={this.state.testDataItems} />
          )}
        </div>
        
        <div className="col-data d-flex flex-row x-scrollable-pane">
          {this.state.testFilter.map((item, i) => (
            <TestDataTableData
              callback={(message: any) => {
                console.log('message:', message)
                this.setSuccessMsg()
              }}
              patientID={this.props.patientID}
              key={item.date+'_'+i}
              testData={item}
              newDataUpdate={this.state.newDataUpdate}
              formTestData={this.state.formTestData}
              testDataItem={this.state.testDataItems}
              setTestData={this.setTestData}
            />
          ))}
        </div>
        <Dialog
          open={this.state.dialogOpen}
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Alert className="mt-3">更新しました。</Alert>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const StyledFormSearch = styled(Row)`
  input {
    width: 50px;
  }
`;

const StyledBtnModal = styled.div`
  position: fixed;
  z-index: 999;
  background: #21252994;
  left: 20px;
  width: 180px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export default TestData;