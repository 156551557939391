import React from "react";
import Row from "react-bootstrap/Row";
import path from "path";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Document, Page, pdfjs } from "react-pdf";
import { FileListType, ChartCommentInfoType } from "../../models/Types";
import "../../../assets/styles/PDFView.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  studentID: string;
  fileUploadNum: number;
}
interface State {
  fileUploadNum: number;
  numPages: number;
  pageNumber: number;
  fileList: FileListType[];
  fileIndex: number;
  fileName: string;
  chartCommentInfo: ChartCommentInfoType[];
}

class PDFViews extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      numPages: 4,
      pageNumber: 1,
      fileUploadNum: this.props.fileUploadNum,
      fileList: [],
      fileIndex: 0,
      fileName: '',
      chartCommentInfo: [],
    };

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.onClickFileListRow = this.onClickFileListRow.bind(this);
    this.deleteButton = this.deleteButton.bind(this);
  }

  public onDocumentLoadSuccess() {
    this.setState({ numPages: 1 });
  }

  public onClickFileListRow(i: number,file_name: string) {
    this.setState({ fileIndex: i, fileName: file_name  });
    // console.log(`fileIndex: ${i}`);
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/chart_pdf/file_list?student_id=${this.props.studentID}`;

    fetch(url, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          let index_file: string =  result.length > 0 ? result[0]['file_name'] : ''
          this.setState({
            fileList: result,
            fileName: index_file,
          });
        },
        (error) => {
          console.log("API Error");
        }
      );

    const url2 = `${process.env.REACT_APP_API_URL}/api/chart_pdf/comment_list?student_id=${this.props.studentID}`;

    fetch(url2, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          this.setState({
            chartCommentInfo: result,
          });
        },
        (error) => {
          console.log("API Error");
        }
      );
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.fileUploadNum !== prevProps.fileUploadNum) {
      const url = `${process.env.REACT_APP_API_URL}/api/chart_pdf/file_list?student_id=${this.props.studentID}`;

      fetch(url, { mode: "cors" })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              fileList: result,
            });
          },
          (error) => {
            console.log("API Error");
          }
        );
    }
  }

  public deleteButton(file_name: string){
   
    const url = `${process.env.REACT_APP_API_URL}/api/chart_pdf/delete_file?student_id=${this.props.studentID}&file_name=${file_name}`;

    fetch(url, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.status === "success") {
            window.location.reload();
          }
        },
        (error) => {
          console.log("API Error");
        }
      );
  }

  public render() {
    const fileListRow = this.state.fileList.map((item: any, i) => {
    

      const filePath = path.basename(item["filePath"]);

      return (
        <tr
          key={i}
          className={i === this.state.fileIndex ? "table-active" : ""}
          onClick={this.onClickFileListRow.bind(this, i, item.file_name)}
        >

          <td className="text-center">{i + 1}</td>
          <td>{filePath}</td>
          <td>{item['timestamp']}</td>
          <td>
            <Button
              className="font-weight-bold"
              variant="outline-info"
              onClick={this.deleteButton.bind(
                this,
                item.file_name
              )}
            >
              削除
              </Button>
          </td>

        </tr>
      );
    });

    return (
      <Row className="mt-5 mb-5">
        <Col md={8} className="ovf-scroll">
          <div>
            {this.state.fileList.length === 0 && (
              <p>ファイルがまだありません</p>
            )}
            {this.state.fileList.length > 0 && (
              <Document
                // file={this.state.fileList[this.state.fileIndex]["filePath"]}
                file={'/chart_pdf/'+this.props.studentID+'/'+this.state.fileName}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={this.state.pageNumber} />
              </Document>
            )}
          </div>
        </Col>
        <Col md={4}>
          <Table id="chart_pdf_list" className="chart_pdf_list_student" bordered hover>
            <thead>
              <th>#</th>
              <th>PDF</th>
              <th>提出日</th>
              <th ></th>
            </thead>
            <tbody>{fileListRow}</tbody>
          </Table>
          <div className="mt-5">
            <h5 className="mb-3 clor-lightnavi">教員コメント</h5>
            <pre className="p-3 text-left custom-comment">
              {this.state.chartCommentInfo.length > 0
                ? this.state.chartCommentInfo[this.state.fileIndex]["comment"]
                : "なし"}
            </pre>
          </div>
        </Col>
      </Row>
    );
  }
}

export default PDFViews;
