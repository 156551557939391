import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container, Form, Col, Row } from "react-bootstrap";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { ButtonSpriner } from "../common/CircularSpinner";
import { PatientBasicInfoType } from "../models/Types";
import "../../assets/styles/IndexFaculty.scss";
import { getUserId, USER_TYPE } from "../../services/AppLocalHelper";
import { checkUserId } from "../../services/AuthService";
interface Props extends RouteComponentProps<{}> { }
interface State {
  form: PatientBasicInfoType;
  isLoading: boolean;
  isEdit: boolean;
  errors: string;
  dialogOpen: boolean;
  departmentInfos: {
    department_id: string;
    department_name: string;
  }[];
  isFacultySupervisor: boolean;
  affiliationFaculty: string;
}

class CreateBasicPatientInfomation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { location } = this.props.history;
    const { data, isEdit } = location.state as any;
    console.log('data:', data)
    let userId = getUserId();
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }
    this.getFaculty(userId);
    this.state = {
      form: data,
      isLoading: false,
      dialogOpen: false,
      isEdit: isEdit || false,
      errors: "",
      departmentInfos: [
        {
          department_id: "",
          department_name: "",
        },
      ],
      isFacultySupervisor: false,
      affiliationFaculty: ""
    };

    this.setField = this.setField.bind(this);
  }
  public getFaculty(facultyID: string){
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${facultyID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
          console.log('getFaculty', result);
     
          this.setState({isFacultySupervisor : result.is_supervisor, affiliationFaculty: result.affiliation_id});
      })
      .catch(console.error);
}

  componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/department-infos/list`;
    fetch(url, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.length > 0) {
            this.setState({
              departmentInfos: result,
            });
          }
        },
        (error) => {
          console.log(error, "Error: loadDepartment");
        }
      );
  }

  handleClose = () => {
    this.setState({
      dialogOpen: true,
    });
    this.props.history.goBack();
  };

  setField = (field: string, value: string) => {
    console.log('field:', field)
    console.log('value:', value)
    this.setState({
      form: {
        ...this.state.form,
        [field]: value,
      },
    });
  };

  onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (/^[a-zA-Z]+$/.test(e.key)) {
      e.preventDefault();
    }
  };

  // setSuccessMessage = (successMsg: string) => {
  //   this.setState({
  //     isSuccess: successMsg,
  //     isLoading: false,
  //   });
  //   setTimeout(() => {
  //     this.setState({
  //       isSuccess: "",
  //     });
  //   }, 2000);
  // };

  setError = (messageError: string) => {
    this.setState({
      errors: messageError ? messageError : "データを入力してください",
      isLoading: false,
    });
    setTimeout(() => {
      this.setState({
        errors: "",
      });
    }, 2000);
  };

  onSubmitPatientInfo = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { form } = this.state;
    const {
      patientID,
      patientName,
      birthday,
      age,
      gender,
      address,
      diseaseName,
      hospitalAdmissionDate,
      hospitalStayDays,
      roomName,
      department,
    } = form as any;
    let year = new Date().getFullYear()
    let newBirthDay = "";
    if (birthday != null && birthday.length > 5) {
      newBirthDay = `${year}-${birthday.substring(4)}`
    } else {
      newBirthDay = `${year}-${birthday}`
    }
    const fromValues = {
      patient_id: patientID,
      patient_name: patientName,
      birthday: newBirthDay,
      age,
      gender,
      address,
      disease_name: diseaseName,
      hospital_admission_date: (Math.abs(hospitalAdmissionDate) || 0) * 24 * 60 * 60,
      hospital_stay_days: hospitalStayDays,
      room_name: roomName,
      department: department || "選択不可",
      affiliation: this.state.isFacultySupervisor ? "COMMON" : this.state.affiliationFaculty,
    };
    console.log('fromValues:', fromValues)
    console.log('hospitalAdmissionDate:', hospitalAdmissionDate)
    this.setState({ errors: "" });
    if (Object.keys(this.state.form).length === 0) {
      this.setState({ errors: "データを入力してください" });
      return;
    } else {
      this.setState({
        isLoading: true,
      });
      const url = `${process.env.REACT_APP_API_URL}/api/patient_info/create`;
      const method = "POST";
      const mode = "cors";
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const body = JSON.stringify(fromValues);

      fetch(url, { method, mode, headers, body })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            isLoading: false,
          });
          if (result.success) {
            this.setState({
              dialogOpen: true,
            });
          } else {
            const messageError = result.error
              ? result.error.message
              : "登録済みの病室名です。";
            if (result.error) {
              this.setError(messageError);
            }
          }
        })
        .catch(() => {
          this.setError("保存に失敗しました");
        });
    }
  };
  public render() {
    return (
      <Container fluid>
        <Row>
          <Col md={3}></Col>
          <Col md={6} className="mt-5 mb-5">
            <h2>患者基本情報入力</h2>

            <Form className="mt-4" onSubmit={this.onSubmitPatientInfo}>
              <Form.Group as={Row} controlId="fg-1">
                <Form.Label
                  column
                  md={4}
                  className="font-weight-bold text-right"
                >
                  患者番号
                </Form.Label>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    disabled={this.state.isEdit}
                    value={this.state.form.patientID || ""}
                    onChange={(e) => this.setField("patientID", e.target.value)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="fg-1">
                <Form.Label
                  column
                  md={4}
                  className="font-weight-bold text-right"
                >
                  氏名
                </Form.Label>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    value={this.state.form.patientName || ""}
                    onChange={(e) =>
                      this.setField("patientName", e.target.value)
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="fg-1">
                <Form.Label
                  column
                  md={4}
                  className="font-weight-bold text-right"
                >
                  生年月日
                </Form.Label>
                <Col md={3}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      required
                      style={{ float: "left" }}
                      autoOk={true}
                      format="MM-DD"
                      value={
                        moment(this.state.form.birthday).format("YYYY-MM-DD") ||
                        moment().format("YYYY-MM-DD")
                      }
                      onChange={(date) => {
                        this.setField(
                          "birthday",
                          moment(date).format("MM-DD") as any
                        );
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Col>
                {/* old input */}
                <Form.Label
                  column
                  md={1}
                  className="font-weight-bold text-right"
                >
                  年齢
                </Form.Label>
                <Col md={2}>
                  <Form.Control
                    type="text"
                    value={this.state.form.age || ""}
                    onChange={(e) =>
                      this.setField("age", e.target.value)
                    }
                    onKeyPress={this.onKeyPress}
                    required
                  />
                </Col>
                {/* end old input */}

              </Form.Group>
              <Form.Group as={Row} controlId="fg-1">
                <Form.Label
                  column
                  md={4}
                  className="font-weight-bold text-right"
                >
                  性別
                </Form.Label>
                <Col md={6} className="text-left">
                  <Form.Check
                    name="gender"
                    inline
                    type="radio"
                    label="男"
                    value="男"
                    checked={this.state.form.gender === '男'}
                    onChange={(e) => this.setField("gender", e.target.value)}
                  />
                  <Form.Check
                    name="gender"
                    inline
                    type="radio"
                    label="女"
                    value="女"
                    checked={this.state.form.gender === '女'}
                    onChange={(e) => this.setField("gender", e.target.value)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="fg-2">
                <Form.Label
                  column
                  md={4}
                  className="font-weight-bold text-right"
                >
                  住所
                </Form.Label>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    value={this.state.form.address || ""}
                    onChange={(e) => this.setField("address", e.target.value)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="fg-3">
                <Form.Label
                  column
                  md={4}
                  className="font-weight-bold text-right"
                >
                  病名
                </Form.Label>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    value={this.state.form.diseaseName || ""}
                    onChange={(e) =>
                      this.setField("diseaseName", e.target.value)
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="fg-4">
                <Form.Label
                  column
                  md={4}
                  className="font-weight-bold text-right"
                >
                  入院日
                </Form.Label>
                <Col md={6}>
                  <Form.Group as={Row}>
                    <Col md={4} style={{ paddingLeft: "0" }}>
                      <Form.Control
                        type="text"
                        value={this.state.form.hospitalAdmissionDate || ""}
                        onChange={(e) =>
                          this.setField("hospitalAdmissionDate", e.target.value)
                        }
                        onKeyPress={this.onKeyPress}
                      />
                    </Col>
                    <Form.Label md={2} className="font-weight-bold text-right">
                      日
                    </Form.Label>
                  </Form.Group>
                  <Form.Text className="text-muted text-left">
                    &nbsp;実習開始日を基準に、前後何日かで入力してください。
                  </Form.Text>
                  <Form.Text className="text-muted text-left">
                    &nbsp;例1）実習開始日＝入院日の場合
                  </Form.Text>
                  <Form.Text className="text-muted text-left">
                    &nbsp;&nbsp;”0”と入力
                  </Form.Text>
                  <Form.Text className="text-muted text-left">
                    &nbsp;例2）実習開始時、入院5日目とする場合
                  </Form.Text>
                  <Form.Text className="text-muted text-left">
                    &nbsp;&nbsp;”-5”と入力
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="fg-3">
                <Form.Label
                  column
                  md={4}
                  className="font-weight-bold text-right"
                >
                  病室名
                </Form.Label>
                <Col md={6}>
                  <Row>
                    <Col md={6} style={{ padding: "0" }}>
                      <Form.Control
                        type="text"
                        name=""
                        value={this.state.form.roomName || ""}
                        onChange={(e) =>
                          this.setField("roomName", e.target.value)
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        value={this.state.form.hospitalStayDays || ""}
                        onChange={(e) =>
                          this.setField("hospitalStayDays", e.target.value)
                        }
                        onKeyPress={this.onKeyPress}
                      />
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="fg-3">
                <Form.Label
                  column
                  md={4}
                  className="font-weight-bold text-right"
                >
                  診療科
                </Form.Label>
                <Col md={6}>
                  <Form.Control
                    as="select"
                    value={this.state.form.department as any}
                    onChange={(e) =>
                      this.setField("department", e.target.value)
                    }
                  >
                    {this.state.departmentInfos.map((department) => (
                      <option key={department.department_id} value={department.department_id}>
                        {department.department_name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Row>
                <Col md={2} />
                <Col md={8}>
                  <ButtonSpriner
                    className="mt-5 sw-custom-btn"
                    isLoading={this.state.isLoading}
                    label="登録"
                  />
                  {this.state.errors && (
                    <Alert severity="error" className="mt-3">
                      {this.state.errors}
                    </Alert>
                  )}
                </Col>
                <Col md={2} />
              </Row>
            </Form>
          </Col>
          <Col md={3}></Col>
        </Row>
        <Dialog
          open={this.state.dialogOpen}
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Alert className="mt-3">保存しました。</Alert>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

export default withRouter(CreateBasicPatientInfomation);
