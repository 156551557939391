import React from "react";
import Form from "react-bootstrap/Form";

interface Props {
  onItemSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value:any;
}
interface State {}

class SignUpStartYearSelect extends React.Component<Props, State> {
  public render() {
    const year = (new Date()).getFullYear() - 1;
    const years = Array.from(new Array(15),( val, index) => index + year);

    const options = years.map((item, i) => <option key={i}  value={item}>{item}</option>);
    return (
      <Form.Control value={this.props.value} required as="select" onChange={this.props.onItemSelect}>
        <option value="">選択</option>
        {options}
      </Form.Control>
    );
  }
}

export default SignUpStartYearSelect;
