import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

interface Props
  extends RouteComponentProps<{ university: string; startDate: string }> {}
interface State {
  studentList: {
    studentID: string;
    studentName: string;
    university_name: string;
    university: string;
    startDate: string;
    endDate: string;
    internDays: number;
  }[];
  newInternDays: number;
  updateInternDaysStatus: string;
  showAlert: boolean;
}

class StudentList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      studentList: [],
      newInternDays: 0,
      updateInternDaysStatus: "",
      showAlert: false,
    };

    this.onStudentListRowClicked = this.onStudentListRowClicked.bind(this);
    this.onSelectInternDays = this.onSelectInternDays.bind(this);
    this.onUpdateInternDays = this.onUpdateInternDays.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
  }

  public onStudentListRowClicked(studentID: string) {
    // SW fix 2021/3/1
    // this.props.history.push(`/faculty/student_info/${studentID}`);

    this.props.history.push(`/index/student/${studentID}`);
  }

  public onSelectInternDays(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = Number(e.target.value);
    this.setState({ newInternDays: value });
  }

  public onUpdateInternDays() {
    const url = `${process.env.REACT_APP_API_URL}/api/student_info/update_intern_days?university=${this.props.match.params.university}&start_date=${this.props.match.params.startDate}&new_intern_days=${this.state.newInternDays}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          this.setState({
            updateInternDaysStatus: "success",
            showAlert: true,
          });
        } else {
          this.setState({
            updateInternDaysStatus: "error",
          });
        }
      })
      .catch(console.error);
  }

  public onCloseAlert() {
    this.setState({ showAlert: false });
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info/student_list?university=${this.props.match.params.university}&start_date=${this.props.match.params.startDate}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          studentList: result,
        });
      })
      .catch(console.error);
  }

  public render() {
    const students = this.state.studentList.map((item, i) => {
      if (item.startDate === null) {
        return (
          <tr
            key={i}
            onClick={this.onStudentListRowClicked.bind(this, item.studentID)}
          >
            <td>{i + 1}</td>
            <td>{item.studentName}</td>
            <td>{item.university_name}</td>
            <td>no data</td>
            <td>no data</td>
          </tr>
        );
      } else {
        const startDate = item.startDate.split("-");
        const endDate = item.endDate.split("-");
        return (
          <tr
            key={i}
            onClick={this.onStudentListRowClicked.bind(this, item.studentID)}
          >
            <td>{i + 1}</td>
            <td>{item.studentID}</td>
            <td>{item.studentName}</td>
            <td>{item.university_name}</td>
            <td>{`${startDate[0]}年${startDate[1]}月${startDate[2]}日`}</td>
            <td>{`${endDate[0]}年${endDate[1]}月${endDate[2]}日`}</td>
            <td>{item.internDays}日目</td>
          </tr>
        );
      }
    });

    const intern_day_array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
      <div className="m-5 d-flex flex-column align-items-center">
        <h2 className="mb-3">実習日の変更</h2>
        <Table id="intern_days">
          <thead className="thead-dark">
            <tr>
              <th>変更前</th>
              <th>変更後</th>
              <th>変更</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {this.state.studentList.length > 0
                  ? this.state.studentList[0]["internDays"]
                  : 0}
                日目
              </td>
              <td>
                <Form>
                  <Form.Row>
                    <Col md={3} />
                    <Col md={6}>
                      <Form.Control
                        as="select"
                        onChange={this.onSelectInternDays}
                      >
                        {intern_day_array.map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col md={3} />
                  </Form.Row>
                </Form>
              </td>
              <td>
                <Button
                  className="mb-4"
                  variant="outline-primary"
                  onClick={this.onUpdateInternDays}
                >
                  更新
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
        {this.state.updateInternDaysStatus === "success" && (
          <Alert
            variant={"success"}
            dismissible
            show={this.state.showAlert}
            onClose={this.onCloseAlert}
          >
            実習日を更新しました
          </Alert>
        )}
        {this.state.updateInternDaysStatus === "error" && (
          <Alert
            variant={"danger"}
            dismissible
            show={this.state.showAlert}
            onClose={this.onCloseAlert}
          >
            実習日の更新に失敗しました
          </Alert>
        )}
        <h2 className="mb-3">学生一覧</h2>
        <Table id="index_faculty_student_list" className="" bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>学籍番号</th>
              <th>学生氏名</th>
              <th>所属施設</th>
              <th>実習開始日</th>
              <th>実習終了日</th>
              <th>実習日</th>
            </tr>
          </thead>
          <tbody>{students}</tbody>
        </Table>
      </div>
    );
  }
}

export default withRouter(StudentList);
