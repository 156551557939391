import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "@material-ui/lab/Alert";
import "../../../assets/styles/NurseDiagnosis.scss";
import CSVReaderImport from "../../common/CSVReaderImport";
import Link from "@material-ui/core/Link";
import styled from "styled-components";
import { NursingDiagnosisPatientType } from "../../models/Types";

interface Props {
  patientID: string;
  nursingDiagnosis: NursingDiagnosisPatientType[];
  onMouseClickUp: Function;
  onMouseClickDown: Function;
  setFormValues: Function;
  setSuccessMessage: Function;
  setNursingDiagnosis: Function;
  onVisibleMedicalRecord: Function;
  setLoading: Function;
  fetchData: Function;
}
interface State {
  successMsg: boolean;
  error: boolean;
}

class NurseDiagnosisPatients extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      successMsg: false,
      error: false,
    };
  }

  onDelete = (nursingDiagnosisID: number, patientID: string) => {
    const confirmDelete = window.confirm("削除してもよろしいですか？");
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/nursing_diagnosis/delete`;
    const method = "DELETE";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify({
      nursingDiagnosisID: nursingDiagnosisID,
      patientID: patientID,
    });
    if (confirmDelete) {
      fetch(url, { method, mode, headers, body })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            this.props.setSuccessMessage("削除しました。");
            this.props.setNursingDiagnosis(nursingDiagnosisID, "DELETE");
          }
        })
        .catch(console.error);
      return true;
    } else {
      return false;
    }
  };

  onUpdate = (nursingDiagnosisID: number) => {
    this.props.setFormValues(nursingDiagnosisID);
  };

  onVisible = (
    nursingDiagnosisID: number,
    patientID: string,
    visibleFlag: boolean
  ) => {
    console.log(nursingDiagnosisID, "nursingDiagnosisID");
    this.props.onVisibleMedicalRecord(nursingDiagnosisID, !visibleFlag);
  };

  actionItem = (visibleFlag: boolean) => [
    {
      label: visibleFlag ? "表示" : "非表示",
      action: this.onVisible,
    },
    {
      label: "編集",
      action: this.onUpdate,
    },
    {
      label: "削除",
      action: this.onDelete,
    },
  ];

  actionHTML = (
    nursingDiagnosisID: number,
    patientID: string,
    visibleFlag: boolean
  ) => {
    return this.actionItem(visibleFlag).map((item) => (
      <Button
        onClick={() => item.action(nursingDiagnosisID, patientID, visibleFlag)}
        key={item.label}
      >
        {item.label}
      </Button>
    ));
  };

  setSuccessMsg = () => {
    this.props.setLoading(false);
    this.setState({
      successMsg: true,
    });
    setTimeout(() => {
      this.setState({
        successMsg: false,
      });
    }, 3000);
  };

  setError = () => {
    this.props.setLoading(false);
    this.setState({
      error: true,
    });
    setTimeout(() => {
      this.setState({
        error: false,
      });
    }, 3000);
  };

  onHandleCSV = (data: any, fileInfo: any) => {
    if (fileInfo.name) {
      const regex = /^.+\.(csv)$/g;
      const checkType = regex.test(fileInfo.name);
      if (checkType) {
        this.props.setLoading(true);
        const newData = {
          patient_id: this.props.patientID,
          nursing_diagnosis_array: data,
        };
        this.fetchAPI(newData);
      } else {
        this.setError();
      }
    }
  };

  fetchAPI = (data: any) => {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/nursing_diagnosis/create_or_update`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          this.props.fetchData();
          this.setSuccessMsg();
        } else {
          this.setError();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setError();
      });
  };

  public render() {
    const nursingDiagnosisRow = this.props.nursingDiagnosis.map((item, i) => (
      <StyleNurseDiagnosisPatients visiable={item.visibleFlag ? 1 : 0} key={i}>
        <Col md={12}>
          <Table id="nursing_diagnosis">
            <thead>
              <tr>
                <th className="number" style={{ verticalAlign: "inherit" }}>
                  No.
                </th>
                <th
                  className="diagnosis_date"
                  style={{ verticalAlign: "inherit", whiteSpace: "nowrap" }}
                >
                  診断年月日
                </th>
                <th className="status" style={{ verticalAlign: "inherit" }}>
                  状況
                </th>
                <th>
                  <div className="float-left">看護診断/共同問題</div>
                  <StyledAction className="float-right">
                    {this.actionHTML(
                      item.nursingDiagnosisID as number,
                      item.patientID as string,
                      item.visibleFlag as boolean
                    )}
                  </StyledAction>
                </th>
                <th style={{ backgroundColor: "#ffffff", border: "none" }}></th>
              </tr>
            </thead>
            <tbody>
              <tr key={item.index}>
                <td>{`${i + 1}`}</td>
                <td>{item.date}</td>
                <td style={{ width: "50px" }}>
                  {item.status}
                </td>
                <td id="nursing_diagnosis" className="p-3">
                  <div className="text-left nurse-diagnosis-patients">
                    <Form.Row>
                      <Col md={2} className="d-flex align-items-center">
                        診断名：
                      </Col>
                      <Col>
                        <span style={{ whiteSpace: "pre-line" }}>{item.diagnosisName}</span>
                      </Col>
                    </Form.Row>
                  </div>
                  <div className=" text-left nurse-diagnosis-patients">
                    <Form.Row>
                      <Col md={2} className="d-flex align-items-center">
                        O：
                      </Col>
                      <Col>
                        <span style={{ whiteSpace: "pre-line" }}>{item.o}</span>
                      </Col>
                    </Form.Row>
                  </div>
                  <div className=" text-left nurse-diagnosis-patients">
                    <Form.Row>
                      <Col md={2} className="d-flex align-items-center">
                        T：
                      </Col>
                      <Col>
                        <span style={{ whiteSpace: "pre-line" }}>{item.t}</span>
                      </Col>
                    </Form.Row>
                  </div>
                  <div className=" text-left nurse-diagnosis-patients">
                    <Form.Row>
                      <Col md={2} className="d-flex align-items-center">
                        E：
                      </Col>
                      <Col>
                        <span style={{ whiteSpace: "pre-line" }}>{item.p}</span>
                      </Col>
                    </Form.Row>
                  </div>
                </td>
                <td
                  className="w-100px"
                  style={{ backgroundColor: "#ffffff", border: "none" }}
                >
                  {i !== 0 && (
                    <Button
                      className="button-up"
                      variant="secondary"
                      size="lg"
                      onClick={this.props.onMouseClickUp.bind(this, i)}
                    >
                      <i className="far fa-arrow-alt-circle-up"></i>
                    </Button>
                  )}
                  {i !== this.props.nursingDiagnosis.length - 1 && (
                    <Button
                      className="button-down"
                      variant="secondary"
                      size="lg"
                      onClick={this.props.onMouseClickDown.bind(this, i)}
                    >
                      <i className="far fa-arrow-alt-circle-down"></i>
                    </Button>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </StyleNurseDiagnosisPatients>
    ));

    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <CSVReaderImport
            handleForce={this.onHandleCSV}
            className="react-csv-input"
          />
          <Link
            href={`${process.env.REACT_APP_API_URL}/api/patient_info/csv-sample-download?fileName=[サンプル]看護診断入力.csv`}
            style={{
              cursor: "pointer",
              color: "#007bff",
              textDecoration: "none",
              marginLeft: "20px",
              marginTop: "10px",
            }}
            color="primary"
          >
            CSVテンプレート
          </Link>
        </div>
        <Col md={12} className="mt-3 mb-3">
          {this.state.successMsg && (
            <Alert className="mt-3 sw-custom-btn">
              データの登録が完了しました。
            </Alert>
          )}
          {this.state.error && (
            <Alert severity="error" className="mt-3 mb-3 sw-custom-btn">
              データの登録に失敗しました。
            </Alert>
          )}
        </Col>
        {nursingDiagnosisRow}
      </div>
    );
  }
}

const StyledAction = styled.div`
  button {
    font-size: 1em;
    text-align: left;
    color: blue;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    margin-left: 10px;
  }

  button:hover,
  button:focus {
    color: blue;
    background: none;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background: none;
    color: blue;
  }
`;

const StyleNurseDiagnosisPatients = styled(Row)`
  .table thead th {
    border-bottom: none;
  }
  .table th,
  .table td {
    border: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    background-color: ${({ visiable }) => (visiable ? "darkgray" : "")};
  }
`;

export default NurseDiagnosisPatients;
