import React from "react";
import Form from "react-bootstrap/Form";

interface Props {
    value:any;
    onItemSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
interface State {
    intern_place: {
        id: number;
        intern_place_name: string;
    }[];
}

class SignUpInternPlace extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            intern_place: [{ id: 0, intern_place_name: ""}]
        };
    }

    public componentDidMount() {

        const url = `${process.env.REACT_APP_API_URL}/api/signup/get_intern_place`;

        fetch(url, { mode: "cors" })
            .then(res => res.json())
            .then(
                result => {
                    console.log(result);
                    this.setState({
                        intern_place: result
                    });
                },
                error => {
                    console.log(error);
                }
            );

    }

    public render() {
        const options = this.state.intern_place.map((item, i) => (
            <option key={i} value={item.id}>{item.intern_place_name}</option>
        ));

        return (
            <Form.Control value={this.props.value} as="select" onChange={this.props.onItemSelect}>
                <option>選択</option>
                {options}
            </Form.Control>
        );
    }
}

export default SignUpInternPlace;
