import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import SignUpStartMonthSelect from "./forms/SignUpStartMonthSelect";
import SignUpStartDaySelect from "./forms/SignUpStartDaySelect";
import SignUpEndMonthSelect from "./forms/SignUpEndMonthSelect";
import SignUpEndDaySelect from "./forms/SignUpEndDaySelect";
import SignUpNurseSelect from "./forms/SignUpNurseSelect";
import { SignupStudentInfo } from "../models/Types";
import "../../assets/styles/SWCustom.scss";
import dayjs from "dayjs";
import SignUpStartYearSelect from './forms/SignUpStartYearSelect';
import SignUpEndYearSelect from './forms/SignUpEndYearSelect';
import SignUpUniversitySelect from './forms/SignUpUniversitySelect';
import SignUpInternPlace from './forms/SignUpInternPlace';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getUserId, USER_TYPE, saveUserId, saveUserType } from "../../services/AppLocalHelper";
import { checkUserId } from "../../services/AuthService";
import { now } from "lodash";
dayjs.extend(utc)
dayjs.extend(timezone)


interface Props extends RouteComponentProps { }
interface State extends SignupStudentInfo {
  facultyIdStatus: string;
  isShow: string;
}

class SignUpStudent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let userId = getUserId()
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }
    let newPass = this.makePassword(6);


    this.state = {
      studentID: "",
      studentName: "",
      password: newPass,
      password2: newPass,
      passwordStatus: "",
      university: "",
      internPlace: "",
      startMonth: "",
      startDay: "",
      endMonth: "",
      endDay: "",
      facultyID: "",
      signupStatus: "",
      // SW add 2021/3/1
      startMonthPeriod: "",
      startDayPeriod: "",
      endMonthPeriod: "",
      endDayPeriod: "",
      startYear: "",
      endYear: "",
      startYearPeriod: "",
      endYearPeriod: "",
      facultyIdStatus: "",
      isShow: "password",
    };

    this.onStudentIDChanged = this.onStudentIDChanged.bind(this);
    this.onStudentNameChanged = this.onStudentNameChanged.bind(this);
    this.onPassword1Changed = this.onPassword1Changed.bind(this);
    this.onPassword2Changed = this.onPassword2Changed.bind(this);
    this.onUniversityChanged = this.onUniversityChanged.bind(this);
    this.onInternPlaceChanged = this.onInternPlaceChanged.bind(this);

    this.onStartMonthChanged = this.onStartMonthChanged.bind(this);
    this.onStartDayChanged = this.onStartDayChanged.bind(this);
    this.onEndMonthChanged = this.onEndMonthChanged.bind(this);
    this.onEndDayChanged = this.onEndDayChanged.bind(this);

    this.onNurseChanged = this.onNurseChanged.bind(this);
    this.signUpStudent = this.signUpStudent.bind(this);
    // SW add 2021/3/1
    this.onStartMonthPeriodChanged = this.onStartMonthPeriodChanged.bind(this);
    this.onStartDayPeriodChanged = this.onStartDayPeriodChanged.bind(this);
    this.onEndMonthPeriodChanged = this.onEndMonthPeriodChanged.bind(this);
    this.onEndDayPeriodChanged = this.onEndDayPeriodChanged.bind(this);

    this.onStartYearChanged = this.onStartYearChanged.bind(this);
    this.onStartYearPeriodChanged = this.onStartYearPeriodChanged.bind(this);
    this.onEndYearChanged = this.onEndYearChanged.bind(this);
    this.onEndYearPeriodChanged = this.onEndYearPeriodChanged.bind(this);

    this.setEndPeriod = this.setEndPeriod.bind(this);
    this.getLastRecord = this.getLastRecord.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.makePassword = this.makePassword.bind(this);
  }

  public onChangeType() {
    let currentType: string = "";
    this.state.isShow == "password" ? currentType = "text" : currentType = "password"

    this.setState({
      isShow: currentType
    })
  }



  public onStartYearChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const startYear: string = e.target.value;
    this.setState({ startYear: startYear, startYearPeriod: startYear });
  }

  public onEndYearChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const endYear: string = e.target.value;
    this.setState({ endYear: endYear, endYearPeriod: endYear });
  }

  public onStartYearPeriodChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const startYearPeriod: string = e.target.value;
    this.setState({ startYearPeriod: startYearPeriod });
  }

  public onEndYearPeriodChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const endYearPeriod: string = e.target.value;
    this.setState({ endYearPeriod: endYearPeriod });
  }



  public onStudentIDChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const studentID: string = e.target.value;
    this.setState({ studentID: studentID });
  }
  public onStudentNameChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const studentName: string = e.target.value;
    this.setState({ studentName: studentName });
  }
  public onPassword1Changed(e: React.ChangeEvent<HTMLInputElement>) {
    const password: string = e.target.value;
    this.setState({ password: password });
  }
  public onPassword2Changed(e: React.ChangeEvent<HTMLInputElement>) {
    const password2: string = e.target.value;
    this.setState({ password2: password2 });
  }
  public onUniversityChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const university: string = e.target.value;
    this.setState({ university: university });
  }
  public onInternPlaceChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const internPlace: string = e.target.value;
    this.setState({ internPlace: internPlace });
  }

  public onStartMonthChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const startMonth: string = e.target.value;

    this.setState({ startMonth: startMonth, startMonthPeriod: startMonth });
  }

  public onStartDayChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const startDay: string = e.target.value;

    this.setState({ startDay: startDay, startDayPeriod: startDay });
  }

  public onEndMonthChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const endMonth: string = e.target.value;

    if (this.state.endDay) {
      this.setEndPeriod(this.state.endYear, endMonth, this.state.endDay)
    } else {

      this.setState({ endMonth: endMonth, endMonthPeriod: endMonth });
    }

  }

  public onEndDayChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const endDay: string = e.target.value;

    if (this.state.endMonth) {
      this.setEndPeriod(this.state.endYear, this.state.endMonth, endDay)
    }
    else {

      this.setState({ endDay: endDay, endDayPeriod: endDay });
    }
  }

  // SW add 2021/3/1
  public onStartMonthPeriodChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const startMonthP: string = e.target.value;

    this.setState({ startMonthPeriod: startMonthP });
  }

  public onStartDayPeriodChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const startDayP: string = e.target.value;

    this.setState({ startDayPeriod: startDayP });
  }

  public onEndMonthPeriodChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const endMonthP: string = e.target.value;

    this.setState({ endMonthPeriod: endMonthP });
  }

  public onEndDayPeriodChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const endDayP: string = e.target.value;

    this.setState({ endDayPeriod: endDayP });
  }

  public setEndPeriod(year: string, month: string, day: string) {
    const selectedDay = dayjs().year(Number(year)).month(Number(month) - 1).date(Number(day)).add(14, "days")


    const endYearP = selectedDay.format("YYYY")

    const endMonthP = selectedDay.format("M")
    const endDateP = selectedDay.format("D")
    this.setState({
      endMonth: month,
      endDay: day,
      endDayPeriod: endDateP.toString(),
      endMonthPeriod: endMonthP.toString(),
      endYearPeriod: endYearP.toString(),
    });
  }


  public onNurseChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const nurseName: string = e.target.value;
    const url = `${process.env.REACT_APP_API_URL}/api/signup_student/nurse_select?faculty_name=${nurseName}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          facultyID: result.faculty_id,
        });
      })
      .catch(console.error);
  }

  public getLastRecord() {
    const url = `${process.env.REACT_APP_API_URL}/api/signup/get_last_id`;

    fetch(url, { mode: "cors" })
      .then(res => res.json())
      .then(
        (result) => {
          // console.log("🚀 ~ file: SignUpStudent.tsx ~ line 215 ~ SignUpStudent ~ getLastRecord ~ result", result)
          let start_date = result.student.length > 0 ? result.student[0].start_date.split('-') : "";
          let end_date = result.student.length > 0 ? result.student[0].end_date.split('-') : "";
          let period_start_date = result.student.length > 0 ? new Date(result.student[0].use_start_date) : new Date();
          let period_end_date = result.student.length > 0 ? new Date(result.student[0].use_end_date) : new Date();
         
          this.setState({
            university: result.student[0].university,
            internPlace: result.student[0].intern_place,

            startYear: Number(start_date[0]).toString(),
            startMonth: Number(start_date[1]).toString(),
            startDay: Number(start_date[2]).toString(),

            endYear: Number(end_date[0]).toString(),
            endMonth: Number(end_date[1]).toString(),
            endDay: Number(end_date[2]).toString(),

            startYearPeriod: String(period_start_date.getFullYear()),
            startMonthPeriod: String(period_start_date.getMonth() + 1),
            startDayPeriod: String(period_start_date.getDate()),

            endYearPeriod: String(period_end_date.getFullYear()),
            endMonthPeriod: String(period_end_date.getMonth() + 1),
            endDayPeriod: String(period_end_date.getDate()),
          });
        },
        error => {
          console.log("API Error");
        }
      );
  }

  public componentDidMount() {
    this.getLastRecord()


  }

  public makePassword(length: any) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
  }

  public signUpStudent(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let format = /[ !　@#$%^&*()+=\[\]{};':"\\|,<>\/?]+/;
    if (format.test(this.state.studentID)) {
      this.setState({ facultyIdStatus: "fail" });
    } else {
      if (this.state.password === this.state.password2) {
        // show confirm alert

        const now = new Date();
        const year = now.getFullYear();
        const startDate: string = `${this.state.startYear}-${this.state.startMonth}-${this.state.startDay}`;
        const endDate: string = `${this.state.endYear}-${this.state.endMonth}-${this.state.endDay}`;

        const startDatePeriod: string = `${this.state.startYearPeriod}-${this.state.startMonthPeriod}-${this.state.startDayPeriod}`;
        const endDatePeriod: string = `${this.state.endYearPeriod}-${this.state.endMonthPeriod}-${this.state.endDayPeriod}`;

        const signupStudentInfo = {
          student_info: {
            student_id: this.state.studentID,
            student_name: this.state.studentName,
            password: this.state.password,
            university: this.state.university,
            intern_place: this.state.internPlace,
            start_date: startDate,
            end_date: endDate,
            faculty_id: this.state.facultyID,
            start_date_period: startDatePeriod,
            end_date_period: endDatePeriod
          },
        };
        const url = `${process.env.REACT_APP_API_URL}/api/signup_student`;
        const method = "POST";
        const mode = "cors";
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const body = JSON.stringify(signupStudentInfo);
        console.log('signupStudentInfo:', signupStudentInfo)

        fetch(url, { method, mode, headers, body })
          .then((res) => res.json())
          .then((result) => {
            if (result.status === "success") {
              saveUserId(this.state.studentID)
              saveUserType(USER_TYPE.STUDENT)
              this.props.history.push(`/index/student/${this.state.studentID}`);
            } else {
              this.setState({ signupStatus: result.status });
            }
          })
          .catch(console.error);
      } else {
        this.setState({ passwordStatus: "fail" });
      }
    }

  }

  public render() {
    return (
      <div className="mt-5 mb-5">
        <h2 className="mb-5">学生登録</h2>
        <Form className="mt-4" onSubmit={this.signUpStudent} autoComplete="off">
          <Form.Group as={Row} controlId="fg-1">
            <Form.Label column md={4} className="font-weight-bold text-right">
              学籍番号
            </Form.Label>
            <Col md={6}>
              <Form.Control
                required
                type="text"
                placeholder="Enter your student ID"
                autoComplete="new-ID"
                onChange={this.onStudentIDChanged}
              />
              <Form.Text className="text-muted text-left">
                &nbsp;半角英数字で入力
              </Form.Text>
              {this.state.facultyIdStatus === "fail" && (
                <Alert className="" variant="danger">
                  /\%$#@は使用できません
                </Alert>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="fg-2" className="mb-5">
            <Form.Label column md={4} className="font-weight-bold text-right">
              氏名
            </Form.Label>
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                autoComplete="new-name"
                onChange={this.onStudentNameChanged}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="fg-3">
            <Form.Label column md={4} className="font-weight-bold text-right">
              パスワード
            </Form.Label>
            <Col md={6}>
              <Form.Control
                required
                type={this.state.isShow}
                placeholder="Enter password"
                autoComplete="new-password"
                onChange={this.onPassword1Changed}
                value={this.state.password}
              />
              <Form.Text className="text-muted text-left">
                &nbsp;半角英数字で入力
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="fg-4">
            <Form.Label column md={4} className="font-weight-bold text-right">
              パスワード（再入力）
            </Form.Label>
            <Col md={6}>
              <Form.Control
                required
                type={this.state.isShow}
                placeholder="Enter password"
                autoComplete="new-password"
                onChange={this.onPassword2Changed}
                value={this.state.password2}
              />
              <Form.Text className="text-muted text-left">
                &nbsp;半角英数字で入力
              </Form.Text>
              {this.state.passwordStatus === "fail" && (
                <Alert className="" variant="danger">
                  パスワードが一致しません．
                </Alert>
              )}
            </Col>

          </Form.Group>

          <Form.Group as={Row} controlId="" className="mb-5">
            <Form.Label column md={4} className="font-weight-bold text-right clor-lightblue">

            </Form.Label>
            <Col md={6}>
              <input
                type="checkbox"
                className="check-show-password"
                // type="password"
                // placeholder="Enter password"
                onClick={this.onChangeType}
              />
              <Form.Text className="text-muted text-left m-0">
                パスワード表示
              </Form.Text>
              <div className="clear"></div>
            </Col>

          </Form.Group>

          <Form.Group as={Row} controlId="fg-5" className="mb-4">
            <Form.Label column md={4} className="font-weight-bold text-right">
              所属施設
            </Form.Label>
            <Col md={6}>
              <SignUpUniversitySelect value={this.state.university} onItemSelect={this.onUniversityChanged} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="fg-6">
            <Form.Label column md={4} className="font-weight-bold text-right">
              実習場所
            </Form.Label>
            <Col md={6}>
              <SignUpInternPlace value={this.state.internPlace} onItemSelect={this.onInternPlaceChanged} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="fg-6" className="mt-5">
            <Form.Label column md={2} className="font-weight-bold">
              実習期間
            </Form.Label>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              開始
            </Form.Label>
            <Col md={2} className="pl-0">

              <SignUpStartYearSelect value={this.state.startYear} onItemSelect={this.onStartYearChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              年
            </Form.Label>
            <Col md={2} className="pl-0">

              <SignUpStartMonthSelect value={this.state.startMonth} onItemSelect={this.onStartMonthChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              月
            </Form.Label>
            <Col md={2} className="pr-0">

              <SignUpStartDaySelect value={this.state.startDay} onItemSelect={this.onStartDayChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              日
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column md={2} className="font-weight-bold">
              &emsp;
            </Form.Label>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              終了
            </Form.Label>
            <Col md={2} className="pl-0">

              <SignUpEndYearSelect value={this.state.endYear} onItemSelect={this.onEndYearChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              年
            </Form.Label>
            <Col md={2} className="pl-0">

              <SignUpEndMonthSelect value={this.state.endMonth} onItemSelect={this.onEndMonthChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              月
            </Form.Label>
            <Col md={2} className="pr-0">

              <SignUpEndDaySelect value={this.state.endDay} onItemSelect={this.onEndDayChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              日
            </Form.Label>
          </Form.Group>


          {/* SW add 2021/3/1 */}
          <Form.Group as={Row} controlId="fg-6" className="mt-5">
            <Form.Label column md={2} className="font-weight-bold">
              使用可能期間
            </Form.Label>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              開始
            </Form.Label>
            <Col md={2} className="pl-0">

              <SignUpStartYearSelect value={this.state.startYearPeriod} onItemSelect={this.onStartYearPeriodChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              年
            </Form.Label>
            <Col md={2} className="pl-0">

              <SignUpStartMonthSelect value={this.state.startMonthPeriod} onItemSelect={this.onStartMonthPeriodChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              月
            </Form.Label>
            <Col md={2} className="pr-0">

              <SignUpStartDaySelect value={this.state.startDayPeriod} onItemSelect={this.onStartDayPeriodChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              日
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column md={2} className="font-weight-bold">
              &emsp;
            </Form.Label>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              終了
            </Form.Label>
            <Col md={2} className="pl-0">

              <SignUpEndYearSelect value={this.state.endYearPeriod} onItemSelect={this.onEndYearPeriodChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              年
            </Form.Label>
            <Col md={2} className="pl-0">

              <SignUpEndMonthSelect value={this.state.endMonthPeriod} onItemSelect={this.onEndMonthPeriodChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              月
            </Form.Label>
            <Col md={2} className="pr-0">

              <SignUpEndDaySelect value={this.state.endDayPeriod} onItemSelect={this.onEndDayPeriodChanged} />
            </Col>
            <Form.Label column md={1} className="font-weight-bold pl-0">
              日
            </Form.Label>
          </Form.Group>
          <Row>
            <Col md={2} />
            <Col md={8}>
              <Button className="d-none" type="submit" disabled>
                disabled enter
              </Button>
              <Button className="mt-5 sw-custom-btn" variant="primary" type="submit" block>
                登録
              </Button>
              {this.state.signupStatus === "student exists" && (
                <Alert className="mt-3" variant="danger">
                  学籍番号が登録済みです
                </Alert>
              )}
              {this.state.signupStatus === "fail" && (
                <Alert className="mt-3" variant="danger">
                  学生登録できません
                </Alert>
              )}
            </Col>
            <Col md={2} />
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(SignUpStudent);
