import React from "react";
import Table from "react-bootstrap/Table";
import { TestDataItemType } from "../../models/Types";
interface Props {
  testDataItem: TestDataItemType[];
}
interface State { }

class TestDataTableLeft extends React.Component<Props, State> {

  public render() {
    let testDataParent = this.props.testDataItem.filter(data => data.is_parent)
    let testDataChild = this.props.testDataItem.filter(data => !data.is_parent)
    return (
      <Table className="test_data_left test_data" bordered hover size="sm">
        <thead>
          <tr>
            <th
              style={{
                height: "50px",
                verticalAlign: "middle",
              }}
            >
              検査日
            </th>
          </tr>
          {/* <tr><th>検査時間</th></tr> */}
        </thead>
        <tbody>
          {testDataParent.map((post: TestDataItemType , i) => (
            <>
              <tr
                 key={post.id+'_'+i}
                style={{
                  height: "50px",
                }}
                className={"tr-sw " + (post.is_parent ? "bg-warning" : "")}
              >
                <td style={{ verticalAlign: "middle" }}>{post.name}</td>
              </tr>

              {
                testDataChild.map((child, j) => Number(child.parent_id) === post.id && (

                  <tr
                    key={child.id+'_'+i+'_'+j}
                    style={{
                      height: "50px",
                    }}
                    className="tr-sw"
                  >
                    <td style={{ verticalAlign: "middle" }}>{child.name}</td>
                  </tr>
                ))
              }
            </>

          ))}
        </tbody>
      </Table>
    );
  }
}

export default TestDataTableLeft;
