import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import { cloneDeep as _cloneDeep } from "lodash";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import "../../assets/styles/IndexFaculty.scss";
import { TestDataItemType } from "../models/Types";
import { changeNodeAtPath } from "react-sortable-tree";

interface ChildComponentProps {
  history: History;
}

const CreateManagerTestDataItems: React.SFC<ChildComponentProps> = ({
  history,
}) => {
  const [testDataParents, setTestDataParents] = useState<TestDataItemType[]>(
    []
  );
  const [testDataChildrens, setTestDataChildrens] = useState<
    TestDataItemType[]
  >([]);
  const [testDataChildrensOrigin, setTestDataChildrensOrigin] = useState<
    TestDataItemType[]
  >([]);
  const [testDataChangeOrder, setTestDataChangeOrder] = useState<
    TestDataItemType[]
  >([]);
  const [isParent, setIsParent] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isShowChildren, setIsShowChildren] = useState(false);
  let [isParentSelect, setIsParentSelect] = useState(0)
  const [input, setInput] = useState<{
    id: number;
    parent_id: number;
    name: string;
  }>({
    id: 0,
    parent_id: 0,
    name: "",
  });
  useEffect(() => {
    const { location } = history;
    const { patientID, studentID, facultyID } = location.state as any;

    // const url = `${process.env.REACT_APP_API_URL}/api/patient_info/test_data?patient_id=${patientID}&student_id=${studentID}&faculty_id=${facultyID}&start_date=null`;
    // SW change student_id -> create_new_test_data for create
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/test_data?patient_id=${patientID}&student_id=create_new_test_data&faculty_id=${facultyID}&start_date=null`;
    fetch(url, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result) {
            const parents = result.items.filter(
              (item: TestDataItemType) => item.is_parent
            );
            const chirldrens = result.items.filter(
              (item: TestDataItemType) => !item.is_parent
            );

            parents.sort((a: any, b: any) => {
              return a.order - b.order;
            });
            setTestDataParents(parents);
            setTestDataChildrens(chirldrens);
            setTestDataChildrensOrigin(chirldrens);
          }
        },
        (error) => {
          console.log(error, "Error: loadTestDataTable");
        }
      );
  }, [isLoading]);

  const setField = (field: number, value: string, parent_id: number) => {
    setInput({ id: field, name: value, parent_id });
  };
  const handleCheckbox = (field: number, parent_id: number, value: string) => {
    console.log('parent_id:', parent_id)
    setIsShowChildren(true);
    setInput({ id: field, name: value, parent_id });
  };

  const swapArrayLocs = (arr: any, index1: number, index2: number) => {
    [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
  };

  const onMouseClickUp = (index: any, data: TestDataItemType[]) => {
    let testDataTemp = _cloneDeep(data);

    testDataTemp[index].order = data[index - 1].order;
    testDataTemp[index - 1].order = data[index].order;

    swapArrayLocs(testDataTemp, index - 1, index);
    setTestDataChangeOrder([testDataTemp[index], testDataTemp[index + 1]]);
    isParent ? setTestDataParents(testDataTemp) : setTestDataChildrens(testDataTemp)
  };

  const onMouseClickDown = (index: any, data: TestDataItemType[]) => {
    console.log('index:', index)
    let testDataTemp = _cloneDeep(data);

    testDataTemp[index].order = data[index + 1].order;
    testDataTemp[index + 1].order = data[index].order;

    swapArrayLocs(testDataTemp, index + 1, index);
    setTestDataChangeOrder([testDataTemp[index], testDataTemp[index + 1]]);
    isParent ? setTestDataParents(testDataTemp) : setTestDataChildrens(testDataTemp)

  };

  const onGetChildren = () => {
    let childrenItems = testDataChildrensOrigin.filter(child => Number(child.parent_id) === Number(input.id));
    setIsParentSelect(input.id)
    setInput({ ...input, name: "" });
    setTestDataChildrens(childrenItems);
    setIsParent(false);
  };

  const backToParent = (e: any) => {
    e.preventDefault();
    setInput({ id: 0, parent_id: 0, name: "" });
    setIsParentSelect(0)
    setIsParent(true);
  };

  const onCreate = () => {
    const newData = [
      {
        name: input.name,
        parent_id: isParent ? 0 : input.id,
      },
    ];

    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/test_data/create`;
    fetchAPI(newData, url, "POST");
  };

  const onUpdate = () => {
    // const newData = [
    //   {
    //     ...input,
    //   },
    // ];
    testDataChildrens.map((child, i) => child.order = i)
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/test_data/update`;
    fetchAPI(isParent ? testDataParents : testDataChildrens, url, "PUT");
  };


  const fetchAPI = (newData: any, url: string, method: string) => {
    const mode = "cors";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(newData);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setInput({ ...input, name: "" });
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 5000);
          setIsLoading(!isLoading);
        }
      })
      .catch(console.error);
  };

  let changeData = (id: number, value: string) => {
    (id > 0 && isParent) ? testDataParents.map((item) => {
      if (item.id === id) {
        item.name = value
      }
    }) : testDataChildrens.map((item) => {
      if (item.id === id) {
        item.name = value
      }
    })
  }

  const contentTestDataItem = (data: TestDataItemType[]) => (
    <StyledTestData>
      {
        data.length > 0 &&
        data.map((item, i) => (
          <StyledTestTable key={i}>
            <Table>
              <tbody>
                <tr key={item.order as any}>
                  <td className="parent-name">
                    <div className="itemName">
                      {i === 0 && <span>設定項目を選択：</span>}
                    </div>
                    <Form.Check
                      name="name"
                      inline
                      type="radio"
                      id={item.name || ""}
                      label={item.name}
                      value={item.name || ""}
                      onChange={(e) =>
                        handleCheckbox(
                          item.id,
                          item.parent_id || (item.id as number),
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td
                    className="w-100px btn-move"
                    style={{ backgroundColor: "#ffffff", border: "none" }}
                  >
                    {i !== 0 && (
                      <Button
                        className="button-up"
                        variant="secondary"
                        size="lg"
                        onClick={() => onMouseClickUp(i, data)}
                      >
                        <i className="far fa-arrow-alt-circle-up"></i>
                      </Button>
                    )}
                    {i !== data.length - 1 && (
                      <Button
                        className="button-down"
                        variant="secondary"
                        size="lg"
                        onClick={() => onMouseClickDown(i, data)}
                      >
                        <i className="far fa-arrow-alt-circle-down"></i>
                      </Button>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </StyledTestTable>
        ))}

      <StyledFormSubmit>
        <Col md={12} className="d-flex">
          <Form.Control
            className="input-name"
            type="text"
            value={input.name || ""}
            onChange={(e) => {
              setField(input.id || 0, e.target.value, input.parent_id)
              changeData(input.id || 0, e.target.value)
            }}
          />
          <Button
            className="ml-3 noPrint"
            variant="primary"
            size="lg"
            type="submit"
            onClick={onUpdate}
          >
            変更
          </Button>
          <Button
            className="ml-3 noPrint"
            variant="primary"
            size="lg"
            type="submit"
          >
            削除
          </Button>
        </Col>
        <Button
          className="noPrint ml-3"
          variant="primary"
          size="lg"
          type="submit"
          onClick={onCreate}
        >
          新規追加
        </Button>
        {isParent && (
          <Button
            className="noPrint ml-3"
            variant="primary"
            size="lg"
            type="submit"
            onClick={onGetChildren}
            disabled={!isShowChildren}
          >
            子要素
          </Button>
        )}
      </StyledFormSubmit>

      {isSuccess && (
        <Row className="ml-4">
          <Col md={9} className="mt-5" style={{ margin: "auto" }}>
            <Alert variant="success">削除しました。</Alert>
          </Col>
        </Row>
      )}
    </StyledTestData>
  );

  let testDataChildrensFilter = (!isParent && isParentSelect > 0) ? testDataChildrens.filter((child) => Number(child.parent_id) === Number(isParentSelect)) : testDataChildrens
  return (
    <Container className="mt-5 mb-5">
      <h2>自己評価項目設定</h2>
      <Breadcrumbs aria-label="breadcrumb">
        {!isParent && (
          <Link color="primary" href="/" onClick={backToParent}>
            カテゴリ一覧
          </Link>
        )}
        <Typography color="textPrimary">血液学</Typography>
      </Breadcrumbs>
      {contentTestDataItem(isParent ? testDataParents : testDataChildrensFilter)}
    </Container>
  );
};

const StyledTestData = styled.div`
  width: 500px;
  margin: 100px auto;
`;
const StyledTestTable = styled.div`
  table {
    width: 380px;
    tr {
      td:first-child {
        display: flex;
      }
      td {
        border-top: none;
        .itemName {
          width: 150px;
        }
      }

      .btn-move {
        padding: 0px;
      }

      .parent-name {
        .form-check {
          color: #3492ff;
        }
      }

      input[type="radio"] {
        transform: scale(1.5);
        margin-right: 10px;
      }
    }
  }
`;

const StyledFormSubmit = styled(Row)`
  .input-name {
    width: 300px;
  }

  .btn,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #e6dab7;
    border-color: #e6dab7;
    color: #333;
  }
`;

export default withRouter(CreateManagerTestDataItems);
