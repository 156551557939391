import React from "react";
import moment from "moment";

export const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
  if (/^[a-zA-Z]+$/.test(e.key)) {
    e.preventDefault();
  }
};

export const onKeyPressNumber = (e: React.KeyboardEvent<HTMLElement>) => {
  if (!/\d+/g.test(e.key)) {
    e.preventDefault();
  }
};

export const convertDate = (date: number) => {
  const dayTime = `${
    Math.floor(date / 1440) ? Math.floor(date / 1440) + "日目" : "0 日目"
  }`;
  const hoursTime = `${convertToHHMMJapan(date % 1440)}`;

  return `${dayTime} ${hoursTime ? hoursTime : ""}`;
};

export const convertHoursTime = (value: string) => {
  if (!value) {
    return value;
  }
  let dateStr = moment().format("YYYY-MM-DD"),
    timeStr = value,
    date = moment(dateStr),
    time = moment(timeStr, "HH:mm");

  date.set({
    hour: time.get("hour"),
    minute: time.get("minute"),
    second: time.get("second"),
  });

  return date;
};

export const getTitleMedicalRecord = (medicalRecord: any) => {
  const dateTime = convertDate(medicalRecord.dateConverted);

  const author = medicalRecord.author;
  const title =
    medicalRecord.title === "" || medicalRecord.title === "&emsp;"
      ? ""
      : `「${medicalRecord.title}」`;

  return `${dateTime} ${author} ${title}`;
};

export const convertToHHMM = (minutesNum: any) => {
  const hours = Math.floor(minutesNum / 60);
  const minutes = minutesNum % 60;

  if (hours || minutes) {
    return [hours, minutes]
      .map((val) => (val < 10 ? `0${val}` : val))
      .join(":");
  }

  return null;
};

export const convertToHHMMJapan = (minutesNum: any) => {
  const hours = Math.floor(minutesNum / 60);
  const minutes = minutesNum % 60;

  if (hours || minutes) {
    return (
      [hours, minutes].map((val) => (val < 10 ? `0${val}` : val)).join("時") +
      "分"
    );
  }

  return "";
};

export const ACTION = {
  CREATE: "CREATE",
  CSV: "CSV",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  VISIBLE: "VISIBLE",
};
