import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { QuestionnaireType } from "../../models/Types";
import "../../../assets/styles/QuestionnaireTable.scss";

interface Props extends RouteComponentProps {
  studentID: string;
  changeIfCompleted: () => void;
}
interface State extends QuestionnaireType {}

class QuestionnaireTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      q1: "",
      q2_1: "",
      q2_2: "",
      q2_3: "",
      q2_4: "",
      q2_5: "",
      q2_6: "",
      q2_7: "",
      disabled : false,
      q3: ""
    };

    this.onChangeQ1 = this.onChangeQ1.bind(this);
    this.onChangeQ2_1 = this.onChangeQ2_1.bind(this);
    this.onChangeQ2_2 = this.onChangeQ2_2.bind(this);
    this.onChangeQ2_3 = this.onChangeQ2_3.bind(this);
    this.onChangeQ2_4 = this.onChangeQ2_4.bind(this);
    this.onChangeQ2_5 = this.onChangeQ2_5.bind(this);
    this.onChangeQ2_6 = this.onChangeQ2_6.bind(this);
    this.onChangeQ2_7 = this.onChangeQ2_7.bind(this);
    this.onChangeQ3 = this.onChangeQ3.bind(this);

    this.onClickCancel = this.onClickCancel.bind(this);
    this.onSubmitQuestionnaire = this.onSubmitQuestionnaire.bind(this);
  }

  public onChangeQ1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ q1: value });
  }

  public onChangeQ2_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ q2_1: value });
  }
  public onChangeQ2_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ q2_2: value });
  }
  public onChangeQ2_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ q2_3: value });
  }
  public onChangeQ2_4(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ q2_4: value });
  }
  public onChangeQ2_5(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ q2_5: value });
  }
  public onChangeQ2_6(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ q2_6: value });
  }
  public onChangeQ2_7(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ q2_7: value });
  }
  public onChangeQ3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ q3: value });
  }

  public onClickCancel() {
    this.props.history.goBack();
  }

  public onSubmitQuestionnaire(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (this.state.disabled) {
      return;
    }
    this.setState({disabled: true});

    const url = `${process.env.REACT_APP_API_URL}/api/questionnaire/save`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    const newSelfEvaluation = {
      student_id: this.props.studentID,
      questionnaire: {
        q1: this.state.q1,
        q2_1: this.state.q2_1,
        q2_2: this.state.q2_2,
        q2_3: this.state.q2_3,
        q2_4: this.state.q2_4,
        q2_5: this.state.q2_5,
        q2_6: this.state.q2_6,
        q2_7: this.state.q2_7,
        q3: this.state.q3
      }
    };
    const body = JSON.stringify(newSelfEvaluation);

    fetch(url, { method, headers, body })
      .then(res => res.json())
      .then(result => {
        console.log(result);
        if (result.status === "success") {
          this.props.changeIfCompleted();
        } else {
          console.log("API Error");
        }
      })
      .catch(console.error);
  }

  public render() {
    return (
      <div className="ml-5">
        <Row>
          <Form.Group as={Col} className="mb-5">
            <Form.Label className="font-weight-bold clor-lightblue">
              1.
              自習目標達成に向けての指導者の関わりで役に立ったことは何ですか？
            </Form.Label>
            <Form.Control as="textarea" rows={5} onChange={this.onChangeQ1} />
          </Form.Group>
          <Table id="questionnaire_table" className="mb-5 questionnaire_table_student" bordered>
            <thead className="bg-lightblue">
              <tr>
                <th>&emsp;</th>
                <th className="option align-middle">そう思う</th>
                <th className="option">
                  やや
                  <br />
                  そう思う
                </th>
                <th className="option">
                  どちらとも
                  <br />
                  いえない
                </th>
                <th className="option">
                  あまり
                  <br />
                  思わない
                </th>
                <th className="option">
                  そう
                  <br />
                  思わない
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left">
                  1)
                  グループカンファレンスや計画の発表に対し、建設的な姿勢で適切な助言をくれますか？
                </td>
                <td>
                  <Form.Check
                    name="item2_1"
                    inline
                    type="radio"
                    label="1"
                    value="1"
                    onChange={this.onChangeQ2_1}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_1"
                    inline
                    type="radio"
                    label="2"
                    value="2"
                    onChange={this.onChangeQ2_1}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_1"
                    inline
                    type="radio"
                    label="3"
                    value="3"
                    onChange={this.onChangeQ2_1}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_1"
                    inline
                    type="radio"
                    label="4"
                    value="4"
                    onChange={this.onChangeQ2_1}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_1"
                    inline
                    type="radio"
                    label="5"
                    value="5"
                    onChange={this.onChangeQ2_1}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  2)
                  看護専門職として責任を学生が理解するように働きかけてくれていますか？
                </td>
                <td>
                  <Form.Check
                    name="item2_2"
                    inline
                    type="radio"
                    label="1"
                    value="1"
                    onChange={this.onChangeQ2_2}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_2"
                    inline
                    type="radio"
                    label="2"
                    value="2"
                    onChange={this.onChangeQ2_2}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_2"
                    inline
                    type="radio"
                    label="3"
                    value="3"
                    onChange={this.onChangeQ2_2}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_2"
                    inline
                    type="radio"
                    label="4"
                    value="4"
                    onChange={this.onChangeQ2_2}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_2"
                    inline
                    type="radio"
                    label="5"
                    value="5"
                    onChange={this.onChangeQ2_2}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  3)
                  学生が緊張しているときには、リラックスさせるようにしてくれていますか？
                </td>
                <td>
                  <Form.Check
                    name="item2_3"
                    inline
                    type="radio"
                    label="1"
                    value="1"
                    onChange={this.onChangeQ2_3}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_3"
                    inline
                    type="radio"
                    label="2"
                    value="2"
                    onChange={this.onChangeQ2_3}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_3"
                    inline
                    type="radio"
                    label="3"
                    value="3"
                    onChange={this.onChangeQ2_3}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_3"
                    inline
                    type="radio"
                    label="4"
                    value="4"
                    onChange={this.onChangeQ2_3}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_3"
                    inline
                    type="radio"
                    label="5"
                    value="5"
                    onChange={this.onChangeQ2_3}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  4) 学生が”看護は興味深い" と思えるような助言をしてくれますか？
                </td>
                <td>
                  <Form.Check
                    name="item2_4"
                    inline
                    type="radio"
                    label="1"
                    value="1"
                    onChange={this.onChangeQ2_4}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_4"
                    inline
                    type="radio"
                    label="2"
                    value="2"
                    onChange={this.onChangeQ2_4}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_4"
                    inline
                    type="radio"
                    label="3"
                    value="3"
                    onChange={this.onChangeQ2_4}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_4"
                    inline
                    type="radio"
                    label="4"
                    value="4"
                    onChange={this.onChangeQ2_4}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_4"
                    inline
                    type="radio"
                    label="5"
                    value="5"
                    onChange={this.onChangeQ2_4}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  5)
                  理論的内容や、既習の知識・技術などを実際に臨床の場で適用してみるように働きかけてくれていますか？
                </td>
                <td>
                  <Form.Check
                    name="item2_5"
                    inline
                    type="radio"
                    label="1"
                    value="1"
                    onChange={this.onChangeQ2_5}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_5"
                    inline
                    type="radio"
                    label="2"
                    value="2"
                    onChange={this.onChangeQ2_5}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_5"
                    inline
                    type="radio"
                    label="3"
                    value="3"
                    onChange={this.onChangeQ2_5}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_5"
                    inline
                    type="radio"
                    label="4"
                    value="4"
                    onChange={this.onChangeQ2_5}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_5"
                    inline
                    type="radio"
                    label="5"
                    value="5"
                    onChange={this.onChangeQ2_5}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  6) 記録物の内容について適切なアドバイスをしてくれていますか？
                </td>
                <td>
                  <Form.Check
                    name="item2_6"
                    inline
                    type="radio"
                    label="1"
                    value="1"
                    onChange={this.onChangeQ2_6}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_6"
                    inline
                    type="radio"
                    label="2"
                    value="2"
                    onChange={this.onChangeQ2_6}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_6"
                    inline
                    type="radio"
                    label="3"
                    value="3"
                    onChange={this.onChangeQ2_6}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_6"
                    inline
                    type="radio"
                    label="4"
                    value="4"
                    onChange={this.onChangeQ2_6}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_6"
                    inline
                    type="radio"
                    label="5"
                    value="5"
                    onChange={this.onChangeQ2_6}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  7)
                  実習の展開経過において，適切なアドバイスをしてくれていますか？
                </td>
                <td>
                  <Form.Check
                    name="item2_7"
                    inline
                    type="radio"
                    label="1"
                    value="1"
                    onChange={this.onChangeQ2_7}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_7"
                    inline
                    type="radio"
                    label="2"
                    value="2"
                    onChange={this.onChangeQ2_7}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_7"
                    inline
                    type="radio"
                    label="3"
                    value="3"
                    onChange={this.onChangeQ2_7}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_7"
                    inline
                    type="radio"
                    label="4"
                    value="4"
                    onChange={this.onChangeQ2_7}
                  />
                </td>
                <td>
                  <Form.Check
                    name="item2_7"
                    inline
                    type="radio"
                    label="5"
                    value="5"
                    onChange={this.onChangeQ2_7}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <Form.Group as={Col} className="mb-5">
            <Form.Label className="font-weight-bold clor-lightblue">
              3. オンライン実習に関して改善点や感想など自由に記載してください。
            </Form.Label>
            <Form.Control as="textarea" rows={8} onChange={this.onChangeQ3} />
          </Form.Group>
        </Row>
        <Row className="mt-4">
          <Button
           className="bg-lightblue"
            size="lg"
            type="submit"
            disabled={this.state.disabled}
            onClick={this.onSubmitQuestionnaire}
          >
            保存
          </Button>
          <Button
            className="ml-3 clor-lightnavi"
            variant="outline-secondary"
            size="lg"
            type="submit"
            onClick={this.onClickCancel}
          >
            戻る
          </Button>
        </Row>
      </div>
    );
  }
}

export default withRouter(QuestionnaireTable);
