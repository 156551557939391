import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import styled from "styled-components";
import { DoctorInstructionType, DoctorInstructionForm } from "../models/Types";
import { TimePicker } from "../common/TimePickerComponent";
import {
  onKeyPress,
  convertToHHMM,
  convertHoursTime,
  ACTION,
} from "../common/function";
import "../../assets/styles/IndexFaculty.scss";
import DoctorInstructionTable from "../patient_info/doctor-instruction/DoctorInstructionTable";
import { ButtonSpriner, LoadingSpriner } from "../common/CircularSpinner";

interface Props extends RouteComponentProps<{}> {
  patientID: string;
  studentID: string;
  facultyID: string;
}
interface State {
  doctorInstructions: DoctorInstructionType[];
  formValues: DoctorInstructionForm;
  errors: boolean;
  successMsg: string;
  hoursTime: any;
  isLoading: boolean;
}

class CreatePatientDoctorInstruction extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      doctorInstructions: [],
      formValues: {} as DoctorInstructionForm,
      errors: false,
      isLoading: false,
      successMsg: "",
      hoursTime: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { location } = this.props;
    const {
      data: { patientID, studentID, facultyID }, } = location.state as any;
    console.log('data:', location.state)

    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/doctor_instruction?patient_id=${patientID}&student_id=${studentID}&faculty_id=${facultyID}`;

    fetch(url, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            doctorInstructions: result,
          });
        },
        (error) => {
          console.log("API Error");
        }
      );
  }

  onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    const { location } = this.props;
    const { formValues } = this.state;
    const {
      data: { patientID },
    } = location.state as any;

    const convertToMinutes =
      moment(this.state.hoursTime).hour() * 60 * 60 +
      moment(this.state.hoursTime).minute() * 60;

    const hoursTime = this.state.hoursTime ? convertToMinutes : 0;
    const dayTime =
      (formValues.dayTime ? formValues.dayTime : 0) * 24 * 60 * 60;

    const data = {
      patient_id: patientID,
      doctorInstructions: [
        {
          doctorInstructionId: formValues.doctorInstructionId || "",
          instruction: formValues.instruction,
          detail: formValues.detail,
          date: Number(dayTime) + Number(hoursTime),
        },
      ],
    };

    const successMsg = this.state.formValues.doctorInstructionId
      ? "更新しました。"
      : "登録しました。";

    this.fetchAPI(
      data,
      "patient_info/doctor_instruction/create_or_update",
      successMsg,
      this.state.formValues.doctorInstructionId ? ACTION.UPDATE : ACTION.CREATE
    );
  };

  fetchAPI = (
    data: any,
    route: string,
    successMsg: string,
    action?: string
  ) => {
    const url = `${process.env.REACT_APP_API_URL}/api/${route}`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          isLoading: false,
        });
        if (result.success) {
          this.fetchData();
          // switch (action) {
          //   case ACTION.CREATE:
          //     const newData = [
          //       ...this.state.doctorInstructions,
          //       {
          //         date: data.date,
          //         instruction: data.instruction,
          //         detail: data.detail,
          //         date_converted: (Number(data.date) / 60) as any,
          //       },
          //     ];

          //     this.setState({
          //       doctorInstructions: newData,
          //     });
          //     break;
          //   default:
          //     this.setDoctorInstructions(
          //       data.doctorInstructionId,
          //       action as any,
          //       data.visibleFlag,
          //       data
          //     );
          //     break;
          // }
          this.setSuccessMessage(successMsg);
          this.onResetForm();
        }
      })
      .catch(console.error);
  };

  // edit medical record
  setFormValues = (doctorInstructionId: number) => {
    const doctorInstructions = this.state.doctorInstructions.find(
      (doctorInstruction) =>
        doctorInstruction.doctorInstructionId === doctorInstructionId
    ) as any;

    const mappingData = {
      doctorInstructionId: doctorInstructionId,
      patientID: doctorInstructions.patientID,
      date: doctorInstructions.date,
      instruction: doctorInstructions.instruction,
      detail: doctorInstructions.detail,
      date_converted: doctorInstructions.date_converted,
      dayTime: Math.floor(doctorInstructions.date_converted / 1440),
      hoursTime: convertToHHMM(doctorInstructions.date_converted % 1440) as any,
      visibleFlag: doctorInstructions.visibleFlag,
    };

    console.log(Math.floor(doctorInstructions.date_converted / 1440));

    this.setState({
      hoursTime: convertHoursTime(mappingData.hoursTime as any) as any,
      formValues: mappingData,
    });
    window.scrollTo(0, 0);
  };

  onVisible = (
    doctorInstructionId: number,
    patientID: string,
    visibleFlag: boolean
  ) => {
    const doctorInstructions = this.state.doctorInstructions.find(
      (doctorInstruction) =>
        doctorInstruction.doctorInstructionId === doctorInstructionId
    ) as any;

    const params = {
      doctorInstructionId: doctorInstructions.doctorInstructionId,
      patient_id: patientID,
      visibleFlag: visibleFlag,
    };
    const route = "patient_info/doctor_instruction/visible";
    this.fetchAPI(params, route, "", ACTION.VISIBLE);
  };

  setDoctorInstructions = (
    doctorInstructionId: number,
    action: string,
    visibleFlag: boolean = false,
    data?: string
  ) => {
    const { doctorInstructions } = this.state;
    let doctorInstructionsNew;
    switch (action) {
      case ACTION.DELETE:
        doctorInstructionsNew = doctorInstructions.filter(
          (item) => item.doctorInstructionId !== doctorInstructionId
        );
        break;
      case ACTION.VISIBLE:
        doctorInstructionsNew = doctorInstructions.map((item) => {
          const visibleByID =
            item.doctorInstructionId === doctorInstructionId
              ? visibleFlag
              : item.visibleFlag;

          return { ...item, visibleFlag: visibleByID };
        });
        break;
      case ACTION.UPDATE:
        doctorInstructionsNew = doctorInstructions.map((doctorInstruction) => {
          const newData =
            doctorInstruction.doctorInstructionId === doctorInstructionId
              ? this.state.formValues
              : {};
          return {
            ...doctorInstruction,
            ...newData,
          };
        });
        break;

      default:
        break;
    }

    if (doctorInstructionsNew) {
      this.setState({
        doctorInstructions: doctorInstructionsNew as any,
      });
    }
  };

  setSuccessMessage = (successMsg: string) => {
    this.setState({
      successMsg,
    });
    setTimeout(() => {
      this.setState({
        successMsg: "",
      });
    }, 2000);
  };

  onResetForm = () => {
    this.setState({
      hoursTime: null,
      formValues: {} as DoctorInstructionForm,
    });
  };

  // set field for event onchange input
  setField = (field: string, value: string) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [field]: value,
      },
    });
  };

  public render() {
    const { location } = this.props;
    const { formValues } = this.state;
    const {data: { patientID },} = location.state as any;
    return (
      <Container fluid>
        <Row>
          <Col md={9} className="mt-5" style={{ margin: "auto" }}>
            <h2>医者の指示入力</h2>

            <Form className="mt-5" onSubmit={this.onSubmitForm}>
              <Row>
                <Col md={6}>
                  <Form.Group as={Row} controlId="fg-1">
                    <Form.Label
                      column
                      md={3}
                      className="font-weight-bold text-left"
                    >
                      指示：
                    </Form.Label>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        value={this.state.formValues.instruction || ""}
                        onChange={(e) =>
                          this.setField("instruction", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group as={Row} controlId="fg-1">
                    <Col md={8}>
                      <Form.Group as={Row} controlId="fg-1">
                        <Form.Label column md={6} className="font-weight-bold">
                          経過日時:
                        </Form.Label>
                        <Col md={6} className="d-flex">
                          <Form.Control
                            style={{ width: "80px" }}
                            type="text"
                            value={this.state.formValues.dayTime}
                            onChange={(e) =>
                              this.setField("dayTime", e.target.value)
                            }
                            onKeyPress={onKeyPress}
                          />
                          <span className="ml-1 mt-1">日目</span>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={4} style={{ marginTop: "-11px" }}>
                      <TimePicker
                        value={this.state.hoursTime || null}
                        onChange={(time: any) =>
                          this.setState({
                            hoursTime: time,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group as={Row}>
                <Col md={2} className="text-left">
                  <Form.Label className="font-weight-bold">
                    詳細内容:
                  </Form.Label>
                </Col>
                <Col md={12}>
                  <Form.Control
                    as="textarea"
                    value={this.state.formValues.detail || ""}
                    rows={5}
                    onChange={(e) => this.setField("detail", e.target.value)}
                  />
                </Col>
              </Form.Group>
              <StyledButton>
                <ButtonSpriner isLoading={this.state.isLoading} label="登録" />
                <Button variant="outline-primary" onClick={this.onResetForm}>
                  クリア
                </Button>
              </StyledButton>
              {this.state.errors && (
                <Alert className="mt-3" severity="error">
                  保存に失敗しました
                </Alert>
              )}
              {this.state.successMsg && (
                <Row className="ml-4">
                  <Col md={9} className="mt-5" style={{ margin: "auto" }}>
                    <Alert className="mt-3">{this.state.successMsg}</Alert>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </Row>


          <DoctorInstructionTable
            doctorInstructions={this.state.doctorInstructions}
            setSuccessMessage={this.setSuccessMessage}
            setDoctorInstructions={this.setDoctorInstructions}
            setFormValues={this.setFormValues}
            onVisible={this.onVisible}
            onFetchData={this.fetchData}
            patientID={patientID}
          />
      </Container>
    );
  }
}

const StyledButton = styled.div`
  margin-top: 40px;
  .btn {
    width: 125px;
    margin-right: 20px;
  }
`;
export default withRouter(CreatePatientDoctorInstruction);
