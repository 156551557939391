import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import ActionPlanRow from "./ActionPlanRow";
import NursingActivityRow from "./NursingActivityRow";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import { NursingPlan } from "../../models/Types";
import _ from 'lodash';
import { checkUserId } from "../../../services/AuthService";
import { USER_TYPE } from "../../../services/AppLocalHelper";
interface Props
  extends RouteComponentProps<{
    studentID: string;
    date: string;
    facultyID: string;
  }> { }
interface State extends NursingPlan {
  facultyID: string;
  nursingGoalCommentByFaculty: string;
  nursingProblemCommentByFaculty: string;
  actionPlanCommentByFaculty: string;
  activityCommentByFaculty: string;
  reviewCommentByFaculty: string;
  overallCommentByFaculty: string;
}

class NursePlanInputs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID
    if (!checkUserId(userId, USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }

    this.state = {
      studentID: this.props.match.params.studentID,
      studentName: "",
      createdAt: "",
      updatedAt: "",

      patientInfos: "",
      overviewMaxDay: "",
      trainingDays: "",

      date: this.props.match.params.date,
      nursingGoal: "",
      nursingProblem: "",
      actionPlans: [
        {
          index: 0,
          startHour: undefined,
          startMinute: undefined,
          endHour: undefined,
          endMinute: undefined,
          plan: undefined,
        },
      ],
      nursingActivity: [
        {
          index: 0,
          activity: undefined,
          result: undefined,
          evaluation: undefined,
        },
      ],
      review: "",
      facultyID: this.props.match.params.facultyID,
      nursingGoalCommentByFaculty: "",
      nursingProblemCommentByFaculty: "",
      actionPlanCommentByFaculty: "",
      activityCommentByFaculty: "",
      reviewCommentByFaculty: "",
      overallCommentByFaculty: "",
    };

    this.onNursingGoalChanged = this.onNursingGoalChanged.bind(this);
    this.onNursingProblemChanged = this.onNursingProblemChanged.bind(this);
    this.onReviewChanged = this.onReviewChanged.bind(this);
    this.onStartHourChanged = this.onStartHourChanged.bind(this);
    this.onStartMinuteChanged = this.onStartMinuteChanged.bind(this);
    this.onEndHourChanged = this.onEndHourChanged.bind(this);
    this.onEndMinuteChanged = this.onEndMinuteChanged.bind(this);
    this.addActionPlanRow = this.addActionPlanRow.bind(this);
    this.removeActionPlanRow = this.removeActionPlanRow.bind(this);
    this.addNursingActivity = this.addNursingActivity.bind(this);
    this.removeNursingActivity = this.removeNursingActivity.bind(this);
    this.onSubmitNursingPlan = this.onSubmitNursingPlan.bind(this);
    this.onSubmitNursingPlanTemp = this.onSubmitNursingPlanTemp.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onMouseClickUp = this.onMouseClickUp.bind(this)
    this.onMouseClickDown = this.onMouseClickDown.bind(this)
    this.onMouseClickUp_2 = this.onMouseClickUp_2.bind(this)
    this.onMouseClickDown_2 = this.onMouseClickDown_2.bind(this)
  }

  public onNursingGoalChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const nursingGoal: string = e.target.value;
    this.setState({ nursingGoal: nursingGoal });
  }
  public onNursingProblemChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const nursingProblem: string = e.target.value;
    this.setState({ nursingProblem: nursingProblem });
  }

  public onStartHourChanged(
    index: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) {
    const newStartHour: string = e.target.value;
    const newActionPlans = this.state.actionPlans;
    newActionPlans[index].startHour = Number(newStartHour);
    this.setState({
      actionPlans: newActionPlans
    });
  }

  public onStartMinuteChanged(
    index: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) {
    const newStartMinute: string = e.target.value;
    const newActionPlans = this.state.actionPlans;
    newActionPlans[index].startMinute = Number(newStartMinute);
    this.setState({

      actionPlans: newActionPlans
    });
  }
  public onEndHourChanged(
    index: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) {
    const newEndHour: string = e.target.value;
    const newActionPlans = this.state.actionPlans;
    newActionPlans[index].endHour = Number(newEndHour);
    this.setState({

      actionPlans: newActionPlans
    });
  }
  public onEndMinuteChanged(
    index: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) {
    const newEndMinute: string = e.target.value;
    const newActionPlans = this.state.actionPlans;
    newActionPlans[index].endMinute = Number(newEndMinute);
    this.setState({

      actionPlans: newActionPlans
    });
  }

  public onPlanChanged(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    const newPlan: string = e.target.value;

    const newActionPlans = this.state.actionPlans;
    newActionPlans[index].plan = newPlan;
    this.setState({

      actionPlans: newActionPlans
    });
  }

  public addActionPlanRow() {

    const itemMaxIndex = _.maxBy(this.state.actionPlans, (item) => item.index);
    const maxIndex = itemMaxIndex?.index ?? 0;
    console.log("🚀 ~ file: NursePlanInputs.tsx ~ line 163 ~ NursePlanInputs ~ addActionPlanRow ~ nextIndex", maxIndex)
    this.setState({
      actionPlans: [...this.state.actionPlans, {
        index: maxIndex + 1,
        startHour: undefined,
        startMinute: undefined,
        endHour: undefined,
        endMinute: undefined,
        plan: '',
      }]
    })
    // this.setState((prevState: State, nextProps: Props) => {
    //   // const arrayLastIndex = prevState.actionPlans.length - 1;
    //   // const index =
    //   //   prevState.actionPlans.length !== 0
    //   //     ? prevState.actionPlans[arrayLastIndex].index + 1
    //   //     : 0;


    //   const newActionPlan = {
    //     index: index,
    //     startHour: undefined,
    //     startMinute: undefined,
    //     endHour: undefined,
    //     endMinute: undefined,
    //     plan: undefined,
    //   };
    //   return { actionPlans: prevState.actionPlans.concat(newActionPlan) };
    // });
  }

  public removeActionPlanRow() {
    this.setState((prevState: State, nextProps: Props) => {
      if (prevState.actionPlans.length > 1) {
        const lastIndex = prevState.actionPlans.length - 1;
        const popedActionPlans = prevState.actionPlans.filter(
          (item, i) => i < lastIndex
        );
        return { actionPlans: popedActionPlans };
      } else {
        return { actionPlans: prevState.actionPlans };
      }
    });
  }

  public onNursingActivityChanged(
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const newActivity: string = e.target.value;
    this.setState((prevState, nextProps) => {
      const newState = prevState.nursingActivity;
      newState[index].activity = newActivity;
      return { nursingActivity: newState };
    });
  }
  public onResultChanged(
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const newResult: string = e.target.value;
    this.setState((prevState, nextProps) => {
      const newState = prevState.nursingActivity;
      newState[index].result = newResult;
      return { nursingActivity: newState };
    });
  }

  public onEvaluationChanged(
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const newEvaluation: string = e.target.value;
    this.setState((prevState, nextProps) => {
      const newState = prevState.nursingActivity;
      newState[index].evaluation = newEvaluation;
      return { nursingActivity: newState };
    });
  }

  public addNursingActivity() {
    const itemMaxIndex = _.maxBy(this.state.nursingActivity, (item) => item.index);
    const maxIndex = itemMaxIndex?.index ?? 0;
    console.log("🚀 ~ file: NursePlanInputs.tsx ~ line 246 ~ NursePlanInputs ~ addNursingActivity ~ maxIndex", maxIndex)

    this.setState({
      nursingActivity: [...this.state.nursingActivity, {
        index: maxIndex + 1,
        activity: '',
        result: '',
        evaluation: ''
      }]
    })


    // this.setState((prevState: State, nextProps: Props) => {
    //   const arrayLastIndex = prevState.nursingActivity.length - 1;
    //   const index =
    //     prevState.nursingActivity.length !== 0
    //       ? prevState.nursingActivity[arrayLastIndex].index + 1
    //       : 0;
    //   const newNursingPlan = {

    //   };
    //   return {
    //     nursingActivity: prevState.nursingActivity.concat(newNursingPlan),
    //   };
    // });
  }
  public removeNursingActivity() {
    this.setState((prevState: State, nextProps: Props) => {
      if (prevState.nursingActivity.length > 1) {
        const lastIndex = prevState.nursingActivity.length - 1;
        const popedNursingActivity = prevState.nursingActivity.filter(
          (item, i) => i < lastIndex
        );
        return { nursingActivity: popedNursingActivity };
      } else {
        return { nursingActivity: prevState.nursingActivity };
      }
    });
  }

  public onReviewChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const review: string = e.target.value;
    this.setState({ review: review });
  }

  public onSubmitNursingPlanTemp(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const newNursingPlan = {
      student_id: this.state.studentID,
      date: this.state.date,
      nursing_goal: this.state.nursingGoal,
      nursing_problem: this.state.nursingProblem,
      action_plans: this.state.actionPlans,
      nursing_activity: this.state.nursingActivity,
      review: this.state.review,
      isTemp: true,
    };

    const url = `${process.env.REACT_APP_API_URL}/api/nursing_plan`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(newNursingPlan);
    console.log('newNursingPlan:', newNursingPlan)

    fetch(url, { method, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          // this.props.history.push(`/nurse_plan/${this.state.studentID}`);
        } else {
          console.log("API Error");
        }
      })
      .catch(console.error);
  }

  public onSubmitNursingPlan(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const newNursingPlan = {
      student_id: this.state.studentID,
      date: this.state.date,
      nursing_goal: this.state.nursingGoal,
      nursing_problem: this.state.nursingProblem,
      action_plans: this.state.actionPlans,
      nursing_activity: this.state.nursingActivity,
      review: this.state.review,
      isTemp: false,
    };

    const url = `${process.env.REACT_APP_API_URL}/api/nursing_plan`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(newNursingPlan);

    fetch(url, { method, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          this.props.history.push(`/nurse_plan/${this.state.studentID}`);
        } else {
          console.log("API Error");
        }
      })
      .catch(console.error);
  }

  public componentDidMount() {
    // fetch nursing plan created by student
    const url = `${process.env.REACT_APP_API_URL}/api/nursing_plan/fetch_data?student_id=${this.props.match.params.studentID}&date=${this.props.match.params.date}`;

    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          studentName: result.student_name,
          overviewMaxDay: result.overviewMaxDay,
          patientInfos: result.patient_in_charge,
          trainingDays: result.training_date,

          createdAt: result.created_at,
          updatedAt: result.updated_at,
          nursingGoal: result.nursing_goal,
          nursingProblem: result.nursing_problem,
          actionPlans: result.action_plans,
          nursingActivity: result.nursing_activity,
          review: result.review,
        });
      })
      .catch(console.error);

    // fetch nursing plan comment created by faculty
    const url2 = `${process.env.REACT_APP_API_URL}/api/faculty_info/nursing_plan_comment/fetch?faculty_id=${this.state.facultyID}&student_id=${this.state.studentID}&date=${this.props.match.params.date}`;

    fetch(url2)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          nursingGoalCommentByFaculty: result.nursingGoalComment,
          nursingProblemCommentByFaculty: result.nursingProblemComment,
          actionPlanCommentByFaculty: result.actionPlanComment,
          activityCommentByFaculty: result.activityComment,
          reviewCommentByFaculty: result.reviewComment,
          overallCommentByFaculty: result.overallComment,
        });
      })
      .catch(console.error);
  }

  public onClickCancel() {
    this.props.history.goBack();
  }

  public swapArrayLocs = (arr: any, index1: number, index2: number) => {
    [arr[index1], arr[index2]] = [arr[index2], arr[index1]]
  }

  public onMouseClickUp(index: number) {
    let actionPlans = [...this.state.actionPlans]
    this.swapArrayLocs(actionPlans, index - 1, index)
    this.setState({ actionPlans })
  }
  public onMouseClickDown(index: number) {
    let actionPlans = [...this.state.actionPlans]
    this.swapArrayLocs(actionPlans, index + 1, index)
    this.setState({ actionPlans })
  }
  public onMouseClickUp_2(index: number) {
    let nursingActivity = [...this.state.nursingActivity]
    this.swapArrayLocs(nursingActivity, index - 1, index)
    this.setState({ nursingActivity })
  }
  public onMouseClickDown_2(index: number) {
    let nursingActivity = [...this.state.nursingActivity]
    this.swapArrayLocs(nursingActivity, index + 1, index)
    this.setState({ nursingActivity })
  }
  public render() {

    console.log("🚀 ~ file: NursePlanInputs.tsx ~ line 430 ~ NursePlanInputs ~ render ~ this.state.actionPlans", this.state.actionPlans)
    const ActionPlans = this.state.actionPlans.map((item, i) => (
      <ActionPlanRow
        key={item.index}
        index={item.index}
        startHour={item.startHour}
        startMinute={item.startMinute}
        endHour={item.endHour}
        endMinute={item.endMinute}
        plan={item.plan}
        onStartHourChanged={this.onStartHourChanged.bind(this, i)}
        onStartMinuteChanged={this.onStartMinuteChanged.bind(this, i)}
        onEndHourChanged={this.onEndHourChanged.bind(this, i)}
        onEndMinuteChanged={this.onEndMinuteChanged.bind(this, i)}
        onPlanChanged={this.onPlanChanged.bind(this, i)}
        onClickUp={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          this.onMouseClickUp(i)
        }}

        onCLickDown={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          this.onMouseClickDown(i)
        }}
        isShowUpButton={!!i}
        isShowDownButton={i !== this.state.actionPlans.length - 1}
      />
    ));

    const NursingActivitys = this.state.nursingActivity.map((item, i) => (
      <NursingActivityRow
        key={item.index}
        activity={item.activity}
        // result={item.result}
        evaluation={item.evaluation}
        onNursingActivityChanged={this.onNursingActivityChanged.bind(
          this,
          i
        )}
        onEvaluationChanged={this.onEvaluationChanged.bind(this, i)}
        onResultChanged={this.onResultChanged.bind(this, i)}
        onClickUp={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          this.onMouseClickUp_2(i)
        }}

        onCLickDown={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          this.onMouseClickDown_2(i)
        }}
        isShowUpButton={!!i}
        isShowDownButton={i !== this.state.nursingActivity.length - 1}
      />
    ));

    // const dateArray = this.props.match.params.date.split("-");
    // const dateTime: string = `${dateArray[0]}年${dateArray[1]}月${dateArray[2]}日`;

    return (
      <Row>
        <Col md={2} />
        <Col md={8}>
          <h3 className="clor-lightnavi">看護計画</h3>
          <div>
            <Table id="nursing_plan_basic_info" className="m-5" bordered>
              <thead className="bg-lightblue">
                <tr>
                  <th>学籍番号</th>
                  <th>学生氏名</th>
                  <th>学生作成日時</th>
                  <th>学生更新日時</th>
                  <th>担当患者</th>
                  <th>患者の入院日数</th>
                  <th>実習日</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.props.match.params.studentID}</td>
                  <td>{this.state.studentName}</td>
                  <td>{this.state.createdAt}</td>
                  <td>{this.state.updatedAt}</td>
                  <td>{this.state.patientInfos}</td>
                  <td>{this.state.overviewMaxDay}</td>
                  <td>{this.state.trainingDays} 日目</td>
                </tr>
              </tbody>
            </Table>
            <Form className="nurse_plan m-5 text-left">
              <Form.Group>
                <Form.Label>
                  <h3 className="clor-lightnavi">1. 今日の看護目標</h3>
                </Form.Label>
                <Form.Control
                  className="textarea_large"
                  as="textarea"
                  rows={5}
                  defaultValue={this.state.nursingGoal}
                  onChange={this.onNursingGoalChanged}
                />
              </Form.Group>
              {this.state.nursingGoalCommentByFaculty === "" ? (
                <div />
              ) : (
                <div className="mt-5">
                  <h5 className="clor-lightnavi">教員コメント</h5>
                  <pre className="comment_faculty mt-3 p-3">
                    {this.state.nursingGoalCommentByFaculty}
                  </pre>
                </div>
              )}
              <Form.Group className="mt-5">
                <Form.Label>
                  <h3 className="clor-lightnavi">2. 看護問題</h3>
                </Form.Label>
                <Form.Control
                  className="textarea_large"
                  as="textarea"
                  rows={5}
                  defaultValue={this.state.nursingProblem}
                  onChange={this.onNursingProblemChanged}
                />
                {this.state.nursingProblemCommentByFaculty === "" ? (
                  <div />
                ) : (
                  <div className="mt-5">
                    <h5 className="clor-lightnavi">教員コメント</h5>
                    <pre className="comment_faculty mt-3 p-3">
                      {this.state.nursingProblemCommentByFaculty}
                    </pre>
                  </div>
                )}
              </Form.Group>
              <h3 className="mt-5 clor-lightnavi">3. 行動計画</h3>
              {ActionPlans}
              <AddCircleOutlinedIcon
                className="mt-4"
                color="secondary"
                fontSize="large"
                onClick={this.addActionPlanRow}
              />
              <RemoveCircleOutlinedIcon
                className="mt-4"
                color="primary"
                fontSize="large"
                onClick={this.removeActionPlanRow}
              />
              {this.state.actionPlanCommentByFaculty === "" ? (
                <div />
              ) : (
                <div className="mt-5 clor-lightnavi">
                  <h5>教員コメント</h5>
                  <pre className="comment_faculty mt-3 p-3">
                    {this.state.actionPlanCommentByFaculty}
                  </pre>
                </div>
              )}
              <Form.Group className="mt-5 mb-5">
                <Form.Label>
                  <h3 className="clor-lightnavi">4. 実施する看護活動と評価</h3>
                </Form.Label>
                <div className="d-flex">
                  <Col md={5}>
                    <h5 className="text-center clor-lightnavi">実施する看護活動</h5>
                  </Col>
                  {/* <Col>
                    <h5 className="text-center">結果</h5>
                  </Col> */}
                  <Col md={5}>
                    <h5 className="text-center clor-lightnavi">評価</h5>
                  </Col>
                  <Col md={2}>
                  </Col>
                </div>
                {NursingActivitys}
                <AddCircleOutlinedIcon
                  color="secondary"
                  fontSize="large"
                  onClick={this.addNursingActivity}
                />
                <RemoveCircleOutlinedIcon
                  color="primary"
                  fontSize="large"
                  onClick={this.removeNursingActivity}
                />
                {this.state.activityCommentByFaculty === "" ? (
                  <div />
                ) : (
                  <div className="mt-5">
                    <h5 className="clor-lightnavi">教員コメント</h5>
                    <pre className="comment_faculty mt-3 p-3">
                      {this.state.activityCommentByFaculty}
                    </pre>
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-5">
                <Form.Label>
                  <h3 className="clor-lightnavi">5. 今日の振り返り</h3>
                </Form.Label>
                <Form.Control
                  className="textarea_large"
                  as="textarea"
                  rows={5}
                  defaultValue={this.state.review}
                  onChange={this.onReviewChanged}
                />
              </Form.Group>
              {this.state.reviewCommentByFaculty === "" ? (
                <div />
              ) : (
                <div className="mt-5">
                  <h5 className="clor-lightnavi">教員コメント</h5>
                  <pre className="comment_faculty mt-3 p-3">
                    {this.state.reviewCommentByFaculty}
                  </pre>
                </div>
              )}
              {this.state.overallCommentByFaculty === "" ? (
                <div />
              ) : (
                <div className="mt-5 mb-5">
                  <h5 className="clor-lightnavi">教員総合コメント</h5>
                  <pre className="comment_faculty mt-3 p-3">
                    {this.state.overallCommentByFaculty}
                  </pre>
                </div>
              )}
              <Button
                className="noPrint bg-lightblue"

                size="lg"
                type="submit"
                onClick={this.onSubmitNursingPlanTemp}
              >
                一時保存
              </Button>
              <Button
                className="ml-3 noPrint bg-lightblue"

                size="lg"
                type="submit"
                onClick={this.onSubmitNursingPlan}
              >
                提出
              </Button>
              <Button
                className="ml-3 noPrint clor-lightnavi"
                variant="outline-secondary"
                size="lg"
                type="button"
                onClick={this.onClickCancel}
              >
                戻る
              </Button>

              <Button
                className="ml-3 noPrint bg-lightblue"

                size="lg"

                onClick={window.print}
              >
                印刷
              </Button>

            </Form>
          </div>
        </Col>
        <Col md={2} />
      </Row>
    );
  }
}

export default withRouter(NursePlanInputs);
