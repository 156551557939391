var CryptoJS = require("crypto-js");
let SECRECT_KEY = "27b66fbc054d201e6b1315b14a2d9629793f4189e78de794aeeb13d244c97d41";

export enum USER_TYPE {
    TEACHER = "teacher",
    STUDENT = "student"
}

export function saveUserId(userId: any) {
    localStorage.setItem('userId', CryptoJS.AES.encrypt(userId, SECRECT_KEY).toString());
}

export function getUserId() {
    let userIdEncrypted = localStorage.getItem('userId');
    if (userIdEncrypted) {
        var bytes = CryptoJS.AES.decrypt(userIdEncrypted, SECRECT_KEY);
        return bytes !== null ? bytes.toString(CryptoJS.enc.Utf8) : null;
    } else {
        return null;
    }
}

export function clearUserId() {
    return localStorage.removeItem('userId');
}

export function saveUserType(type: string) {
    localStorage.setItem('userType', type);
}

export function getUserType() {
    return localStorage.getItem('userType');
}

export function clearUserType() {
    return localStorage.removeItem('userType');
}
