import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Dropzone from "react-dropzone";
import "../../../assets/styles/DropzoneBox.scss";

interface Props extends RouteComponentProps {
  studentID: string;
  onFileUploadChanged: (status: string) => void;
  onFileUploaded: (uploadedFileName: string) => void;
}
interface State {}

class DropzoneBox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onDropFile = this.onDropFile.bind(this);
  }

  public onDropFile(acceptedFiles: File[]) {
    console.log(acceptedFiles[0]["name"]);
    const formData = new FormData();
    formData.append("file_uploaded", acceptedFiles[0]);
    formData.append("student_id", this.props.studentID);

    const url = `${process.env.REACT_APP_API_URL}/api/chart_upload`;
    const method = "POST";
    const body = formData;

    fetch(url, { method, body })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.status === "success") {
          this.props.onFileUploadChanged("success");
          this.props.onFileUploaded(acceptedFiles[0]["name"]);
          window.location.reload();
        } else {
          this.props.onFileUploadChanged("fail");
          console.log("API Error");
        }
      })
      .catch(console.error);
      
      // window.location.reload();
  }

  public render() {
    return (
      <Dropzone onDrop={this.onDropFile} accept="application/pdf">
        {({ getRootProps, getInputProps }) => (
          <section id="dropzone_box">
            <div id="dropzone_area" {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="clor-lightnavi">ここにPDFファイルをドラッグ＆ドロップ</p>
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}

export default withRouter(DropzoneBox);
