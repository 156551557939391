import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TransferList from "./OverviewTransferList";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#21252994",
  },
}))(MuiDialogContent);

export default function OverviewDialogs({
  typeNameParents,
  setOverviewDataTable,
  disableItems
}: {
  typeNameParents: any;
  setOverviewDataTable: Function;
  disableItems: any
}) {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [itemDisable, setItemDisable] = React.useState<any>([]);
  const [itemDisplay, setItemDisplay] = React.useState<any>([]);

  React.useEffect(() => setItemDisplay(typeNameParents), [typeNameParents]);
  React.useEffect(() => setItemDisable(disableItems), [disableItems])

  const handleClickOpen = () => {
    setOpen(true);
    setIsDirty(false);
  };

  const handleClose = () => {
    if (isDirty) {
      const newData = typeNameParents.concat(
        itemDisplay.filter((checked: any) => typeNameParents.indexOf(checked) === -1)
      );

      const dataDisplay = newData.filter(
        (test: any) => itemDisable.indexOf(test) === -1
      );
      setOverviewDataTable(dataDisplay, 'cola');
    }
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        項目の追加・変更
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          入力項目の追加・変更
        </DialogTitle>
        <DialogContent dividers>
          <TransferList
            itemDisable={itemDisable}
            itemDisplay={itemDisplay}
            setIsDirty={setIsDirty}
            setItemDisable={setItemDisable}
            setItemDisplay={setItemDisplay}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const StyledSelect = styled.div`
  display: flex;
  align-items: center;
`;
