import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { SelfEvaluationTypeKansaiKokusai } from "../../../models/Types";

interface Props extends RouteComponentProps<{ studentID: string }> {}
interface State extends SelfEvaluationTypeKansaiKokusai {
  studentName: string;
  startDate: string;
  endDate: string;
}

class SelfEvaluationTableKansaiKokusaiKyusei extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      studentID: this.props.match.params.studentID,
      studentName: "",
      startDate: "",
      endDate: "",
      item1_1: "",
      item1_2: "",
      item1_3: "",
      item1_4: "",
      item1_5: "",
      item1_6: "",
      item1_7: "",
      item1_8: "",
      item2_1: "",
      item2_2: "",
      item2_3: "",
      item2_4: "",
      item2_5: "",
      item2_6: "",
      item3_1: "",
      item3_2: "",
      item3_3: "",
      item3_4: "",
      item3_5: "",
      item3_6: "",
      item3_7: "",
      item3_8: "",
      item3_9: "",
      item3_10: "",
      item3_11: "",
      item3_12: "",
      item3_13: "",
      item3_14: "",
      item4_1: "",
      item4_2: "",
      item4_3: "",
      item4_4: "",
      item4_5: "",
      item4_6: "",
      item5_1_1: "",
      item5_1_2: "",
      item5_1_3: "",
      item5_2_1: "",
      item5_2_2: "",
      item5_2_3: "",
      item5_3_1: "",
      item5_3_2: "",
      item5_3_3: "",
      item5_4_1: "",
      item5_4_2: "",
      item5_4_3: "",
      item5_4_4: "",
      item5_4_5: "",
      item5_4_6: "",
      item5_4_7: "",
      item5_5_1: "",
      item5_5_2: "",
      item5_5_3: "",
    };

    this.onClickCancel = this.onClickCancel.bind(this);
  }

  public onClickCancel() {
    this.props.history.goBack();
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation/kansai_kokusai/fetch?student_id=${this.state.studentID}`;

    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          item1_1: result.item1_1,
          item1_2: result.item1_2,
          item1_3: result.item1_3,
          item1_4: result.item1_4,
          item1_5: result.item1_5,
          item1_6: result.item1_6,
          item1_7: result.item1_7,
          item1_8: result.item1_8,
          item2_1: result.item2_1,
          item2_2: result.item2_2,
          item2_3: result.item2_3,
          item2_4: result.item2_4,
          item2_5: result.item2_5,
          item2_6: result.item2_6,
          item3_1: result.item3_1,
          item3_2: result.item3_2,
          item3_3: result.item3_3,
          item3_4: result.item3_4,
          item3_5: result.item3_5,
          item3_6: result.item3_6,
          item3_7: result.item3_7,
          item3_8: result.item3_8,
          item3_9: result.item3_9,
          item3_10: result.item3_10,
          item3_11: result.item3_11,
          item3_12: result.item3_12,
          item3_13: result.item3_13,
          item3_14: result.item3_14,
          item4_1: result.item4_1,
          item4_2: result.item4_2,
          item4_3: result.item4_3,
          item4_4: result.item4_4,
          item4_5: result.item4_5,
          item4_6: result.item4_6,
          item5_1_1: result.item5_1_1,
          item5_1_2: result.item5_1_2,
          item5_1_3: result.item5_1_3,
          item5_2_1: result.item5_2_1,
          item5_2_2: result.item5_2_2,
          item5_2_3: result.item5_2_3,
          item5_3_1: result.item5_3_1,
          item5_3_2: result.item5_3_2,
          item5_3_3: result.item5_3_3,
          item5_4_1: result.item5_4_1,
          item5_4_2: result.item5_4_2,
          item5_4_3: result.item5_4_3,
          item5_4_4: result.item5_4_4,
          item5_4_5: result.item5_4_5,
          item5_4_6: result.item5_4_6,
          item5_4_7: result.item5_4_7,
          item5_5_1: result.item5_5_1,
          item5_5_2: result.item5_5_2,
          item5_5_3: result.item5_5_3,
        });
      })
      .catch(console.error);

    const url2 = `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${this.state.studentID}`;

    fetch(url2)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          studentName: result.studentName,
          startDate: result.startDate,
          endDate: result.endDate,
          // internHospitalWard: this.state.internHospitalWard,
          // attendDays: this.state.attendDays,
          // attendDaysAll: this.state.attendDaysAll,
          // lateEarlyLeaveHours: this.state.lateEarlyLeaveHours,
        });
      })
      .catch(console.error);
  }

  public render() {
    return (
      <Container>
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <h2 className="m-5">自己評価表 (関西国際大学 急性実習)</h2>
            <Table
              id="self_evaluation_kansai_kokusai_tougou_student_info"
              bordered
            >
              <tbody>
                <tr>
                  <td className="text-left">
                    学生番号 &nbsp; {this.state.studentID}
                    <br />
                    学生氏名 &nbsp; {this.state.studentName}
                  </td>
                  <td className="text-left">
                    実習場所
                    <br />
                    神戸大学医学部付属病院 Web病棟
                  </td>
                </tr>
                <tr>
                  <td className="text-left">
                    実習期間
                    <br />
                    {this.state.startDate} ~ {this.state.endDate}
                  </td>
                  <td className="text-left">
                    出席状況 -/-日
                    <br />
                    遅刻・早退時間数 -時間
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table id="self_evaluation_goal_kansai_kokusai" bordered>
              <thead className="thead-dark">
                <tr>
                  <th>実習目標</th>
                  <th colSpan={1}>&emsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="goal_big">
                    1.【術前】
                    <br />
                    手術を受ける患者の器質的・機能的障害と心理・社会的な状況を把握し、麻酔・手術の影響を予測して合併症を予防するための援助を行うことができる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td>C</td>
                      <td className="goal_small">
                        患者に生じている器質的・機能的な障害を指摘できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        患者に生じている器質的・機能的な障害に対応した援助ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        手術・麻酔を受けることによって予測される身体的影響を指摘できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>術後合併症の予防をはかるよう援助できる。</td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_4 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_4 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_4 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_4 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        術前の検査、治療、処置の目的および留意点を説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_5 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_5 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_5 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_5 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        術前の検査、治療、処置を受ける患者に対応した適切な援助および介助ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_6"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_6 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_6"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_6 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_6"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_6 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_6"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_6 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        手術を受けるという体験が、患者・家族に及ぼしている心理・社会的影響を指摘できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_7"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_7 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_7"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_7 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_7"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_7 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_7"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_7 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        手術・麻酔を受ける患者・家族の心理・社会的状態に対応した援助ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_8"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_8 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_8"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_8 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_8"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_8 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_8"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_8 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    2.【術中】
                    <br />
                    手術室に入室する患者の心理状態を推測し、それに対応した援助を説明でき、手術見学を通して患者に実施された手術の内容を説明することができる。
                  </td>
                  <td className="goal_small p-0">
                    <tr>
                      <td>C</td>
                      <td className="goal_small">
                        入室時の患者の心理状態を推測し、それに対応した援助が説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        手術室入室前から手術開始までに行われる麻酔導入、手術準備の内容が説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>手術操作・術中処置についての経過が説明できる。</td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>麻酔から覚醒する患者の状態が説明できる。</td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_4 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_4 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_4 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_4 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        患者に装着されるチューブ類・ドレーン類の挿入目的、部位が説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_5"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_5 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_5"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_5 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_5"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_5 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_5"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_5 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>手術に携わる医療チームメンバーを指摘できる。</td>
                      <td>
                        <Form.Check
                          name="item2_6"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_6 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_6"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_6 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_6"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_6 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_6"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_6 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    3.【術後】
                    <br />
                    術後の合併症の予防および変化した形態・機能の回復段階に合わせて日常生活の援助ができ、患者が主体的に療養生活に取り組めるように援助することができる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td>C</td>
                      <td className="goal_small">
                        手術後に必要な身体の観察項目が述べられる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>術後の身体の観察を行い、異常の早期発見ができる。</td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        手術による形態・機能の変化を指摘することができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>術後合併症を予防する援助ができる。</td>
                      <td>
                        <Form.Check
                          name="item3_4"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_4 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_4"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_4 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_4"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_4 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_4"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_4 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>手術に伴う苦痛を説明できる。</td>
                      <td>
                        <Form.Check
                          name="item3_5"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_5 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_5"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_5 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_5"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_5 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_5"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_5 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>苦痛を緩和・除去するための援助ができる。</td>
                      <td>
                        <Form.Check
                          name="item3_6"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_6 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_6"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_6 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_6"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_6 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_6"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_6 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        変化した形態・機能によって日常生活がどのように規制されているか指摘できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_7"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_7 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_7"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_7 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_7"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_7 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_7"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_7 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        変化した形態・機能の回復段階に合わせて日常生活の援助ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_8"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_8 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_8"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_8 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_8"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_8 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_8"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_8 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        術後の検査、治療、処置の目的・理由および留意点が説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_9"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_9 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_9"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_9 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_9"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_9 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_9"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_9 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        術後の検査、治療、処置を受ける患者に対して適切な援助および介助ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_10"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_10 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_10"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_10 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_10"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_10 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_10"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_10 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        患者・家族が術後の状況をどのように受け止めているか説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_11"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_11 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_11"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_11 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_11"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_11 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_11"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_11 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        患者・家族が主体的に療養生活に取り組めるように援助することができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_12"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_12 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_12"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_12 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_12"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_12 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_12"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_12 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>患者および家族に必要な社会資源を指摘できる。</td>
                      <td>
                        <Form.Check
                          name="item3_13"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_13 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_13"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_13 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_13"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_13 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_13"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_13 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>必要な社会資源を活用できるように援助できる。</td>
                      <td>
                        <Form.Check
                          name="item3_14"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_14 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_14"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_14 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_14"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_14 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_14"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_14 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    4.
                    <br />
                    手術療法を受ける患者・家族の健康上の問題を明らかにし、看護過程を展開できる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td>C</td>
                      <td className="goal_small">
                        どのような情報が必要かを述べることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        必要な患者の情報を様々な手段を用いて集めることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        集めた情報を解釈・分析して、身体、心理・社会的側面からアセスメントできる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>患者の全体像（関連図）を描写できる。</td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_4 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_4 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_4 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_4 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        健康上の問題を特定し、アセスメントに基づいた援助計画を立案できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_5 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_5 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_5 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_5 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>立案した計画に基づいて実施した援助を評価できる。</td>
                      <td>
                        <Form.Check
                          name="item4_6"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_6 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_6"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_6 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_6"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_6 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_6"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_6 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    5.
                    <br />
                    実習を通して患者の尊厳を重んじる態度、医療チームの一員としての誠実で謙虚な態度、自己研鑽し続ける態度を身につけることができる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td className="align-middle">A</td>
                      <td className="p-0">
                        <tr>
                          <td className="goal_middle" rowSpan={3}>
                            患者および家族に対して誠意をもって接することができる。
                          </td>
                          <td>
                            ①患者および家族に対して丁寧な言葉遣いで話すことができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_1"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_1_1 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_1"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_1_1 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_1"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_1_1 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_1"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_1_1 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ②成人期の患者に受け入れられる身だしなみを整えることができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_2"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_1_2 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_2"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_1_2 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_2"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_1_2 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_2"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_1_2 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>③患者および家族に実習日の前後で挨拶できる。</td>
                          <td>
                            <Form.Check
                              name="item5_1_3"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_1_3 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_3"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_1_3 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_3"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_1_3 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_3"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_1_3 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">A</td>
                      <td className="p-0">
                        <tr>
                          <td className="goal_middle" rowSpan={3}>
                            いかなる場面でも人としての尊厳を重んじ、気持ちを思いやる態度がとれる。
                          </td>
                          <td>①患者の考え方や価値観を大切にする。</td>
                          <td>
                            <Form.Check
                              name="item5_2_1"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_2_1 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_1"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_2_1 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_1"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_2_1 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_1"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_2_1 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>②患者のプライバシーを守ることができる。</td>
                          <td>
                            <Form.Check
                              name="item5_2_2"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_2_2 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_2"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_2_2 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_2"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_2_2 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_2"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_2_2 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ③患者の気持ちや思いを考えて、患者の立場に立つことができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_3"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_2_3 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_3"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_2_3 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_3"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_2_3 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_3"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_2_3 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">A</td>
                      <td className="p-0">
                        <tr>
                          <td className="goal_middle" rowSpan={3}>
                            患者の安全・安楽に配慮し、ケアに取り組むことができる。
                          </td>
                          <td>①患者のペースに合わせることができる。</td>
                          <td>
                            <Form.Check
                              name="item5_3_1"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_3_1 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_1"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_3_1 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_1"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_3_1 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_1"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_3_1 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ②患者に不必要な苦痛を与えないよう計画性をもってケアできる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_2"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_3_2 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_2"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_3_2 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_2"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_3_2 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_2"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_3_2 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>③患者のケアは、勝手な自己判断で行わない。</td>
                          <td>
                            <Form.Check
                              name="item5_3_3"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_3_3 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_3"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_3_3 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_3"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_3_3 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_3"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_3_3 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">A</td>
                      <td className="p-0">
                        <tr>
                          <td className="goal_middle" rowSpan={7}>
                            看護学生として責任ある実習態度がとれる。
                          </td>
                          <td>
                            ①時間厳守の態度で臨み、遅刻・欠勤の場合は連絡できる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_1"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_1 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_1"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_1 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_1"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_1 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_1"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_1 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>②実習中の所在は常に明確にすることができる。</td>
                          <td>
                            <Form.Check
                              name="item5_4_2"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_2 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_2"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_2 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_2"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_2 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_2"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_2 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ③ケアおよび観察事項は、確実に報告することができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_3"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_3 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_3"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_3 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_3"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_3 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_3"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_3 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>④記録物の提出期限を守ることができる。</td>
                          <td>
                            <Form.Check
                              name="item5_4_4"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_4 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_4"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_4 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_4"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_4 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_4"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_4 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ⑤病院スタッフおよびグループメンバーと協調して行動することができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_5"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_5 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_5"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_5 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_5"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_5 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_5"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_5 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ⑥指導者および病院スタッフの助言やアドバイスを謙虚に聞き入れることができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_6"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_6 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_6"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_6 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_6"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_6 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_6"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_6 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ⑦患者に確実なケアを提供するために実習前・実習中の学習が主体的にできる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_7"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_7 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_7"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_7 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_7"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_7 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_7"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_7 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">A</td>
                      <td className="p-0">
                        <tr>
                          <td className="goal_middle" rowSpan={3}>
                            カンファレンスの充実に努める。
                          </td>
                          <td>
                            ①メンバーの発表に対してアサーティブに意見を述べることができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_1"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_5_1 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_1"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_5_1 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_1"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_5_1 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_1"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_5_1 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ②他人の考えを取り入れて自分の考えを発展することができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_2"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_5_2 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_2"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_5_2 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_2"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_5_2 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_2"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_5_2 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ③グループメンバーとして役割を分担し、カンファレンスを円滑に運営できる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_3"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_5_3 === "A"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_3"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_5_3 === "B"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_3"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_5_3 === "C"}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_3"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_5_3 === "D"}
                              disabled
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-4">
          <Button
            variant="outline-secondary"
            size="lg"
            type="submit"
            onClick={this.onClickCancel}
          >
            Back
          </Button>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SelfEvaluationTableKansaiKokusaiKyusei);
