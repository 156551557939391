import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { SelfEvaluationTypeKobeJoshiBosei } from "../../../models/Types";

interface Props extends RouteComponentProps<{ studentID: string }> {}
interface State extends SelfEvaluationTypeKobeJoshiBosei {}

class SelfEvaluationTableBosei extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      studentID: this.props.match.params.studentID,
      item1_1: 0,
      item1_2: 0,
      item1_3: 0,
      item1_4: 0,
      item1_5: 0,
      item2_1: 0,
      item2_2: 0,
      item3_1: 0,
      item3_2: 0,
      item4: 0,
      item5: 0,
      item6_1: 0,
      item6_2: 0,
      item7: 0,
      item8_1: 0,
      item8_2: 0,
      item8_3: 0,
    };

    this.onChangeItem1_1 = this.onChangeItem1_1.bind(this);
    this.onChangeItem1_2 = this.onChangeItem1_2.bind(this);
    this.onChangeItem1_3 = this.onChangeItem1_3.bind(this);
    this.onChangeItem1_4 = this.onChangeItem1_4.bind(this);
    this.onChangeItem1_5 = this.onChangeItem1_5.bind(this);
    this.onChangeItem2_1 = this.onChangeItem2_1.bind(this);
    this.onChangeItem2_2 = this.onChangeItem2_2.bind(this);
    this.onChangeItem3_1 = this.onChangeItem3_1.bind(this);
    this.onChangeItem3_2 = this.onChangeItem3_2.bind(this);
    this.onChangeItem4 = this.onChangeItem4.bind(this);
    this.onChangeItem5 = this.onChangeItem5.bind(this);
    this.onChangeItem6_1 = this.onChangeItem6_1.bind(this);
    this.onChangeItem6_2 = this.onChangeItem6_2.bind(this);
    this.onChangeItem7 = this.onChangeItem7.bind(this);
    this.onChangeItem8_1 = this.onChangeItem8_1.bind(this);
    this.onChangeItem8_2 = this.onChangeItem8_2.bind(this);
    this.onChangeItem8_3 = this.onChangeItem8_3.bind(this);

    this.onClickCancel = this.onClickCancel.bind(this);
    this.onSubmitSelfEvaluation = this.onSubmitSelfEvaluation.bind(this);
  }

  public onChangeItem1_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item1_1: value });
  }
  public onChangeItem1_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item1_2: value });
  }
  public onChangeItem1_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item1_3: value });
  }
  public onChangeItem1_4(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item1_4: value });
  }
  public onChangeItem1_5(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item1_5: value });
  }
  public onChangeItem2_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item2_1: value });
  }
  public onChangeItem2_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item2_2: value });
  }
  public onChangeItem3_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item3_1: value });
  }
  public onChangeItem3_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item3_2: value });
  }
  public onChangeItem4(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item4: value });
  }
  public onChangeItem5(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item5: value });
  }
  public onChangeItem6_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item6_1: value });
  }
  public onChangeItem6_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item6_2: value });
  }
  public onChangeItem7(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item7: value });
  }
  public onChangeItem8_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item8_1: value });
  }
  public onChangeItem8_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item8_2: value });
  }
  public onChangeItem8_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    this.setState({ item8_3: value });
  }

  public onClickCancel() {
    this.props.history.goBack();
  }

  public onSubmitSelfEvaluation(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation/kobe_joshi/bosei/save`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const newSelfEvaluation = {
      self_evaluation: {
        student_id: this.state.studentID,
        item1_1: this.state.item1_1,
        item1_2: this.state.item1_2,
        item1_3: this.state.item1_3,
        item1_4: this.state.item1_4,
        item1_5: this.state.item1_5,
        item2_1: this.state.item2_1,
        item2_2: this.state.item2_2,
        item3_1: this.state.item3_1,
        item3_2: this.state.item3_2,
        item4: this.state.item4,
        item5: this.state.item5,
        item6_1: this.state.item6_1,
        item6_2: this.state.item6_2,
        item7: this.state.item7,
        item8_1: this.state.item8_1,
        item8_2: this.state.item8_2,
        item8_3: this.state.item8_3,
      },
    };
    const body = JSON.stringify(newSelfEvaluation);

    fetch(url, { method, headers, body })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.status === "success") {
          this.props.history.goBack();
        } else {
          console.log("API Error");
        }
      })
      .catch(console.error);
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation/kobe_joshi/bosei/fetch?student_id=${this.state.studentID}`;

    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          item1_1: result.item1_1,
          item1_2: result.item1_2,
          item1_3: result.item1_3,
          item1_4: result.item1_4,
          item1_5: result.item1_5,
          item2_1: result.item2_1,
          item2_2: result.item2_2,
          item3_1: result.item3_1,
          item3_2: result.item3_2,
          item4: result.item4,
          item5: result.item5,
          item6_1: result.item6_1,
          item6_2: result.item6_2,
          item7: result.item7,
          item8_1: result.item8_1,
          item8_2: result.item8_2,
          item8_3: result.item8_3,
        });
      })
      .catch(console.error);
  }

  public render() {
    return (
      <Container>
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <h2 className="mb-3">自己評価表 (神戸女子大学 母性実習)</h2>
            <Table id="self_evaluation_kobe_joshi" bordered>
              <thead className="thead-dark">
                <tr>
                  <th>実習目標</th>
                  <th colSpan={1}>&emsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="goal_big">
                    １．周産期における母子の心身における生理的変化や生活環境が健康に及ぼす影響を理解する。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td className="goal_small">
                        １）妊娠期における対象者（妊婦や胎児）の経過について妊婦健康診査や対象者の言動からアセスメントできる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item1_1 === 4}
                          onChange={this.onChangeItem1_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item1_1 === 3}
                          onChange={this.onChangeItem1_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item1_1 === 2}
                          onChange={this.onChangeItem1_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item1_1 === 1}
                          onChange={this.onChangeItem1_1}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ２）分娩期における対象者（妊婦や胎児）の分娩経過や適応過程を知る。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item1_2 === 4}
                          onChange={this.onChangeItem1_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item1_2 === 3}
                          onChange={this.onChangeItem1_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item1_2 === 2}
                          onChange={this.onChangeItem1_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item1_2 === 1}
                          onChange={this.onChangeItem1_2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ３）産褥期における対象者の退行性・進行性変化、全身の回復過程、心理・社会的状態をアセスメントできる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item1_3 === 4}
                          onChange={this.onChangeItem1_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item1_3 === 3}
                          onChange={this.onChangeItem1_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item1_3 === 2}
                          onChange={this.onChangeItem1_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item1_3 === 1}
                          onChange={this.onChangeItem1_3}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ４）新生児期における対象者の子宮外生活への適応過程をアセスメントする。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item1_4 === 4}
                          onChange={this.onChangeItem1_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item1_4 === 3}
                          onChange={this.onChangeItem1_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item1_4 === 2}
                          onChange={this.onChangeItem1_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item1_4 === 1}
                          onChange={this.onChangeItem1_4}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ５）母と子および家族が身体的・心理的・社会的にどのように関連し合っているのかを考えることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item1_5 === 4}
                          onChange={this.onChangeItem1_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item1_5 === 3}
                          onChange={this.onChangeItem1_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item1_5 === 2}
                          onChange={this.onChangeItem1_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item1_5 === 1}
                          onChange={this.onChangeItem1_5}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ２．妊産婦と新生児の健康課題や健康問題に対する看護過程を実施する。
                  </td>
                  <td className="goal_small p-0">
                    <tr>
                      <td className="goal_small">
                        １）ウエルネスやリスクマネジメントの視点に基づいて、観察やコミュニケーションを通して対象者の健康課題や健康問題をアセスメントする。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item2_1 === 4}
                          onChange={this.onChangeItem2_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item2_1 === 3}
                          onChange={this.onChangeItem2_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item2_1 === 2}
                          onChange={this.onChangeItem2_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item2_1 === 1}
                          onChange={this.onChangeItem2_1}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ２）ウエルネスやリスクアセスメントの視点に基づいて、対象者の健康課題や健康問題に対応した看護援助を計画、実施、評価する。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item2_2 === 4}
                          onChange={this.onChangeItem2_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item2_2 === 3}
                          onChange={this.onChangeItem2_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item2_2 === 2}
                          onChange={this.onChangeItem2_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item2_2 === 1}
                          onChange={this.onChangeItem2_2}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ３．母児の愛着形成および親役割取得を促進するための援助について理解する
                  </td>
                  <td className="p-0">
                    <tr>
                      <td className="goal_small">
                        １）妊娠期にある対象者や家族が、妊娠・出産・育児についてどのように受け止めているのかを考えることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item3_1 === 4}
                          onChange={this.onChangeItem3_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item3_1 === 3}
                          onChange={this.onChangeItem3_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item3_1 === 2}
                          onChange={this.onChangeItem3_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item3_1 === 1}
                          onChange={this.onChangeItem3_1}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ２）産褥期にある対象者（母親や新生児）や家族について、愛着形成や親役割取得状況を観察し、これらを促進するための援助を考えることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item3_2 === 4}
                          onChange={this.onChangeItem3_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item3_2 === 3}
                          onChange={this.onChangeItem3_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item3_2 === 2}
                          onChange={this.onChangeItem3_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item3_2 === 1}
                          onChange={this.onChangeItem3_2}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ４．出産の体験を知り、出産が女性に及ぼす影響や支援を考える。
                  </td>
                  <td className="p-0">
                    <tr className="without_small_goal">
                      <td>
                        <Form.Check
                          name="item4"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item4 === 4}
                          onChange={this.onChangeItem4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item4 === 3}
                          onChange={this.onChangeItem4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item4 === 2}
                          onChange={this.onChangeItem4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item4 === 1}
                          onChange={this.onChangeItem4}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ５．妊娠期、産褥期における教育的支援を理解する。
                  </td>
                  <td className="p-0">
                    <tr className="without_small_goal">
                      <td>
                        <Form.Check
                          name="item5"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item5 === 4}
                          onChange={this.onChangeItem5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item5 === 3}
                          onChange={this.onChangeItem5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item5 === 2}
                          onChange={this.onChangeItem5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item5 === 1}
                          onChange={this.onChangeItem5}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ６．母子と家族に関係する社会資源や制度について考える。
                  </td>
                  <td className="goal_small p-0">
                    <tr>
                      <td className="goal_small">
                        １）妊娠期における対象者が健康に妊娠経過を過ごし、安心して分娩をむかえることに関係する社会資源や制度を知る。
                      </td>
                      <td>
                        <Form.Check
                          name="item6_1"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item6_1 === 4}
                          onChange={this.onChangeItem6_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_1"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item6_1 === 3}
                          onChange={this.onChangeItem6_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_1"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item6_1 === 2}
                          onChange={this.onChangeItem6_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_1"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item6_1 === 1}
                          onChange={this.onChangeItem6_1}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ２）退院後の母子の生活について考え、健康に生活することや育児を支援することに関係する社会資源や制度について考えることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item6_2"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item6_2 === 4}
                          onChange={this.onChangeItem6_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_2"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item6_2 === 3}
                          onChange={this.onChangeItem6_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_2"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item6_2 === 2}
                          onChange={this.onChangeItem6_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item6_2"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item6_2 === 1}
                          onChange={this.onChangeItem6_2}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    ７．これらの目標を通して母性看護の役割とは何かを考える。
                  </td>
                  <td className="p-0">
                    <tr className="without_small_goal">
                      <td>
                        <Form.Check
                          name="item7"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item7 === 4}
                          onChange={this.onChangeItem7}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item7 === 3}
                          onChange={this.onChangeItem7}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item7 === 2}
                          onChange={this.onChangeItem7}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item7"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item7 === 1}
                          onChange={this.onChangeItem7}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">８．実習への取り組み。</td>
                  <td className="goal_small p-0">
                    <tr>
                      <td className="goal_small">
                        １）積極性や主体性を持って実習に臨むことができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item8_1"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item8_1 === 4}
                          onChange={this.onChangeItem8_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_1"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item8_1 === 3}
                          onChange={this.onChangeItem8_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_1"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item8_1 === 2}
                          onChange={this.onChangeItem8_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_1"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item8_1 === 1}
                          onChange={this.onChangeItem8_1}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>２）学生として責任ある行動がとれる。</td>
                      <td>
                        <Form.Check
                          name="item8_2"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item8_2 === 4}
                          onChange={this.onChangeItem8_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_2"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item8_2 === 3}
                          onChange={this.onChangeItem8_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_2"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item8_2 === 2}
                          onChange={this.onChangeItem8_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_2"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item8_2 === 1}
                          onChange={this.onChangeItem8_2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>３）今後の学習の課題を明らかにできる。</td>
                      <td>
                        <Form.Check
                          name="item8_3"
                          inline
                          type="radio"
                          label={4}
                          value={4}
                          checked={this.state.item8_3 === 4}
                          onChange={this.onChangeItem8_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_3"
                          inline
                          type="radio"
                          label={3}
                          value={3}
                          checked={this.state.item8_3 === 3}
                          onChange={this.onChangeItem8_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_3"
                          inline
                          type="radio"
                          label={2}
                          value={2}
                          checked={this.state.item8_3 === 2}
                          onChange={this.onChangeItem8_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item8_3"
                          inline
                          type="radio"
                          label={1}
                          value={1}
                          checked={this.state.item8_3 === 1}
                          onChange={this.onChangeItem8_3}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-4">
          <Button
            variant="primary"
            size="lg"
            type="submit"
            onClick={this.onSubmitSelfEvaluation}
          >
            Save
          </Button>
          <Button
            className="ml-3"
            variant="outline-secondary"
            size="lg"
            type="submit"
            onClick={this.onClickCancel}
          >
            Cancel
          </Button>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SelfEvaluationTableBosei);
