import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { TestDataItemType } from "../../models/Types";
import TestDataRecord from "./TestDataRecord";
import { cloneDeep as _cloneDeep } from "lodash";
import styled from "styled-components";

type TestDataItemRecordType = {
  date: string;
  date_time_text: string;
  date_time: string,
  results: {
    id: number;
    display_order: number;
    item_id: number;
    parent_id: number;
    result: string;
  }[];
};

interface Props {
  testData: TestDataItemRecordType;
  testDataItem: TestDataItemType[];
  formTestData: any;
  newDataUpdate: any;
  patientID: any,
  callback: Function
  setTestData: Function;
}
interface State {
  formValues: any;
  inputDate: any;
}

class TestDataTableData extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      formValues: {},
      inputDate: this.convertDate(Number(this.props.testData.date) as any),
    };
  }

  componentDidMount() {
    const cloneData = _cloneDeep(this.props.testData.results);
    const newData = {} as any;

    cloneData.forEach((items: any) => {
      newData[items.id] = {
        date: this.props.testData.date,
        item_id: items.item_id,
        result: items.result,
      };
    });

    this.setState({
      formValues: newData,
    });
  }

  setFormValues = (newData: any) => {
    this.setState({
      formValues: newData,
    });
  };

  filterObject = (obj: any, filter: any, filterValue: any) =>
    Object.keys(obj).reduce(
      (acc, val) =>
        obj[val][filter] !== filterValue
          ? acc
          : {
            ...acc,
            [val]: obj[val],
          },
      {}
    );

  convertDate = (date: number) => {
    const dayTime = `${Math.floor(date / 1440) ? Math.floor(date / 1440) + "" : "0"
      }`;

    return `${dayTime}`;
  };
  fetchAPI = (data: any) => {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/update_date_test_data_records`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        this.props.setTestData([]);
        if (result.success) {
         // this.setSuccessMsg();
        } else {
         // this.setError();
        }
      })
      .catch((error) => {
        console.log(error);
       // this.setError();
      });
  };
  unForcus = (e: any) => {
    const data = {
      patientID : this.props.patientID,
      oldDate: this.props.testData.date,
      newDate: this.state.inputDate,
      oldDatetime: this.props.testData.date_time
    };
    if(this.props.testData.date != this.state.inputDate){
      this.fetchAPI(data);
    }
  }
  onChangeDate = (newDate: any, oldDate : number) => {
    this.setState({
      inputDate: newDate,
    });
    // const cloneFormValues = _cloneDeep(this.state.formValues);
    // const filteredByKey = this.filterObject(
    //   this.state.formValues,
    //   "date",
    //   this.props.testData.date
    // );
    
    // Object.keys(filteredByKey).forEach((key) => {
    //   cloneFormValues[key].date = newDate;
    // });

  };
 

  public render() {
    let testDataParent = this.props.testDataItem.filter(data => data.is_parent)
    let testDataChild = this.props.testDataItem.filter(data => !data.is_parent)
    return (
      <Table
        className="test_data sw-test_data"
        bordered
        hover
        size="sm"
        style={{ width: "130px" }}
      >
        <thead>
          <tr>
            <th className="d-flex align-items-center">
              <Input
                value={this.state.inputDate}
                onBlur={this.unForcus}
                onChange={(e: any) => this.onChangeDate(e.target.value, this.state.inputDate)}
                style={{ width: "50px" }}
              />
              日目
            </th>
          </tr>
        </thead>
        <tbody>
          {testDataParent.map((item: TestDataItemType) => (
            <>
              <tr
                style={{ height: "50px" }}
                className={String(item.id)}
              // key={item.id}
              >
                <td>
                  <TestDataRecord
                    hasParent={item.is_parent}
                    callback={(data: any) => this.props.callback(data)}
                    patientID={this.props.patientID}
                    itemName={item.name}
                    parentId={item.parent_id}
                    testData={this.props.testData}
                    newDataUpdate={this.props.newDataUpdate}
                    date={this.props.testData.date}
                    itemId={item.id}
                    itemOrder={item.order}
                    formValues={this.props.formTestData}
                  />
                </td>
              </tr>

              {
                testDataChild.map((child) => Number(child.parent_id) === item.id && (
                  <tr
                    style={{ height: "50px" }}
                    className={String(item.id)}
                  // key={item.id}
                  >
                    <td>
                      <TestDataRecord
                        hasParent={child.is_parent}
                        callback={(data: any) => this.props.callback(data)}
                        patientID={this.props.patientID}
                        itemName={child.name}
                        parentId={child.parent_id}
                        testData={this.props.testData}
                        newDataUpdate={this.props.newDataUpdate}
                        date={this.props.testData.date}
                        itemId={child.id}
                        itemOrder={child.order}
                        formValues={this.props.formTestData}
                      />
                    </td>
                  </tr>
                ))
              }
            </>
          ))}
        </tbody>
      </Table>
    );
  }
}

const Input = styled(Form.Control)``;

export default TestDataTableData;