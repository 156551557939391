import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ListRoomSelect from "../hospital_ward/forms/ListRoomSelect";
import styled from "styled-components";
import HospitalRoomSetting from "../hospital_ward/HospitalRoomSetting";

import "../../assets/styles/IndexFaculty.scss";

interface Props extends RouteComponentProps<{}> {}
interface State {
  currentFilter: string | number;
}

class CreatePatientAssignment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentFilter: "all",
    };
  }

  onItemSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentFilter: event.target.value || "all",
    });
  };

  render() {
    const { location } = this.props;
    const { data } = location.state as any;
    return (
      <Container className="mt-5 mb-5">
        {data && (
          <Table
            id="index_faculty_student_list"
            className="sw-custom-table"
            bordered
            hover
          >
            <thead className="thead-dark">
              <tr>
                <th>施設名</th>
                <th>学生数</th>
                <th>実習開始日</th>
                <th>実習終了日</th>
                <th>実習日</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data.university_name}</td>
                <td>{data.studentNum}</td>
                <td>{data.startDate}</td>
                <td>{data.endDate}</td>
                <td>{data.internDays}日目</td>
              </tr>
            </tbody>
          </Table>
        )}

        <StyledHeader>
          <Form.Label column md={1} className="ml-5">
            病室
          </Form.Label>
          <ListRoomSelect onItemSelect={this.onItemSelect} />
          <Col md={6}>
            <h2 className="ml-4 p-3">患者割当</h2>
          </Col>
        </StyledHeader>

        <StyledHospitalRoom>
          <HospitalRoomSetting
            university={data}
            currentFilter={this.state.currentFilter}
          />
        </StyledHospitalRoom>
      </Container>
    );
  }
}

const StyledHeader = styled(Row)`
  display: flex;
  align-items: center;
  margin-top: 50px;
`;

const StyledHospitalRoom = styled.div`
  width: 990px;
  margin: auto;
`;

export default withRouter(CreatePatientAssignment);
