import React from "react";
import LoginStudent from "./LoginStudent";
import LoginFaculty from "./LoginFaculty";
import "../../assets/styles/Login.scss";
import HomePathContext from "../../helpers/HomePathContext";

interface Props {}
interface State {}

class LoginBox extends React.Component<Props, State> {

  static contextType = HomePathContext;
  
  public componentDidMount() {
    this.context?.removeStudentPath();
  }
  public render() {
    return (
      <div>
        <LoginStudent />
        <LoginFaculty />
      </div>
    );
  }
}

export default LoginBox;
