import React from "react";
import {
  uniqBy as _uniqBy,
  isEmpty as _isEmpty,
} from "lodash";
import moment from "moment";
import Link from "@material-ui/core/Link";
import OverviewTableRow from "./OverviewTableRow";
import OverviewTableCol from "./OverviewTableCol";
import Alert from "@material-ui/lab/Alert";
import { Col, Row, Form, Button } from "react-bootstrap";
import styled from "styled-components";
import { onKeyPressNumber } from "../../common/function";
import OverviewDialogs from "../../common/OverviewDialogs";
import CSVReaderImport from "../../common/CSVReaderImport";
import { LoadingSpriner } from "../../common/CircularSpinner";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import "../../../assets/styles/Overview.scss";
import "../../../assets/styles/SWCustom.scss";
import Table from "react-bootstrap/Table";

interface Props {
  patientID: string;
  studentID: string;
  facultyID: string;
}
interface State {
  dataRow: any;
  dataCol: any;
  isAutoSave: boolean;
  isDeleteSuccess: boolean;
  typeNameParents: any;
  isLoading: boolean;
  successMsg: boolean;
  error: boolean;
  disableItems: any,
  formValues: {
    startDate: number | null;
    endDate: number | null;
  };
  dialogOpen: boolean;
}

class Overview extends React.Component<Props, State> {
  private supportPageOffset: boolean | null;
  private isCSS1Compat: boolean | null;
  private demoItem2: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      disableItems: undefined,
      dataRow: null,
      dataCol: [],
      typeNameParents: [],
      isAutoSave: false,
      isDeleteSuccess: false,
      isLoading: false,
      successMsg: false,
      error: false,
      formValues: {
        startDate: null,
        endDate: null,
      },
      dialogOpen: false,
    };

    this.supportPageOffset = window.pageXOffset !== undefined;
    this.isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
    this.demoItem2 = React.createRef();
  }

  loadParents() {
    let pdata =  this.state.dataRow != null ? this.state.dataRow.mr_value[0].pdata : [];
    fetch(`${process.env.REACT_APP_API_URL}/api/settings/overview_data/getParents`, { mode: "cors" })
      .then((res) => res.json())
      .then((data) => {
        let disableItems = [] as any;
        if (data) {
          data.map((item: any) => {
            let child = {
              cola: item.type_id,
              colb: null,
              colc: null,
              namea: item.type_name,
              nameb: null,
              isDisplay: false,
              pdata: pdata,
              base_type_id: item.type_id,
              parent_type_id: item.type_id,
            }
            disableItems.push(child);
          })
        }
        disableItems = disableItems.filter((item: any) => this.state.typeNameParents.find((item2: any) => item.cola === item2.cola) == undefined);
        this.setState({
          disableItems: disableItems
        })
        this.initialShowData()
      })
  }

  handleClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  async loadOverview(loadParent = true, displaysData: any = [], parentIds: any = []) {
    this.setState({
      dataRow: null,
      dataCol: [],
      typeNameParents: [],
    });
    await fetch(
      `${process.env.REACT_APP_API_URL}/api/patient_info/get_data_patient?patient_id=${this.props.patientID}&student_id=${this.props.studentID}&faculty_id=${this.props.facultyID}&start_date=null`,
      { mode: "cors" }
    )
      .then((res) => res.json())
      .then(
        (xhr) => {
          if (xhr.statusCode !== 200) {
            alert(xhr.data);
          } else {
            let typeNameParents = _uniqBy(xhr.data.dataRow.mr_value, "cola");
            if (displaysData.length > 0) {
              typeNameParents = typeNameParents.filter((item: any,) => {
                return displaysData.indexOf(item.cola) > -1;
              })
            };
            this.setState({
              dataRow: {
                ...xhr.data.dataRow,
                mr_value: xhr.data.dataRow.mr_value.map((row: any) => {
                  const newPdata = [];
                  newPdata.push({
                    id: `add_${row.base_type_id}`,
                    mr_date: xhr.data.dataRow.mr_date,
                    mr_time: moment().format("HH:mm:ss"),
                    mr_value: "",
                    type_id: row.base_type_id,
                  });

                  if (displaysData.length > 0) {
                    if (displaysData.indexOf(row.cola) > -1) {
                      return {
                        ...row,
                        isDisplay: true,
                        pdata: row.pdata !== -1 ? row.pdata : newPdata,
                      };
                    } else {
                      return {
                        ...row,
                        isDisplay: false,
                        pdata: row.pdata !== -1 ? row.pdata : newPdata,
                      };
                    }
                  } else {
                    return {
                      ...row,
                      isDisplay: true,
                      pdata: row.pdata !== -1 ? row.pdata : newPdata,
                    };
                  }
                }),
                mr_time: xhr.data.dataRow.mr_time
                  ? xhr.data.dataRow.mr_time
                  : [{ hour: "", mr_time: moment().format("HH:mm:ss") }],
              },
              dataCol: xhr.data.dataCol
                .map((col: any) => {
                  return {
                    ...col,
                    isFilter: true,
                    mr_time2: col.mr_time,
                    mr_time: col.mr_time ? col.mr_time : [{ hour: "", mr_time: moment().format("HH:mm:ss") }],
                    mr_value: col.mr_value.map((row: any) => {
                      const newPdata = [];
                      newPdata.push({
                        id: `add_${row.base_type_id}`,
                        mr_date: xhr.data.dataRow.mr_date,
                        mr_time: moment().format("HH:mm:ss"),
                        mr_value: "",
                        type_id: row.base_type_id,
                      });

                      if (displaysData.length > 0) {
                        if (displaysData.indexOf(row.cola) > -1) {
                          return {
                            ...row,
                            isDisplay: true,
                            pdata: row.pdata !== -1 ? row.pdata : newPdata,
                          };
                        } else {
                          return {
                            ...row,
                            isDisplay: false,
                            pdata: row.pdata !== -1 ? row.pdata : newPdata,
                          };
                        }
                      } else {
                        return {
                          ...row,
                          isDisplay: true,
                          pdata: row.pdata !== -1 ? row.pdata : newPdata,
                        };
                      }
                    }),
                  };
                }).sort(function (a: any, b: any) {
                  return (a.mr_time2 == null || b.mr_time2 == null) || parseInt(a.mr_time2.toString().replace(":00:00", "")) - parseInt(b.mr_time2.toString().replace(":00:00", ""))
                })
              ,
            });

            if (parentIds.length > 0) {
              this.setState({
                disableItems: this.state.disableItems.filter((item: any) => parentIds.find((item2: any) => item.parent_type_id == item2) == undefined),
                typeNameParents,
              });
            }


            if (loadParent) {
              this.setState({
                typeNameParents,
              });
              this.loadParents();
            }
          }
        },
        (error) => {
          console.log("Error: ");
        }
      );
  };

  async initialShowData() {
    console.log('this.state.typeNameParents:', this.state.typeNameParents)
    this.setState({
      disableItems: _uniqBy(this.state.disableItems.concat(this.state.typeNameParents.filter((item: any) => item.pdata[0].visible_flag === 1 || item.pdata[0].visible_flag === null || item.pdata[0].visible_flag === undefined )), 'cola'),
      typeNameParents: _uniqBy(this.state.typeNameParents.filter((item: any) => item.pdata[0].visible_flag === 0), 'cola')
    })
    this.setOverviewDataTable(this.state.typeNameParents, 'cola', true);
  }

  async componentDidMount() {
    await this.loadOverview();
    window.addEventListener("scroll", this.handleScroll, true);
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, true);
  }

  setIsAutoSave = () => {
    this.setState({
      isAutoSave: true,
      dialogOpen: true,
    });
    setTimeout(() => {
      this.setState({
        isAutoSave: false,
        dialogOpen: false,
      });
    }, 3000);
  };


  setIsDeleteSuccess = () => {
    this.setState({
      isDeleteSuccess: true,
    });
    setTimeout(() => {
      this.setState({
        isDeleteSuccess: false,
      });
    }, 3000);
  };

  handleScroll = () => {
    var x = this.supportPageOffset
      ? window.pageXOffset
      : this.isCSS1Compat
        ? document.documentElement.scrollLeft
        : document.body.scrollLeft;
    let y = x - 0;

    this.demoItem2.current.style.left = y + "px";
  };

  actionDelete = (typeID: number) => {
    console.log('-----actionDelete----');
    console.log('patientID:',this.props.patientID);
    console.log('typeID:',typeID);
    const displays = this.state.dataRow.mr_value.filter(
      (item: any) => item.base_type_id !== typeID
    );
    this.setOverviewDataTable(displays, "base_type_id", true);
    this.updateVisibleFlag({patientID: this.props.patientID, typeID});    
  };
  updateVisibleFlag = (data: any) => {
    const url = `${process.env.REACT_APP_API_URL}/api/settings/overview_data/update_visible_flag`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
      
      })
      .catch((error) => {
        console.log(error);
     
      });
  };
  // set field for event onchange input
  setField = (field: string, value: string) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [field]: value,
      },
    });
  };

  onSearch = () => {
    const startDate = this.state.formValues.startDate || 0;
    const endDate = this.state.formValues.endDate || 365;
    const newData = this.state.dataCol.map((item: any) => {
      let isDisplay = false;
      if (item.mr_date >= Number(startDate) && item.mr_date < Number(endDate)) {
        isDisplay = true;
      }

      return {
        ...item,
        isFilter: isDisplay,
        mr_value: item.mr_value.map((row: any) => ({
          ...row,
          isDisplay,
        })),
      };
    });

    this.setState({
      dataCol: newData,
    });
  };

  setSuccessMsg = () => {
    this.setState({
      successMsg: true,
      isLoading: false,
    });
    setTimeout(() => {
      this.setState({
        successMsg: false,
      });
    }, 3000);
  };

  setError = () => {
    this.setState({
      error: true,
      isLoading: false,
    });
    setTimeout(() => {
      this.setState({
        error: false,
      });
    }, 3000);
  };

  onHandleCSV = (data: any, fileInfo: any) => {
    if (fileInfo.name) {
      const regex = /^.+\.(csv)$/g;
      const checkType = regex.test(fileInfo.name);
      if (checkType) {
        this.setState({
          isLoading: true,
        });

        const newData = {
          patient_id: this.props.patientID,
          data,
        };

        this.fetchAPI(newData);
      } else {
        this.setError();
      }
    }
  };

  fetchAPI = (data: any) => {
    const url = `${process.env.REACT_APP_API_URL}/api/settings/overview_data/csv-import`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          this.setSuccessMsg();
        } else {
          this.setError();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setError();
      });
  };

  getActionCol = () => {
    let actionCol: any;
    if (!_isEmpty(this.state.dataRow)) {
      actionCol = (
        <StyledTable
          hover
          size="sm"
          className="settings__overview float-left"
        >
          <tbody>
            <tr>
              <td className="bg-white">&emsp;</td>
            </tr>
            <tr>
              <td className="bg-white">&emsp;</td>
            </tr>
            {this.state.dataRow.mr_value.length > 0 &&
              this.state.dataRow.mr_value.map(
                (item: any, index: any) =>
                  item.isDisplay && (
                    <tr key={index}>
                      <td>
                        <Link
                          style={{
                            cursor: "pointer",
                            color: "#007bff",
                            textDecoration: "none",
                          }}
                          color="primary"
                          onClick={() => this.actionDelete(item.base_type_id)}
                        >
                          行削除
                        </Link>
                      </td>
                    </tr>
                  )
              )}
          </tbody>
        </StyledTable>
      );
    }

    return actionCol;
  };

  setOverviewDataTable = async (dataDisplay: any, dialogCola: any, init = false) => {
    const displayCol = dataDisplay.map((display: any) => display.cola);
    const idDisable = this.state.typeNameParents.filter((item: any) => displayCol.indexOf(item.cola) <= -1);
    if (!init) await this.setVisibleFlag(displayCol);

    // this.setState({ disableItems: _uniqBy(this.state.disableItems.concat(idDisable), 'cola') });
    console.log('this.state.disableItems:', this.state.disableItems)
    
    let parentIds = dataDisplay.map((item: any) => item.parent_type_id).filter((chil: any) => chil !== undefined);

    let displaysData: any = [];
    if (dialogCola === "cola") {
      displaysData = dataDisplay.map((display: any) => display.cola);
    } else {
      displaysData = dataDisplay.map((display: any) => display.base_type_id);
    }

    if (parentIds.length > 0) {
      await this.findAndInsertOverviewData(parentIds)
      await this.loadOverview(false, displaysData, parentIds);
    } else {
      const newRow = {
        ...this.state.dataRow,
        mr_value: this.state.dataRow.mr_value.map((row: any) => {
          if (!displaysData.includes(row[dialogCola])) {
            return {
              ...row,
              isDisplay: false,
            };
          } else {
            return {
              ...row,
              isDisplay: true,
            };
          }
        }),
      };

      const newCol = this.state.dataCol.map((col: any) => ({
        ...col,
        mr_value: col.mr_value.map((valueItem: any) => {
          if (!displaysData.includes(valueItem[dialogCola])) {
            return {
              ...valueItem,
              isDisplay: false,
            };
          } else {
            return {
              ...valueItem,
              isDisplay: true,
            };
          }
        }),
      }));

      this.setState({
        dataRow: newRow,
        dataCol: newCol,
      });
      return;
    }
  };

  async setVisibleFlag(displayCol: any) {
    const parentId = this.props.patientID;
    const data = {
      displayCol,
      parentId,
    }

    const url = `${process.env.REACT_APP_API_URL}/api/settings/overview_data/setVisible`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    await fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {

      })
      .catch((error) => {
        console.log(error);
        this.setError();
      });
  }

  async findAndInsertOverviewData(parentIds: any) {
    const dataColExam = this.state.dataCol.map((item: any) => item.mr_time)
    const parentId = this.props.patientID;
    const data = {
      dataColExam,
      parentIds,
      parentId
    }
    const url = `${process.env.REACT_APP_API_URL}/api/settings/overview_data/insertParents`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    await fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {

      })
      .catch((error) => {
        console.log(error);
        this.setError();
      });
  }

  setDataCol = (dataCol: any) => {
    this.setState({
      dataCol,
    });
  };

  public render() {
    return (
      <div className="datagrid-viewport mt-5">
        <StyledBtnModal>
          {(this.state.typeNameParents.length > 0 && this.state.disableItems !== undefined) && (
            <OverviewDialogs
              typeNameParents={this.state.typeNameParents}
              setOverviewDataTable={this.setOverviewDataTable}
              disableItems={this.state.disableItems}
            />
          )}
        </StyledBtnModal>
        <StyledFormSearch>
          <Col
            md={12}
            style={{ margin: "auto" }}
            className="d-flex justify-content-center align-items-baseline"
          >
            <Form.Label className="font-weight-bold pr-3">
              表示範囲：
            </Form.Label>
            <Form.Control
              className="m-0"
              required
              type="text"
              onKeyPress={onKeyPressNumber}
              value={this.state.formValues.startDate || ""}
              onChange={(e) => this.setField("startDate", e.target.value)}
            />
            <Form.Label className="font-weight-bold pr-3">日目</Form.Label>
            <Form.Label className="font-weight-bold pr-2">～</Form.Label>
            <Form.Control
              className="m-0"
              required
              type="text"
              onKeyPress={onKeyPressNumber}
              value={this.state.formValues.endDate || ""}
              onChange={(e) => this.setField("endDate", e.target.value)}
            />
            <Form.Label className="font-weight-bold pr-3">日目</Form.Label>
            <Button onClick={this.onSearch} variant="primary">
              <span>反映</span>
            </Button>

            <div
              style={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <CSVReaderImport
                handleForce={this.onHandleCSV}
                className="react-csv-input ml-5"
              />
              <Link
                href={`${process.env.REACT_APP_API_URL}/api/patient_info/csv-sample-download?fileName=[サンプル]オーバービュー入力.csv`}
                style={{
                  cursor: "pointer",
                  color: "#007bff",
                  textDecoration: "none",
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
                color="primary"
              >
                CSVテンプレート
              </Link>
            </div>
          </Col>
          <Col md={12} className="mb-5 mt-3">
            {this.state.isLoading && <LoadingSpriner />}
            {this.state.successMsg && (
              <Alert className="mt-3 sw-custom-btn">
                データの登録が完了しました。
              </Alert>
            )}
            {this.state.error && (
              <Alert severity="error" className="mt-3 sw-custom-btn">
                データの登録に失敗しました。
              </Alert>
            )}

            {this.state.isAutoSave && (
              <Alert className="mt-3 sw-custom-btn" severity="success">
                データ自動更新しました。
              </Alert>
            )}

            {this.state.isDeleteSuccess && (
              <Alert className="mt-3 sw-custom-btn" severity="success">
                削除しました。
              </Alert>
            )}
          </Col>
        </StyledFormSearch>

        <div
          id="demoItem2"
          className="col-label datagrid-pinned-data"
          ref={this.demoItem2}
        >
          {!_isEmpty(this.state.dataRow) && (
            <OverviewTableRow dataRow={this.state.dataRow} />
          )}
        </div>
        <div className="col-data d-flex flex-row x-scrollable-pane">
          <OverviewTableCol
            dataCol={this.state.dataCol}
            setDataCol={this.setDataCol}
            patientID={this.props.patientID}
            setIsAutoSave={this.setIsAutoSave}
            setIsDeleteSuccess={this.setIsDeleteSuccess}
            loadOverview={this.loadOverview}
          />
          {this.getActionCol()}
        </div>
        {this.state.successMsg && (
          <Alert className="mt-3 sw-custom-btn">
            データの登録が完了しました。
          </Alert>
        )}
        {this.state.error && (
          <Alert severity="error" className="mt-3 sw-custom-btn">
            データの登録に失敗しました。
          </Alert>
        )}

        {this.state.isAutoSave && (
          <Alert className="mt-3 sw-custom-btn" severity="success">
            データ自動更新しました。
          </Alert>
        )}
        {this.state.isDeleteSuccess && (
          <Alert className="sw-custom-btn" severity="success">
            削除しました。
          </Alert>
        )}
        <Dialog
          open={this.state.dialogOpen}
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Alert className="mt-3">更新しました。</Alert>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const StyledTable = styled(Table)`
  margin-left: 20px;
  width: 100px;
  td {
    height: 39px;
    vertical-align: center;
    padding: none;
    text-align: center;
    border-top: none;
  }
`;

const StyledFormSearch = styled(Row)`
  input {
    width: 50px;
  }
`;

const StyledBtnModal = styled.div`
  position: fixed;
  z-index: 999;
  background: #21252994;
  left: 20px;
  width: 180px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export default Overview;
