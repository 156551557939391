import React from "react";
import Form from "react-bootstrap/Form";

interface Props {
    value:any;
    isFacultySupervisor?: boolean;
    onItemSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
interface State {
    university: {
        id: number;
        university_id: string;
        university_name: string;
    }[];
}

class SignUpUniversitySelect extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            university: [{ id: 0, university_id: "", university_name: "" }]
        };
    }

    public componentDidMount() {

        const url = `${process.env.REACT_APP_API_URL}/api/signup/get_university`;

        fetch(url, { mode: "cors" })
            .then(res => res.json())
            .then(
                result => {
                    console.log(result);
                    this.setState({
                        university: result
                    });
                },
                error => {
                    console.log(error);
                }
            );

    }

    public render() {
        let datas = this.props.isFacultySupervisor == false ?  this.state.university.filter(x => x.university_id == this.props.value) : this.state.university;
        const options = datas.map((item, i) => (
            <option key={i} value={item.university_id}>{item.university_name}</option>
        ));

        return (
            <Form.Control value={this.props.value} as="select" onChange={this.props.onItemSelect}>
                <option>選択</option>
                {options}
            </Form.Control>
        );
    }
}

export default SignUpUniversitySelect;
