import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import IndexStudentBasicInfo from "./IndexStudentBasicInfo";
import IndexStudentLinks from "./IndexStudentLinks";
// import IndexStudentAttendance from "./IndexStudentAttendance";
import "../../assets/styles/IndexStudent.scss";
import "../../assets/styles/SWCustom.scss";
import AutoHomePath from './../common/AutoHomePath';
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";
import img_patient_infos from "../../assets/images/patient_infos.jpg";
import img_nurse_plan from "../../assets/images/nurse_plan.jpg";
import img_chart from "../../assets/images/chart.jpg";
import img_nurse_diagnosis from "../../assets/images/nurse_diagnosis.jpg";
import img_self_evaluation from "../../assets/images/self_evaluation.jpg";
import img_questions from "../../assets/images/questions.jpg";
import HomePathContext from './../../helpers/HomePathContext';
import img_group from '../../assets/images/group.png'
import { getUserId, USER_TYPE } from "../../services/AppLocalHelper";
import { checkUserId } from "../../services/AuthService";

interface Props extends RouteComponentProps<{ studentID: string }> { }
interface State {
  internDay: number;
  university: string;
  nursingFacultyComment: string;
  chartComment: string;
  nursingDiagnosisComment: string;
  confirm1: boolean;
  confirm2: boolean;
  updateStatus: string;
  isConfirm1Disabled: boolean;
  isConfirm2Disabled: boolean;
  facultyID: string;
  isNotes: boolean;
  notesCheckedAt: string;
}

class IndexStudent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);




    this.state = {
      internDay: 0,
      university: "",
      nursingFacultyComment: '',
      chartComment: '',
      nursingDiagnosisComment: '',
      confirm1: false,
      confirm2: false,
      updateStatus: '',
      isConfirm1Disabled: false,
      isConfirm2Disabled: false,
      facultyID: '',
      isNotes: false,
      notesCheckedAt: '',
    };

    this.goToMyZoom = this.goToMyZoom.bind(this);
    this.goToPatientInfo = this.goToPatientInfo.bind(this);
    this.goToNursePlan = this.goToNursePlan.bind(this);
    this.goToChartView = this.goToChartView.bind(this);
    this.goToSelfEvaluation = this.goToSelfEvaluation.bind(this);
    this.goToNursingDiagnois = this.goToNursingDiagnois.bind(this);
    this.questionnaireButton = this.questionnaireButton.bind(this);
    this.goToQuestionnaire = this.goToQuestionnaire.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.updateDateTime = this.updateDateTime.bind(this);
    this.confirm1 = this.confirm1.bind(this);
    this.confirm2 = this.confirm2.bind(this);
  }
  static contextType = HomePathContext;
  public goToPatientInfo() {
    this.props.history.push(
      `/all_hospital_rooms/${this.props.match.params.studentID}`
    );
  }

  public goToNursePlan() {
    this.props.history.push(`/nurse_plan/${this.props.match.params.studentID}`);
  }

  public goToChartView() {
    this.props.history.push(`/chart_view/${this.props.match.params.studentID}`);
  }

  public goToNursingDiagnois() {
    this.props.history.push(
      `/nursing_diagnosis/student/${this.props.match.params.studentID}?faculty_id=${this.state.facultyID}`
    );
  }

  public goToSelfEvaluation() {
    this.props.history.push(
      `/self_evaluation/${this.props.match.params.studentID}`);
  }

  public goToQuestionnaire() {

    this.props.history.push(
      `/questionnaire/${this.props.match.params.studentID}`
    );
  }

  public goToMyZoom() {
    this.props.history.push(
      '/students/zoom_event'
    )
  }

  public updateDateTime(e: React.MouseEvent<HTMLFormElement>) {
    e.preventDefault();
    const updateTime = {
      confirm1: this.state.confirm1,
      confirm2: this.state.confirm2,
      student_id: this.props.match.params.studentID,
    }

    const url = `${process.env.REACT_APP_API_URL}/api/student_info/update_notes_date`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(updateTime);
    fetch(url, { method, headers, body })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.status === "success") {
          this.setState({
            isConfirm1Disabled: true,
            isConfirm2Disabled: true,
            updateStatus: '',
            isNotes: true,
          });
        } else {
          this.setState({ updateStatus: result.status });
        }
      })
      .catch(console.error);
  }

  public confirm1(e: React.ChangeEvent<HTMLInputElement>) {
    const confirm1 = e.target.checked;
    console.log("🚀 ~ file: IndexStudent.tsx ~ line 146 ~ IndexStudent ~ confirm1 ~ confirm1", confirm1)
    this.setState({ confirm1: confirm1 });
  }
  public confirm2(e: React.ChangeEvent<HTMLInputElement>) {
    const confirm2 = e.target.checked;
    this.setState({ confirm2: confirm2 });
  }
  public componentDidMount() {
    let userId = this.props.match.params.studentID
    let checkLogin = checkUserId(userId, USER_TYPE.STUDENT);
    if (!checkLogin) {
      this.props.history.push('/')
      return;
    } else {
      const url = `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${this.props.match.params.studentID}`;
      fetch(url)
        .then((res) => res.json())
        .then((result) => {
          const internDay: number = Number(result["internDay"]);

          const isChecked: boolean = !!result.notes_checked_at;

          this.setState({
            internDay: internDay,
            university: result["university"],
            facultyID: result.facultyID,
            isNotes: isChecked,
            confirm1: isChecked,
            confirm2: isChecked,
            isConfirm1Disabled: isChecked,
            isConfirm2Disabled: isChecked,
          });

          // console.log('contextType', this.context)
          // if (internDay === 0) {
          if (result["university"] === '甲南女子大学') {
            this.context?.setStudentPath([{ url: `/all_hospital_rooms/${this.props.match.params.studentID}`, name: "患者情報", type: "patient_infos" },
            { url: `/nurse_plan/${this.props.match.params.studentID}`, name: "看護計画", type: "nurse_plan" },
            { url: `/chart_view/${this.props.match.params.studentID}`, name: "関連図", type: "chart_view" },
            { url: `/nursing_diagnosis/student/${this.props.match.params.studentID}?faculty_id=${this.state.facultyID}`, name: "看護診断", type: "nursing_diagnosis" },
            { url: `/questionnaire/${this.props.match.params.studentID}`, name: "アンケート", type: "questionnaire" }
            ]);
          } else {
            this.context?.setStudentPath([{ url: `/all_hospital_rooms/${this.props.match.params.studentID}`, name: "患者情報", type: "patient_infos" },
            { url: `/nurse_plan/${this.props.match.params.studentID}`, name: "看護計画", type: "nurse_plan" },
            { url: `/chart_view/${this.props.match.params.studentID}`, name: "関連図", type: "chart_view" },
            { url: `/nursing_diagnosis/student/${this.props.match.params.studentID}?faculty_id=${this.state.facultyID}`, name: "看護診断", type: "nursing_diagnosis" },
            { url: `/self_evaluation/${this.props.match.params.studentID}`, name: "自己評価", type: "self_evaluation" },
            { url: `/questionnaire/${this.props.match.params.studentID}`, name: "アンケート", type: "questionnaire" }
            ]);
          }
        })
        .catch(console.error);

      const url2 = `${process.env.REACT_APP_API_URL}/api/student_info/comment_update_date?student_id=${this.props.match.params.studentID}&table_name=nursing_plan_faculty_comment`;
      fetch(url2)
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            nursingFacultyComment: result,
          });
        })
        .catch(console.error);

      const url3 = `${process.env.REACT_APP_API_URL}/api/student_info/comment_update_date?student_id=${this.props.match.params.studentID}&table_name=chart_comment`;
      fetch(url3)
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            chartComment: result,
          });
        })
        .catch(console.error);

      const url4 = `${process.env.REACT_APP_API_URL}/api/student_info/comment_update_date?student_id=${this.props.match.params.studentID}&table_name=nursing_diagnosis_faculty_comment`;
      fetch(url4)
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            nursingDiagnosisComment: result,
          });
        })
        .catch(console.error);
    }

  }
  public questionnaireButton() {
    if (this.state.internDay === 10) {
      return (
        <Button
          className="index_student mt-5 mr-4 font-weight-bold"
          variant="outline-success"
          onClick={this.goToQuestionnaire}
        >
          実習後アンケート
        </Button>
      );
    } else {
      return <div>Button</div>;
    }
  }
  public renderButton() {
    // let isInternDay10: boolean = this.state.internDay === 0;



    // console.log("🚀 ~ file: IndexStudent.tsx ~ line 222 ~ IndexStudent ~ renderButton ~ isInternDay10", isInternDay10)
    let isUnivesity: boolean = this.state.university === "甲南女子大学";
    return (
      <Container className="mt-5 mb-5">
        <h2 className="clor-lightnavi">学生 Index</h2>
        <Row>
          <Col md={5}>
            <IndexStudentBasicInfo
              studentID={this.props.match.params.studentID}
            />
            <IndexStudentLinks userID={this.props.match.params.studentID} userType="student"/>
          </Col>
          <Col md={7}>
            <div className="row-index mt-5 mr-4 font-weight-bold">
              <div
                className="index_student-2 first"
                onClick={this.goToPatientInfo}
              >
                <Image src={img_patient_infos} alt="patient_infos" />
                <span className="title patient_infos">
                  患者情報
                </span>
              </div>
            </div>

            <div className="row-index mt-5 mr-4 ">
              <div
                className="index_student-2 second"
              >
                <div className="w-150px font-weight-bold bd-color-red"
                  onClick={this.goToNursePlan}
                >
                  <Image src={img_nurse_plan} alt="nurse_plan" />
                  <span className="title nurse_plan">
                    看護計画
                  </span>
                </div>
                <div className="w-30 clor-lightnavi">
                  コメント更新：<br />
                  {this.state.nursingFacultyComment}
                </div>
              </div>
              <div
                className="index_student-2 second"
              >
                <div className="w-150px font-weight-bold bd-color-red"
                  onClick={this.goToChartView}
                >
                  <Image src={img_chart} alt="chart" />
                  <span className="title nurse_plan">
                    関連図
                  </span>
                </div>
                <div className="w-30 clor-lightnavi">
                  コメント更新：<br />
                  {this.state.chartComment}
                </div>
              </div>
              <div
                className="index_student-2 second"
              >
                <div className="w-150px font-weight-bold bd-color-red"
                  onClick={this.goToNursingDiagnois}
                >
                  <Image src={img_nurse_diagnosis} alt="nurse_diagnosis" />
                  <span className="title nurse_plan">
                    看護診断
                  </span>
                </div>
                <div className="w-30 clor-lightnavi">
                  コメント更新：<br />
                  {this.state.nursingDiagnosisComment}
                </div>
              </div>
              <div className="clear"></div>
            </div>
            <div className="row-index mt-5 mr-4 ">
              {/* {isInternDay10 && (
                <> */}
              {!isUnivesity && (
                <div
                  className="index_student-2 third"
                >
                  <div className="w-150px font-weight-bold bd-color-green"
                    onClick={this.goToSelfEvaluation}
                  >
                    <Image src={img_self_evaluation} alt="self_evaluation" />
                    <span className="title self_evaluation">
                      自己評価
                    </span>
                  </div>
                </div>
              )}
              <div
                className="index_student-2 third"
              >
                <div className="w-150px font-weight-bold bd-color-green"
                  onClick={this.goToQuestionnaire}
                >
                  <Image src={img_questions} alt="questions" />
                  <span className="title self_evaluation">
                    実習後アンケート
                  </span>
                </div>
              </div>
              {/* </>

              )} */}
              <div className="clear"></div>
            </div>

            <div className="mt-5 ml-5">
              <span className="font-weight-bold text-danger">
                システム使用上の注意事項
              </span>
              <ol className="text-left mt-2 clor-lightnavi">
                <li>
                  本WEBカルテシステム内の患者情報は、実在した複数人の患者情報を基に、臨床指導者が臨床での経過から逸脱することのないように加工をして教材化したものです。以下の行為については禁止します。
                  <br />
                  ①システム内の患者情報を転写したり、出力したりすることを禁止します。ただし、実習での学習に必要な情報を記録に残すことは構いません。
                  <br />
                  ②学習の場以外でシステム内の患者情報を他言することは禁止します。
                  <br />
                  ③個人のID（学籍番号）とパスワードは自身で管理し、ブラウザに保存しないでください。ID・パスワードの紛失による情報漏洩の責任は負えません。
                </li>
                <li>
                  本WEBカルテシステムの使用期限は実習終了日から2週間とします。
                </li>
                <li>
                  本WEBカルテシステム内で行った教育の評価として、「学生の記録に対する指導者のコメント」「実習終了後の自己評価」「アンケート評価」を使用することがあります。
                  <br />
                  その場合も、学生さんの氏名、大学名などの個人情報を公開することが無いことをお約束いたします。本システムにおける教育の評価、改善、質向上を目的とした情報提供にご同意いただける方は以下に☑してください。
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <AutoHomePath pathname={this.props.location.pathname} />
      </Container>

    );
  }
  public render() {
    return (
      <div>
        {this.renderButton()}
        <Row>
          <Col md={5}></Col>
          <Col md={7}>
            <Form className="mt-4 index-student-form">
              <Form.Group as={Row} controlId="confirm1">
                <Col md={2}>
                  <Form.Control
                    type="checkbox"
                    disabled={this.state.isConfirm1Disabled}
                    checked={this.state.confirm1}
                    className="index-student-checkbox"
                    onChange={this.confirm1}
                  />
                </Col>
                <Form.Label column md={9} className="text-left clor-lightnavi">
                  上記の注意事項にある情報の取り扱いを遵守します。
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row} controlId="confirm2">
                <Col md={2}>
                  <Form.Control
                    type="checkbox"
                    disabled={this.state.isConfirm2Disabled}
                    checked={this.state.confirm2}
                    className="index-student-checkbox"
                    onChange={this.confirm2}
                  />
                </Col>
                <Form.Label column md={9} className="text-left clor-lightnavi">
                  ３の説明を理解し、情報提供に同意します。
                </Form.Label>
              </Form.Group>
              <Button className="mt-5 sw-index-btn" variant="primary" type="submit" block onClick={this.updateDateTime} disabled={this.state.isNotes}>
                送信
              </Button>
              {this.state.updateStatus === "confirm fail" && (
                <Alert className="mt-3" variant="danger">
                  注意事項を確認のうえ、チェックを入れて下さい
                </Alert>
              )}
            </Form>
          </Col>
        </Row>
      </div>
    )

  }
}

export default withRouter(IndexStudent);
