import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import {
  surgeryRecordData501,
  surgeryRecordData502,
  surgeryRecordData503
} from "./surgery_record_data";
import "../../../assets/styles/SurgeryRecord.scss";
import { checkUserId } from "../../../services/AuthService";
import { USER_TYPE } from "../../../services/AppLocalHelper";

interface Props
  extends RouteComponentProps<{
    patientID: string;
    studentID: string;
    facultyID: string;
    startDate: string;
  }> { }
interface State {
  recordData: {
    patient_id: string;
    recode_date: string;
    recode_type: string;
    recode_name: string;
    url: string;
  }[];
}

class SurgeryRecord extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID === "supervisor-faculty" ? this.props.match.params.facultyID : this.props.match.params.studentID;
    if (!checkUserId(userId, this.props.match.params.studentID === "supervisor-faculty" ? USER_TYPE.TEACHER : USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }

    this.state = {
      recordData: []
    };
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/surgery_record/fetch?patient_id=${this.props.match.params.patientID}&student_id=${this.props.match.params.studentID}&faculty_id=${this.props.match.params.facultyID}&start_date=${this.props.match.params.startDate}`;
    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log(result);
        this.setState({
          recordData: result
        });
      })
      .catch(console.error);
  }

  public render() {
    const surgeryRecordRows = this.state.recordData.map((item, i) => (
      <tr key={i} className="patient_501">
        <td>{item.recode_type}</td>
        <td>{item.recode_date}</td>
        <td className="d-block">
          <a href={item.url} target={"_blank"}>
            {item.recode_name}
          </a>
        </td>
      </tr>
    ));
    return (
      <Row className="mt-5 mb-5">
        <Col md={2} />
        <Col md={8} className="d-flex flex-column align-items-center">
          <h2 className="mb-4">手術記録</h2>
          <Table id="surgery_record" bordered hover>
            <thead className="thead-dark">
              <tr>
                <th>手術種別</th>
                <th>手術日</th>
                <th>手術名</th>
              </tr>
            </thead>
            <tbody>
              {surgeryRecordRows}
            </tbody>
          </Table>
        </Col>
        <Col md={2} />
      </Row>
    );
  }
}

export default SurgeryRecord;
