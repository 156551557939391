import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Alert from "@material-ui/lab/Alert";
import { convertDate } from "../../common/function";
import { LoadingSpriner } from "../../common/CircularSpinner";
import { DoctorInstructionType } from "../../models/Types";
import CSVReaderImport from "../../common/CSVReaderImport";
import Link from "@material-ui/core/Link";
import "../../../assets/styles/DoctorInstruction.scss";
import { Button } from "@material-ui/core";

interface Props extends RouteComponentProps<{}> {
  doctorInstructions: DoctorInstructionType[];
  setFormValues: Function;
  setSuccessMessage: Function;
  setDoctorInstructions: Function;
  onVisible: Function;
  onFetchData: Function;
  patientID: string;
}
interface State {
  isLoading: boolean;
  successMsg: boolean;
  error: boolean;
}

class DoctorInstructionTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      successMsg: false,
      error: false,
    };
  }

  onDelete = (doctorInstructionId: number, patientID: string) => {
    const confirmDelete = window.confirm("削除してもよろしいですか？");
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/doctor_instruction/delete`;
    const method = "DELETE";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify({
      doctorInstructionId: doctorInstructionId,
      patientID: patientID,
    });
    if (confirmDelete) {
      fetch(url, { method, mode, headers, body })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            this.props.setSuccessMessage("削除しました。");
            this.props.setDoctorInstructions(doctorInstructionId, "DELETE");
          }
        })
        .catch(console.error);
      return true;
    } else {
      return false;
    }
  };

  onUpdate = (doctorInstructionId: number) => {
    this.props.setFormValues(doctorInstructionId);
  };

  onVisible = (
    doctorInstructionId: number,
    patientID: string,
    visibleFlag: boolean
  ) => {
    this.props.onVisible(doctorInstructionId, patientID, !visibleFlag);
  };

  actionItem = (visibleFlag: boolean) => [
    {
      label: visibleFlag ? "表示" : "非表示",
      action: this.onVisible,
    },
    {
      label: "編集",
      action: this.onUpdate,
    },
    {
      label: "削除",
      action: this.onDelete,
    },
  ];

  actionHTML = (
    doctorInstructionId: number,
    patientID: string,
    visibleFlag: boolean
  ) => {
    return this.actionItem(visibleFlag).map((item) => (
      <Button
        onClick={() => item.action(doctorInstructionId, patientID, visibleFlag)}
        key={item.label}
      >
        {item.label}
      </Button>
    ));
  };

  setSuccessMsg = () => {
    this.setState({
      successMsg: true,
      isLoading: false,
    });
    setTimeout(() => {
      this.setState({
        successMsg: false,
      });
    }, 3000);
  };

  setError = () => {
    this.setState({
      error: true,
    });
    setTimeout(() => {
      this.setState({
        error: false,
        isLoading: false,
      });
    }, 3000);
  };

  onHandleCSV = (data: any, fileInfo: any) => {
    if (fileInfo.name) {
      const regex = /^.+\.(csv)$/g;
      const checkType = regex.test(fileInfo.name);
      if (checkType) {
        // this.props.setLoading(true);
        this.setState({
          isLoading: true,
        });
        const newData = {
          patient_id: this.props.patientID,
          doctorInstructions: data,
        };
        this.fetchAPI(newData);
      } else {
        this.setError();
      }
    }
  };

  fetchAPI = (data: any) => {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/doctor_instruction/create_or_update?mode=csv`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          this.props.onFetchData();
          this.setSuccessMsg();
        } else {
          this.setError();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setError();
      });
  };

  public render() {
    return (
      <Row className="mt-5">
        <Col md={12}>{this.state.isLoading && <LoadingSpriner />}</Col>
        <Col md={9} style={{ margin: "auto" }}>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <CSVReaderImport
              handleForce={this.onHandleCSV}
              className="react-csv-input"
            />
            <Link
              href={`${process.env.REACT_APP_API_URL}/api/patient_info/csv-sample-download?fileName=[サンプル]医者の指示入力.csv`}
              style={{
                cursor: "pointer",
                color: "#007bff",
                textDecoration: "none",
                marginLeft: "20px",
                marginTop: "10px",
              }}
              color="primary"
            >
              CSVテンプレート
            </Link>
          </div>
          <Col md={12} className="mt-3 mb-3">
            {this.state.successMsg && (
              <Alert className="mt-3 sw-custom-btn">
                データの登録が完了しました。
              </Alert>
            )}
            {this.state.error && (
              <Alert severity="error" className="mt-3 mb-3 sw-custom-btn">
                データの登録に失敗しました。
              </Alert>
            )}
          </Col>
          {this.props.doctorInstructions.length > 0 && (
          <Table responsive bordered hover size="sm">
            <thead className="thead-dark">
              <tr>
                <th id="date">指示開始日</th>
                <th id="instruction">指示</th>
                <th id="detail">詳細内容</th>
              </tr>
            </thead>
            <tbody>
              {this.props.doctorInstructions.map((item, i) => {
                return (
                  <tr
                    key={i}
                    style={{
                      backgroundColor: item.visibleFlag
                        ? "darkgray"
                        : "initial",
                    }}
                  >
                    <td id="date" className="text-nowrap">
                      {convertDate(parseInt(item.date_converted))}
                    </td>
                    <td id="instruction" className="text-nowrap">
                      {item.instruction === "&emsp;" ? "" : item.instruction}
                    </td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "350px" }}>
                        <pre className="text-left p-2">{item.detail}</pre>
                      </div>
                      <StyledAction className="float-right">
                        {this.actionHTML(
                          item.doctorInstructionId as number,
                          item.patientID as string,
                          item.visibleFlag as boolean
                        )}
                      </StyledAction>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          )}
     </Col>
      </Row>
    );
  }
}

const StyledAction = styled.div`
  button {
    font-size: 1em;
    text-align: left;
    color: blue;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
  }

  button:hover,
  button:focus {
    color: blue;
    background: none;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background: none;
    color: blue;
  }
`;

export default withRouter(DoctorInstructionTable);
