import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { TestDataItemType } from "../models/Types";
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
      display: "flex",
      overflow: "auto",
    },
    cardHeader: {},
    list: {
      width: 200,
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: "#fff",
      "&:hover": {
        background: "#fff",
      },
    },
    p: {
      color: "#fff",
    },
  })
);

function not(a: TestDataItemType[], b: TestDataItemType[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: TestDataItemType[], b: TestDataItemType[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: TestDataItemType[], b: TestDataItemType[]) {
  return [...a, ...not(b, a)];
}

export default function TransferList({
  testDataItems,
  selectValue,
  itemDisable,
  itemDisplay,
  setItemDisable,
  setItemDisplay,
}: {
  testDataItems: TestDataItemType[];
  itemDisable: TestDataItemType[];
  itemDisplay: TestDataItemType[];
  selectValue: any;
  setItemDisable: Function;
  setItemDisplay: Function;
}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState<TestDataItemType[]>([]);
  const [left, setLeft] = React.useState<TestDataItemType[]>(itemDisplay);
  const [right, setRight] = React.useState<TestDataItemType[]>(itemDisable);

  // React.useEffect(() => {
  //   if (testDataItems.length > 0) {
  //     const itemTest = testDataItems.filter((test) => {
  //       let matches = right.filter(obj => obj.id === test.id);
  //       return Number(test.parent_id) === Number(selectValue) && matches.length < 1;
  //     });
  //     setLeft(itemTest);
  //   }
  // }, [selectValue]);
  React.useEffect(() => {
    setLeft(itemDisplay);
  }, [itemDisplay]);
  React.useEffect(() => {
    setRight(itemDisable);
  }, [itemDisable]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: TestDataItemType) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: TestDataItemType[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: TestDataItemType[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setItemDisable(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setItemDisplay(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setItemDisplay(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setItemDisable(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: React.ReactNode, items: TestDataItemType[]) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} 選択`}
      />
      <Divider />
      <List dense component="div" role="list">
        {items.map((value: any, index: any) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={index}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <StyledGrid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <p className={classes.p}>表示する項目</p>
        {customList("選択肢", left)}
        <p></p>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <ChevronRightIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
          >
            <ChevronLeftIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <p className={classes.p}>非表示の項目</p>
        {customList("選択肢", right)}
        <span className={classes.p}>入力された、データは削除されません。</span>
      </Grid>
    </StyledGrid>
  );
}

const StyledGrid = styled(Grid)`
  .MuiCard-root {
    width: 300px;
    height: 400px;
  }
`;
