import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginBox from "../login/LoginBox";
import SignUpBox from "../login/SignUpBox";

interface Props {}
interface State {}

class TopPage extends React.Component<Props, State> {
  public render() {
    return (
      <Container fluid>
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
            <LoginBox />
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
    );
  }
}

export default TopPage;
