import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import "../../assets/styles/AllNursingPlansView.scss";
import { checkUserId } from "../../services/AuthService";
import { USER_TYPE } from "../../services/AppLocalHelper";

interface Props extends RouteComponentProps<{ facultyID: string }> { }
interface State {
  nursingPlans: {
    studentID: string;
    studentName: string;
    date: string;
    createdAt: string;
    updatedAt: string;
    updatedAtByFaculty: string;
  }[];
  data: any
}

class AllNursingPlansView extends React.Component<Props, State> {

  private student_id: string | null;

  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.facultyID
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }

    const { location } = this.props;
    this.state = {
      nursingPlans: [],
      data: location.state
    };

    this.goToNursingPlanForFaculty = this.goToNursingPlanForFaculty.bind(this);
    const query = new URLSearchParams(this.props.location.search);
    this.student_id = query.get('student_id');

  }

  public goToNursingPlanForFaculty(studentID: string, date: string) {
    if (date !== undefined) {
      // console.log(studentID);
      // console.log(date);
      this.props.history.push(
        `/faculty/nursing_plan/${this.props.match.params.facultyID}/${studentID.trim()}/${date}`
      );
    }
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/student_info/all_nursing_plan_lists?student_id=${this.student_id}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          nursingPlans: result,
        });
      })
      .catch(console.error);
  }

  public render() {
    const nursingPlanRows = this.state.nursingPlans.map((item, i) => (
      <tr
        key={i}
        onClick={this.goToNursingPlanForFaculty.bind(
          this,
          item.studentID,
          item.date
        )}
      >
        <td>{item.studentID}</td>
        <td>{item.studentName}</td>
        <td>{item.createdAt}</td>
        <td>{item.updatedAt}</td>
        <td>{item.updatedAtByFaculty}</td>
      </tr>
    ));

    return (
      <Container>
        <Row className="m-5 d-flex flex-column align-items-center">
          <h2 className="text-center">看護計画一覧</h2>
          <Table className="m-5" bordered hover>
            <thead className="thead-dark">
              <tr>
                <th>学籍番号</th>
                <th>学生氏名</th>
                <th>学生作成日</th>
                <th>学生更新日</th>
                <th>教員更新日</th>
              </tr>
            </thead>
            <tbody>{nursingPlanRows}</tbody>
          </Table>
        </Row>
      </Container>

    );
  }
}

export default withRouter(AllNursingPlansView);
