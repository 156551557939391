import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { ActionPlanRowForFacultyType } from "../../models/Types";
import "../../../assets/styles/SWCustom.scss";


interface SelectStartHourProps {
  startHour: number | undefined;
}
interface SelectStartMinuteProps {
  startMinute: number | undefined;
}
interface SelectEndHourProps {
  endHour: number | undefined;
}
interface SelectEndMinuteProps {
  endMinute: number | undefined;
}

interface Props extends ActionPlanRowForFacultyType {}

interface State {}

export default class ActionPlanRow extends React.Component<Props, State> {
  public render() {
    console.log(this.props.startMinute);
    console.log(this.props.endMinute);
    const startHour =
      this.props.startHour === null ? "7" : this.props.startHour;
    let startMinute =
      this.props.startMinute === null ? "00" : this.props.startMinute;
    startMinute = startMinute === 0 ? "00" : startMinute;
    const endHour = this.props.endHour === null ? "7" : this.props.endHour;
    let endMinute = this.props.endMinute === null ? "00" : this.props.endMinute;
    endMinute = endMinute === 0 ? "00" : endMinute;

    return (
      <Row>

        <Col md={7}>  
          <span className="colCustom">{`${startHour} : ${startMinute}`} 〜 {`${endHour} : ${endMinute}`}</span> <span>{this.props.plan}</span>
        </Col>
      </Row>
    );
  }
}


