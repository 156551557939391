import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IndexStudentBasicInfo from "../IndexStudentBasicInfo";
import SelfEvaluationTable from "./SelfEvaluationTable";
import "../../../assets/styles/SelfEvaluation.scss";
import { checkUserId } from "../../../services/AuthService";
import { USER_TYPE } from "../../../services/AppLocalHelper";

interface Props extends RouteComponentProps<{ studentID: string }> { }
interface State { }

class SelfEvaluationView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID
    if (!checkUserId(userId, USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }
  }

  public componentDidMount() { }

  public render() {
    return (
      <Container>
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <h2>自己評価表</h2>
            <IndexStudentBasicInfo
              studentID={this.props.match.params.studentID}
            />
            <SelfEvaluationTable
              studentID={this.props.match.params.studentID}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SelfEvaluationView);
