import React from "react";
import { cloneDeep as _cloneDeep, isEmpty as _isEmpty } from "lodash";
import Form from "react-bootstrap/Form";
import useDebounce from "../../common/useDebounce";
import styled from "styled-components";

type TestDataItemRecordType = {
  date: string;
  date_time_text: string;
  date_time: string,
  results: {
    id: number;
    display_order: number;
    item_id: number;
    parent_id: number;
    result: string;
  }[];
};

interface Props {
  testData: TestDataItemRecordType;
  itemId: number;
  date: any;
  itemOrder: any;
  formValues: any;
  newDataUpdate: any;
  parentId: any,
  itemName: any,
  patientID: any,
  callback: Function,
  hasParent: boolean
}
interface State {
  formValues: any;
  formValuesDirty: any;
}

const TestDataRecord: React.FunctionComponent<Props> = ({
  testData,
  itemId,
  date,
  itemOrder,
  formValues,
  newDataUpdate,
  parentId,
  itemName,
  patientID,
  callback,
  hasParent
}) => {
  let [message, setMessage] = React.useState('')
  const [formValueRecord, setFormValueRecord] = React.useState(formValues);
  const [formValueRecordDirty, setFormValueRecordDirty] = React.useState(
    newDataUpdate
  );
  const debouncedSearchTerm = useDebounce(formValueRecord, 3000);

  // Here's where the API call happens
  // We use useEffect since this is an asynchronous action
  React.useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      if (debouncedSearchTerm && !_isEmpty(formValueRecordDirty)) {
        const newData = {};

        // const url = `${process.env.REACT_APP_API_URL}/api/settings/overview_data/create_or_update`;
        // const mode = "cors";
        // const headers = {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // };
        // const body = JSON.stringify(newData);
        // fetch(url, { method: "POST", mode, headers, body })
        //   .then((res) => res.json())
        //   .then((result) => {
        //     if (result.success) {
        //     }
        //   })
        //   .catch(console.error);
      }
    },
    // This is the useEffect input array
    // Our useEffect function will only execute if this value changes ...
    // ... and thanks to our hook it will only change if the original ...
    // value (searchTerm) hasn't changed for more than 500ms.
    [debouncedSearchTerm]
  );


  const onChange = (value: any, id: any) => {
    const cloneFormValues = _cloneDeep(formValueRecord);
    const cloneFormValuesDirty = _cloneDeep(formValueRecordDirty);

    cloneFormValues[id] = {
      date: date,
      item_id: itemId,
      result: value,
    };

    cloneFormValuesDirty[id] = {
      date: date,
      item_id: itemId,
      result: value,
    };

    setFormValueRecord(cloneFormValues);
  };

  let unForcus = (e: any) => {
    console.log('e:', e)
    let datetime = testData.date_time_text
    console.log('datetime:', datetime)
    console.log('e.target.value:', e.target.value)
    if (testData.date_time) {
      let data = [
        {
          result: e.target.value,
          item_name: itemName,
          patient_id: patientID,
          date_time: testData.date_time,
          id: itemInfo?.id
        }
      ]
      console.log('data:', datetime)
      fetchAPI(data)
      callback(message)
    }
  }

  let fetchAPI = (data: any) => {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/test_data_records/create?mode=edit`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          // this.setSuccessMsg();
          setMessage(result.success)
        } else {
          // this.setError();
        }
      })
      .catch((error) => {
        console.log(error);
        setMessage(error?.message)
      });
  };

  const itemInfo = testData.results.find((r) => {
    return r.item_id === itemId;
  });

  let value = "";
  if (itemInfo) {
    const id = itemInfo?.id;
    value = formValueRecord[id]?.result;
  }

  return (
    <span>
      {!hasParent && (
        itemInfo != null && itemInfo.id > 0
          ? (
            <Input
              // id={itemInfo.parent_id}
              type="text"
              value={value}
              onBlur={!hasParent && unForcus}
              onChange={(e: any) => onChange(e.target.value, itemInfo?.id)}
            />
          )
          : (
            <Input
              type="text"
              onBlur={unForcus}
              onChange={(e: any) => onChange(e.target.value, itemId)}
            />
          )
      )}
    </span>
    
  );
};

const Input = styled(Form.Control)``;

export default TestDataRecord;
