import React, { createContext, useContext, useReducer } from 'react';

interface IContextProps {
  setHomePath: any;
  data: any
}

const HomePathContext = createContext({} as IContextProps);
const SET_HOME_PATH = 'SET_HOME_PATH';
const SET_STUDENT_PATH = 'SET_STUDENT_PATH';
const REMOVE_STUDENT_PATH = 'REMOVE_STUDENT_PATH';

export function HomePathContextProvider(props: any) {

  const studentLocal = React.useMemo(
    () => localStorage.getItem("student_path"),
    [],
  );

  const studentPathData = React.useMemo(
    () => !!studentLocal ? JSON.parse(studentLocal) : null,
    [],
  );



  const [state, dispatch] = useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case SET_HOME_PATH:
          return {
            ...prevState,
            path: action.path,
          };
        case SET_STUDENT_PATH:
          return {
            ...prevState,
            isStudent: true,
            student_path: action.student_path
          };
        case REMOVE_STUDENT_PATH:
          return {
            ...prevState,
            isStudent: false,
            student_path: []
          };
      }
    },
    {
      path: localStorage.getItem("homepath") || "/",
      isStudent: studentPathData?.isStudent ?? false,
      student_path: studentPathData?.student_path ?? [],
    },
  );

  const pathContext = React.useMemo(
    () => ({
      setHomePath: (path: string) => {
        dispatch({ type: SET_HOME_PATH, path });
      },

      setStudentPath: (student_path: string) => {
        dispatch({ type: SET_STUDENT_PATH, student_path });
        localStorage.setItem("student_path", JSON.stringify({ isStudent: true, student_path }))
      },

      removeStudentPath: () => {
        localStorage.removeItem("student_path");
        dispatch({ type: REMOVE_STUDENT_PATH });
      },

    }),
    [],
  );

  return (
    <HomePathContext.Provider
      value={{
        ...pathContext,
        data: state,
      }
      }
    >
      { props.children}
    </ HomePathContext.Provider >
  );
}

export default HomePathContext;

export const useHomePath = () => {
  return useContext(HomePathContext);
};
