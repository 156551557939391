import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { QuestionnaireResult } from "../../models/Types";
import "../../../assets/styles/QuestionnaireResult.scss";
import { checkUserId } from "../../../services/AuthService";
import { USER_TYPE } from "../../../services/AppLocalHelper";

interface Props
  extends RouteComponentProps<{
    university: string;
    startDate: string;
    endDate: string;
    facultyID: string;
  }> { }
interface State {
  facultyID: string;
  baseURL: string;
  questionnaireResult: QuestionnaireResult;
}

class QuestionnaireResultView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.facultyID
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }
    
    this.state = {
      facultyID: this.props.match.params.facultyID,
      baseURL: '',
      questionnaireResult: {
        q1: [],
        q2_1: {
          option1: 0,
          option2: 0,
          option3: 0,
          option4: 0,
          option5: 0,
        },
        q2_2: {
          option1: 0,
          option2: 0,
          option3: 0,
          option4: 0,
          option5: 0,
        },
        q2_3: {
          option1: 0,
          option2: 0,
          option3: 0,
          option4: 0,
          option5: 0,
        },
        q2_4: {
          option1: 0,
          option2: 0,
          option3: 0,
          option4: 0,
          option5: 0,
        },
        q2_5: {
          option1: 0,
          option2: 0,
          option3: 0,
          option4: 0,
          option5: 0,
        },
        q2_6: {
          option1: 0,
          option2: 0,
          option3: 0,
          option4: 0,
          option5: 0,
        },
        q2_7: {
          option1: 0,
          option2: 0,
          option3: 0,
          option4: 0,
          option5: 0,
        },
        q3: [],
        all_num: 0,
      },
    };

  }

  public componentDidMount() {
    const url3 = `${process.env.REACT_APP_API_URL}/api/questionnaire/result?faculty_id=${this.props.match.params.facultyID}&university=${this.props.match.params.university}&start_date=${this.props.match.params.startDate}&end_date=${this.props.match.params.endDate}&show=false`;
    fetch(url3)
      .then((res) => res.json())
      .then((result) => {

      })
      .catch(console.error);
      
    const url = `${process.env.REACT_APP_API_URL}/api/questionnaire/result?faculty_id=${this.props.match.params.facultyID}&university=${this.props.match.params.university}&start_date=${this.props.match.params.startDate}&end_date=${this.props.match.params.endDate}&show=true`;

    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          questionnaireResult: result,
        });
      })
      .catch(console.error);

    const url2 = `${process.env.REACT_APP_API_URL}/api/questionnaire/get_link`;
    fetch(url2)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          baseURL: result,
        });
      })
      .catch(console.error);


    //   const url3 = `${process.env.REACT_APP_API_URL}/api/questionnaire/result?faculty_id=${this.props.match.params.facultyID}&university=${this.props.match.params.university}&start_date=${this.props.match.params.startDate}&end_date=${this.props.match.params.endDate}&show=false`;
    // fetch(url3)
    //   .then((res) => res.json())
    //   .then((result) => {
       
    //   })
    //   .catch(console.error);
  }

  public render() {
    console.log(this.state.baseURL);

    const answerQ1Row = this.state.questionnaireResult["q1"].map((item, i) => (
      <tr key={i} className="text-left">
        <td className="text-center">{i + 1}</td>
        <td>{item}</td>
      </tr>
    ));

    const answerQ3Row = this.state.questionnaireResult["q3"].map((item, i) => (
      <tr key={i} className="text-left">
        <td className="text-center">{i + 1}</td>
        <td>{item}</td>
      </tr>
    ));

    return (
      <Row>
        <Col md={2} />
        <Col md={8}>
          <h2 className="mt-5 mb-4">実習アンケート結果</h2>
          <div className="txt-left">
            <a
              className="mr-4 font-weight-bold btn-print"
              href={`${this.state.baseURL}`}
            >
              Excel出力
            </a>
          </div>

          <div>
            <h5 className="mb-3">
              1.
              自習目標達成に向けての指導者の関わりで役に立ったことは何ですか？
            </h5>
            <Table className="questionnaire_result_text" bordered>
              <thead className="thead-dark">
                <tr>
                  <th className="number">No. </th>
                  <th>回答</th>
                </tr>
              </thead>
              <tbody>{answerQ1Row}</tbody>
            </Table>
          </div>
          <Table id="questionnaire_table" className="mt-5 mb-5" bordered>
            <thead className="thead-dark">
              <tr>
                <th>&emsp;</th>
                <th className="option align-middle">そう思う</th>
                <th className="option">
                  やや
                  <br />
                  そう思う
                </th>
                <th className="option">
                  どちらとも
                  <br />
                  いえない
                </th>
                <th className="option">
                  あまり
                  <br />
                  思わない
                </th>
                <th className="option">
                  そう
                  <br />
                  思わない
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left">
                  1)
                  グループカンファレンスや計画の発表に対し、建設的な姿勢で適切な助言をくれますか？
                </td>
                <td>
                  {this.state.questionnaireResult["q2_1"]["option1"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_1"]["option2"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_1"]["option3"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_1"]["option4"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_1"]["option5"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  2)
                  看護専門職として責任を学生が理解するように働きかけてくれていますか？
                </td>
                <td>
                  {this.state.questionnaireResult["q2_2"]["option1"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_2"]["option2"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_2"]["option3"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_2"]["option4"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_2"]["option5"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  3)
                  学生が緊張しているときには、リラックスさせるようにしてくれていますか？
                </td>
                <td>
                  {this.state.questionnaireResult["q2_3"]["option1"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_3"]["option2"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_3"]["option3"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_3"]["option4"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_3"]["option5"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  4) 学生が”看護は興味深い" と思えるような助言をしてくれますか？
                </td>
                <td>
                  {this.state.questionnaireResult["q2_4"]["option1"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_4"]["option2"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_4"]["option3"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_4"]["option4"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_4"]["option5"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  5)
                  理論的内容や、既習の知識・技術などを実際に臨床の場で適用してみるように働きかけてくれていますか？
                </td>
                <td>
                  {this.state.questionnaireResult["q2_5"]["option1"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_5"]["option2"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_5"]["option3"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_5"]["option4"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_5"]["option5"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  6) 記録物の内容について適切なアドバイスをしてくれていますか？
                </td>
                <td>
                  {this.state.questionnaireResult["q2_6"]["option1"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_6"]["option2"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_6"]["option3"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_6"]["option4"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_6"]["option5"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  7)
                  実習の展開経過において，適切なアドバイスをしてくれていますか？
                </td>
                <td>
                  {this.state.questionnaireResult["q2_7"]["option1"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_7"]["option2"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_7"]["option3"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_7"]["option4"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
                <td>
                  {this.state.questionnaireResult["q2_7"]["option5"]}/
                  {this.state.questionnaireResult["all_num"]}
                </td>
              </tr>
            </tbody>
          </Table>
          <div>
            <h5>
              3. オンライン実習に関して改善点や感想など自由に記載してください。
            </h5>
            <Table className="questionnaire_result_text" bordered>
              <thead className="thead-dark">
                <tr>
                  <th className="number">No. </th>
                  <th>回答</th>
                </tr>
              </thead>
              <tbody>{answerQ3Row}</tbody>
            </Table>
          </div>
        </Col>
        <Col md={2} />
      </Row>
    );
  }
}

export default withRouter(QuestionnaireResultView);
