import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

interface Props {
  activity: string | undefined;
  // result: string | undefined;
  evaluation: string | undefined;
  onNursingActivityChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onResultChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEvaluationChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;

  onClickUp: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCLickDown: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isShowUpButton: boolean;
  isShowDownButton: boolean;

}
interface State { }

class NursingActivityRow extends React.Component<Props, State> {
  public render() {
    return (
      <div className="mb-4 d-flex">
        <Col md={5}>
          <Form.Control
            className=""
            as="textarea"
            rows={10}
            defaultValue={this.props.activity}
            onChange={this.props.onNursingActivityChanged}
          />
        </Col>
        {/* <Col>
          <Form.Control
            className=""
            as="textarea"
            rows={10}
            defaultValue={this.props.result}
            onChange={this.props.onResultChanged}
          />
        </Col> */}
        <Col md={5}>
          <Form.Control
            className=""
            as="textarea"
            rows={10}
            defaultValue={this.props.evaluation}
            onChange={this.props.onEvaluationChanged}
          />
        </Col>
        <Col md={2}>
          {this.props.isShowUpButton && (<Button
            className="btn-up"
            variant="secondary"
            size="lg"
            type="submit"
            onClick={this.props.onClickUp}
          >
            <i className="far fa-arrow-alt-circle-up"></i>
          </Button>)}


          {this.props.isShowDownButton && (<Button
            className="btn-up"
            variant="secondary"
            size="lg"
            type="submit"
            onClick={this.props.onCLickDown}
          >
            <i className="far fa-arrow-alt-circle-down"></i>
          </Button>)}
        </Col>

      </div>
    );
  }
}

export default NursingActivityRow;
