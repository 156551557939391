import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withRouter, RouteComponentProps } from "react-router-dom";
import IndexStudentBasicInfo from "../IndexStudentBasicInfo";
import SelfEvaluationTable from "./SelfEvaluationTable";
import SelfEvaluationViewKansaiKokusai from "./kansai_kokusai/SelfEvaluationViewKansaiKokusai";
import SelfEvaluationViewKobeJoshiForFaculty from "./kobe_joshi/SelfEvaluationViewKobeJoshiForFaculty";
import "../../../assets/styles/SelfEvaluation.scss";
import { getUserId, USER_TYPE } from "../../../services/AppLocalHelper";
import { checkUserId } from "../../../services/AuthService";

interface Props
extends RouteComponentProps<{ university: string; startDate: string; endDate: string; facultyID: string }> {}
interface State {
  university: string;
  startDate: string;
}

class SelfEvaluationView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = getUserId()
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }

    this.state = {
      university: "",
      startDate: "",
    };
  }

  public componentDidMount() {
    // const url = `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${this.props.match.params.studentID}`;
    // fetch(url)
    //   .then((res) => res.json())
    //   .then((result) => {
    //     this.setState({
    //       university: result.university,
    //       startDate: result.startDate,
    //     });
    //   })
    //   .catch(console.error);
  }

  public render() {

    return (
      <Container>
        {/* <SelfEvaluationTable studentID={this.props.match.params.studentID} /> */}
        <SelfEvaluationTable university={this.props.match.params.university} 
        startDate={this.props.match.params.startDate}
        endDate={this.props.match.params.endDate}
        facultyID={this.props.match.params.facultyID}
        />
      </Container>
     
    );
  }
}

export default withRouter(SelfEvaluationView);
