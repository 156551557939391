import React from "react";
import CSVReader from "react-csv-reader";
import styled from "styled-components";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

const CSVReaderImport = ({ className, handleForce }) => {
  return (
    <StyledCSVReader
      cssClass={className}
      label="CSV 取込"
      onFileLoaded={handleForce}
      parserOptions={papaparseOptions}
    />
  );
};

const StyledCSVReader = styled(CSVReader)`
  input[type="file"] {
    display: none;
  }
  .csv-input {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
  }
`;

export default CSVReaderImport;
