import infos from "../../assets/images/info.png";
import calendar from "../../assets/images/calendar.png";
import tree from "../../assets/images/tree.png";
import book from "../../assets/images/book.png";
import check from "../../assets/images/check.png";
import pencil from "../../assets/images/pencil.png";
import CoLabSVG from '../../assets/images/co_lab_note.svg'
import React, { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import "../../assets/styles/Header.scss";
import { useHomePath } from './../../helpers/HomePathContext';
import { getUserId, getUserType, USER_TYPE } from "../../services/AppLocalHelper";

const IconByType = (props: any) => {
    switch (props.type) {
        case 'patient_infos':
            return (<img src={infos} width="25" height="25" className="d-inline-block align-top m-50" alt="React" />);
        case 'nurse_plan':
            return (<img src={calendar} width="25" height="25" className="d-inline-block align-top m-50" alt="React" />);
        case 'chart_view':
            return (<img src={tree} width="25" height="25" className="d-inline-block align-top m-50" alt="React" />);
        case 'nursing_diagnosis':
            return (<img src={book} width="25" height="25" className="d-inline-block align-top m-50" alt="React" />);
        case 'self_evaluation':
            return (<img src={check} width="25" height="25" className="d-inline-block align-top m-50" alt="React" />);
        case 'questionnaire':
            return (<img src={pencil} width="25" height="25" className="d-inline-block align-top m-50" alt="React" />);
        default:
            return null;
    }


}

const HomeNav = () => {
    const { data } = useHomePath()

    if (getUserId() && getUserType() == USER_TYPE.STUDENT) {
        return (
            <Nav className="mr-auto">
                {data.student_path.map((item: any) => (
                    <Nav.Link key={item.type} href={item.url}>
                        {/* <HomeRoundedIcon fontSize="large" /> */}
                        <IconByType type={item.type} />
                        <br />
                        <span className="align-middle">{item.name}</span>
                    </Nav.Link>
                ))}
                <a href="https://www.co-labnote.net/toyota/"> <img src={CoLabSVG} alt="" height="70" /></a>
            </Nav>
        );
    }
    return null;
}

export default HomeNav;

