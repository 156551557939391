import React, { useState, useEffect, useReducer } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { cloneDeep as _cloneDeep } from "lodash";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import styled from "styled-components";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Spinner from "react-bootstrap/Spinner";
import "../../../assets/styles/SWCustom.scss";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import { LinkInfoType } from "../../models/Types";
import { getUserId, USER_TYPE } from "../../../services/AppLocalHelper";
import { checkUserId } from "../../../services/AuthService";
import UniversitySelect from './UniversitySelect';
const SUBMIT_TYPE = {
    DEFINER: "DEFINER",
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    DELETE: "DELETE"
};

const ManagerLinkInfo: React.FunctionComponent = () => {
    const [overviewDataGroupParents, setOverviewDataGroupParents] = useState<
        LinkInfoType[]
    >([]);

    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [faculty, setFaculty] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState("");
    const [isErrors, setErrors] = useState(false);
    const [vector, setVector] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);


    const [inputUniversity, setInputUniversity] = useState<{

        isSupervisor: boolean,
        affiliation: string,
        affiliationCurrent: string,
        facultyID: string
    }>({
        isSupervisor: false,
        affiliation: "",
        affiliationCurrent: "",
        facultyID: ""

    });
    const history2 = useHistory();
    useEffect(() => {
        let userId = getUserId();
        if (!checkUserId(userId, USER_TYPE.TEACHER)) {
            history2.push('/')
        }
        setFaculty(userId);
        const urlFaculty = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${userId}`;
        fetch(urlFaculty)
            .then((res) => res.json())
            .then((result) => {
                setInputUniversity({
                    facultyID: result.faculty_id,
                    affiliation: result.affiliation_id,
                    affiliationCurrent: result.affiliation_id,
                    isSupervisor: result.is_supervisor,
                });
                getData(result.is_supervisor, result.affiliation_id, result.affiliation_id);
            })
            .catch(console.error);
    }, [isLoading]);





    const handleChangeValue = (
        item: LinkInfoType,
        value: string,
        index: number,
        type: number
    ) => {
        if (type == 1) {
            overviewDataGroupParents[index].link_text = value;
        } else {
            overviewDataGroupParents[index].link_url = value;
        }
        forceUpdate();
    };

    const swapArrayLocs = (arr: any, index1: number, index2: number) => {
        [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
    };

    const onMouseClickUp = (index: number, data: any) => {
        let overviewDataGroupTemp = _cloneDeep(data);

        overviewDataGroupTemp[index].display_order = data[index - 1].display_order;
        overviewDataGroupTemp[index - 1].display_order = data[index].display_order;
        swapArrayLocs(overviewDataGroupTemp, index - 1, index);
        setOverviewDataGroupParents(overviewDataGroupTemp);

    };

    const onMouseClickDown = (index: number, data: any) => {
        let overviewDataGroupTemp = _cloneDeep(data);
        overviewDataGroupTemp[index].display_order = data[index + 1].display_order;
        overviewDataGroupTemp[index + 1].display_order = data[index].display_order;
        swapArrayLocs(overviewDataGroupTemp, index + 1, index);
        console.log(overviewDataGroupTemp);
        setOverviewDataGroupParents(overviewDataGroupTemp);
    };

    const history = useHistory();


    const vectorCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVector(e.target.checked);
    };
    const onUniversityChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const affiliation: string = e.target.value;
        console.log('affiliation:', affiliation);
        setInputUniversity({ affiliation: affiliation, affiliationCurrent: inputUniversity.affiliationCurrent, facultyID: inputUniversity.facultyID, isSupervisor: inputUniversity.isSupervisor });
        getData(inputUniversity.isSupervisor,affiliation, inputUniversity.affiliationCurrent);
    };
    const onCreate = () => {
        setIsSubmit(SUBMIT_TYPE.CREATE);
        const newData = {
            affiliation: inputUniversity.affiliation,
            links: overviewDataGroupParents,
            faculty: faculty
        };
        console.log('onCreate: ', newData);

        const url = `${process.env.REACT_APP_API_URL}/api/link/createOrUpdate`;
        fetchAPI(newData, url, "POST", '変更しました。');
    };
    const addNew = () => {
        console.log('addNew = overviewDataGroupParents:', overviewDataGroupParents);
        if (overviewDataGroupParents.length > 0) {
            const lastItem = overviewDataGroupParents[overviewDataGroupParents.length - 1];
            const display_order_add = lastItem.display_order + 1;
            overviewDataGroupParents.push({ id: 0, display_order: display_order_add, link_text: '', link_url: '' });
        } else {
            overviewDataGroupParents.push({ id: 0, display_order: 0, link_text: '', link_url: '' });
        }
        console.log('overviewDataGroupParents New:', overviewDataGroupParents);
        setOverviewDataGroupParents(overviewDataGroupParents);
        forceUpdate();
    };
    const removeItem = () => {
        console.log('removeItem = overviewDataGroupParents:', overviewDataGroupParents);
        if (overviewDataGroupParents.length > 0) {
            overviewDataGroupParents.pop();
            setOverviewDataGroupParents(overviewDataGroupParents);
            forceUpdate();
        }
    };
    const showError = () => {
        setErrors(true);
        setTimeout(() => {
            setErrors(false);
        }, 3000);
    };
    const getData = (isSupervisor: boolean, affiliationValue: string, affiliationCurrent: string) => {
        console.log('inputUniversity: ', inputUniversity);

        let affiliation = isSupervisor ? affiliationValue : affiliationCurrent;
        const url = `${process.env.REACT_APP_API_URL}/api/link/list?affiliation=${affiliation}`;
        fetch(url, { mode: "cors" })
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result) {
                        result.sort((a: any, b: any) => {
                            return a.order - b.order;
                        });
                        setOverviewDataGroupParents(result);

                    }
                },
                (error) => {
                    console.log(error, "Error: loadTestDataTable");
                    showError();
                }
            );
    }
    const fetchAPI = (data: any, url: string, method: string, message: string = '') => {
        const mode = "cors";
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        const body = JSON.stringify(data);

        fetch(url, { method, mode, headers, body })
            .then((res) => res.json())
            .then((res) => {
                if (res.result.code == 200) {
                    setIsSuccess(true);
                    setSuccessMessage(message);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                    // setIsLoading(!isLoading);
                } else {
                    showError();
                }
                setIsSubmit("");
            })
            .catch(() => {
                showError();
                setIsSubmit("");
            });
    };



    return (
        <Container className="mt-5 mb-5 test-data-item-child">
            <h2>リンク設定</h2>
            <Breadcrumbs aria-label="breadcrumb">
                <Col md={12}>
                    <UniversitySelect value={inputUniversity} onItemSelect={onUniversityChanged} />
                </Col>
            </Breadcrumbs>
            <StyledOverviewDataGroup>
                <Table className="linkInfoTable">
                    <thead>
                        <tr>
                            <td className="text-left col-url-name" >
                                <strong>表示テキスト</strong>
                            </td>
                            <td className="text-left">
                                <strong>URL</strong>
                            </td>
                            <td></td>
                        </tr>
                    </thead>
                </Table>
                {overviewDataGroupParents.length > 0 &&
                    overviewDataGroupParents.map((item, i) => (
                        <StyledOverviewGroup key={i}>
                            <Table>
                                <tbody className="ManagerTestDataItemChild">
                                    <tr key={item.display_order}>
                                        <td className="link_text">
                                            <Form.Control
                                                className="input-link_text"
                                                type="text"
                                                placeholder="表示テキスト"
                                                value={item.link_text || ""}
                                                onChange={(e) =>
                                                    handleChangeValue(
                                                        item,
                                                        e.target.value,
                                                        i,
                                                        1
                                                    )
                                                }
                                            />
                                        </td>
                                        <td className="link_url">
                                            <Form.Control
                                                className="input-link_url"
                                                type="text"
                                                placeholder="URL"
                                                value={item.link_url || ""}
                                                onChange={(e) =>
                                                    handleChangeValue(
                                                        item,
                                                        e.target.value,
                                                        i,
                                                        2
                                                    )
                                                }
                                            />
                                        </td>
                                        <td
                                            className="w-100px btn-move"
                                            style={{ backgroundColor: "#ffffff", border: "none" }}
                                        >
                                            {i !== 0 && (
                                                <Button
                                                    className="button-up"
                                                    variant="secondary"
                                                    size="lg"
                                                    onClick={() => onMouseClickUp(i, overviewDataGroupParents)}
                                                >
                                                    <i className="far fa-arrow-alt-circle-up"></i>
                                                </Button>
                                            )}
                                            {i !== overviewDataGroupParents.length - 1 && (
                                                <Button
                                                    className="button-down"
                                                    variant="secondary"
                                                    size="lg"
                                                    onClick={() => onMouseClickDown(i, overviewDataGroupParents)}
                                                >
                                                    <i className="far fa-arrow-alt-circle-down"></i>
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </StyledOverviewGroup>
                    ))}
                <Row>
                    <Col md={12} className="text-left">
                        <AddCircleOutlinedIcon
                            color="secondary"
                            fontSize="large"
                            onClick={addNew}
                        />
                        <RemoveCircleOutlinedIcon
                            color="primary"
                            fontSize="large"
                            onClick={removeItem}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="text-center">
                        <Button
                            className="noPrint ml-3 mt-3"
                            variant="primary"
                            size="lg"
                            type="submit"
                            onClick={onCreate}
                        >
                            {isSubmit === SUBMIT_TYPE.CREATE ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </>
                            ) : (
                                <span>登録</span>
                            )}
                        </Button>
                    </Col>
                </Row>

                {isSuccess && (
                    <Row className="ml-4">
                        <Col md={9} className="mt-5" style={{ margin: "auto" }}>
                            <Alert variant="success">{successMessage}</Alert>
                        </Col>
                    </Row>
                )}
                {isErrors && (
                    <Row className="ml-4">
                        <Col md={9} className="mt-5" style={{ margin: "auto" }}>
                            <Alert variant="danger">エラーが発生しました</Alert>
                        </Col>
                    </Row>
                )}
            </StyledOverviewDataGroup>
        </Container>
    );
};

const StyledOverviewDataGroup = styled.div`
  width: 900px;
  margin: 100px auto;
  .col-url-name{
    width: 400px;
  }
`;
const StyledOverviewGroup = styled.div`
  table {
    tr {
      td:first-child {
        display: flex;
      }
      td {
        border-top: none;
        .itemName {
          width: 150px;
        }
      }

      .btn-move {
        padding: 0px;
      }

      .parent-name {
        .form-check {
          color: #3492ff;
        }
      }

      input[type="radio"] {
        transform: scale(1.5);
        margin-right: 10px;
      }
    }
  }
`;


export default withRouter(ManagerLinkInfo);
