import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import IndexStudentBasicInfo from "../../IndexStudentBasicInfo";
import SelfEvaluationTableBosei from "./SelfEvaluationTableBosei";
import "../../../../assets/styles/SelfEvaluation.scss";

interface Props extends RouteComponentProps<{ studentID: string }> {}
interface State {}

class SelfEvaluationViewKobeJoshi extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.goToSelfEvaluationKobeJoshiBosei = this.goToSelfEvaluationKobeJoshiBosei.bind(
      this
    );
    this.goToSelfEvaluationKobeJoshiSyoni = this.goToSelfEvaluationKobeJoshiSyoni.bind(
      this
    );
  }

  public goToSelfEvaluationKobeJoshiBosei() {
    this.props.history.push(
      `/self_evaluation_kobe_joshi_bosei/${this.props.match.params.studentID}`
    );
  }

  public goToSelfEvaluationKobeJoshiSyoni() {
    this.props.history.push(
      `/self_evaluation_kobe_joshi_syoni/${this.props.match.params.studentID}`
    );
  }

  public render() {
    return (
      <Container className="mt-5 mb-5">
        <h2>自己評価の選択</h2>
        <Row>
          <Col>
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-info"
              onClick={this.goToSelfEvaluationKobeJoshiBosei}
            >
              母性
            </Button>
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-info"
              onClick={this.goToSelfEvaluationKobeJoshiSyoni}
            >
              小児
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SelfEvaluationViewKobeJoshi);
