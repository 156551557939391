import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../../assets/styles/SWCustom.scss";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

interface Props extends RouteComponentProps {
  studentID: string;
  facultyID: string;
}
interface State {


  nursingPlans: {
    studentID: string;
    studentName: string;
    date: string;
    createdAt: string;
    updatedAt: string;
    updatedAtByFaculty: string;
  }[];
}

class NursePlanList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {


      nursingPlans: [],
    };
  }

  public showNursingRecord(date: string) {

    const url: string = `/nurse_plan_create/${this.props.studentID}/${date}/${this.props.facultyID}`;

    this.props.history.push(url);
  }

  public componentDidMount() {


    const url = `${process.env.REACT_APP_API_URL}/api/nursing_plan/fetch_list?student_id=${this.props.studentID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          nursingPlans: result,
        });
      })
      .catch(console.error);
  }

  public render() {

    const nursingPlanRows = this.state.nursingPlans.map((item, i) => (
      <tr
        key={i}
        onClick={this.showNursingRecord.bind(
          this,
          item.date
        )}
      >

        <td>{item.createdAt}</td>

        <td>{item.updatedAtByFaculty?.length ? item.updatedAtByFaculty : 'no-data'}</td>
       
      </tr>
    ));

    return (
      <div className="m-5">
        <h2 className="mb-4 clor-lightnavi">看護計画の記録</h2>
        <Row className="sw-table">
          <Col>
            {!!nursingPlanRows?.length && (
              <Table className="m-5 list_plan" bordered hover>
                <thead className="bg-lightblue">
                  <tr>

                    <th>作成日</th>

                    <th>コメント更新日</th>
                  </tr>
                </thead>
                <tbody>{nursingPlanRows}</tbody>
              </Table>
            )}

          </Col>



        </Row>

      </div>
    );
  }
}

export default withRouter(NursePlanList);
