import React, { Component } from 'react';
import Table from "react-bootstrap/Table";
import _ from 'lodash';

interface Props {
  dataRow: any
}
let isRendered = false;

class OverviewTableRow extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.leftData = this.leftData.bind(this);
    this.leftDataNew = this.leftDataNew.bind(this);
    this.rowSpanFunc = this.rowSpanFunc.bind(this);
  }

  leftData() {
    const list = this.props.dataRow;
    if (list == undefined) return;

    let result = [];

    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      // col A
      result.push(
        <tr key={Math.random()}>
          <td rowSpan={item.col_a.count || 1}>[{item.col_a.type_id}] - {item.col_a.type_name}</td>
          <td>[{_.get(item, "col_a.col_b[0].type_id", "")}] - {_.get(item, "col_a.col_b[0].type_name", "")}</td>
          <td>[{_.get(item, "col_a.col_b[0].col_c[0].type_id", "")}] - {_.get(item, "col_a.col_b[0].col_c[0].type_name", "")}</td>
        </tr>
      );

      // col B
      for (let j = 1; j < item.col_a.col_b.length; j++) {
        const colB = item.col_a.col_b[j];
        console.log(colB);


        result.push(
          <tr key={Math.random()}>
            <td rowSpan={1}>[{_.get(colB, "type_id", "")}] - {_.get(colB, "type_name", "")}</td>
            <td>[{_.get(colB, "col_c[0].type_id", "")}] - {_.get(colB, "col_c[0].type_name", "")}</td>
          </tr>
        );

        // col C
        if (colB.col_c.length > 0) {
          for (let k = 1; k < colB.col_c.length; k++) {
            const colC = colB.col_c[k];

            result.push(
              <tr key={Math.random()}>
                <td>[{_.get(colC, "type_id", "")}] - {_.get(colC, "type_name", "")}</td>
              </tr>
            );
          }
        }
      }

    }
    return result;
  }

  leftDataNew() {
    if (!this.props.dataRow) return;
    if (isRendered) return;
    let result: any[] = [];

    const mr_time = this.props.dataRow.mr_time;
    this.props.dataRow.mr_value.map((item: any) => {

      if (item.pdata[0] !== undefined && item.pdata[0].visible_flag !== undefined && parseInt(item.pdata[0].visible_flag) === 0) {
        console.log('item.pdata[0]:', item.pdata[0])
        let tmpColCount = 0;
        // tổng số cột
        // nếu độ dài của mảng arrTemp = tổng số cột thì next row mới

        let arrData: any[] = [];
        const pdata = item.pdata.sort(function (a: any, b: any) {
          return (a.mr_time == null || b.mr_time == null) || parseInt(a.mr_time.replace(":00:00", "")) - parseInt(b.mr_time.replace(":00:00", ""))
        });
        const tmpRowCount = Math.ceil(pdata.length / mr_time.length);
        // console.log(tmpRowCount);

        for (let i = 0; i < tmpRowCount; i++) {
          const element = pdata.splice(0, mr_time.length);
          // console.log(element, pdata);

          // row
          result.push(
            <tr key={Math.random()}>
              <td key={Math.random()}>{item.namea}</td>
              <td key={Math.random()}>{item.nameb}</td>
              <td key={Math.random()}>{item.namec}</td>
            </tr>
          );
        }
      }

    })
    setTimeout(() => this.rowSpanFunc(), 1000);
    isRendered = true;
    return result;
  }

  rowSpanFunc() {
    const tableOverview = document.getElementById('overviewXXX') as HTMLTableElement;
    if (tableOverview == null) return;

    for (let i = 0; i < 2; i++) {
      let headerCell = null;
      // for (let row of tableOverview.rows) {

      for (let j = 0; j < tableOverview.rows.length; j++) {
        // break three line header
        if (j < 3) continue;
        let row = tableOverview.rows[j];
        const firstCell = row.cells[i];

        if (headerCell === null || firstCell.innerText !== headerCell.innerText) {
          headerCell = firstCell;
        } else {
          headerCell.rowSpan++;
          firstCell.style.display = "none";
        }
      }
    }
  }

  render() {
    return (
      <Table
        className="patient__overview patient_info_overview_left float-left"
        id="overviewXXX"
        bordered
        hover
        size="sm"
      >
        <thead>
          <tr>
            <th className="bg-white"></th>
            <th colSpan={2}>日付</th>
          </tr>
          <tr>
            <th className="bg-white"></th>
            <th colSpan={2}>入院日数</th>
          </tr>
          <tr>
            <th className="bg-white">&emsp;</th>
            <th>&emsp;</th>
            <th>時刻</th>
          </tr>
        </thead>
        <tbody>

          {this.leftDataNew()}
        </tbody>
      </Table>
    );
  }
}

export default OverviewTableRow;
