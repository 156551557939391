import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import InternUniversityList from "./InternUniversityList";
import IndexFacultyBasicInfo from "./IndexFacultyBasicInfo";
import IndexStudentLinks from "../student_view/IndexStudentLinks";
import PatientInfoControl from "./PatientInfoControl";
import PatientInfoControl400s from "./PatientInfoControl400s";
import PatientInfoControl300s from "./PatientInfoControl300s";
import SignUpBox from "../login/SignUpBox";
import "../../assets/styles/IndexFaculty.scss";
import AutoHomePath from './../common/AutoHomePath';
import "../../assets/styles/SWCustom.scss";
import { USER_TYPE } from "../../services/AppLocalHelper";
import { checkUserId } from "../../services/AuthService";
import FacultyComment from "./share_comment/FacultyComment";

interface Props extends RouteComponentProps<{ facultyID: string }> { }
interface State {
  isSupervisor: boolean;
  isSubSupervisor: boolean;
  affiliation: string;
  facultyID: string;
}

class IndexFaculty extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.facultyID
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }

    this.state = { isSupervisor: false, isSubSupervisor: false, affiliation: "", facultyID: "" };

    this.goToPatientInfo = this.goToPatientInfo.bind(this);
    this.goToNuringPlans = this.goToNuringPlans.bind(this);
    this.goToChartView = this.goToChartView.bind(this);
    this.goToAllNursingDiagnosisView = this.goToAllNursingDiagnosisView.bind(
      this
    );
    this.goToSelfEvaluations = this.goToSelfEvaluations.bind(this);
    this.goToQuestionnaireResult = this.goToQuestionnaireResult.bind(this);
    this.fetchFacultyInfo = this.fetchFacultyInfo.bind(this);
    // SW fix 2021/3/1
    this.goToInternUniversityList = this.goToInternUniversityList.bind(this);
    this.goToSettingMenu = this.goToSettingMenu.bind(this);
  }
  // SW fix 2021/3/1
  public goToInternUniversityList(type: String) {
    this.props.history.push(`/faculty/intern_university_list/${this.props.match.params.facultyID}?type=${type}&supervisor=${this.state.isSupervisor}`);
    // const win = window.open();
    // win?.focus();
  }
  public goToSettingMenu() {
    this.props.history.push(`/faculty/settings_menu/${this.props.match.params.facultyID}`);
    // const win = window.open(`/faculty/settings_menu/${this.props.match.params.facultyID}`);
    // win?.focus();
  }


  public goToPatientInfo() {
    this.props.history.push(`/all_hospital_rooms/supervisor-faculty`);
  }

  public goToNuringPlans() {
    this.props.history.push(
      `/faculty/all_nursing_plans/${this.props.match.params.facultyID}`
    );
  }

  public goToChartView() {
    this.props.history.push(
      `/chart_view_all/${this.props.match.params.facultyID}`
    );
  }
  public goToAllNursingDiagnosisView() {
    this.props.history.push(
      `/nursing_diagnosis/faculty_all_list/${this.props.match.params.facultyID}`
    );
  }

  public goToSelfEvaluations() {
    this.props.history.push(
      `/faculty/self_evaluation_list/${this.props.match.params.facultyID}`
    );
  }

  public goToQuestionnaireResult() {
    this.props.history.push(
      `/faculty/questionnaire_result/${this.props.match.params.facultyID}`
    );
  }

  public fetchFacultyInfo(
    isSupervisor: boolean,
    isSubSupervisor: boolean,
    affiliation: string,
    facultyID: string
  ) {
    this.setState({
      isSupervisor: isSupervisor,
      isSubSupervisor: isSubSupervisor,
      affiliation: affiliation,
      facultyID: facultyID,
    });
  }

  public render() {
    return (
      <Container className="mt-5 mb-5">
        {this.state.isSupervisor && (
          <h2>管理者Index</h2>
        )}
        {!this.state.isSupervisor && (
          <h2>教員Index</h2>
        )}
        <Row>
          <Col md={5}>
            <Row>
              <IndexFacultyBasicInfo
                facultyID={this.props.match.params.facultyID}
                fetchFacultyInfo={this.fetchFacultyInfo}
              />
            </Row>
            <Row>
               <IndexStudentLinks userID={this.props.match.params.facultyID} userType="faculty" />
            </Row>
            {/* SW fix layout 2021/3/1 */}
            {/* {this.state.isSupervisor && <SignUpBox />} */}
          </Col>

          <Col md={7} className="custom-pad-top">
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-info"
              onClick={() => this.goToInternUniversityList('patient-information')}
            >
              患者情報
            </Button>
         
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-danger"
              onClick={() => this.goToInternUniversityList('training-information')}
            >
              学生情報
            </Button>
            <br />
            <Button
              className="index_student mt-5 mr-4 font-weight-bold"
              variant="outline-success"
              onClick={() => this.goToInternUniversityList('survey-information')}
            >
              調査情報
            </Button>
        
            {(this.state.isSupervisor || this.state.isSubSupervisor) && (
              <Button
                className="index_student mt-5 mr-4 font-weight-bold"
                variant="outline-warning"
                onClick={this.goToSettingMenu}
              >
                設定
              </Button>
            )}
             <Row>
          
             <Col md={12} className="custom-pad-top">
                  <FacultyComment 
                        facultyID={this.props.match.params.facultyID}
                        fetchFacultyInfo={this.fetchFacultyInfo}
                  />
              </Col>
          </Row>
          </Col>
        </Row>
       
        <AutoHomePath pathname={this.props.location.pathname} />
      </Container>
    );
  }
}

export default withRouter(IndexFaculty);
