import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// SW add 2021/3/2
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ListRoomSelect from "./forms/ListRoomSelect";
import { HospitalRoomType } from "../models/Types";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import _ from "lodash";
import "./../../assets/styles/SWCustom.scss";
import "../../assets/styles/HospitalRoom.scss";
import { checkUserId } from "../../services/AuthService";
import { USER_TYPE } from "../../services/AppLocalHelper";

// import HospitalWardInfo from "./HospitalWardInfo";
// import HospitalRoom from "./HospitalRoom";

interface Props extends RouteComponentProps<{ studentID: string }> { }
interface State {
  currentFilter: String;
  type: String;
  hospitalRooms: HospitalRoomType[];

  patient_id_sl: string;
  room_name_sl: string;
  patient_name_sl: string;
  updateState: string;
  showAlert: boolean;

  radioCheckID: string | null;
  isFacultySupervisor: boolean;
  facultyAffiliation: string;
  isEnabledAdd: boolean;
}

class AllHospitalRoom extends React.Component<Props, State> {
  private university: string | null;
  private type: String | null;
  private start_date: string | null;
  private end_date: string | null;
  private faculty_id: string | null;
  private hospital_room_ref: any;

  constructor(props: Props) {
    super(props);
    // SW add 2021/3/2
    const query = new URLSearchParams(this.props.location.search);

    let userId = this.props.match.params.studentID === "supervisor-faculty" ? query.get("facultyID") ?? "" : this.props.match.params.studentID
    if (!checkUserId(userId, this.props.match.params.studentID === "supervisor-faculty" ? USER_TYPE.TEACHER : USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }
    if (this.props.match.params.studentID === "supervisor-faculty") {
      this.getFaculty(userId);
    } else {
      this.getStudentInfo(userId);
    }
    this.state = {
      currentFilter: "all",
      type: "",
      hospitalRooms: [],
      patient_id_sl: "",
      room_name_sl: "",
      patient_name_sl: "",
      updateState: "",
      showAlert: true,
      isFacultySupervisor: false,
      radioCheckID: null,
      facultyAffiliation: "",
      isEnabledAdd: true
    };
    this.createRoom = this.createRoom.bind(this);
    this.onItemSelect = this.onItemSelect.bind(this);
    this.addRoomStudent = this.addRoomStudent.bind(this);

    this.onClickTable = this.onClickTable.bind(this);
    this.onRadioChanged = this.onRadioChanged.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);

    this.university = query.get("university");
    this.start_date = query.get("startDate");
    this.end_date = query.get("endDate");
    this.type = query.get("type");
    this.faculty_id = query.get("facultyID");
  }
  public getFaculty(facultyID: string) {
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${facultyID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({ isFacultySupervisor: result.is_supervisor, facultyAffiliation: result.affiliation_id });
        if (!result.is_supervisor) {
          this.addPatient(result.affiliation_id)
        }
      })
      .catch(console.error);
  }

  public getStudentInfo(facultyID: string) {
    const url = `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${facultyID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          facultyAffiliation: result.university_id,
        });
      })
      .catch(console.error);
  }

  public addPatient(affiliation: string) {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/allowAddPatient?affiliation=${affiliation}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        console.log('addPatient', result);
        this.setState({ isEnabledAdd: result.isEnabled });
      })
      .catch(console.error);
  }
  // SW add 2021/3/2
  public createRoom() {
    this.props.history.push("/create-new/basic-patient-information", {
      data: {},
      isEdit: false,
    });
  }

  public onClickTable(patientID: string, e: React.MouseEvent<HTMLElement>) {
    this.props.history.push(
      `/patient_info/${patientID}/${this.props.match.params.studentID}/${this.faculty_id
      }/${this.start_date}/${this.end_date}/${this.university}?type=${this.type || "all"
      }`
    );
    window.location.reload()
  }

  public onItemSelect(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ currentFilter: event.target.value || "all" });
  }

  public onCloseAlert() {
    this.setState({ showAlert: false });
  }

  public addRoomStudent() {
    const addPatientInfos = {
      student_id: this.props.match.params.studentID,
      patient_id: this.state.patient_id_sl,
      room_name: this.state.room_name_sl,
      patient_name: this.state.patient_name_sl,
    };
    if (this.state.patient_id_sl != "") {
      const url = `${process.env.REACT_APP_API_URL}/api/student_info/add_patientinfos_to_studentinfos`;
      const method = "POST";
      const mode = "cors";
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const body = JSON.stringify(addPatientInfos);

      fetch(url, { method, mode, headers, body })
        .then((res) => res.json())
        .then((result) => {
          if (result.status === "success") {
            this.setState({ updateState: "success", showAlert: true });
          } else {
            this.setState({ updateState: "error" });
            console.log("API Error");
          }
        })
        .catch(console.error);
    }
  }

  public onRadioChanged(
    patientID: string,
    roomName: string,
    patientName: string
  ) {
    this.setState({
      radioCheckID: patientID,
      room_name_sl: roomName,
      patient_id_sl: patientID,
      patient_name_sl: patientName,
    });
  }
  // end add
  public setClass(affiliation: string) {
    if (this.props.match.params.studentID === "supervisor-faculty" && this.state.facultyAffiliation !== affiliation) {
      if (this.type === "setting" && affiliation == 'COMMON' && !this.state.isFacultySupervisor) {
        return "sw-disabled";
      }
    }
    return "";
  }
  public componentDidMount() {
    const isStudent: Boolean =
      this.props.match.params.studentID !== "supervisor-faculty";
    if (isStudent) {
      const url = `${process.env.REACT_APP_API_URL}/api/patient_info/all?student_id=${this.props.match.params.studentID}`;
      fetch(url)
        .then((res) => res.json())
        .then((result) => {
          if (result !== null) {
            const selectedRoom = _.find(result, {
              radio_check: true,
            });
            this.setState({
              hospitalRooms: result,
              radioCheckID: selectedRoom?.patientID,
            });
          }
        })
        .catch(console.error);
    } else {
      const url2 = `${process.env.REACT_APP_API_URL}/api/patient_info/all?student_id=${this.props.match.params.studentID}&university=${this.university}&start_date=${this.start_date}&end_date=${this.end_date}`;
      fetch(url2)
        .then((res) => res.json())
        .then((result) => {
          if (result !== null) {
            this.setState({
              hospitalRooms: result,
            });
          }
        })
        .catch(console.error);
    }
  }

  public render() {
    // SW add 2021/3/2
    const isRenderAdditionalCol: Boolean = this.type === "setting";
    const isStudent: Boolean =
      this.props.match.params.studentID != "supervisor-faculty" &&
      this.props.match.params.studentID != "faculty-kobeU";

    let listHospital =
      this.state.currentFilter === "all"
        ? this.state.hospitalRooms
        : this.state.hospitalRooms.filter(
          (item) =>
            Number(item.roomName) >= Number(this.state.currentFilter) &&
            Number(item.roomName) < Number(this.state.currentFilter) + 100
        );
    const affiliation = this.state.facultyAffiliation;
    listHospital = this.state.isFacultySupervisor ? listHospital : listHospital.filter(function (el: any) {
      return el.affiliation == affiliation || el.affiliation == "COMMON";
    });
    const hospitalRooms = listHospital.map((item, i) => {
      return (
        <div className="float-left m-3 ml-5">
          {!isStudent && (
            <Table
              key={i}
              bordered
              id={"hospital_room"}
              className={this.setClass(item.affiliation)}
              onClick={this.onClickTable.bind(this, item.patientID)}
            >
              <tbody>
                <tr>
                  <td>病室</td>
                  <td>{item.roomName}</td>
                </tr>
                <tr>
                  <td>診療科</td>
                  <td>{item.department}</td>
                </tr>
                <tr>
                  <td>患者氏名</td>
                  <td>{item.patientName}</td>
                </tr>
              </tbody>
            </Table>
          )}

          {isStudent && (
            <Table
              key={i}
              bordered
              id={"hospital_room"}
              className="all_room_hospital"
              onClick={this.onClickTable.bind(this, item.patientID)}
            >
              <tbody>
                <tr>
                  <td>病室</td>
                  <td>{item.roomName}</td>
                </tr>
                <tr>
                  <td>診療科</td>
                  <td>{item.department}</td>
                </tr>
                <tr>
                  <td>患者氏名</td>
                  <td>{item.patientName}</td>
                </tr>
              </tbody>
            </Table>
          )}

          {isStudent && (
            <div>
              <Form.Check
                name="site_name"
                type="radio"
                checked={item.patientID === this.state.radioCheckID}
                label="受け持ち"
                onChange={this.onRadioChanged.bind(
                  this,
                  item.patientID,
                  item.roomName,
                  item.patientName
                )}
              />
            </div>
          )}
        </div>
      );
    });

    return (
      <Container className="mt-5 mb-5">
        {/* SW add 2021/3/2 */}
        {isRenderAdditionalCol && (
          <>
            <Form.Group as={Row}>
              <Form.Label column md={1} className="">
                病室
              </Form.Label>
              <ListRoomSelect onItemSelect={this.onItemSelect} />
            </Form.Group>
          </>
        )}
        {isStudent && (
          <h2 className="ml-4 p-3 clor-lightnavi">
            神戸大学&nbsp;&nbsp;Web&nbsp;病棟&emsp;病室一覧
          </h2>
        )}
        {!isStudent && (
          <h2 className="ml-4 p-3">
            神戸大学&nbsp;&nbsp;Web&nbsp;病棟&emsp;病室一覧
          </h2>
        )}
        <Row>{hospitalRooms}</Row>

        {/* SW add 2021/3/2 */}
        {isRenderAdditionalCol && (
          <div>
            <Button
              className="m-5"
              size="lg"
              variant="primary"
              disabled={!this.state.isEnabledAdd}
              onClick={this.createRoom}
            >
              新規作成
            </Button>
          </div>
        )}
        {isStudent && (
          <div>
            <Button
              className="m-5 bg-lightblue"
              size="lg"
              variant="primary"
              onClick={this.addRoomStudent}
            >
              保存
            </Button>
            {this.state.updateState === "success" && (
              <Alert
                variant={"success"}
                dismissible
                show={this.state.showAlert}
                onClose={this.onCloseAlert}
              >
                更新しました
              </Alert>
            )}
            {this.state.updateState === "error" && (
              <Alert
                variant={"danger"}
                dismissible
                show={this.state.showAlert}
                onClose={this.onCloseAlert}
              >
                更新できません
              </Alert>
            )}
          </div>
        )}
      </Container>
    );
  }
}

export default AllHospitalRoom;
