import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../../assets/styles/SelfEvaluation.scss";
import QuestionnaireTable from "./QuestionnaireTable";
import { checkUserId } from "../../../services/AuthService";
import { USER_TYPE } from "../../../services/AppLocalHelper";

interface Props extends RouteComponentProps<{ studentID: string }> { }
interface State {
  ifCompleted: boolean;
}

class QuestionnaireView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID
    if (!checkUserId(userId, USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }

    this.state = {
      ifCompleted: false
    };
    this.changeIfCompleted = this.changeIfCompleted.bind(this);
  }

  public changeIfCompleted() {
    this.setState({ ifCompleted: true });
  }

  public componentDidMount() {
    // fetch
    const url = `${process.env.REACT_APP_API_URL}/api/questionnaire/if_completed?student_id=${this.props.match.params.studentID}`;
    fetch(url)
      .then(res => res.json())
      .then(result => {
        this.setState({ ifCompleted: result.if_completed });
      })
      .catch(console.error);
  }

  public render() {
    return (
      <Row className="mt-5 mb-5">
        <Col md={2} />
        <Col md={8}>
          <h2 className="mb-5 clor-lightblue">実習後アンケート</h2>
          {this.state.ifCompleted ? (
            <h5 className="clor-lightblue">ご回答ありがとうございました。</h5>
          ) : (
            <div>
              <h5 className="mb-5 clor-lightblue">
                より良い実習支援を行うための参考資料にしたいと考えていますので，ご意見をお聞かせください。
              </h5>
              <QuestionnaireTable
                studentID={this.props.match.params.studentID}
                changeIfCompleted={this.changeIfCompleted}
              />
            </div>
          )}
        </Col>
        <Col md={2} />
      </Row>
    );
  }
}

export default withRouter(QuestionnaireView);
