import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import React from "react";
import Nav from "react-bootstrap/Nav";
import "../../assets/styles/Header.scss";
import { useHomePath } from './../../helpers/HomePathContext';

const HomeButton = () => {
    const { data } = useHomePath()

    return (
        <Nav className="mr-auto">
            <Nav.Link id="home" href={data.path}>
                <HomeRoundedIcon fontSize="large" />
                <span className="align-middle">Home</span>
            </Nav.Link>
        </Nav>
    );
}

export default HomeButton;
