import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TransferList from "./TransferList";
import { TestDataItemType } from "../models/Types";
import { TestDataItemRecordTypeNew } from "../models/Types";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#21252994",
  },
}))(MuiDialogContent);

export default function CustomizedDialogs({
  testDataItems,
  testDataRecords,
  setTestData,
  patientID,
}: {
  testDataItems: TestDataItemType[];
  testDataRecords: TestDataItemRecordTypeNew[];
  setTestData: Function;
  patientID: string
}) {
  const [open, setOpen] = React.useState(false);
  const [itemDisable, setItemDisable] = React.useState<TestDataItemType[]>([]);
  const [itemDisplay, setItemDisplay] = React.useState<TestDataItemType[]>([]);
  const [selectValue, setSelectValue] = React.useState<number>();
  const [options, setOption] = React.useState<TestDataItemType[]>([]);

  React.useEffect(() => {
    if (testDataItems.length > 0) {
      const getOption = testDataItems.filter((item) => item.is_parent);
      setOption(getOption);
      if (getOption[0]) {
        fetchAPI(getOption[0].id);
      }
    }
  }, [testDataItems]);

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const handleClickOpen = () => {
    if (testDataItems.length > 0) {
      // testDataItems.forEach
    }
    setOpen(true);
  };
  const fetchAPI = (parent_id: any) => {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/get_test_data_items?parent_id=${parent_id}&patient_id=${patientID}`;
    const method = "GET";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    fetch(url, { method, mode, headers })
      .then((res) => res.json())
      .then((res) => {
        if (res.result.success) {
          // let displayItems = testDataItems.filter((item) => item.parent_id == parent_id);
          console.log('get_test_data_items result hiddens:', res.result.hiddens);
          console.log('get_test_data_items result shows:', res.result.shows);
          // console.log('testDataItems - displayItems:', displayItems);
          // console.log('testDataItems - All:', testDataItems);
          setItemDisable(res.result.hiddens)
          setItemDisplay(res.result.shows)
          setSelectValue(parent_id);
        }
      })
      .catch((error) => {
        console.log(error);

      });
  };
  const handleClose = () => {

    const newData = testDataItems.concat(
      itemDisplay.filter((checked) => testDataItems.indexOf(checked) === -1)
    );

    const dataDisplay = newData.filter(
      (test) => itemDisable.indexOf(test) === -1
    );
    console.log('handleClose dataDisplay:', dataDisplay);
    console.log('handleClose itemDisable:', itemDisable);
    console.log('handleClose itemDisplay:', itemDisplay);
    const item_ids = itemDisplay.map(({id}) => id);
    // updateVisibleTestData();
    console.log('item_ids', item_ids);
    updateVisibleTestData(item_ids, dataDisplay);
  
  };
  const updateVisibleTestData = (item_ids: any, dataDisplay: any) => {
    const data = {
      parent_id: selectValue,
      patient_id: patientID,
      item_ids: item_ids,
      date_times: testDataRecords.map(({date_time}) => date_time)
    }
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/setVisiblePatientInfoTestData`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        console.log('updateVisibleTestData:', result);
        setTestData(dataDisplay);
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleChange = (event: any) => {
    console.log('handleChange', event.target.value);
    fetchAPI(event.target.value);

  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        項目の追加・変更
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          入力項目の追加・変更
        </DialogTitle>
        <DialogContent dividers>
          <StyledSelect>
            <InputLabel style={{ color: "#fff" }}>カテゴリー：</InputLabel>
            <FormControl>
              <NativeSelect
                value={selectValue}
                defaultValue={options.length > 0 && options[0].id}
                onChange={handleChange}
                input={<BootstrapInput />}
              >
                {options.length > 0 &&
                  options.map((option: TestDataItemType, index: number) => (
                    <option
                      key={index}
                      aria-label={option.name}
                      value={option.id}
                    >
                      {option.name}
                    </option>
                  ))}
              </NativeSelect>
            </FormControl>
          </StyledSelect>
          <TransferList
            testDataItems={testDataItems}
            selectValue={selectValue}
            itemDisable={itemDisable}
            itemDisplay={itemDisplay}
            setItemDisable={setItemDisable}
            setItemDisplay={setItemDisplay}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const StyledSelect = styled.div`
  display: flex;
  align-items: center;
`;
