import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import CSVReaderImport from "../common/CSVReaderImport";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Link from "@material-ui/core/Link";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "@material-ui/lab/Alert";
import { ButtonSpriner, LoadingSpriner } from "../common/CircularSpinner";
import "../../assets/styles/IndexFaculty.scss";

interface ChildComponentProps {
  history: History;
}
const CreateNursingInformation: React.SFC<ChildComponentProps> = ({
  history,
}) => {
  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [csvLoading, setCSVLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [successMsgCSV, setSuccessMsgCSV] = useState(false);
  const [errorCSV, setErrorCSV] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [message, setMessage] = useState("更新しました。");

  useEffect(() => {
    initialData();
  }, []);

  const initialData = () => {
    const { location } = history;
    const {
      data: { patientID },
    } = location.state as any;

    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/nursing_info?patient_id=${patientID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          setMessage("登録完了しました。");
          setFormValues({
            ...result,
          });
        }
      })
      .catch(console.error);
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const {
      location: { state },
    } = history;
    const { data: { patientID } } = state as any;
    const newData = [{ ...formValues, patient_id: patientID }];

    fetchAPI(newData, false);
  };

  const fetchAPI = (data: any, isCSV: boolean = true) => {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/nursing_info/create`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(data);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);
        if (result.success) {
          setCSVLoading(false);
          setSuccessMsg(!isCSV);
          setSuccessMsgCSV(isCSV);
          setTimeout(() => {
            setSuccessMsg(false);
            setSuccessMsgCSV(false);
          }, 2000);
          
          initialData();
        } else {
          setErrors(!isCSV);
          setErrorCSV(isCSV);
          setTimeout(() => {
            setErrors(false);
            setErrorCSV(false);
            setCSVLoading(false);
          }, 2000);
          setFormValues({});
        }
      })
      .catch(console.error);
  };

  const onHandleCSV = (data: any, fileInfo: any) => {
    if (fileInfo.name) {
      const regex = /^.+\.(csv)$/g;
      const checkType = regex.test(fileInfo.name);
      if (checkType) {
        setCSVLoading(true);
        fetchAPI(data);
      } else {
        setErrorCSV(true);
        setTimeout(() => {
          setErrorCSV(false);
        }, 3000);
      }
    }
  };

  const setFormField = (field: string, value: string) => {
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  const elements = (formValue: any) => [
    {
      label: "健康知覚・健康管理",
      name: "health",
      value: formValue.health,
    },
    {
      label: "自己認識・自己概念",
      name: "self_awarness",
      value: formValue.self_awarness,
    },
    {
      label: "栄養・代謝",
      name: "nutrition",
      value: formValue.nutrition,
    },
    {
      label: "役割・関係",
      name: "role",
      value: formValue.role,
    },
    {
      label: "排泄",
      name: "excretion",
      value: formValue.excretion,
    },
    {
      label: "性・生殖",
      name: "sex",
      value: formValue.sex,
    },
    {
      label: "活動・運動",
      name: "activity",
      value: formValue.activity,
    },
    {
      label: "コーピング・ストレス耐性",
      name: "stress",
      value: formValue.stress,
    },
    {
      label: "認知・知覚",
      name: "cognition",
      value: formValue.cognition,
    },
    {
      label: "価値・信念",
      name: "value",
      value: formValue.value,
    },
    {
      label: "睡眠・休息",
      name: "sleep",
      value: formValue.sleep,
    },
    {
      label: "その他",
      name: "other",
      value: formValue.other,
    },
  ];

  return (
    <Container className="mt-5">
      <h2 className="mb-5">看護情報入力</h2>
      <Form onSubmit={onSubmit}>
        {csvLoading && <LoadingSpriner />}
        {successMsgCSV && (
          <Alert className="mt-3 sw-custom-btn">
            データの登録が完了しました。
          </Alert>
        )}
        {errorCSV && (
          <Alert severity="error" className="mt-3 sw-custom-btn">
            データの登録に失敗しました。
          </Alert>
        )}
        <Row>
          <Col md={9} style={{ margin: "auto" }}>
            <div
              style={{
                float: "right",
                display: "flex",
                alignItems: "baseline",
                marginBottom: "10px",
              }}
            >
              <Link
                href={`${process.env.REACT_APP_API_URL}/api/patient_info/csv-sample-download?fileName=[サンプル]看護情報入力.csv`}
                style={{
                  cursor: "pointer",
                  color: "#007bff",
                  textDecoration: "none",
                  marginRight: "20px",
                  marginTop: "10px",
                }}
                color="primary"
              >
                CSVテンプレート
              </Link>
              <CSVReaderImport
                handleForce={onHandleCSV}
                className="react-csv-input"
              />
            </div>
          </Col>
        </Row>
        <StyledNursingInfo>
          {elements(formValues).map((element, index) => (
            <Row key={index}>
              <Col md={9} style={{ margin: "auto" }}>
                <Card className="mb-4">
                  <Card.Header as="h5">{element.label}</Card.Header>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    value={element.value || ""}
                    onChange={(e) => setFormField(element.name, e.target.value)}
                  />
                </Card>
              </Col>
            </Row>
          ))}
        </StyledNursingInfo>
        <Row>
          <Col md={12}>
            <ButtonSpriner isLoading={loading} label="保存" />
          </Col>
        </Row>
      </Form>
      {successMsg && <Alert className="mt-3 sw-custom-btn">{message}</Alert>}
      {errors && (
        <Row className="ml-4">
          <Col md={6} className="mt-5" style={{ margin: "auto" }}>
            <Alert className="mt-3" severity="error">
              看護情報を入力できません
            </Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const StyledNursingInfo = styled.div`
  margin: auto;
  .form-control {
    border: none;
  }
  textarea {
    resize: none;
  }
}
`;
export default withRouter(CreateNursingInformation);
