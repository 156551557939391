import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { cloneDeep as _cloneDeep } from "lodash";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Spinner from "react-bootstrap/Spinner";
import "../../../assets/styles/SWCustom.scss";
import { OverviewDataGroupType, TestDataItemNewType } from "../../models/Types";
import { getUserId, USER_TYPE } from "../../../services/AppLocalHelper";
import { checkUserId } from "../../../services/AuthService";

const SUBMIT_TYPE = {
    DEFINER: "DEFINER",
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    DELETE: "DELETE"
};

const ManagerTestDataItems: React.FunctionComponent = () => {
    const [overviewDataGroupParents, setOverviewDataGroupParents] = useState<
        TestDataItemNewType[]
    >([]);
    const [overviewDataGroupChildrens, setOverviewDataGroupChildrens] = useState<
        TestDataItemNewType[]
    >([]);
    const [overviewGroupChangeOrder, setOverviewGroupChangeOrder] = useState<
        TestDataItemNewType[]
    >([]);
    const [
        overviewDataGroupChildrensOrigin,
        setOverviewDataGroupChildrensOrigin,
    ] = useState<TestDataItemNewType[]>([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isParent, setIsParent] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState("");
    const [isErrors, setErrors] = useState(false);
    const [isShowChildren, setIsShowChildren] = useState(false);
    const [vector, setVector] = useState(false);
    const [dataType, setDataType] = useState<number>(1);
    const [unit, setUnit] = useState("");
    const [unitCheck, setUnitCheck] = useState("");
    const [parentNameBreakcrum, setParentNameBreakcrum] = useState("");

    const [input, setInput] = useState<{
        id?: number;
        parent_id?: string;
        item_name: string;
        order?: number;
    }>({
        id: 0,
        parent_id: "",
        item_name: "",
        order: 0,
    });
    const history = useHistory();
    useEffect(() => {
        let userId = getUserId()
        if (!checkUserId(userId, USER_TYPE.TEACHER)) {
            history.push('/')
        }

        const url = `${process.env.REACT_APP_API_URL}/api/settings/test_data_items?parent_id=0`;
        fetch(url, { mode: "cors" })
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result) {
                        const parents = result;
                        // let chirldrens: OverviewDataGroupType[];
                        // if (isParent) {
                        //   chirldrens = result.filter(
                        //     (item: OverviewDataGroupType) => item.p_type_id !== 0
                        //   );
                        // } else {
                        //   chirldrens = result.filter(
                        //     (item: OverviewDataGroupType) =>
                        //       item.p_type_id === input.p_type_id
                        //   );
                        // }

                        setInput({
                            ...input,
                            item_name: "",
                        });

                        parents.sort((a: any, b: any) => {
                            return a.order - b.order;
                        });

                        setOverviewDataGroupParents(parents);
                        // setOverviewDataGroupChildrens(chirldrens);
                        // setOverviewDataGroupChildrensOrigin(
                        //   result.filter(
                        //     (item: OverviewDataGroupType) => item.p_type_id !== 0
                        //   )
                        // );
                    }
                },
                (error) => {
                    console.log(error, "Error: loadTestDataTable");
                    showError();
                }
            );
    }, [isLoading]);

    const setField = (field: number, value: string, order?: number, parent_id?: string) => {
        setInput({ id: field, item_name: value, order, parent_id });
    };

    const resetDefiner = () => {
        setDataType(1);
        setUnit("");
        setUnitCheck("");
        setVector(false);
    };

    const handleCheckbox = (
        item: TestDataItemNewType,
        value: string
    ) => {
        // const data = isParent
        //   ? overviewDataGroupParents
        //   : overviewDataGroupChildrensOrigin;
        // const overview = data.find((test) => test.type_name === value);

        // if (overview) {
        //   setDataType(overview.definer.data_type || 1);
        //   setUnit(overview.definer.unit || "");
        //   setUnitCheck(overview.definer.unit || "");
        //   setVector(!!overview.vector);
        // }

        // setIsShowChildren(true);
        // setVector(vector === 1);
        setInput({ id: item.id, parent_id: item.parent_id, item_name: value, order: item.order });
    };

    const swapArrayLocs = (arr: any, index1: number, index2: number) => {
        [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
    };

    const onMouseClickUp = (index: number, data: any) => {
        console.log('onMouseClickUp: ', index, data);

        let overviewDataGroupTemp = _cloneDeep(data);

        overviewDataGroupTemp[index].order = data[index - 1].order;
        overviewDataGroupTemp[index - 1].order = data[index].order;

        swapArrayLocs(overviewDataGroupTemp, index - 1, index);
        // setOverviewGroupChangeOrder([
        //     overviewDataGroupTemp[index],
        //     overviewDataGroupTemp[index - 1],
        // ]);
        if (isParent) {
            setOverviewDataGroupParents(overviewDataGroupTemp);
        } else {
            setOverviewDataGroupChildrens(overviewDataGroupTemp);
        }
        let overviewGroupChangeSort = [
            overviewDataGroupTemp[index],
            overviewDataGroupTemp[index - 1],
        ];
        onUpdateSort(overviewGroupChangeSort);
    };

    const onMouseClickDown = (index: number, data: any) => {
        let overviewDataGroupTemp = _cloneDeep(data);

        overviewDataGroupTemp[index].order = data[index + 1].order;
        overviewDataGroupTemp[index + 1].order = data[index].order;

        swapArrayLocs(overviewDataGroupTemp, index + 1, index);
        // setOverviewGroupChangeOrder([
        //     overviewDataGroupTemp[index],
        //     overviewDataGroupTemp[index + 1],
        // ]);
        if (isParent) {
            setOverviewDataGroupParents(overviewDataGroupTemp);
        } else {
            setOverviewDataGroupChildrens(overviewDataGroupTemp);
        }
        let overviewGroupChangeSort = [
            overviewDataGroupTemp[index],
            overviewDataGroupTemp[index + 1],
        ];
        onUpdateSort(overviewGroupChangeSort);
    };
    const onUpdateSort = (overviewGroupChangeSort: any) => {
        const url = `${process.env.REACT_APP_API_URL}/api/settings/test_data_item/update`;
        fetchAPI(overviewGroupChangeSort, url, "PUT", '変更しました。');
    };

    const onGetChildren = () => {
        if (input.id != 0) {
            history.push(`/faculty/settings/test_data_item/${input.id}`);
        }
    };

    const backToParent = (e: any) => {
        e.preventDefault();
        setInput({ id: 0, order: 0, item_name: "" });
        setIsParent(true);
    };

    const vectorCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVector(e.target.checked);
    };

    const onChangeDataType = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataType(Number(e.target.value));
    };

    const onChangeUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUnit(e.target.value);
    };

    const onCreate = () => {
        setIsSubmit(SUBMIT_TYPE.CREATE);
        const newData = {
            ...input,
            vector: vector ? 1 : 0,
            isParent: isParent ? 1 : 0,
        };
        newData.parent_id = '0';
        const url = `${process.env.REACT_APP_API_URL}/api/settings/test_data_item/create`;
        fetchAPI(newData, url, "POST", '追加しました。');
    };

    const onUpdate = () => {
        setIsSubmit(SUBMIT_TYPE.UPDATE);
        const overview: any = overviewGroupChangeOrder.find(
            (item: any) => item.id === input.id
        );
        if (overview) {
            overview.item_name = input.item_name;
            //   overview.vector = vector ? 1 : 0;
            //   overview.isParent = isParent ? 1 : 0;
        } else {
            overviewGroupChangeOrder.push({
                ...input,
                vector: vector ? 1 : 0,
            } as any);
        }

        console.log(overviewGroupChangeOrder);

        const url = `${process.env.REACT_APP_API_URL}/api/settings/test_data_item/update`;
        fetchAPI(overviewGroupChangeOrder, url, "PUT", '変更しました。');
        // setInput({ id: 0, order: 0, item_name: "" });
    };
    const onDelete = () => {
        setIsSubmit(SUBMIT_TYPE.DELETE);
        console.log('input.id', input.id);

        const url = `${process.env.REACT_APP_API_URL}/api/settings/test_data_item/delete`;
        const params = {
            id: input.id
        }
        fetchAPI(params, url, "POST", '削除しました。');
        // setInput({ id: 0, order: 0, item_name: "" });
    }
    const onSubmitDefiner = () => {
        setIsSubmit(SUBMIT_TYPE.DEFINER);
        const newData = {
            type_id: input.id,
            unit,
            data_type: dataType,
        };

        const url = `${process.env.REACT_APP_API_URL}/api/settings/overview/definer/create_or_update`;
        fetchAPI(newData, url, "PUT");
    };

    const showError = () => {
        setErrors(true);
        setTimeout(() => {
            setErrors(false);
        }, 3000);
    };

    const fetchAPI = (data: any, url: string, method: string, message: string = '') => {
        const mode = "cors";
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        const body = JSON.stringify(data);

        fetch(url, { method, mode, headers, body })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setIsSuccess(true);
                    setSuccessMessage(message);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                    setIsLoading(!isLoading);
                } else {
                    showError();
                }
                setIsSubmit("");
            })
            .catch(() => {
                showError();
                setIsSubmit("");
            });
    };

    const inputDirty = () => {
        // if (isShowChildren) return false;
        // if (overviewGroupChangeOrder.length > 0) return false;
        // const isDirty = overviewDataGroupParents.find(
        //   (ov: any) => ov.item_name === input.item_name
        // );

        // console.log(isDirty, "isDirty");
        // console.log(input);

        // if (isDirty) return true;
        if (input.id == 0) return true;

        return false;
    };

    const contentTestDataItem = (data: TestDataItemNewType[]) => (
        <StyledOverviewDataGroup>
            {data.length > 0 &&
                data.map((item, i) => (
                    <StyledOverviewGroup key={i}>
                        <Table>
                            <tbody className="ManagerTestDataItems">
                                <tr key={item.order}>
                                    <td className="parent-name">
                                        <div className="itemName">
                                            {i === 0 && <span>設定項目を選択：</span>}
                                        </div>
                                        <Form.Check
                                            name="name"
                                            inline
                                            type="radio"
                                            id={'item_' + item.id.toString() || ""}
                                            label={item.item_name}
                                            value={item.item_name || ""}
                                            onChange={(e) =>
                                                handleCheckbox(
                                                    item,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td
                                        className="w-100px btn-move"
                                        style={{ backgroundColor: "#ffffff", border: "none" }}
                                    >
                                        {i !== 0 && (
                                            <Button
                                                className="button-up"
                                                variant="secondary"
                                                size="lg"
                                                onClick={() => onMouseClickUp(i, data)}
                                            >
                                                <i className="far fa-arrow-alt-circle-up"></i>
                                            </Button>
                                        )}
                                        {i !== data.length - 1 && (
                                            <Button
                                                className="button-down"
                                                variant="secondary"
                                                size="lg"
                                                onClick={() => onMouseClickDown(i, data)}
                                            >
                                                <i className="far fa-arrow-alt-circle-down"></i>
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </StyledOverviewGroup>
                ))}

            {/* <Form.Group as={Row} controlId="confirm2" className="index-student-form">
        <Col md={8}>
          <Form.Control
            type="checkbox"
            checked={vector}
            className="index-student-checkbox"
            onChange={vectorCheckbox}
          />
        </Col>
         <Form.Label column md={4} className="text-left">
          種別を折り返し
        </Form.Label> 
      </Form.Group>*/}

            <StyledFormSubmit>
                <Col md={12} className="d-flex">
                    <Form.Control
                        className="input-name"
                        type="text"
                        value={input.item_name || ""}
                        onChange={(e) =>
                            setField(input.id || 0, e.target.value, input.order, input.parent_id)
                        }
                    />
                    <Button
                        className="ml-3 noPrint"
                        variant="primary"
                        type="submit"
                        disabled={inputDirty()}
                        onClick={onUpdate}
                    >
                        {isSubmit === SUBMIT_TYPE.UPDATE ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </>
                        ) : (
                            <span>変更</span>
                        )}
                    </Button>
                    <Button
                        className="ml-3 noPrint"
                        variant="primary"
                        type="submit"
                        disabled={inputDirty()}
                        onClick={onDelete}>

                        {isSubmit === SUBMIT_TYPE.DELETE ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </>
                        ) : (
                            <span>削除</span>
                        )}
                    </Button>
                </Col>
                <Button
                    className="noPrint ml-3 mt-3"
                    variant="primary"
                    type="submit"
                    disabled={input.item_name === ""}
                    onClick={onCreate}
                >
                    {isSubmit === SUBMIT_TYPE.CREATE ? (
                        <>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </>
                    ) : (
                        <span>新規追加</span>
                    )}
                </Button>
                <Button
                    className="noPrint ml-3 mt-3"
                    variant="primary"
                    type="submit"
                    disabled={inputDirty()}
                    onClick={onGetChildren}
                >
                    子要素
                </Button>
            </StyledFormSubmit>

            {isSuccess && (
                <Row className="ml-4">
                    <Col md={9} className="mt-5" style={{ margin: "auto" }}>
                        <Alert variant="success">{successMessage}</Alert>
                    </Col>
                </Row>
            )}
            {isErrors && (
                <Row className="ml-4">
                    <Col md={9} className="mt-5" style={{ margin: "auto" }}>
                        <Alert variant="danger">エラーが発生しました</Alert>
                    </Col>
                </Row>
            )}

            {/* <StyledDefiner>
        <p>
          入力規則の設定：
          {unitCheck ? "現在、設定されています。" : "現在、設定されてません。"}
        </p>
        <StyledBtn>
          <Form.Control
            as="select"
            value={dataType}
            onChange={onChangeDataType}
          >
            <option value={1}>数値</option>
            <option value={2}>小数点</option>
            <option value={3}>〇</option>
          </Form.Control>
          <Form.Label className="ml-2 text-left">単位：</Form.Label>
          <Form.Control
            className="input-name"
            type="text"
            value={unit || ""}
            onChange={onChangeUnit}
          />
          <Button
            className="ml-3 noPrint"
            variant="primary"
            type="submit"
            disabled={!isShowChildren}
            onClick={onSubmitDefiner}
          >
            {isSubmit === SUBMIT_TYPE.DEFINER ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              <span>更新</span>
            )}
          </Button>
        </StyledBtn>
        <Link style={{ marginRight: "-55px", float: "right" }} color="primary">
          解除
        </Link>
      </StyledDefiner> */}
        </StyledOverviewDataGroup>
    );

    return (
        <Container className="mt-5 mb-5">
            <h2>検査データ項目設定</h2>
            <Breadcrumbs aria-label="breadcrumb">
                {isParent && <Typography color="textPrimary">項目一覧</Typography>}
                {!isParent && (
                    <Link onClick={backToParent} color="primary">
                        項目一覧
                    </Link>
                )}
                {!isParent && (
                    <Typography color="textPrimary">{parentNameBreakcrum}</Typography>
                )}
            </Breadcrumbs>
            {contentTestDataItem(
                isParent ? overviewDataGroupParents : overviewDataGroupChildrens
            )}
        </Container>
    );
};

const StyledOverviewDataGroup = styled.div`
  width: 500px;
  margin: 100px auto;
`;
const StyledOverviewGroup = styled.div`
  table {
    tr {
      td:first-child {
        display: flex;
      }
      td {
        border-top: none;
        .itemName {
          width: 150px;
        }
      }

      .btn-move {
        padding: 0px;
      }

      .parent-name {
        .form-check {
          color: #3492ff;
        }
      }

      input[type="radio"] {
        transform: scale(1.5);
        margin-right: 10px;
      }
    }
  }
`;

const StyledFormSubmit = styled(Row)`
  .input-name {
    width: 300px;
  }

  .btn,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #e6dab7;
    border-color: #e6dab7;
    color: #333;
  }
`;

const StyledDefiner = styled.div`
  float: left;
  display: inline-block;
  padding: 20px 60px;
  height: 150px;
  margin-top: 30px;
  border: 1px solid;
`;

const StyledBtn = styled.div`
  display: flex;

  .form-control,
  input {
    width: 100px;
  }
`;

export default withRouter(ManagerTestDataItems);