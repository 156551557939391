import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import TestData from "./test_data/TestData";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SignUpBox from "../login/SignUpBox";
import "../../assets/styles/IndexFaculty.scss";

interface Props extends RouteComponentProps<{}> {}
interface State {}

class CreatePatientTestData extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { location } = this.props;
    const { patientID, studentID, facultyID } = location.state as any;

    return (
      <Container fluid className="mt-5 mb-5" style={{ position: "relative" }}>
        <Row>
          <Col md={12}>
            <h2 className="mb-5">検査データ入力</h2>
            <TestData
              patientID={patientID}
              studentID={studentID}
              facultyID={facultyID}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(CreatePatientTestData);
