import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { SelfEvaluationTypeKansaiKokusaiTougou } from "../../../models/Types";

interface Props extends RouteComponentProps<{ studentID: string }> {}
interface State extends SelfEvaluationTypeKansaiKokusaiTougou {}

class SelfEvaluationTableKansaiKokusaiTougouForFaculty extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      studentID: this.props.match.params.studentID,
      studentName: "",
      startDate: "",
      endDate: "",
      attendDays: 0,
      attendDaysAll: 0,
      lateEarlyLeaveHours: 0,
      item1_1_1: "",
      item1_1_2: "",
      item1_2_1: "",
      item1_2_2: "",
      item1_3_1: "",
      item1_3_2: "",
      item2_1_1: "",
      item2_1_2: "",
      item2_2_1: "",
      item2_2_2: "",
      item3_1_1: "",
      item3_1_2: "",
      item4_1_1: "",
      item4_1_2: "",
      item4_1_3: "",
      item5_1_1: "",
      item5_1_2: "",
      item5_1_3: "",
    };

    this.onClickCancel = this.onClickCancel.bind(this);
  }

  public onClickCancel() {
    this.props.history.goBack();
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation/kansai_kokusai_tougou/fetch?student_id=${this.state.studentID}`;

    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          item1_1_1: result.item1_1_1,
          item1_1_2: result.item1_1_2,
          item1_2_1: result.item1_2_1,
          item1_2_2: result.item1_2_2,
          item1_3_1: result.item1_3_1,
          item1_3_2: result.item1_3_2,
          item2_1_1: result.item2_1_1,
          item2_1_2: result.item2_1_2,
          item2_2_1: result.item2_2_1,
          item2_2_2: result.item2_2_2,
          item3_1_1: result.item3_1_1,
          item3_1_2: result.item3_1_2,
          item4_1_1: result.item4_1_1,
          item4_1_2: result.item4_1_2,
          item4_1_3: result.item4_1_3,
          item5_1_1: result.item5_1_1,
          item5_1_2: result.item5_1_2,
          item5_1_3: result.item5_1_3,
        });
      })
      .catch(console.error);

    const url2 = `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${this.state.studentID}`;

    fetch(url2)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          studentName: result.studentName,
          startDate: result.startDate,
          endDate: result.endDate,
          // internHospitalWard: this.state.internHospitalWard,
          // attendDays: this.state.attendDays,
          // attendDaysAll: this.state.attendDaysAll,
          // lateEarlyLeaveHours: this.state.lateEarlyLeaveHours,
        });
      })
      .catch(console.error);
  }

  public render() {
    return (
      <Container>
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <h2 className="m-5">自己評価表 (関西国際大学 統合実習)</h2>
            <Table
              id="self_evaluation_kansai_kokusai_tougou_student_info"
              bordered
            >
              <tbody>
                <tr>
                  <td className="text-left">
                    学生番号 &nbsp; {this.state.studentID}
                    <br />
                    学生氏名 &nbsp; {this.state.studentName}
                  </td>
                  <td className="text-left">
                    実習場所
                    <br />
                    神戸大学医学部付属病院 Web病棟
                  </td>
                </tr>
                <tr>
                  <td className="text-left">
                    実習期間
                    <br />
                    {this.state.startDate} ~ {this.state.endDate}
                  </td>
                  <td className="text-left">
                    出席状況 -/-日
                    <br />
                    遅刻・早退時間数 -時間
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table id="self_evaluation_goal_kansai_kokusai_tougou" bordered>
              <thead className="thead-dark">
                <tr>
                  <th>実習目標</th>
                  <th colSpan={1}>&emsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3} className="goal_big">
                    1.
                    既習の知識や技術を応用し、対象者の特性や健康上のニーズに応じたケアを提供することができる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td rowSpan={2}>
                        患者のかかえる疾患と病態生理を理解し、対応した援助を学ぶ。
                      </td>
                      <td className="goal_small">
                        患者の身体状況とその原因および病態生理について述べる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_1_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_1_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_1_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_1_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="goal_small">
                        患者の身体状況に対応した援助を行う。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_1_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_1_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_1_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_1_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="p-0">
                    <tr>
                      <td rowSpan={2}>
                        患者の日常生活上、充足されていない二―ズを明らかにし、援助を行う。
                      </td>
                      <td className="goal_small">
                        患者が日常生活を送るうえで、充足されていないニーズについて述べる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_2_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_2_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_2_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_2_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="goal_small">
                        その人らしさを尊重した方法で、日常生活の援助を行う。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_2_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_2_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_2_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_2_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="p-0">
                    <tr>
                      <td rowSpan={2}>
                        患者の心理・社会的状態を理解し、援助を行う。
                      </td>
                      <td className="goal_small">
                        患者の心理・社会的状態について述べる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_3_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_3_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_3_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_3_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="goal_small">
                        患者の心理・社会的状態に対応した援助を行う。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_3_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_3_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_3_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_3_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} className="goal_big">
                    2．保健医療チームの一員として、計画的・継続的に提供される看護を学ぶことができる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td rowSpan={2}>
                        看護職のメンバーおよびリーダーの役割・責任を理解する。
                      </td>
                      <td className="goal_small">
                        メンバーおよびリーダーの役割・責任を説明する。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_1_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_1_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_1_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_1_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="goal_small">
                        メンバーの役割・責任を果たす。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_1_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_1_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_1_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_1_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="p-0">
                    <tr>
                      <td rowSpan={2}>
                        複数の患者への看護の優先順位を考慮した行動計画を立案し、援助を行う。
                      </td>
                      <td className="goal_small">
                        複数の患者への看護の優先順位を明らかにした行動計画について述べる。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_2_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_2_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_2_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_2_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="goal_small">
                        複数の患者への行動計画にそった援助を行う。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_2_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_2_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_2_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_2_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    3．他職種との協働における看護専門職者の役割・機能と、保健・医療・福祉の総合的ケア提供の在り方について学ぶことができる。
                  </td>
                  <td className="p-0">
                    <tr id="item3_1_1">
                      <td rowSpan={2}>
                        他職種および他機関との協働または連携について理解する。
                      </td>
                      <td className="goal_small">
                        他部門、他職種および他機関との協働または連携における看護師の役割について説明する。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_1_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_1_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_1_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_1_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="goal_small">
                        他部門、他職種および他機関との協働または連携における看護師の役割について話し合う。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_1_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_1_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_1_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_1_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    4．看護の専門性を理解し、専門職者としての自覚を高めるとともに、学生から社会人に移行する準備ができる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td rowSpan={3}>看護職者としてのあるべき態度を養う。</td>
                      <td className="goal_small">
                        いかなる場面においても、人としての尊厳を思いやる態度・言葉遣いをする。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_1_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_1_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_1_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_1_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="goal_small">
                        患者および家族の気持ちを思いやり、ケアに積極的に取り組む。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_1_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_1_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_1_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_1_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="goal_small">
                        看護を学ぶ者として、責任をもって行動する。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_1_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_1_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_1_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_1_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    5．看護職者としての役割、責務、態度について学習を深め、将来に向けての自己課題を明確にできる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td rowSpan={3}>
                        看護専門職者としての役割、責務について理解する。
                      </td>
                      <td className="goal_small">
                        実務を通して看護専門職者としての役割、責務を学び、自立して行動する。
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item5_1_1 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item5_1_1 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item5_1_1 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item5_1_1 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="goal_small">
                        課題学習およびカンファレンスに積極的に取り組む。
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item5_1_2 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item5_1_2 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item5_1_2 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item5_1_2 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="goal_small">
                        将来に向けての自己課題を明確にする。
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item5_1_3 === "A"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item5_1_3 === "B"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item5_1_3 === "C"}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item5_1_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item5_1_3 === "D"}
                          disabled
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-4">
          <Button
            variant="outline-secondary"
            size="lg"
            type="submit"
            onClick={this.onClickCancel}
          >
            Back
          </Button>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SelfEvaluationTableKansaiKokusaiTougouForFaculty);
