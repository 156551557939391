import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StudentRegisterForm from "./StudentRegisterForm";

interface Props extends RouteComponentProps<{ type: string }> { }
interface State { }

class StudentRegister extends React.Component<Props, State> {
    public render() {
        return (
            <Container fluid>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <StudentRegisterForm />
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>
        );
    }
}

export default StudentRegister;
