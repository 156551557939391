import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { SelfEvaluationTypeKansaiKokusai } from "../../../models/Types";

interface Props extends RouteComponentProps<{ studentID: string }> {}
interface State extends SelfEvaluationTypeKansaiKokusai {}

class SelfEvaluationTableKansaiKokusaiKyusei extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      studentID: this.props.match.params.studentID,
      item1_1: "",
      item1_2: "",
      item1_3: "",
      item1_4: "",
      item1_5: "",
      item1_6: "",
      item1_7: "",
      item1_8: "",
      item2_1: "",
      item2_2: "",
      item2_3: "",
      item2_4: "",
      item2_5: "",
      item2_6: "",
      item3_1: "",
      item3_2: "",
      item3_3: "",
      item3_4: "",
      item3_5: "",
      item3_6: "",
      item3_7: "",
      item3_8: "",
      item3_9: "",
      item3_10: "",
      item3_11: "",
      item3_12: "",
      item3_13: "",
      item3_14: "",
      item4_1: "",
      item4_2: "",
      item4_3: "",
      item4_4: "",
      item4_5: "",
      item4_6: "",
      item5_1_1: "",
      item5_1_2: "",
      item5_1_3: "",
      item5_2_1: "",
      item5_2_2: "",
      item5_2_3: "",
      item5_3_1: "",
      item5_3_2: "",
      item5_3_3: "",
      item5_4_1: "",
      item5_4_2: "",
      item5_4_3: "",
      item5_4_4: "",
      item5_4_5: "",
      item5_4_6: "",
      item5_4_7: "",
      item5_5_1: "",
      item5_5_2: "",
      item5_5_3: "",
    };

    this.onChangeItem1_1 = this.onChangeItem1_1.bind(this);
    this.onChangeItem1_2 = this.onChangeItem1_2.bind(this);
    this.onChangeItem1_3 = this.onChangeItem1_3.bind(this);
    this.onChangeItem1_4 = this.onChangeItem1_4.bind(this);
    this.onChangeItem1_5 = this.onChangeItem1_5.bind(this);
    this.onChangeItem1_6 = this.onChangeItem1_6.bind(this);
    this.onChangeItem1_7 = this.onChangeItem1_7.bind(this);
    this.onChangeItem1_8 = this.onChangeItem1_8.bind(this);
    this.onChangeItem2_1 = this.onChangeItem2_1.bind(this);
    this.onChangeItem2_2 = this.onChangeItem2_2.bind(this);
    this.onChangeItem2_3 = this.onChangeItem2_3.bind(this);
    this.onChangeItem2_4 = this.onChangeItem2_4.bind(this);
    this.onChangeItem2_5 = this.onChangeItem2_5.bind(this);
    this.onChangeItem2_6 = this.onChangeItem2_6.bind(this);
    this.onChangeItem3_1 = this.onChangeItem3_1.bind(this);
    this.onChangeItem3_2 = this.onChangeItem3_2.bind(this);
    this.onChangeItem3_3 = this.onChangeItem3_3.bind(this);
    this.onChangeItem3_4 = this.onChangeItem3_4.bind(this);
    this.onChangeItem3_5 = this.onChangeItem3_5.bind(this);
    this.onChangeItem3_6 = this.onChangeItem3_6.bind(this);
    this.onChangeItem3_7 = this.onChangeItem3_7.bind(this);
    this.onChangeItem3_8 = this.onChangeItem3_8.bind(this);
    this.onChangeItem3_9 = this.onChangeItem3_9.bind(this);
    this.onChangeItem3_10 = this.onChangeItem3_10.bind(this);
    this.onChangeItem3_11 = this.onChangeItem3_11.bind(this);
    this.onChangeItem3_12 = this.onChangeItem3_12.bind(this);
    this.onChangeItem3_13 = this.onChangeItem3_13.bind(this);
    this.onChangeItem3_14 = this.onChangeItem3_14.bind(this);
    this.onChangeItem4_1 = this.onChangeItem4_1.bind(this);
    this.onChangeItem4_2 = this.onChangeItem4_2.bind(this);
    this.onChangeItem4_3 = this.onChangeItem4_3.bind(this);
    this.onChangeItem4_4 = this.onChangeItem4_4.bind(this);
    this.onChangeItem4_5 = this.onChangeItem4_5.bind(this);
    this.onChangeItem4_6 = this.onChangeItem4_6.bind(this);
    this.onChangeItem5_1_1 = this.onChangeItem5_1_1.bind(this);
    this.onChangeItem5_1_2 = this.onChangeItem5_1_2.bind(this);
    this.onChangeItem5_1_3 = this.onChangeItem5_1_3.bind(this);
    this.onChangeItem5_2_1 = this.onChangeItem5_2_1.bind(this);
    this.onChangeItem5_2_2 = this.onChangeItem5_2_2.bind(this);
    this.onChangeItem5_2_3 = this.onChangeItem5_2_3.bind(this);
    this.onChangeItem5_3_1 = this.onChangeItem5_3_1.bind(this);
    this.onChangeItem5_3_2 = this.onChangeItem5_3_2.bind(this);
    this.onChangeItem5_3_3 = this.onChangeItem5_3_3.bind(this);
    this.onChangeItem5_4_1 = this.onChangeItem5_4_1.bind(this);
    this.onChangeItem5_4_2 = this.onChangeItem5_4_2.bind(this);
    this.onChangeItem5_4_3 = this.onChangeItem5_4_3.bind(this);
    this.onChangeItem5_4_4 = this.onChangeItem5_4_4.bind(this);
    this.onChangeItem5_4_5 = this.onChangeItem5_4_5.bind(this);
    this.onChangeItem5_4_6 = this.onChangeItem5_4_6.bind(this);
    this.onChangeItem5_4_7 = this.onChangeItem5_4_7.bind(this);
    this.onChangeItem5_5_1 = this.onChangeItem5_5_1.bind(this);
    this.onChangeItem5_5_2 = this.onChangeItem5_5_2.bind(this);
    this.onChangeItem5_5_3 = this.onChangeItem5_5_3.bind(this);

    this.onClickCancel = this.onClickCancel.bind(this);
    this.onSubmitSelfEvaluation = this.onSubmitSelfEvaluation.bind(this);
  }

  public onChangeItem1_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    console.log(value);
    this.setState({ item1_1: value });
  }
  public onChangeItem1_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item1_2: value });
  }
  public onChangeItem1_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item1_3: value });
  }
  public onChangeItem1_4(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item1_4: value });
  }
  public onChangeItem1_5(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item1_5: value });
  }
  public onChangeItem1_6(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item1_6: value });
  }
  public onChangeItem1_7(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item1_7: value });
  }
  public onChangeItem1_8(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item1_8: value });
  }
  public onChangeItem2_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item2_1: value });
  }
  public onChangeItem2_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item2_2: value });
  }
  public onChangeItem2_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item2_3: value });
  }
  public onChangeItem2_4(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item2_4: value });
  }
  public onChangeItem2_5(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item2_5: value });
  }
  public onChangeItem2_6(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item2_6: value });
  }
  public onChangeItem3_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_1: value });
  }
  public onChangeItem3_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_2: value });
  }
  public onChangeItem3_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_3: value });
  }
  public onChangeItem3_4(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_4: value });
  }
  public onChangeItem3_5(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_5: value });
  }
  public onChangeItem3_6(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_6: value });
  }
  public onChangeItem3_7(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_7: value });
  }
  public onChangeItem3_8(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_8: value });
  }
  public onChangeItem3_9(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_9: value });
  }
  public onChangeItem3_10(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_10: value });
  }
  public onChangeItem3_11(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_11: value });
  }
  public onChangeItem3_12(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_12: value });
  }
  public onChangeItem3_13(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_13: value });
  }
  public onChangeItem3_14(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item3_14: value });
  }
  public onChangeItem4_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item4_1: value });
  }
  public onChangeItem4_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item4_2: value });
  }
  public onChangeItem4_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item4_3: value });
  }
  public onChangeItem4_4(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item4_4: value });
  }
  public onChangeItem4_5(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item4_5: value });
  }
  public onChangeItem4_6(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item4_6: value });
  }
  public onChangeItem5_1_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_1_1: value });
  }
  public onChangeItem5_1_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_1_2: value });
  }
  public onChangeItem5_1_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_1_3: value });
  }
  public onChangeItem5_2_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_2_1: value });
  }
  public onChangeItem5_2_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_2_2: value });
  }
  public onChangeItem5_2_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_2_3: value });
  }
  public onChangeItem5_3_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_3_1: value });
  }
  public onChangeItem5_3_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_3_2: value });
  }
  public onChangeItem5_3_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_3_3: value });
  }
  public onChangeItem5_4_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_4_1: value });
  }
  public onChangeItem5_4_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_4_2: value });
  }
  public onChangeItem5_4_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_4_3: value });
  }
  public onChangeItem5_4_4(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_4_4: value });
  }
  public onChangeItem5_4_5(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_4_5: value });
  }
  public onChangeItem5_4_6(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_4_6: value });
  }
  public onChangeItem5_4_7(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_4_7: value });
  }
  public onChangeItem5_5_1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_5_1: value });
  }
  public onChangeItem5_5_2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_5_2: value });
  }
  public onChangeItem5_5_3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.setState({ item5_5_3: value });
  }

  public onClickCancel() {
    this.props.history.goBack();
  }

  public onSubmitSelfEvaluation(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation/kansai_kokusai/save`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const newSelfEvaluation = {
      self_evaluation: {
        student_id: this.state.studentID,
        item1_1: this.state.item1_1,
        item1_2: this.state.item1_2,
        item1_3: this.state.item1_3,
        item1_4: this.state.item1_4,
        item1_5: this.state.item1_5,
        item1_6: this.state.item1_6,
        item1_7: this.state.item1_7,
        item1_8: this.state.item1_8,
        item2_1: this.state.item2_1,
        item2_2: this.state.item2_2,
        item2_3: this.state.item2_3,
        item2_4: this.state.item2_4,
        item2_5: this.state.item2_5,
        item2_6: this.state.item2_6,
        item3_1: this.state.item3_1,
        item3_2: this.state.item3_2,
        item3_3: this.state.item3_3,
        item3_4: this.state.item3_4,
        item3_5: this.state.item3_5,
        item3_6: this.state.item3_6,
        item3_7: this.state.item3_7,
        item3_8: this.state.item3_8,
        item3_9: this.state.item3_9,
        item3_10: this.state.item3_10,
        item3_11: this.state.item3_11,
        item3_12: this.state.item3_12,
        item3_13: this.state.item3_13,
        item3_14: this.state.item3_14,
        item4_1: this.state.item4_1,
        item4_2: this.state.item4_2,
        item4_3: this.state.item4_3,
        item4_4: this.state.item4_4,
        item4_5: this.state.item4_5,
        item4_6: this.state.item4_6,
        item5_1_1: this.state.item5_1_1,
        item5_1_2: this.state.item5_1_2,
        item5_1_3: this.state.item5_1_3,
        item5_2_1: this.state.item5_2_1,
        item5_2_2: this.state.item5_2_2,
        item5_2_3: this.state.item5_2_3,
        item5_3_1: this.state.item5_3_1,
        item5_3_2: this.state.item5_3_2,
        item5_3_3: this.state.item5_3_3,
        item5_4_1: this.state.item5_4_1,
        item5_4_2: this.state.item5_4_2,
        item5_4_3: this.state.item5_4_3,
        item5_4_4: this.state.item5_4_4,
        item5_4_5: this.state.item5_4_5,
        item5_4_6: this.state.item5_4_6,
        item5_4_7: this.state.item5_4_7,
        item5_5_1: this.state.item5_5_1,
        item5_5_2: this.state.item5_5_2,
        item5_5_3: this.state.item5_5_3,
      },
    };

    console.log(newSelfEvaluation);
    const body = JSON.stringify(newSelfEvaluation);

    fetch(url, { method, headers, body })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.status === "success") {
          this.props.history.goBack();
        } else {
          console.log("API Error");
        }
      })
      .catch(console.error);
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/self_evaluation/kansai_kokusai/fetch?student_id=${this.state.studentID}`;

    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          item1_1: result.item1_1,
          item1_2: result.item1_2,
          item1_3: result.item1_3,
          item1_4: result.item1_4,
          item1_5: result.item1_5,
          item1_6: result.item1_6,
          item1_7: result.item1_7,
          item1_8: result.item1_8,
          item2_1: result.item2_1,
          item2_2: result.item2_2,
          item2_3: result.item2_3,
          item2_4: result.item2_4,
          item2_5: result.item2_5,
          item2_6: result.item2_6,
          item3_1: result.item3_1,
          item3_2: result.item3_2,
          item3_3: result.item3_3,
          item3_4: result.item3_4,
          item3_5: result.item3_5,
          item3_6: result.item3_6,
          item3_7: result.item3_7,
          item3_8: result.item3_8,
          item3_9: result.item3_9,
          item3_10: result.item3_10,
          item3_11: result.item3_11,
          item3_12: result.item3_12,
          item3_13: result.item3_13,
          item3_14: result.item3_14,
          item4_1: result.item4_1,
          item4_2: result.item4_2,
          item4_3: result.item4_3,
          item4_4: result.item4_4,
          item4_5: result.item4_5,
          item4_6: result.item4_6,
          item5_1_1: result.item5_1_1,
          item5_1_2: result.item5_1_2,
          item5_1_3: result.item5_1_3,
          item5_2_1: result.item5_2_1,
          item5_2_2: result.item5_2_2,
          item5_2_3: result.item5_2_3,
          item5_3_1: result.item5_3_1,
          item5_3_2: result.item5_3_2,
          item5_3_3: result.item5_3_3,
          item5_4_1: result.item5_4_1,
          item5_4_2: result.item5_4_2,
          item5_4_3: result.item5_4_3,
          item5_4_4: result.item5_4_4,
          item5_4_5: result.item5_4_5,
          item5_4_6: result.item5_4_6,
          item5_4_7: result.item5_4_7,
          item5_5_1: result.item5_5_1,
          item5_5_2: result.item5_5_2,
          item5_5_3: result.item5_5_3,
        });
      })
      .catch(console.error);
  }

  public render() {
    return (
      <Container>
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <h2 className="m-5">自己評価表 (関西国際大学 急性実習)</h2>
            <Table id="self_evaluation_goal_kansai_kokusai" bordered>
              <thead className="thead-dark">
                <tr>
                  <th>実習目標</th>
                  <th colSpan={1}>&emsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="goal_big">
                    1.【術前】
                    <br />
                    手術を受ける患者の器質的・機能的障害と心理・社会的な状況を把握し、麻酔・手術の影響を予測して合併症を予防するための援助を行うことができる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td>C</td>
                      <td className="goal_small">
                        患者に生じている器質的・機能的な障害を指摘できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_1 === "A"}
                          onChange={this.onChangeItem1_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_1 === "B"}
                          onChange={this.onChangeItem1_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_1 === "C"}
                          onChange={this.onChangeItem1_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_1 === "D"}
                          onChange={this.onChangeItem1_1}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        患者に生じている器質的・機能的な障害に対応した援助ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_2 === "A"}
                          onChange={this.onChangeItem1_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_2 === "B"}
                          onChange={this.onChangeItem1_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_2 === "C"}
                          onChange={this.onChangeItem1_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_2 === "D"}
                          onChange={this.onChangeItem1_2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        手術・麻酔を受けることによって予測される身体的影響を指摘できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_3 === "A"}
                          onChange={this.onChangeItem1_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_3 === "B"}
                          onChange={this.onChangeItem1_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_3 === "C"}
                          onChange={this.onChangeItem1_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_3 === "D"}
                          onChange={this.onChangeItem1_3}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>術後合併症の予防をはかるよう援助できる。</td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_4 === "A"}
                          onChange={this.onChangeItem1_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_4 === "B"}
                          onChange={this.onChangeItem1_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_4 === "C"}
                          onChange={this.onChangeItem1_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_4"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_4 === "D"}
                          onChange={this.onChangeItem1_4}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        術前の検査、治療、処置の目的および留意点を説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_5 === "A"}
                          onChange={this.onChangeItem1_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_5 === "B"}
                          onChange={this.onChangeItem1_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_5 === "C"}
                          onChange={this.onChangeItem1_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_5"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_5 === "D"}
                          onChange={this.onChangeItem1_5}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        術前の検査、治療、処置を受ける患者に対応した適切な援助および介助ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_6"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_6 === "A"}
                          onChange={this.onChangeItem1_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_6"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_6 === "B"}
                          onChange={this.onChangeItem1_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_6"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_6 === "C"}
                          onChange={this.onChangeItem1_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_6"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_6 === "D"}
                          onChange={this.onChangeItem1_6}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        手術を受けるという体験が、患者・家族に及ぼしている心理・社会的影響を指摘できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_7"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_7 === "A"}
                          onChange={this.onChangeItem1_7}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_7"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_7 === "B"}
                          onChange={this.onChangeItem1_7}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_7"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_7 === "C"}
                          onChange={this.onChangeItem1_7}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_7"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_7 === "D"}
                          onChange={this.onChangeItem1_7}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        手術・麻酔を受ける患者・家族の心理・社会的状態に対応した援助ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item1_8"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item1_8 === "A"}
                          onChange={this.onChangeItem1_8}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_8"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item1_8 === "B"}
                          onChange={this.onChangeItem1_8}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_8"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item1_8 === "C"}
                          onChange={this.onChangeItem1_8}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item1_8"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item1_8 === "D"}
                          onChange={this.onChangeItem1_8}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    2.【術中】
                    <br />
                    手術室に入室する患者の心理状態を推測し、それに対応した援助を説明でき、手術見学を通して患者に実施された手術の内容を説明することができる。
                  </td>
                  <td className="goal_small p-0">
                    <tr>
                      <td>C</td>
                      <td className="goal_small">
                        入室時の患者の心理状態を推測し、それに対応した援助が説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_1 === "A"}
                          onChange={this.onChangeItem2_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_1 === "B"}
                          onChange={this.onChangeItem2_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_1 === "C"}
                          onChange={this.onChangeItem2_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_1 === "D"}
                          onChange={this.onChangeItem2_1}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        手術室入室前から手術開始までに行われる麻酔導入、手術準備の内容が説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_2 === "A"}
                          onChange={this.onChangeItem2_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_2 === "B"}
                          onChange={this.onChangeItem2_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_2 === "C"}
                          onChange={this.onChangeItem2_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_2 === "D"}
                          onChange={this.onChangeItem2_2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>手術操作・術中処置についての経過が説明できる。</td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_3 === "A"}
                          onChange={this.onChangeItem2_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_3 === "B"}
                          onChange={this.onChangeItem2_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_3 === "C"}
                          onChange={this.onChangeItem2_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_3 === "D"}
                          onChange={this.onChangeItem2_3}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>麻酔から覚醒する患者の状態が説明できる。</td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_4 === "A"}
                          onChange={this.onChangeItem2_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_4 === "B"}
                          onChange={this.onChangeItem2_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_4 === "C"}
                          onChange={this.onChangeItem2_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_4"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_4 === "D"}
                          onChange={this.onChangeItem2_4}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        患者に装着されるチューブ類・ドレーン類の挿入目的、部位が説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item2_5"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_5 === "A"}
                          onChange={this.onChangeItem2_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_5"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_5 === "B"}
                          onChange={this.onChangeItem2_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_5"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_5 === "C"}
                          onChange={this.onChangeItem2_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_5"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_5 === "D"}
                          onChange={this.onChangeItem2_5}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>手術に携わる医療チームメンバーを指摘できる。</td>
                      <td>
                        <Form.Check
                          name="item2_6"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item2_6 === "A"}
                          onChange={this.onChangeItem2_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_6"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item2_6 === "B"}
                          onChange={this.onChangeItem2_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_6"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item2_6 === "C"}
                          onChange={this.onChangeItem2_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item2_6"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item2_6 === "D"}
                          onChange={this.onChangeItem2_6}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    3.【術後】
                    <br />
                    術後の合併症の予防および変化した形態・機能の回復段階に合わせて日常生活の援助ができ、患者が主体的に療養生活に取り組めるように援助することができる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td>C</td>
                      <td className="goal_small">
                        手術後に必要な身体の観察項目が述べられる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_1 === "A"}
                          onChange={this.onChangeItem3_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_1 === "B"}
                          onChange={this.onChangeItem3_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_1 === "C"}
                          onChange={this.onChangeItem3_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_1 === "D"}
                          onChange={this.onChangeItem3_1}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>術後の身体の観察を行い、異常の早期発見ができる。</td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_2 === "A"}
                          onChange={this.onChangeItem3_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_2 === "B"}
                          onChange={this.onChangeItem3_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_2 === "C"}
                          onChange={this.onChangeItem3_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_2 === "D"}
                          onChange={this.onChangeItem3_2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        手術による形態・機能の変化を指摘することができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_3 === "A"}
                          onChange={this.onChangeItem3_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_3 === "B"}
                          onChange={this.onChangeItem3_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_3 === "C"}
                          onChange={this.onChangeItem3_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_3 === "D"}
                          onChange={this.onChangeItem3_3}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>術後合併症を予防する援助ができる。</td>
                      <td>
                        <Form.Check
                          name="item3_4"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_4 === "A"}
                          onChange={this.onChangeItem3_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_4"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_4 === "B"}
                          onChange={this.onChangeItem3_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_4"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_4 === "C"}
                          onChange={this.onChangeItem3_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_4"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_4 === "D"}
                          onChange={this.onChangeItem3_4}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>手術に伴う苦痛を説明できる。</td>
                      <td>
                        <Form.Check
                          name="item3_5"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_5 === "A"}
                          onChange={this.onChangeItem3_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_5"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_5 === "B"}
                          onChange={this.onChangeItem3_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_5"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_5 === "C"}
                          onChange={this.onChangeItem3_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_5"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_5 === "D"}
                          onChange={this.onChangeItem3_5}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>苦痛を緩和・除去するための援助ができる。</td>
                      <td>
                        <Form.Check
                          name="item3_6"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_6 === "A"}
                          onChange={this.onChangeItem3_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_6"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_6 === "B"}
                          onChange={this.onChangeItem3_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_6"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_6 === "C"}
                          onChange={this.onChangeItem3_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_6"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_6 === "D"}
                          onChange={this.onChangeItem3_6}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        変化した形態・機能によって日常生活がどのように規制されているか指摘できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_7"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_7 === "A"}
                          onChange={this.onChangeItem3_7}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_7"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_7 === "B"}
                          onChange={this.onChangeItem3_7}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_7"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_7 === "C"}
                          onChange={this.onChangeItem3_7}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_7"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_7 === "D"}
                          onChange={this.onChangeItem3_7}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        変化した形態・機能の回復段階に合わせて日常生活の援助ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_8"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_8 === "A"}
                          onChange={this.onChangeItem3_8}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_8"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_8 === "B"}
                          onChange={this.onChangeItem3_8}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_8"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_8 === "C"}
                          onChange={this.onChangeItem3_8}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_8"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_8 === "D"}
                          onChange={this.onChangeItem3_8}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        術後の検査、治療、処置の目的・理由および留意点が説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_9"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_9 === "A"}
                          onChange={this.onChangeItem3_9}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_9"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_9 === "B"}
                          onChange={this.onChangeItem3_9}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_9"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_9 === "C"}
                          onChange={this.onChangeItem3_9}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_9"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_9 === "D"}
                          onChange={this.onChangeItem3_9}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        術後の検査、治療、処置を受ける患者に対して適切な援助および介助ができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_10"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_10 === "A"}
                          onChange={this.onChangeItem3_10}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_10"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_10 === "B"}
                          onChange={this.onChangeItem3_10}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_10"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_10 === "C"}
                          onChange={this.onChangeItem3_10}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_10"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_10 === "D"}
                          onChange={this.onChangeItem3_10}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        患者・家族が術後の状況をどのように受け止めているか説明できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_11"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_11 === "A"}
                          onChange={this.onChangeItem3_11}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_11"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_11 === "B"}
                          onChange={this.onChangeItem3_11}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_11"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_11 === "C"}
                          onChange={this.onChangeItem3_11}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_11"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_11 === "D"}
                          onChange={this.onChangeItem3_11}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        患者・家族が主体的に療養生活に取り組めるように援助することができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item3_12"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_12 === "A"}
                          onChange={this.onChangeItem3_12}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_12"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_12 === "B"}
                          onChange={this.onChangeItem3_12}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_12"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_12 === "C"}
                          onChange={this.onChangeItem3_12}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_12"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_12 === "D"}
                          onChange={this.onChangeItem3_12}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>患者および家族に必要な社会資源を指摘できる。</td>
                      <td>
                        <Form.Check
                          name="item3_13"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_13 === "A"}
                          onChange={this.onChangeItem3_13}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_13"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_13 === "B"}
                          onChange={this.onChangeItem3_13}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_13"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_13 === "C"}
                          onChange={this.onChangeItem3_13}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_13"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_13 === "D"}
                          onChange={this.onChangeItem3_13}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>必要な社会資源を活用できるように援助できる。</td>
                      <td>
                        <Form.Check
                          name="item3_14"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item3_14 === "A"}
                          onChange={this.onChangeItem3_14}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_14"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item3_14 === "B"}
                          onChange={this.onChangeItem3_14}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_14"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item3_14 === "C"}
                          onChange={this.onChangeItem3_14}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item3_14"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item3_14 === "D"}
                          onChange={this.onChangeItem3_14}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    4.
                    <br />
                    手術療法を受ける患者・家族の健康上の問題を明らかにし、看護過程を展開できる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td>C</td>
                      <td className="goal_small">
                        どのような情報が必要かを述べることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_1 === "A"}
                          onChange={this.onChangeItem4_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_1 === "B"}
                          onChange={this.onChangeItem4_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_1 === "C"}
                          onChange={this.onChangeItem4_1}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_1"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_1 === "D"}
                          onChange={this.onChangeItem4_1}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>
                        必要な患者の情報を様々な手段を用いて集めることができる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_2 === "A"}
                          onChange={this.onChangeItem4_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_2 === "B"}
                          onChange={this.onChangeItem4_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_2 === "C"}
                          onChange={this.onChangeItem4_2}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_2"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_2 === "D"}
                          onChange={this.onChangeItem4_2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        集めた情報を解釈・分析して、身体、心理・社会的側面からアセスメントできる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_3 === "A"}
                          onChange={this.onChangeItem4_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_3 === "B"}
                          onChange={this.onChangeItem4_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_3 === "C"}
                          onChange={this.onChangeItem4_3}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_3"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_3 === "D"}
                          onChange={this.onChangeItem4_3}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>患者の全体像（関連図）を描写できる。</td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_4 === "A"}
                          onChange={this.onChangeItem4_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_4 === "B"}
                          onChange={this.onChangeItem4_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_4 === "C"}
                          onChange={this.onChangeItem4_4}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_4"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_4 === "D"}
                          onChange={this.onChangeItem4_4}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        健康上の問題を特定し、アセスメントに基づいた援助計画を立案できる。
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_5 === "A"}
                          onChange={this.onChangeItem4_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_5 === "B"}
                          onChange={this.onChangeItem4_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_5 === "C"}
                          onChange={this.onChangeItem4_5}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_5"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_5 === "D"}
                          onChange={this.onChangeItem4_5}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>立案した計画に基づいて実施した援助を評価できる。</td>
                      <td>
                        <Form.Check
                          name="item4_6"
                          inline
                          type="radio"
                          label="A"
                          value="A"
                          checked={this.state.item4_6 === "A"}
                          onChange={this.onChangeItem4_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_6"
                          inline
                          type="radio"
                          label="B"
                          value="B"
                          checked={this.state.item4_6 === "B"}
                          onChange={this.onChangeItem4_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_6"
                          inline
                          type="radio"
                          label="C"
                          value="C"
                          checked={this.state.item4_6 === "C"}
                          onChange={this.onChangeItem4_6}
                        />
                      </td>
                      <td>
                        <Form.Check
                          name="item4_6"
                          inline
                          type="radio"
                          label="D"
                          value="D"
                          checked={this.state.item4_6 === "D"}
                          onChange={this.onChangeItem4_6}
                        />
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td className="goal_big">
                    5.
                    <br />
                    実習を通して患者の尊厳を重んじる態度、医療チームの一員としての誠実で謙虚な態度、自己研鑽し続ける態度を身につけることができる。
                  </td>
                  <td className="p-0">
                    <tr>
                      <td className="align-middle">A</td>
                      <td className="p-0">
                        <tr>
                          <td className="goal_middle" rowSpan={3}>
                            患者および家族に対して誠意をもって接することができる。
                          </td>
                          <td>
                            ①患者および家族に対して丁寧な言葉遣いで話すことができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_1"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_1_1 === "A"}
                              onChange={this.onChangeItem5_1_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_1"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_1_1 === "B"}
                              onChange={this.onChangeItem5_1_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_1"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_1_1 === "C"}
                              onChange={this.onChangeItem5_1_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_1"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_1_1 === "D"}
                              onChange={this.onChangeItem5_1_1}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ②成人期の患者に受け入れられる身だしなみを整えることができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_2"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_1_2 === "A"}
                              onChange={this.onChangeItem5_1_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_2"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_1_2 === "B"}
                              onChange={this.onChangeItem5_1_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_2"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_1_2 === "C"}
                              onChange={this.onChangeItem5_1_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_2"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_1_2 === "D"}
                              onChange={this.onChangeItem5_1_2}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>③患者および家族に実習日の前後で挨拶できる。</td>
                          <td>
                            <Form.Check
                              name="item5_1_3"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_1_3 === "A"}
                              onChange={this.onChangeItem5_1_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_3"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_1_3 === "B"}
                              onChange={this.onChangeItem5_1_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_3"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_1_3 === "C"}
                              onChange={this.onChangeItem5_1_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_1_3"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_1_3 === "D"}
                              onChange={this.onChangeItem5_1_3}
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">A</td>
                      <td className="p-0">
                        <tr>
                          <td className="goal_middle" rowSpan={3}>
                            いかなる場面でも人としての尊厳を重んじ、気持ちを思いやる態度がとれる。
                          </td>
                          <td>①患者の考え方や価値観を大切にする。</td>
                          <td>
                            <Form.Check
                              name="item5_2_1"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_2_1 === "A"}
                              onChange={this.onChangeItem5_2_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_1"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_2_1 === "B"}
                              onChange={this.onChangeItem5_2_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_1"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_2_1 === "C"}
                              onChange={this.onChangeItem5_2_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_1"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_2_1 === "D"}
                              onChange={this.onChangeItem5_2_1}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>②患者のプライバシーを守ることができる。</td>
                          <td>
                            <Form.Check
                              name="item5_2_2"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_2_2 === "A"}
                              onChange={this.onChangeItem5_2_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_2"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_2_2 === "B"}
                              onChange={this.onChangeItem5_2_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_2"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_2_2 === "C"}
                              onChange={this.onChangeItem5_2_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_2"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_2_2 === "D"}
                              onChange={this.onChangeItem5_2_2}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ③患者の気持ちや思いを考えて、患者の立場に立つことができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_3"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_2_3 === "A"}
                              onChange={this.onChangeItem5_2_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_3"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_2_3 === "B"}
                              onChange={this.onChangeItem5_2_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_3"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_2_3 === "C"}
                              onChange={this.onChangeItem5_2_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_2_3"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_2_3 === "D"}
                              onChange={this.onChangeItem5_2_3}
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">A</td>
                      <td className="p-0">
                        <tr>
                          <td className="goal_middle" rowSpan={3}>
                            患者の安全・安楽に配慮し、ケアに取り組むことができる。
                          </td>
                          <td>①患者のペースに合わせることができる。</td>
                          <td>
                            <Form.Check
                              name="item5_3_1"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_3_1 === "A"}
                              onChange={this.onChangeItem5_3_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_1"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_3_1 === "B"}
                              onChange={this.onChangeItem5_3_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_1"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_3_1 === "C"}
                              onChange={this.onChangeItem5_3_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_1"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_3_1 === "D"}
                              onChange={this.onChangeItem5_3_1}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ②患者に不必要な苦痛を与えないよう計画性をもってケアできる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_2"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_3_2 === "A"}
                              onChange={this.onChangeItem5_3_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_2"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_3_2 === "B"}
                              onChange={this.onChangeItem5_3_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_2"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_3_2 === "C"}
                              onChange={this.onChangeItem5_3_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_2"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_3_2 === "D"}
                              onChange={this.onChangeItem5_3_2}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>③患者のケアは、勝手な自己判断で行わない。</td>
                          <td>
                            <Form.Check
                              name="item5_3_3"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_3_3 === "A"}
                              onChange={this.onChangeItem5_3_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_3"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_3_3 === "B"}
                              onChange={this.onChangeItem5_3_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_3"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_3_3 === "C"}
                              onChange={this.onChangeItem5_3_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_3_3"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_3_3 === "D"}
                              onChange={this.onChangeItem5_3_3}
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">A</td>
                      <td className="p-0">
                        <tr>
                          <td className="goal_middle" rowSpan={7}>
                            看護学生として責任ある実習態度がとれる。
                          </td>
                          <td>
                            ①時間厳守の態度で臨み、遅刻・欠勤の場合は連絡できる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_1"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_1 === "A"}
                              onChange={this.onChangeItem5_4_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_1"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_1 === "B"}
                              onChange={this.onChangeItem5_4_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_1"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_1 === "C"}
                              onChange={this.onChangeItem5_4_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_1"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_1 === "D"}
                              onChange={this.onChangeItem5_4_1}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>②実習中の所在は常に明確にすることができる。</td>
                          <td>
                            <Form.Check
                              name="item5_4_2"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_2 === "A"}
                              onChange={this.onChangeItem5_4_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_2"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_2 === "B"}
                              onChange={this.onChangeItem5_4_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_2"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_2 === "C"}
                              onChange={this.onChangeItem5_4_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_2"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_2 === "D"}
                              onChange={this.onChangeItem5_4_2}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ③ケアおよび観察事項は、確実に報告することができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_3"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_3 === "A"}
                              onChange={this.onChangeItem5_4_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_3"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_3 === "B"}
                              onChange={this.onChangeItem5_4_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_3"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_3 === "C"}
                              onChange={this.onChangeItem5_4_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_3"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_3 === "D"}
                              onChange={this.onChangeItem5_4_3}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>④記録物の提出期限を守ることができる。</td>
                          <td>
                            <Form.Check
                              name="item5_4_4"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_4 === "A"}
                              onChange={this.onChangeItem5_4_4}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_4"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_4 === "B"}
                              onChange={this.onChangeItem5_4_4}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_4"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_4 === "C"}
                              onChange={this.onChangeItem5_4_4}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_4"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_4 === "D"}
                              onChange={this.onChangeItem5_4_4}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ⑤病院スタッフおよびグループメンバーと協調して行動することができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_5"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_5 === "A"}
                              onChange={this.onChangeItem5_4_5}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_5"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_5 === "B"}
                              onChange={this.onChangeItem5_4_5}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_5"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_5 === "C"}
                              onChange={this.onChangeItem5_4_5}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_5"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_5 === "D"}
                              onChange={this.onChangeItem5_4_5}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ⑥指導者および病院スタッフの助言やアドバイスを謙虚に聞き入れることができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_6"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_6 === "A"}
                              onChange={this.onChangeItem5_4_6}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_6"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_6 === "B"}
                              onChange={this.onChangeItem5_4_6}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_6"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_6 === "C"}
                              onChange={this.onChangeItem5_4_6}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_6"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_6 === "D"}
                              onChange={this.onChangeItem5_4_6}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ⑦患者に確実なケアを提供するために実習前・実習中の学習が主体的にできる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_7"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_4_7 === "A"}
                              onChange={this.onChangeItem5_4_7}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_7"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_4_7 === "B"}
                              onChange={this.onChangeItem5_4_7}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_7"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_4_7 === "C"}
                              onChange={this.onChangeItem5_4_7}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_4_7"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_4_7 === "D"}
                              onChange={this.onChangeItem5_4_7}
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">A</td>
                      <td className="p-0">
                        <tr>
                          <td className="goal_middle" rowSpan={3}>
                            カンファレンスの充実に努める。
                          </td>
                          <td>
                            ①メンバーの発表に対してアサーティブに意見を述べることができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_1"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_5_1 === "A"}
                              onChange={this.onChangeItem5_5_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_1"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_5_1 === "B"}
                              onChange={this.onChangeItem5_5_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_1"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_5_1 === "C"}
                              onChange={this.onChangeItem5_5_1}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_1"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_5_1 === "D"}
                              onChange={this.onChangeItem5_5_1}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ②他人の考えを取り入れて自分の考えを発展することができる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_2"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_5_2 === "A"}
                              onChange={this.onChangeItem5_5_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_2"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_5_2 === "B"}
                              onChange={this.onChangeItem5_5_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_2"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_5_2 === "C"}
                              onChange={this.onChangeItem5_5_2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_2"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_5_2 === "D"}
                              onChange={this.onChangeItem5_5_2}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ③グループメンバーとして役割を分担し、カンファレンスを円滑に運営できる。
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_3"
                              inline
                              type="radio"
                              label="A"
                              value="A"
                              checked={this.state.item5_5_3 === "A"}
                              onChange={this.onChangeItem5_5_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_3"
                              inline
                              type="radio"
                              label="B"
                              value="B"
                              checked={this.state.item5_5_3 === "B"}
                              onChange={this.onChangeItem5_5_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_3"
                              inline
                              type="radio"
                              label="C"
                              value="C"
                              checked={this.state.item5_5_3 === "C"}
                              onChange={this.onChangeItem5_5_3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name="item5_5_3"
                              inline
                              type="radio"
                              label="D"
                              value="D"
                              checked={this.state.item5_5_3 === "D"}
                              onChange={this.onChangeItem5_5_3}
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-4">
          <Button
            variant="primary"
            size="lg"
            type="submit"
            onClick={this.onSubmitSelfEvaluation}
          >
            Save
          </Button>
          <Button
            className="ml-3"
            variant="outline-secondary"
            size="lg"
            type="submit"
            onClick={this.onClickCancel}
          >
            Cancel
          </Button>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SelfEvaluationTableKansaiKokusaiKyusei);
