import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

interface Props {
  isLoading?: boolean;
  label?: string;
  className?: string;
}

export const ButtonSpriner = ({ isLoading, label, className }: Props) => (
  <Button
    className={className}
    variant="primary"
    type="submit"
    disabled={isLoading}
  >
    {isLoading ? (
      <>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </>
    ) : (
      <span>{label}</span>
    )}
  </Button>
);

export const LoadingSpriner = ({ className }: Props) => (
  <Spinner className={className} animation="border" variant="primary" />
);
