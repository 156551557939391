import React from "react";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../../../assets/styles/PatientInfoControl.scss";

interface Props {
    facultyID: string;
    patientID: string;
    university: string;
    startDate: string;
    endDate: string;
}

interface State {
    overView: number;
    medicalRecord: number;
    testData: number;
    doctorInstruction: number;
    updateState: string;
    showAlert: boolean;

    // max day
    medicalDay: number;
    overviewDay: number;
    testdataDay: number;
    doctorDay: number;
    room: string;
}

class PatientInfoControl503 extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    
        this.state = {
          overView: 1,        
          medicalRecord: 1,        
          testData: 1,         
          doctorInstruction: 1,         
          updateState: "",
          showAlert: true,
          medicalDay: 0,
          overviewDay: 0,
          testdataDay: 0,
          doctorDay: 0,
          room: '',
        };

        this.onSelectOverview = this.onSelectOverview.bind(this);       
        this.onSelectMedicalRecord = this.onSelectMedicalRecord.bind(this);     
        this.onSelectTestData = this.onSelectTestData.bind(this);     
        this.onSelectDoctorInstruction = this.onSelectDoctorInstruction.bind(this);     
        this.onSaveDayLimit = this.onSaveDayLimit.bind(this);
        this.onCloseAlert = this.onCloseAlert.bind(this);
        this.getMaxDay = this.getMaxDay.bind(this);
        this.fetchMaxDay =  this.fetchMaxDay.bind(this);
    }

    public onSelectOverview(e: React.ChangeEvent<HTMLSelectElement>) {
        const value = Number(e.target.value);
        this.setState({ overView: value });
    }

    public onSelectMedicalRecord(e: React.ChangeEvent<HTMLSelectElement>) {
        const value = Number(e.target.value);
        this.setState({ medicalRecord: value });
    }
   
    public onSelectTestData(e: React.ChangeEvent<HTMLSelectElement>) {
        const value = Number(e.target.value);
        this.setState({ testData: value });
    }
   
    public onSelectDoctorInstruction(e: React.ChangeEvent<HTMLSelectElement>) {
        const value = Number(e.target.value);
        this.setState({ doctorInstruction: value });
    }
    public onSaveDayLimit() {
        const dayLimit = {
          over_view: this.state.overView,         
          medical_record: this.state.medicalRecord,         
          test_data: this.state.testData,        
          doctor_instruction: this.state.doctorInstruction,    
          patient_id: this.props.patientID , 
          university:this.props.university,
          start_date:this.props.startDate,
          end_date: this.props.endDate  
        };
    
        const url = `${process.env.REACT_APP_API_URL}/api/patient_info/control/update`;
        const method = "POST";
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const body = JSON.stringify(dayLimit);
    
        fetch(url, { method, headers, body })
          .then((res) => res.json())
          .then((result) => {
            if (result.status === "success") {
              this.setState({ updateState: "success", showAlert: true });
            } else {
              this.setState({ updateState: "error" });
              console.log("API Error");
            }
          })
          .catch(console.error);
    }
    public onCloseAlert() {
        this.setState({ showAlert: false });
    }

    public getMaxDay(){
        const url = `${process.env.REACT_APP_API_URL}/api/patient_info/control/get_max_day?patient_id=${this.props.patientID}`;
    
        fetch(url)
          .then((res) => res.json())
          .then((result) => {
            this.setState({
                medicalDay: Number(result.medical_record),
                overviewDay: Number(result.over_view),
                testdataDay: Number(result.test_data),
                doctorDay: Number(result.doctor_instruction),
                room: result.room
            });
          })
          .catch(console.error);
    }

    public fetchMaxDay(){
        const url = `${process.env.REACT_APP_API_URL}/api/patient_info/control/fetch_max_day?patient_id=${this.props.patientID}&university=${this.props.university}&start_date=${this.props.startDate}&end_date=${this.props.endDate}`;
    
        fetch(url)
          .then((res) => res.json())
          .then((result) => {
            this.setState({
              overView: result.overview,
              medicalRecord: result.medical_record,
              testData: result.test_data,
              doctorInstruction: result.doctor_instruction,
            });
          })
          .catch(console.error);
    }
    public componentDidMount() {
        this.getMaxDay()
        this.fetchMaxDay()
    }
    public render() {

        const over_view_days = [...Array(this.state.overviewDay)].map((_, i) => i + 1);
        const medical_days = [...Array(this.state.medicalDay)].map((_, i) => i + 1);
        const test_data_days = [...Array(this.state.testdataDay)].map((_, i) => i + 1);
        const doctor_days = [...Array(this.state.doctorDay)].map((_, i) => i + 1);

        return (
            <div>
                <Table id="patient_info_control" className="ml-3 mt-5 mb-5">
                    <thead className="thead-dark">
                        <tr>
                        <th>患者情報</th>
                        <th>表示最大日数</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr className="patient503">
                        <td className="text-left">{this.state.room}&nbsp; オーバービュー</td>
                        <td>
                            <Form>
                            <Form.Row>
                            <Col md={2} />
                                <Col md={8}>
                                <Form.Control
                                    as="select"
                                    value={this.state.overView}
                                    onChange={this.onSelectOverview}
                                >
                                    {over_view_days.map((item, i) => (
                                        <option key={i} value={i + 1}>
                                            {item}
                                        </option>
                                    ))}
                                </Form.Control>
                                </Col>
                                <Col md={2} />
                            </Form.Row>
                            </Form>
                        </td>
                        </tr>
                        <tr className="patient503">
                        <td className="text-left">{this.state.room}&nbsp;診療記録</td>
                        <td>
                            <Form>
                            <Form.Row>
                            <Col md={2} />
                                <Col md={8}>
                                <Form.Control
                                    as="select"
                                    value={this.state.medicalRecord}
                                    onChange={this.onSelectMedicalRecord}
                                >
                                    {medical_days.map((item, i) => (
                                    <option key={i} value={i + 1}>
                                        {item}
                                    </option>
                                    ))}
                                </Form.Control>
                                </Col>
                                <Col md={2} />
                            </Form.Row>
                            </Form>
                        </td>
                        </tr>
                        <tr className="patient503">
                        <td className="text-left">{this.state.room}&nbsp;検査データ</td>
                        <td>
                            <Form>
                            <Form.Row>
                            <Col md={2} />
                                <Col md={8}>
                                <Form.Control
                                    as="select"
                                    value={this.state.testData}
                                    onChange={this.onSelectTestData}
                                >
                                    {test_data_days.map((item, i) => (
                                    <option key={i} value={i + 1}>
                                        {item}
                                    </option>
                                    ))}
                                </Form.Control>
                                </Col>
                                <Col md={2} />
                            </Form.Row>
                            </Form>
                        </td>
                        </tr>
                        <tr className="patient503">
                        <td className="text-left">{this.state.room}&nbsp;医師の指示</td>
                        <td>
                            <Form>
                            <Form.Row>
                                <Col md={2} />
                                <Col md={8}>
                                <Form.Control
                                    as="select"
                                    value={this.state.doctorInstruction}
                                    onChange={this.onSelectDoctorInstruction}
                                >
                                    {doctor_days.map((item, i) => (
                                    <option key={i} value={i + 1}>
                                        {item}
                                    </option>
                                    ))}
                                </Form.Control>
                                </Col>
                                <Col md={2} />
                            </Form.Row>
                            </Form>
                        </td>
                        </tr>
                    </tbody>
                </Table>
                <Button
                    className="mb-4"
                    size="lg"
                    variant="outline-primary"
                    onClick={this.onSaveDayLimit}
                    >
                    保存
                </Button>
                {this.state.updateState === "success" && (
                    <Alert
                        variant={"success"}
                        dismissible
                        show={this.state.showAlert}
                        onClose={this.onCloseAlert}
                    >
                        表示最大日数を更新しました
                    </Alert>
                )}
                {this.state.updateState === "error" && (
                    <Alert
                        variant={"danger"}
                        dismissible
                        show={this.state.showAlert}
                        onClose={this.onCloseAlert}
                    >
                        表示最大日数の更新に失敗しました
                    </Alert>
                )}
            </div>
        )
    }
       
}
export default PatientInfoControl503;