import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FacultyCommnentInfo } from "../../models/Types";
import Alert from "@material-ui/lab/Alert";
import { LoadingSpriner } from "../../common/CircularSpinner";
interface Props {
    facultyID: string;
    fetchFacultyInfo: (
        isSupervisor: boolean,
        isSubSupervisor: boolean,
        affiliation: string,
        facultyID: string
    ) => void;
}
interface State extends FacultyCommnentInfo { }

class FacultyComment extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            facultyID: "",
            facultyName: "",
            affiliation: "",
            internPlace: "",
            comment: "",
            msg: '',
            isEdit: false,
            hasError: false,
            isSuccess: false,
            isLoading: false,
        };
    }

    public componentDidMount() {
        const url = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${this.props.facultyID}`;
        fetch(url)
            .then((res) => res.json())
            .then((result) => {
                console.log('result faculty_info', result);
                this.setState({
                    facultyID: result.faculty_id,
                    facultyName: result.faculty_name,
                    affiliation: result.affiliation,
                    internPlace: result.internPlace_id
                });
                this.getListComment();
            })
            .catch(console.error);
    }
    public getListComment() {
        const url = `${process.env.REACT_APP_API_URL}/api/faculty_info/comment/list?affiliation=${this.state.affiliation}&intern_place=${this.state.internPlace}`;
        fetch(url)
            .then((res) => res.json())
            .then((result) => {
                console.log('result getListComment', result);
                this.setState({
                    comment: result == null ? "" : result.comment
                });
            })
            .catch(console.error);
    }
    public onSaveComment(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        this.fetchAPI(2);

    }
    public onCommentChanged(e: React.ChangeEvent<HTMLInputElement>) {
        const newComment: string = e.target.value;
        console.log("🚀 ~ file: NursingDiagnosisFaculty.tsx ~ line 59 ~ NursingDiagnosisFaculty ~ onNursingCommentChanged ~ newComment", newComment)
      
        this.setState({
          comment: newComment
        });
    }
    fetchAPI = (type: number) => {
        const router = type === 1 ? 'checkBeforeCreateOrUpdate' : 'createOrUpdate';
        const url = `${process.env.REACT_APP_API_URL}/api/faculty_info/comment/${router}`;
        const method = "POST";
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        const mode = "cors";
        const data = {
            affiliation: this.state.affiliation,
            intern_place: this.state.internPlace,
            updated_faculty: this.state.facultyID,
            comment: this.state.comment
        }
        const body = JSON.stringify(data);
        this.setState({isLoading: true});
        fetch(url, { method, mode, headers, body })
            .then((res) => res.json())
            .then((res) => {
                if (res.result.code == 200) {
                    if(type === 1){
                        this.setState({
                            msg: res.result.message,
                            isSuccess: true,
                            hasError: false,
                            isEdit: true,
                            isLoading:false
                        });
                    }else{
                        this.setState({
                            msg: res.result.message,
                            isSuccess: true,
                            hasError: false,
                            isEdit: false,
                            isLoading:false
                        });
                    }
                  
                } else {
                    this.setState({
                        msg: res.result.message,
                        isSuccess: false,
                        hasError: true,
                        isEdit: false,
                        isLoading:false
                    });
                }
                this.getListComment();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    msg: 'Server Error',
                    isSuccess: false,
                    hasError: true,
                    isEdit: false,
                    isLoading:false
                });
            });
    }
    public onCheckComment(e: React.MouseEvent<HTMLButtonElement>) {
        this.getListComment();
        e.preventDefault();
        this.fetchAPI(1);
       
    }
  
    public render() {
        return (
            <Form.Group className="m-5 text-center">
                <Form.Control className="" as="textarea" rows={13}
                    value={this.state.comment}
                    readOnly={!this.state.isEdit}
                    onChange={this.onCommentChanged.bind(
                        this
                      )}
                />
                <Col md={12}  className="m-0">
                    {this.state.isLoading && <LoadingSpriner />}
                    {this.state.isSuccess && this.state.msg != '' && (
                        <Alert className="mt-3">
                            {this.state.msg}
                        </Alert>
                    )}
                    {this.state.hasError && this.state.msg != '' && (
                        <Alert severity="error" className="mt-3">
                            <div dangerouslySetInnerHTML={{ __html: this.state.msg }} />
                        </Alert>
                    )}
                </Col>
                {this.state.isEdit ? (
                    <Button className="mt-2" variant="info" size="lg"
                        onClick={this.onSaveComment.bind(
                            this
                          )}
                    >
                        保存
                    </Button>
                ) : (

                    <Button className="mt-2" variant="info" size="lg"
                        onClick={this.onCheckComment.bind(
                            this
                          )}
                    >
                        編集
                    </Button>
                )}
            </Form.Group>
        );
    }
}

export default FacultyComment;
