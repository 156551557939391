import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { convertHoursTime, convertToHHMM, convertToHHMMJapan, onKeyPress } from '../../common/function'
import { TimePicker } from '../../common/TimePickerComponent'
import DropzoneBox from './pdf/DropzoneBox'
import PDFViews from './pdf/PDFViews'
import closeButton from '../../../assets/images/close.svg'
import { ButtonSpriner } from '../../common/CircularSpinner'
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";
import moment from 'moment'
import fi from 'date-fns/esm/locale/fi/index.js'

// 
type Message = {
    type: "error" | "success" | "loading" | "ide",
    message: string
}

type PdfForms = {
    recordType: number,
    recordName: string | undefined,
    time: any,
    date: string | undefined,
}

export type FileInfo = {
    filePath: string,
    patient_id: string,
    recode_date: string
    recode_file_name: string,
    recode_type: string,
    upload_file_name: string,
    dateConverted: number,
    time: any,
    date: any,
    id: number,

}

type FormError = {
    recordType: string,
    recordName: string,
    time: string,
    date: string,
}

const PdfManagerIndex = () => {
    const history = useHistory()
    const location = useLocation()
    const [page, setPage] = useState(1)
    const [message, setMessage] = useState<Message>({ type: "ide", message: "" })
    const [formValues, setFormValue] = useState<PdfForms>({ recordName: "", recordType: 1, time: "", date: "" })
    const [formErrors, setFormErrors] = useState<FormError>({ recordName: "", recordType: "", time: "", date: "" })
    const [fileSelect, setFileSelect] = useState<FileInfo | null>(null)
    const [file, setFile] = useState<File | null>(null)
    const { data: { patientID } } = location.state as any
    const [pdfFiles, setPdfFiles] = useState<FileInfo[] | null>(null)
    const setFieldValue = (field: string, value: any) => {
        setFormValue({
            ...formValues,
            [field]: value
        })
        if (value) {
            setFormErrors({
                ...formErrors,
                [field]: ""
            })
        }
    }

    useEffect(() => {
        getAllPdfFiles()
    }, [])


    const getAllPdfFiles = async () => {
        const url = `${process.env.REACT_APP_API_URL}/api/patient/pdf-all?patientID=${patientID}`;
        const method = "GET";
        fetch(url, { method })
            .then((res) => res.json())
            .then((result) => {
                if (result) {
                    setPdfFiles(result as FileInfo[])
                    if (result && result.length > 0) {
                        resetData(Number.parseInt(result[0].recode_type))
                    }
                }

            })
            .catch(console.error);
    }

    const deleteFile = async ({ type, fileName }: { type: "one" | "all", fileName: string }) => {
        const url = `${process.env.REACT_APP_API_URL}/api/patient/pdf-delete?patientID=${patientID}&fileName=${fileName}&type=${type}`;
        const method = "GET";
        fetch(url, { method })
            .then((res) => res.json())
            .then((result) => {
                if (result.status == "success") {
                    setMessage({ type: "success", message: "削除が完了しました。" })
                    getAllPdfFiles()
                    resetData(formValues.recordType)
                } else {
                    setMessage({ type: "error", message: "例外エラーが発生しました。管理者へご連絡してください。" })
                }
            })
            .catch((err) => {
                setMessage({ type: "error", message: "例外エラーが発生しました。管理者へご連絡してください。" })
            });

        setTimeout(() => {
            setMessage({
                type: "ide",
                message: ""
            })
        }, 3000)
    }

    const resetData = (recordValue: any) => {
        setFile(null)
        setFileSelect({ filePath: "", patient_id: "", recode_date: "", recode_file_name: "", recode_type: "", upload_file_name: "", dateConverted: 0, time: "", date: "", id: 0 })
        setFormValue({ recordName: "", recordType: recordValue, time: "", date: "" })
        setFormErrors({ recordName: "", recordType: "", time: "", date: "" })
    }

    const onSelectFile = (event: any) => {
        document.getElementById('upLoad')?.click()
    }

    const handleUploadClick = (file: File) => {
        setFileSelect({
            filePath: URL.createObjectURL(file),
            patient_id: "",
            recode_date: formValues.date!,
            recode_file_name: formValues.recordName!,
            recode_type: formValues.recordType.toString(),
            upload_file_name: file.name,
            dateConverted: 0,
            time: formValues.time,
            date: formValues.date,
            id: fileSelect?.id ?? 0
        })
        setFile(file)
    };

    const editFile = (file: FileInfo) => {
        setFileSelect({
            ...file,
            time: convertToHHMM(file.dateConverted % 1440),
            date: Math.floor(file.dateConverted / 1440)
        })
        const times = convertToHHMM(file.dateConverted % 1440) as any;
        setFormValue({
            recordName: file.recode_file_name,
            recordType: Number.parseInt(file.recode_type),
            time: convertHoursTime(times ? times : "00:00"),
            date: Math.floor(file.dateConverted / 1440).toString()
        })
        console.log("🚀 ~ file: index.tsx ~ line 159 ~ editFile ~ convertHoursTime", convertHoursTime(convertToHHMM(file.dateConverted % 1440) as any))
    }

    const listFilePdf = () => {
        return (
            <Col className='offset-sm-1 mt-5' sm={10}>
                {pdfFiles && pdfFiles.length > 0 && pdfFiles.map((file: FileInfo, index: number) => (
                    <Row key={index} className='mt-2 bg-white p-2 rounded shadow-sm' style={{ cursor: "pointer", alignItems: "center", justifyContent: "space-between" }} >
                        <Col>
                            <Row>
                                <Col sm={3} className='text-left' >{Math.floor(file.dateConverted / 1440)}日目 {convertToHHMMJapan(file.dateConverted % 1440) === "" ? "00時00分" : convertToHHMMJapan(file.dateConverted % 1440)}</Col>
                                <Col sm={9} className='text-left'>{file.recode_file_name}</Col>
                            </Row>
                        </Col>

                        <div className='d-flex text-info'>
                            <div className='ml-2 ' onClick={() => editFile(file)}><ins>編集</ins></div>
                            <div className='ml-2 ' onClick={() => deleteFile({ type: "one", fileName: file.upload_file_name })}><ins>削除</ins></div>
                        </div>
                    </Row>
                ))}
            </Col>
        )
    }

    const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setMessage({ type: "loading", message: "" })
        const convertToMinutes = moment(formValues.time).hour() * 60 * 60 + moment(formValues.time).minute() * 60;
        const hoursTime = formValues.time ? convertToMinutes : 0;
        const dayTime = Number.parseInt(formValues.date ?? "0") * 24 * 60 * 60;
        const recode_date = Number(dayTime) + Number(hoursTime)
        const formData = new FormData();
        formData.append("fileUploaded", file ?? "");
        formData.append("patientID", patientID);
        formData.append("recordType", formValues.recordType.toString() ?? "1");
        formData.append('recordName', formValues.recordName ?? "")
        formData.append("recodeDate", recode_date.toString());
        formData.append("uploadFileName", fileSelect ? fileSelect.upload_file_name : "");
        formData.append("hasFile", file ? "1" : "0");
        formData.append("id", fileSelect ? fileSelect.id.toString() : "0");
        const url = `${process.env.REACT_APP_API_URL}/api/patient/pdf-upload`;
        const method = "POST";
        const body = formData;
        fetch(url, { method, body })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === "success") {
                    setMessage({ type: "success", message: fileSelect?.id === 1 ? "更新が完了しました。" : "登録が完了しました。" })
                    getAllPdfFiles()
                    resetData(formValues.recordType)
                } else {
                    setMessage({ type: "error", message: "例外エラーが発生しました。管理者へご連絡してください。" })
                }
            })
            .catch((error) => setMessage({ type: "error", message: "例外エラーが発生しました。管理者へご連絡してください。" }));

        setTimeout(() => {
            setMessage({
                type: "ide",
                message: ""
            })
        }, 3000)
    }

    return (
        <Container fluid >
            <Row>
                <Col md={9} className="mt-5" style={{ margin: "auto" }}>
                    <h2 className='mb-5'>記録PDF入力</h2>
                    <Col md={5} style={{ margin: "auto" }}>
                        {(message.type !== "ide" && message.type !== "loading") && (
                            <Alert className="mt-3" variant="filled" severity={message.type === "success" ? "success" : "error"}>
                                {message.message}
                            </Alert>
                        )}

                    </Col>
                    <Form className='mt-4' onSubmit={submitForm}>
                        <div style={{ borderBottom: "1px solid black" }}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={1} className="text-right">
                                    種別：
                                </Form.Label>
                                <Col sm={2}>
                                    <Form.Control
                                        as="select"
                                        value={formValues.recordType}
                                        onChange={(e) => {
                                            resetData(e.target.value)
                                        }}
                                        required
                                        disabled={pdfFiles && pdfFiles?.length > 0 ? true : false}
                                    >
                                        <option value={1}>手術記録</option>
                                        <option value={2}>助産記録</option>
                                    </Form.Control>
                                </Col>
                                <Col className='text-left'>
                                    <p style={{ lineHeight: "30px" }}>種別を変更する場合、登録済みの記録を全て削除してください。</p>
                                </Col>
                            </Form.Group>
                        </div>

                        <Form.Group as={Row} className="mb-3 mt-4">
                            <Form.Label column sm={1} className="text-right">
                                経過日時：
                            </Form.Label>
                            <Col sm={1}>
                                <Form.Control
                                    as="input"
                                    value={formValues.date ?? ""}
                                    onChange={(e) => setFieldValue("date", e.target.value)}
                                    onKeyPress={onKeyPress}
                                    required
                                />
                            </Col>
                            <p className='mt-1'>日目</p>
                            <Col sm={2} style={{ marginTop: "-11px" }}>
                                <TimePicker
                                    value={formValues.time !== "" ? formValues.time : null}
                                    onChange={(time: any) => setFieldValue("time", time)}
                                    required
                                />
                            </Col>

                        </Form.Group>
                        <Col sm={5} className="offset-1">
                            <p className='text-left text-danger m-0'>{formErrors.date}</p>
                            <p className='text-left text-danger m-0'>{formErrors.time}</p>
                        </Col>
                        <Form.Group as={Row} className="mb-3 mt-4">
                            <Form.Label column sm={1} className="text-right">
                                記録名：
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control as="input"
                                    value={formValues?.recordName ?? ""}
                                    onChange={(e) => setFieldValue("recordName", e.target.value)}
                                    required
                                />
                                <p className='text-left text-danger'>{formErrors.recordName}</p>
                            </Col>
                        </Form.Group>

                        <Col className='offset-sm-1 mt-5' sm={10}>
                            {/* <StyleInputContainer style={{ cursor: "pointer" }} onClick={onSelectFile}>
                                <StyledInput
                                    id="upLoad"
                                    placeholder="kadjaksjdhkjhadjk"
                                    accept="application/pdf"
                                    type="file"
                                    onChange={handleUploadClick}
                                />
                                <p className="clor-lightnavi" style={{ lineHeight: "30px" }}>ここにPDFファイルをドラッグ＆ドロップ</p>
                            </StyleInputContainer> */}

                            <DropzoneBox
                                onChange={(file) => handleUploadClick(file)}
                            />
                        </Col>
                        {fileSelect && fileSelect.upload_file_name !== "" &&
                            <Col className='offset-sm-1 mt-5' sm={10}>
                                <Row className='mt-'>
                                    <p className='' style={{ margin: "0px" }}>{fileSelect.upload_file_name}</p>
                                    <div className='ml-2 bg-danger text-white rounded-circle p-1'
                                        onClick={() => {
                                            setFile(null)
                                            setFileSelect({
                                                ...fileSelect,
                                                upload_file_name: "",
                                            })
                                        }}><img src={closeButton} alt="" /></div>
                                </Row>
                            </Col>

                        }

                        <Col className='offset-sm-1 mt-5 ' sm={10}>
                            <div className={`${file === null && "empty-file"}`} style={{ minHeight: "500px", border: "1px solid black" }}>
                                {

                                    fileSelect && fileSelect?.patient_id !== "" && fileSelect.upload_file_name !== "" ?
                                        (
                                            <Document
                                                file={`/${Number.parseInt(fileSelect.recode_type) === 1 ? "surgery_records" : "josan_records"}/${patientID}/${fileSelect.upload_file_name}`}
                                                onLoadSuccess={() => setPage(4)}
                                            >
                                                <Page pageNumber={1} />
                                            </Document>
                                        ) :

                                        file ? (
                                            <StyleDocument
                                                file={fileSelect?.filePath}
                                                onLoadSuccess={() => setPage(4)}>
                                                <Page pageNumber={1} />
                                            </StyleDocument>
                                        ) : (
                                            <p>PDFプレビュー</p>
                                        )}
                            </div>
                        </Col>

                        <StyledButton>
                            <ButtonSpriner isLoading={message.type === "loading"} label="登録" />
                            <Button variant="outline-primary" onClick={resetData}>
                                クリア
                            </Button>
                        </StyledButton>
                    </Form>

                    {listFilePdf()}
                </Col>
            </Row >
        </Container >
    )
}

export default PdfManagerIndex

const StyledButton = styled.div`
  margin-top: 40px;
  .btn {
    width: 125px;
    margin-right: 20px;
  }
`;

const StyleDocument = styled(Document)`
  justify-content: center;
  display: flex;
`;

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
`;

const StyleInputContainer = styled.div`
    width: 100%;
    height: 50px !important;
    border: 7px dotted #999999;
    border-radius: 5px;
    box-shadow: 0 0 0 5px #ffffff;
    margin-top: 1rem;
    color: black
    cursor: pointer;
`;
