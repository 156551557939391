import React, { createContext, useContext } from "react";

const RouteDataContext = createContext({} as any);
interface Props {
  data: any;
}
interface State {}
export class RouteDataContextProvider extends React.Component<Props, State> {
  // Context state
  state = {
    data: {},
  };

  componentDidMount() {
    this.setData(this.props.data);
  }

  // Method to update state
  setData = (data: any) => {
    this.setState(() => ({ data }));
  };
  render() {
    const { data } = this.state;
    const { setData } = this;
    return (
      <RouteDataContext.Provider
        value={{
          data,
          setData,
        }}
      >
        {this.props.children}
      </RouteDataContext.Provider>
    );
  }
}

export default RouteDataContext;

export const useRouteDataContext = () => {
  return useContext(RouteDataContext);
};
