import React from "react";
import Form from "react-bootstrap/Form";

interface Props {
  onItemSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value:any;
}
interface State {}

class SignUpEndDaySelect extends React.Component<Props, State> {
  public render() {
    const days: number[] = Array.from(new Array(31)).map((v, i) => i + 1);
    const options = days.map((item, i) => <option key={i} value={item}>{item}</option>);
    return (
      <Form.Control value={this.props.value} required as="select" onChange={this.props.onItemSelect}>
        <option value="">選択</option>
        {options}
      </Form.Control>
    );
  }
}

export default SignUpEndDaySelect;
