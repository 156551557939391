import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import TestDataTableLeft from "./TestDataTableLeft";
import TestDataTableData from "./TestDataTableData";
import "../../../assets/styles/TestData.scss";
import { TestDataItemRecordType, TestDataItemType } from "../../models/Types";

interface Props
  extends RouteComponentProps<{ patientID: string; studentID: string; facultyID: string; startDate: string; }> { }
interface State {
  testDataItems: TestDataItemType[],
  testDataRecords: TestDataItemRecordType[];

}

class TestData extends React.Component<Props, State> {

  private supportPageOffset: boolean | null;
  private isCSS1Compat: boolean | null;
  private demoItem2: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      testDataItems: [],
      testDataRecords: []
    };


    this.supportPageOffset = window.pageXOffset !== undefined;
    this.isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
    this.demoItem2 = React.createRef();

  }

  public componentDidMount() {
    // const patientIDNum = this.props.match.params.patientID.slice(-1);
    console.log('facultyID', this.props.match.params.facultyID)
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/test_data?patient_id=${this.props.match.params.patientID}&student_id=${this.props.match.params.studentID}&faculty_id=${this.props.match.params.facultyID}&start_date=${this.props.match.params.startDate}`;

    fetch(url, { mode: "cors" })
      .then(res => res.json())
      .then(
        result => {
          console.log('patient1', result);
          this.setState({
            testDataItems: result.items,
            testDataRecords: result.records
          });
        },
        error => {
          console.log("Error: loadAllStudentTableItems");
        }
      );

    window.addEventListener('scroll', this.handleScroll, true);
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = () => {
    var x = this.supportPageOffset ? window.pageXOffset : this.isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;

    let y = x;
    this.demoItem2.current.style.left = y + "px";
  }

  public render() {
    return (
   
        <div className="datagrid-viewport-testdata">
          <div id="demoItem2" className='col-label datagrid-pinned-data' ref={this.demoItem2}>
            {this.state.testDataRecords.length > 0 &&
              <TestDataTableLeft testDataItem={this.state.testDataItems} />
            }
          </div>

          <div className="col-data d-flex flex-row x-scrollable-pane">
            {this.state.testDataRecords.map((item, i) => (
              <TestDataTableData key={i} testData={item} testDataItem={this.state.testDataItems} />
            ))}
          </div>

        </div>
      
    );
  }
}

export default TestData;
