import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SignUpFaculty from "./SignUpFaculty";
import SignUpStudent from "./SignUpStudent";

interface Props extends RouteComponentProps<{ type: string }> {}
interface State {}

class SignUpView extends React.Component<Props, State> {
  public render() {
    return (
      <Container fluid>
        <Row>
          <Col md={3}></Col>
          <Col md={6}>
            {this.props.match.params.type === "faculty" ? (
              <SignUpFaculty />
            ) : (
              <SignUpStudent />
            )}
          </Col>
          <Col md={3}></Col>
        </Row>
      </Container>
    );
  }
}

export default SignUpView;
