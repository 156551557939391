import React from "react";
import Table from "react-bootstrap/Table";
import { TestDataItemRecordType, TestDataItemType } from "../../models/Types";
import TestDataRecord from "./TestDataRecord";

interface Props {
  testData: TestDataItemRecordType,
  testDataItem: TestDataItemType[]
}
interface State {}

class TestDataTableData extends React.Component<Props, State> {

  public render() {
  
    return (
      <Table className="test_data sw-test_data2" bordered hover size="sm">
        <thead>
          <tr><th style={{minWidth: '150px'}}>{this.props.testData.date}</th></tr>
          {/* <tr><th>{this.props.testData.date_time_text}</th></tr> */}
        </thead>
        <tbody>
          {this.props.testDataItem.map((item: TestDataItemType) =>
            <tr key={item.id} >
              <td>
                <TestDataRecord testData={this.props.testData} itemId={item.id} />                
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

export default TestDataTableData;
