import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
// import Form from "react-bootstrap/Form";
// // import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import logo from "../../assets/images/logo.svg";
import "../../assets/styles/Header.scss";
import HomeButton from './HomeButton';
import RootRouter from './../../helpers/RootRouter';
import HomeNav from './HomeNav';


interface Props { }
interface State { }

class Header extends React.Component<Props, State> {

  public render() {


    return (
      <Container fluid className="p-0">
        <Navbar className="p-1 bg-white" expand="lg">
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="50"
              height="50"
              className="d-inline-block align-top ml-3"
              alt="React"
            />
          </Navbar.Brand>
          {/* <Nav className="mr-auto">
            <Nav.Link id="home" href={HomePathHelper.homeUrl}>
              <HomeRoundedIcon fontSize="large" />
              <span className="align-middle">Home</span>
            </Nav.Link>
          </Nav> */}
          <HomeButton />
          <HomeNav />
          <div> <Button
            size="lg"
            onClick={RootRouter.goBack}
            className="ml-3 noPrint bg-lightblue"
          >
            戻る
           </Button></div>
        </Navbar>
      </Container>
    );
  }
}

export default Header;
