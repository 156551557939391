import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Alert from "@material-ui/lab/Alert";

interface Props {
  onCloseModal: Function;
  dialogOpen: boolean;
}
interface State {}

class ConfirmDialog extends React.Component<Props, State> {
  handleClose = () => {
    this.props.onCloseModal(false);
  };

  render() {
    return (
      <Dialog
        open={this.props.dialogOpen}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Alert className="mt-3">更新しました。</Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="primary"
            variant="contained"
          >
            オーケー
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmDialog;
