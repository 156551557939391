import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { LinkInfoType } from "../models/Types";
import { getUserId } from "../../services/AppLocalHelper";
import Image from "react-bootstrap/Image";
import img_KobeHospital from "../../assets/images/KobeHospital.svg";
import img_GLNECTOKYO from "../../assets/images/GLNECTOKYO.svg";
import img_ShinryoBuko from "../../assets/images/ShinryoBunko.svg";
interface Props {
  userID: string;
  userType: string;
 }
interface State { 
  commonlinks: LinkInfoType[];
  links: LinkInfoType[];
}

class IndexStudentLinks extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      commonlinks: [],
      links: []
    };
  }

  public componentDidMount() {
    this.loadLinks('COMMON');
    let userId = getUserId();
    console.log('this.props.userID:',this.props.userID);
    console.log('this.props.userType:',this.props.userType);
    const urlFaculty = this.props.userType == 'student' ? `${process.env.REACT_APP_API_URL}/api/student_info?student_id=${userId}`:  `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${userId}`;
    fetch(urlFaculty)
        .then((res) => res.json())
        .then((result) => {
          let affiliation = this.props.userType == 'student' ? result.university_id : result.affiliation_id;
          this.loadLinks(affiliation);
        })
        .catch(console.error);
   
  }
  public loadLinks(affiliation: string){
    const url = `${process.env.REACT_APP_API_URL}/api/link/list?affiliation=${affiliation}`;
    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log('link common: ', result); 
        if(affiliation == "COMMON"){
          this.setState({ commonlinks: result });
        }else{
          this.setState({ links: result });
        }       
    
      })
      .catch(console.error);
  }
  
  public render() {
    return (
      <div>
        <h5 className="mb-3 clor-lightnavi">外部サイトリンク</h5>
        <ListGroup className="mt-5">
          {/* for sale start */}
        　<ListGroup.Item
            action
            target={"_blank"}
            href="https://www.hosp.kobe-u.ac.jp/kahop/"
          >
            <Image src={img_KobeHospital} alt="KobeHospital" width="100%" />
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://www.glnectokyo.com/"
          >
            <Image src={img_GLNECTOKYO} alt="GLNECTOKYO" width="100%" />
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://shinryobunko.co.jp/"
          >
            <Image src={img_ShinryoBuko} alt="ShinryoBuko" width="100%" />
          </ListGroup.Item>
          {/* for sale end */}
          {this.state.commonlinks && this.state.commonlinks.map((link, i) => {  
            return (<ListGroup.Item
              action
              target={"_blank"}
              href={link.link_url}
            >
              {link.link_text}
            </ListGroup.Item>) 
          })}
          {/* <ListGroup.Item
            action
            target={"_blank"}
            href="https://www.hosp.kobe-u.ac.jp/about/index.html"
          >
            病院概要
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://www.hosp.kobe-u.ac.jp/kahop/about/"
          >
            看護部挨拶
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://www.hosp.kobe-u.ac.jp/kahop/department/"
          >
            病棟紹介
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://www.hosp.kobe-u.ac.jp/kahop/teams/specalist/index.html#sp"
          >
            専門・認定看護師紹介
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://www.hosp.kobe-u.ac.jp/kahop/teams/"
          >
            チーム医療
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://www.hosp.kobe-u.ac.jp/kahop/education/"
          >
            教育体制
          </ListGroup.Item> */}
          {/* <ListGroup.Item
            action
            target={"_blank"}
            href="https://www.nursingskills.jp/Account/Login"
          >
            ナーシングスキル
          </ListGroup.Item> */}
        </ListGroup>
        <ListGroup className="mt-5">
        {this.state.links && this.state.links.map((link, i) => {  
            return (<ListGroup.Item
              action
              target={"_blank"}
              href={link.link_url}
            >
              {link.link_text}
            </ListGroup.Item>) 
          })}
          {/* <ListGroup.Item
            action
            target={"_blank"}
            href="https://kobe-u-ac-jp.zoom.us/rec/share/2YcYYKy0lMN3wOCvUWMtDHYyh1GNa8itUkWrOTi_LZmiCDGFIB96x8BCysrbaK4_.RW0DoIMRUl3AHq5L"
          >
            神戸大学医学部付属病院 ICU・HCU オリエンテーション
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://kobe-u-ac-jp.zoom.us/rec/share/Xm8GF4CWe8HVrZZtpnt_-u73vsl4MssOWjxKSrKI3Mawm-FjN_UIbaZnztt_rxYY.CUdZPzly5NNGkfhN"
          >
            神戸大学医学部附属病院 ICU･HCU　部署紹介スライド（音声なし）
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://kobe-u-ac-jp.zoom.us/rec/share/6l2Nv1qZ6bUbjuH4gft6gsnwgt2oVbcDWGjJjs7O2FAeKK3MQJSU49_F0KZ7l331.gaK87ltato2AS4V3"
          >
            神戸大学医学部附属病院 手術部：術中記録について
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://kobe-u-ac-jp.zoom.us/rec/share/fA4IAKKbMNBw7WdfpI2ngjKh3UbBi2O8Ili5MV4yx76BRoN7DRKOphU0uM9xJp_i.cJD_ZbEUovFgPewQ"
          >
            神戸大学医学部附属病院 手術部 部署紹介スライド（音声なし）
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://kobe-u-ac-jp.zoom.us/rec/share/CHaGIHDivt0uPQ_dmj_L9hJy5-Q6ox-mwEpqTuqB6qIZPwVoRPYIWouW42E5ncw.h5E1vsR7gfWlWdLi"
          >
            神戸大学医学部附属病院 手術室について
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://kobe-u-ac-jp.zoom.us/rec/share/9qVA6PNUe2V-VfaElNzFlr00UBhPnMFKiMaYOb6UCG6OcgqkPEUblOpOB06zuldJ.IWl1tJ4L29fFvCGu"
          >
            神戸大学医学部附属病院 NICU/GCUオリエンテーション
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://kobe-u-ac-jp.zoom.us/rec/share/wY8u3Et3_lnG4WDxc5WOi5PHkEfcYwSPc3hi-sHBhc863PjeXn-n4fLmNkLb4uoq.1rIYilojD60eN3_i"
          >
            神戸大学医学部附属病院 産科/MFICUオリエンテーション
          </ListGroup.Item>

          <ListGroup.Item
            action
            target={"_blank"}
            href="https://kobe-u-ac-jp.zoom.us/rec/share/hx8ZkmoNW6xXudrG1M8n5NQNL9npnaWUEEJXsysFur2RtE0Ic6aFfPBn_rsPSkU2.7rAdT1TDBf6f3v6G"
          >
            神戸大学医学部附属病院　小児医療センター紹介
          </ListGroup.Item>
          <ListGroup.Item
            action
            target={"_blank"}
            href="https://00m.in/HQqOa"
          >
            第二病棟（精神科）オリエンテーション
          </ListGroup.Item> */}
        </ListGroup>
      </div>
    );
  }

}

export default IndexStudentLinks;
