import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import SignUpBox from "../login/SignUpBox";
import "../../assets/styles/IndexFaculty.scss";

interface Props extends RouteComponentProps<{ }> {}
interface State {

}

class CreatePatientSurgeryRecord extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

  }
 
  public render() {
    return (
      <Container className="mt-5 mb-5">
        <h2>手術記録入力</h2>
        
      </Container>
    );
  }
}

export default withRouter(CreatePatientSurgeryRecord);
