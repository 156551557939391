import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Row";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { SelfEvaluationType } from "../../models/Types";
import _ from "lodash";
import "../../../assets/styles/SWCustom.scss";

interface Props extends RouteComponentProps {
  university: string; 
  startDate: string; 
  endDate: string; 
  facultyID: string; 
}
interface State extends SelfEvaluationType {
  // studentName: string;
  // startDate: string;
  // endDate: string;
  intern_place: string;
  questions: [];
  answers: any[];
  total: string;
  // listMergeIndex: questionCount;
  questionCount: any[];

}

class SelfEvaluationTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      studentID: "",
      questions: [],
      answers: [],
      // studentName: "",
      // startDate: "",
      // endDate: "",
      intern_place: "",
      total: "",
     
      questionCount: [],
    };

    this.onClickBack = this.onClickBack.bind(this);
    this.loadQuestionCount = this.loadQuestionCount.bind(this);
  }

  public onClickBack() {
    this.props.history.goBack();
  }

  public componentDidMount() {
    this.LoadQuestions();
    this.LoadAnswers();
    this.loadQuestionCount();


    const url1 = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${this.props.facultyID}`;

    fetch(url1)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          intern_place: result.internPlace,
        });
      })
      .catch(console.error);
  }



  public LoadAnswers() {
    fetch(`${process.env.REACT_APP_API_URL}/api/self_evaluation/faculty_answers?univesity=${this.props.university}&start_date=${this.props.startDate}&end_date=${this.props.endDate}`,
      { mode: "cors" })
      .then((res) => res.json())
      .then((xhr) => {
        this.setState({
          answers: xhr.answers,
          total: xhr.total
        });
      }, (error) => {
        console.log("Error: ");
      });
  }

  public LoadQuestions() {
    fetch(`${process.env.REACT_APP_API_URL}/api/self_evaluation/faculty_questions?univesity=${this.props.university}&start_date=${this.props.startDate}&end_date=${this.props.endDate}`,
      { mode: "cors" })
      .then((res) => res.json())
      .then((xhr) => {
        this.setState({
          questions: xhr.questions
        });

        // console.log("questions: ", this.state.questions);
      }, (error) => {
        console.log("Error: ");
      });
  }

  
  public loadQuestionCount(){
    fetch(`${process.env.REACT_APP_API_URL}/api/self_evaluation/count_question?univesity=${this.props.university}&start_date=${this.props.startDate}&end_date=${this.props.endDate}`,
    { mode: "cors" })
    .then((res) => res.json())
    .then((xhr) => {
      this.setState({
        questionCount: xhr.result
      });
       

      
    }, (error) => {
      console.log("Error: ");
    });
  }



  QuestionsData() {
    return this.state.questions.map((question: any, index) => {
      let row: any[] = [];
      let i1 = question.col1.length;
      let i2 = question.col2.length;
      let i3 = question.col3.length;
   
      let listMerge = [];


      for (let i = 0; i < i3; i++) {
        let itemName = 'item_' + question.col3[i].index1 + '_' + question.col3[i].index2 + '_' + question.col3[i].index3;
        let itemChk = _.find(this.state.answers, function (obj) { return obj.name == itemName });
        let checkedValued = 'ALL';
        let value_a = 0;
        let value_b = 0;
        let value_c = 0;
        let value_d = 0;

        if (itemChk !== undefined) {
          checkedValued = itemChk.value;
          value_a = itemChk.col_a;
          value_b = itemChk.col_b;
          value_c = itemChk.col_c;
          value_d = itemChk.col_d;
        }
        //First row
        if (i == 0) {

          let mergeRow = i3 / i2;
          let iMerge = this.state.questionCount.length;

          for(let ind = 0; ind < iMerge; ind++){
         
            if(question.col3[i].index1 === this.state.questionCount[ind].index1 && question.col3[i].index2 == this.state.questionCount[ind].index2){
            
              mergeRow = this.state.questionCount[ind].count - 1;
            }
          } 

          row.push(
            <tr key={Math.random()}>
              <td className="txt-left width-20" rowSpan={i3}>{question.col1[0].content}</td>
              <td className="txt-left width-20" rowSpan={mergeRow}>{question.col2[0].content}</td>
              <td className="txt-left width-35">{question.col3[0].content}</td>
              <td className="w-100px">
                {value_a} / {this.state.total}
              </td>
              <td className="w-100px">
                {value_b}/ {this.state.total}
              </td>
              <td className="w-100px">
                {value_c}/ {this.state.total}
              </td>
              <td className="w-100px">
                {value_d}/ {this.state.total}
              </td>
            </tr>
          );
        } else {
          let Col1;
          let Col2;
          let Col3;
          //col1
          if (i <= i1 - 1) {
            Col1 = <td className="txt-left" >{question.col1[question.col3[i].index1 - 1].content}</td>
          }

          //col2
          if (question.col3[i].index3 == 1) {

            let mergeRow = 1;
            let iMerge = this.state.questionCount.length;

            for(let ind = 0; ind < iMerge; ind++){
           
              if(question.col3[i].index1 === this.state.questionCount[ind].index1 && question.col3[i].index2 == this.state.questionCount[ind].index2){
              
                mergeRow = this.state.questionCount[ind].count - 1;
              }
            }
            
            Col2 = <td className="txt-left" rowSpan={mergeRow}>{question.col2[question.col3[i].index2 - 1].content}</td>
          } 

          //col3
          if (i <= i3 - 1) {
            Col3 = <td className="txt-left" >{question.col3[i].content}</td>
          }



          //Next rows
          row.push(
            <tr key={Math.random()}>
              {Col1}
              {Col2}
              {Col3}
              <td className="w-100px">
                {value_a} / {this.state.total}
              </td>
              <td className="w-100px">
                {value_b}/ {this.state.total}
              </td>
              <td className="w-100px">
                {value_c}/ {this.state.total}
              </td>
              <td className="w-100px">
                {value_d}/ {this.state.total}
              </td>
            </tr>
          );
        }
      }
      return row
    })
  };

  public render() {
  //  console.log ('this.state.questionCount[ind].index1', this.state.questionCount)
    return (
      <Container>

        <Row>
          <Col className="d-flex flex-column align-items-center">
            <h2 className="mb-3">自己評価表</h2>
            <Table
              id="self_evaluation_kansai_kokusai_tougou_student_info"
              bordered
            >
              <tbody>
                <tr>
                  {/* <td className="text-left">
                    学生番号 &nbsp; {this.state.studentID}
                    <br />
                    学生氏名 &nbsp; {this.state.studentName}
                  </td> */}
                  <td className="text-left">
                    実習期間
                    <br />
                    {this.props.startDate} ~ {this.props.endDate}
                  </td>
                  <td className="text-left">
                    実習場所
                    <br />
                    {this.state.intern_place}
                  </td>
                </tr>
                {/* <tr>
                  <td className="text-left">
                    実習期間
                    <br />
                    {this.props.startDate} ~ {this.props.endDate}
                  </td>
                  <td className="text-left">
                    出席状況 -/-日
                    <br />
                    遅刻・早退時間数 -時間
                  </td>
                </tr> */}
              </tbody>
            </Table>
            <Table id="self_evaluation_goal" className="ml-5" bordered>
              <thead className="thead-dark">
                <tr>
                  <th>実習目標</th>
                  <th colSpan={2}>&emsp;</th>
                  <th>A</th>
                  <th>B</th>
                  <th>C</th>
                  <th>D</th>
                </tr>
              </thead>
              <tbody>
                {this.QuestionsData()}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="ml-5">
          <Button
            className="ml-3"
            variant="outline-secondary"
            size="lg"
            type="submit"
            onClick={this.onClickBack}
          >
            Back
          </Button>
        </Row>
      </Container>
    );
  }

}

export default withRouter(SelfEvaluationTable);
