import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Header from "./components/common/Header";
// import Sidebar from "./components/common/Sidebar";
import TopPage from "./components/top/TopPage";
import SignUpView from "./components/login/SignUpView";
import StudentRegister from "./components/login/StudentRegister";
import IndexStudent from "./components/student_view/IndexStudent";
import IndexFaculty from "./components/faculty_view/IndexFaculty";
import StudentList from "./components/faculty_view/StudentList";
import IndexStudentForFaculty from "./components/faculty_view/IndexStudentForFaculty";
import AllNursingPlansView from "./components/faculty_view/AllNursingPlansView";
import NursePlanInputsForFaculty from "./components/faculty_view/nursing_plan/NursePlanInputsForFaculty";
import AllHospitalRoom from "./components/hospital_ward/AllHospitalRoom";
import PatientInfoView from "./components/patient_info/PatientInfoView";
import NursePlanView from "./components/student_view/nurse_plan/NursePlanView";
import NursePlanInputs from "./components/student_view/nurse_plan/NursePlanInputs";
import ChartView from "./components/student_view/chart/ChartView";
import AllChartView from "./components/faculty_view/chart/AllChartView";
import ChartViewForFaculty from "./components/faculty_view/chart/ChartViewForFaculty";
import NursingDiagnosisStudent from "./components/student_view/nursing_diagnosis/NursingDiagnosisStudent";
import NursingDiagnosisFaculty from "./components/faculty_view/nursing_diagnosis/NursingDiagnosisFaculty";
import AllNursingDiagnosisView from "./components/faculty_view/nursing_diagnosis/AllNursingDiagnosisView";
import InternUniversityListForSelfEvaluation from "./components/faculty_view/self_evaluation/InternUniversityListForSelfEvaluation";
import StudentListForSelfEvaluation from "./components/faculty_view/self_evaluation/StudentListForSelfEvaluation";
import SelfEvaluationView from "./components/student_view/self_evaluation/SelfEvaluationView";
import SelfEvaluationViewKansaiKokusai from "./components/student_view/self_evaluation/kansai_kokusai/SelfEvaluationViewKansaiKokusai";
import SelfEvaluationTableKansaiKokusaiKyusei from "./components/student_view/self_evaluation/kansai_kokusai/SelfEvaluationTableKansaiKokusaiKyusei";
import SelfEvaluationTableKansaiKokusaiTougou from "./components/student_view/self_evaluation/kansai_kokusai/SelfEvaluationTableKansaiKokusaiTougou";
import SelfEvaluationTableKansaiKokusaiKyuseiForFaculty from "./components/faculty_view/self_evaluation/kansai_kokusai/SelfEvaluationTableKansaiKokusaiKyuseiForFaculty";
import SelfEvaluationTableKansaiKokusaiTougouForFaculty from "./components/faculty_view/self_evaluation/kansai_kokusai/SelfEvaluationTableKansaiKokusaiTougouForFaculty";

import SelfEvaluationViewKobeJoshi from "./components/student_view/self_evaluation/kobe_joshi/SelfEvaluationViewKobeJoshi";
import SelfEvaluationTableBosei from "./components/student_view/self_evaluation/kobe_joshi/SelfEvaluationTableBosei";
import SelfEvaluationTableSyoni from "./components/student_view/self_evaluation/kobe_joshi/SelfEvaluationTableSyoni";

import SelfEvaluationTableKobeJoshiBoseiForFaculty from "./components/faculty_view/self_evaluation/kobe_joshi/SelfEvaluationTableKobeJoshiBoseiForFaculty";
import SelfEvaluationTableKobeJoshiSyoniForFaculty from "./components/faculty_view/self_evaluation/kobe_joshi/SelfEvaluationTableKobeJoshiSyoniForFaculty";

import AllSelfEvaluationView from "./components/faculty_view/self_evaluation/AllSelfEvaluationView";
import SelfEvaluationForFacultyView from "./components/faculty_view/self_evaluation/SelfEvaluationForFacultyView";
import QuestionnaireView from "./components/student_view/questionnaire/QuestionnaireView";
import QuestionnaireResultView from "./components/faculty_view/questionnaire_result/QuestionnaireResultView";
// SW fix 2021/3/1
import InternUniversityList from "./components/faculty_view/InternUniversityList";
import SettingMenu from "./components/faculty_view/SettingMenu";
import UserList from "./components/faculty_view/settings/UserList";
import StudentListSWCustom from "./components/faculty_view/StudentListSWCustom";
import ManagerOverviews from "./components/faculty_view/settings/ManagerOverviews";
import ManagerTestDataItems from "./components/faculty_view/settings/ManagerTestDataItems";
import CreateBasicPatientInfomation from "./components/form_create_new/CreateBasicPatientInfomation";
import CreatePatientInformationOverview from "./components/form_create_new/CreatePatientInformationOverview";
import CreatePatientMedicalRecords from "./components/form_create_new/CreatePatientMedicalRecords";
import CreatePatientTestData from "./components/form_create_new/CreatePatientTestData";
import CreateNursingInformation from "./components/form_create_new/CreateNursingInformation";
import CreateNursingDiagnosis from "./components/form_create_new/CreateNursingDiagnosis";
import CreatePatientDoctorInstruction from "./components/form_create_new/CreatePatientDoctorInstruction";
import CreatePatientMidwiferyRecord from "./components/form_create_new/CreatePatientMidwiferyRecord";
import CreatePatientSurgeryRecord from "./components/form_create_new/CreatePatientSurgeryRecord";
import CreateManagerTestDataItems from "./components/form_create_new/CreateManagerTestDataItems";

import ManagerTestDataItemChild from "./components/faculty_view/settings/ManagerTestDataItemChild";
import "typeface-roboto";
import "./assets/styles/App.scss";
import { HomePathContextProvider } from './helpers/HomePathContext';
import CreateSelfEvaluationItems from "./components/form_create_new/CreateSelfEvaluationItems";
import CreatePatientAssignment from "./components/form_create_new/CreatePatientAssignment";
import RootRouter from './helpers/RootRouter';
import UpdateStudent from "./components/faculty_view/update/UpdateStudent";
import UpdateFauctly from "./components/faculty_view/update/UpdateFauctly";
import ManagerLinkInfo from "./components/faculty_view/link_info/ManagerLinkInfo";
import PdfManagerIndex from "./components/patient_info/pdf_manager";
function App() {
  return (
    <HomePathContextProvider>
      <div className="App">
        <Container className="p-0 min-height bg-gray" fluid>
          <Row>
            <Header />
          </Row>
          <Row>
            <Col className="p-0">
              <BrowserRouter ref={el => RootRouter.rootRoute = el}>
                <Switch>
                  <Route exact={true} path={"/"} component={TopPage} />
                  <Route path={"/signup/:type"} component={SignUpView} />
                  <Route path={"/update_info/:studentID"} component={StudentRegister} />
                  <Route
                    path={"/index/student/:studentID"}
                    component={IndexStudent}
                  />
                  <Route
                    path={"/index/faculty/:facultyID"}
                    component={IndexFaculty}
                  />
                  <Route
                    path={"/faculty/student_list/:university/:startDate"}
                    component={StudentList}
                  />
                  {/* HKB */}
                  <Route
                    path={"/faculty/settings/user_list"}
                    component={UserList}
                  />
                  <Route
                    path={'/faculty/settings/update_student/:studentID'}
                    component={UpdateStudent}
                  />
                  <Route
                    path={'/faculty/settings/update_fauctly/:fauctlyID'}
                    component={UpdateFauctly}
                  />

                  <Route
                    path={"/faculty/settings/manager-overviews"}
                    component={ManagerOverviews}
                  />
                  <Route
                    path={"/faculty/settings/test_data_items"}
                    component={ManagerTestDataItems}
                  />
                  <Route
                    path={"/faculty/settings/test_data_item/:parentId"}
                    component={ManagerTestDataItemChild}
                  />
                  <Route
                    path={"/faculty/settings/link_infos"}
                    component={ManagerLinkInfo}
                  />
                  <Route
                    path={"/faculty/student_info/:studentID"}
                    component={IndexStudentForFaculty}
                  />
                  <Route
                    path={"/faculty/all_nursing_plans/:facultyID"}
                    component={AllNursingPlansView}
                  />
                  <Route
                    path={"/faculty/nursing_plan/:facultyID/:studentID/:date"}
                    component={NursePlanInputsForFaculty}
                  />
                  <Route
                    path={"/all_hospital_rooms/:studentID"}
                    component={AllHospitalRoom}
                  />
                  <Route
                    path={"/patient_info/:patientID/:studentID/:facultyID/:startDate/:endDate/:university"}
                    component={PatientInfoView}
                  />
                  <Route
                    path={"/nurse_plan/:studentID"}
                    component={NursePlanView}
                  />
                  <Route
                    path={"/nurse_plan_create/:studentID/:date/:facultyID"}
                    component={NursePlanInputs}
                  />
                  <Route path={"/chart_view/:studentID"} component={ChartView} />
                  <Route
                    path={"/chart_view_all/:facultyID"}
                    component={AllChartView}
                  />
                  <Route
                    path={"/chart_view_for_faculty/:facultyID/:studentID"}
                    component={ChartViewForFaculty}
                  />
                  <Route
                    path={"/nursing_diagnosis/student/:studentID"}
                    component={NursingDiagnosisStudent}
                  />
                  <Route
                    path={"/nursing_diagnosis/faculty_all_list/:facultyID"}
                    component={AllNursingDiagnosisView}
                  />
                  <Route
                    path={"/nursing_diagnosis/faculty/:studentID"}
                    component={NursingDiagnosisFaculty}
                  />
                  <Route
                    path={
                      "/faculty/student_list_self_evaluation/:university/:startDate/:endDate/:facultyID"
                    }
                    component={StudentListForSelfEvaluation}
                  />
                  <Route
                    path={"/self_evaluation/:studentID"}
                    component={SelfEvaluationView}
                  />
                  <Route
                    path={"/self_evaluation_kansai_kokusai/:studentID"}
                    component={SelfEvaluationViewKansaiKokusai}
                  />
                  <Route
                    path={"/self_evaluation_kansai_kokusai_kyusei/:studentID"}
                    component={SelfEvaluationTableKansaiKokusaiKyusei}
                  />
                  <Route
                    path={"/self_evaluation_kansai_kokusai_tougou/:studentID"}
                    component={SelfEvaluationTableKansaiKokusaiTougou}
                  />
                  <Route
                    path={"/self_evaluation_kobe_joshidai/:studentID"}
                    component={SelfEvaluationViewKobeJoshi}
                  />
                  <Route
                    path={"/self_evaluation_kobe_joshi_bosei/:studentID"}
                    component={SelfEvaluationTableBosei}
                  />
                  <Route
                    path={"/self_evaluation_kobe_joshi_syoni/:studentID"}
                    component={SelfEvaluationTableSyoni}
                  />
                  <Route
                    path={"/faculty/self_evaluation_list/:facultyID"}
                    component={InternUniversityListForSelfEvaluation}
                  />
                  <Route
                    path={
                      // "/faculty/self_evaluation_for_faculty/:studentID/:facultyID"
                      "/faculty/self_evaluation_for_faculty/:university/:startDate/:endDate/:facultyID"
                    }
                    component={SelfEvaluationForFacultyView}
                  />
                  <Route
                    path={
                      "/faculty/self_evaluation_table_kansai_kokusai_kyusei/:studentID"
                    }
                    component={SelfEvaluationTableKansaiKokusaiKyuseiForFaculty}
                  />
                  <Route
                    path={
                      "/faculty/self_evaluation_table_kansai_kokusai_tougou/:studentID"
                    }
                    component={SelfEvaluationTableKansaiKokusaiTougouForFaculty}
                  />
                  <Route
                    path={
                      "/faculty/self_evaluation_table_kobe_joshi_bosei/:studentID"
                    }
                    component={SelfEvaluationTableKobeJoshiBoseiForFaculty}
                  />
                  <Route
                    path={
                      "/faculty/self_evaluation_table_kobe_joshi_syoni/:studentID"
                    }
                    component={SelfEvaluationTableKobeJoshiSyoniForFaculty}
                  />
                  <Route
                    path={"/questionnaire/:studentID"}
                    component={QuestionnaireView}
                  />
                  <Route
                    path={"/faculty/questionnaire_result/:university/:startDate/:endDate/:facultyID"}
                    component={QuestionnaireResultView}
                  />

                  {/* SW fix 2021/3/1 */}
                  <Route
                    path={"/faculty/intern_university_list/:facultyID"}
                    component={InternUniversityList}
                  />
                  <Route
                    path={"/faculty/settings_menu/:facultyID"}
                    component={SettingMenu}
                  />
                  <Route
                    path={"/faculty/student_list_custom/:university/:startDate/:endDate/:internDays/:facultyID"}
                    component={StudentListSWCustom}
                  />
                  <Route
                    path={"/create-new/basic-patient-information"}
                    component={CreateBasicPatientInfomation}
                  />
                  <Route
                    path={"/create-new/patient-information-overview"}
                    component={CreatePatientInformationOverview}
                  />
                  <Route
                    path={"/create-new/patient-information-medical-records"}
                    component={CreatePatientMedicalRecords}
                  />
                  <Route
                    path={"/create-new/patient-information-test-data"}
                    component={CreatePatientTestData}
                  />
                  <Route
                    path={"/create-new/nursing-information"}
                    component={CreateNursingInformation}
                  />
                  <Route
                    path={"/create-new/nursing-diagnosis"}
                    component={CreateNursingDiagnosis}
                  />
                  <Route
                    path={"/create-new/patient-information-doctor-instruction"}
                    component={CreatePatientDoctorInstruction}
                  />
                  <Route
                    path={"/create-new/patient-information-midwifery-record"}
                    component={CreatePatientMidwiferyRecord}
                  />
                  <Route
                    path={"/create-new/patient-information-surgery-record"}
                    component={CreatePatientSurgeryRecord}
                  />
                  <Route
                    path={"/create-new/self-evaluation-item"}
                    component={CreateSelfEvaluationItems}
                  />
                  <Route
                    path={"/create-new/patient-assignment"}
                    component={CreatePatientAssignment}
                  />
                  <Route
                    path={"/create-new/manager-test-data-items"}
                    component={CreateManagerTestDataItems}
                  />
                   <Route
                    path={"/create-new/patient-pdf-manager"}
                    component={PdfManagerIndex}
                  />
                </Switch>
              </BrowserRouter>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-muted mt-5 mb-5">
                Copyright ©︎ Kobe University Hospital Department of Nursing
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </HomePathContextProvider>
  );
}

export default App;
