import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
interface Props {
    value:any;
    onItemSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
interface State {
    university: {
        id: number;
        university_id: string;
        university_name: string;
    }[];
}

class UniversitySelect extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            university: [{ id: 0, university_id: "", university_name: "" }]
        };
    }

    public componentDidMount() {

        const url = `${process.env.REACT_APP_API_URL}/api/signup/get_university`;

        fetch(url, { mode: "cors" })
            .then(res => res.json())
            .then(
                result => {
                    console.log(result);
                    this.setState({
                        university: result
                    });
                },
                error => {
                    console.log(error);
                }
            );

    }

    public render() {
        let datas = this.props.value.isSupervisor ? this.state.university : this.state.university.filter(x => x.university_id == this.props.value.affiliation);
        const options =  datas.map((item, i) => (            
            <option key={i} value={item.university_id}>{item.university_name}</option>
        )) ;
      
        
        return (
            <Form.Group as={Row} controlId="fg-5" className="mb-4">
            <Form.Label column md={4} className="font-weight-bold text-right">
             所属
            </Form.Label>
            <Col md={8}>
            <Form.Control  value={this.props.value.affiliation} as="select" onChange={this.props.onItemSelect}>
                {/* <option>選択</option> */}
                {options}
            </Form.Control>
            </Col>
            </Form.Group>
        );
    }
}

export default UniversitySelect;
