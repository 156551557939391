import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  RouteComponentProps,
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PatientBasicInfo from "./PatientBasicInfo";
import PatientInfoItems from "./PatientInfoItems";
// import Overview1 from "./overview/patient1/Overview";
// import Overview2 from "./overview/patient2/Overview";
// import Overview3 from "./overview/patient3/Overview";
// import Overview4 from "./overview/patient4/Overview";
// import Overview504 from "./overview/patient504/Overview";
// import Overview505 from "./overview/patient505/Overview";
// import Overview506 from "./overview/patient506/Overview";
// import Overview507 from "./overview/patient507/Overview";
// import Overview402 from "./overview/patient402/Overview";
// import Overview403 from "./overview/patient403/Overview";
// import Overview404 from "./overview/patient404/Overview";
import Overview301 from "./overview/patient301/Overview";
// import Overview302 from "./overview/patient302/Overview";
import TestData from "./test_data/TestData";
// import TestData2 from "./test_data/patient2/TestData";
// import TestData3 from "./test_data/patient3/TestData";
// import TestData4 from "./test_data/patient4/TestData";
// import TestData504 from "./test_data/patient504/TestData";
// import TestData505 from "./test_data/patient505/TestData";
// import TestData506 from "./test_data/patient506/TestData";
// import TestData507 from "./test_data/patient507/TestData";
// import TestData402 from "./test_data/patient402/TestData";
// import TestData403 from "./test_data/patient403/TestData";
// import TestData404 from "./test_data/patient404/TestData";
// import TestData301 from "./test_data/patient301/TestData";
// import TestData302 from "./test_data/patient302/TestData";
import MedicalRecord from "./medical_record/MedicalRecord";
import NurseInfo from "./NurseInfo";
import NurseDiagnosis from "./nurse_diagnosis/NurseDiagnosis";
import DoctorInstruction from "./DoctorInstruction";
import SurgeryRecord from "./surgery_record/SurgeryRecord";
import JosanRecord from "./josan_record/JosanRecord";

import "../../assets/styles/Overview.scss";
import CreatePatientInformationOverview from "../form_create_new/CreatePatientInformationOverview";
import CreatePatientMedicalRecords from "../form_create_new/CreatePatientMedicalRecords";
import CreatePatientTestData from "../form_create_new/CreatePatientTestData";
import CreateNursingInformation from "../form_create_new/CreateNursingInformation";
import CreateNursingDiagnosis from "../form_create_new/CreateNursingDiagnosis";
import CreatePatientMidwiferyRecord from "../form_create_new/CreatePatientMidwiferyRecord";
import CreatePatientSurgeryRecord from "../form_create_new/CreatePatientSurgeryRecord";
import CreatePatientDoctorInstruction from "../form_create_new/CreatePatientDoctorInstruction";
import CreateManagerTestDataItems from "../form_create_new/CreateManagerTestDataItems";
import { checkUserId } from "../../services/AuthService";
import { USER_TYPE } from "../../services/AppLocalHelper";
import PdfManagerIndex from "./pdf_manager";
interface Props
  extends RouteComponentProps<{ patientID: string; studentID: string; facultyID: string; startDate: string; }> { }

interface State { }

class PatientInfoView extends React.Component<Props, State> {
  // SW add 2021/3/3
  // private faculty_id : string | null;
  // private start_date: string | null;

  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID === "supervisor-faculty" ? this.props.match.params.facultyID : this.props.match.params.studentID;
    if (!checkUserId(userId, this.props.match.params.studentID === "supervisor-faculty" ? USER_TYPE.TEACHER : USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }

    this.getType = this.getType.bind(this);
    const query = new URLSearchParams(this.props.location.search);
    // this.faculty_id = query.get('facultyID');
    // this.start_date = query.get('start_date');
  }
  public getType() {
    const query = new URLSearchParams(this.props.location.search)
    const type = query.get("type")
    return type;
  }

  public render() {
    const type = this.getType();
    const patient_id = this.props.match.params.patientID;

    return (
      <Container fluid className="mt-4 p-0">
        <Row className="ml-4 mb-4">
          <Col>
            <PatientBasicInfo patientID={this.props.match.params.patientID} facultyID={this.props.match.params.facultyID} studentID={this.props.match.params.studentID} startDate={this.props.match.params.startDate} />
          </Col>
        </Row>
        <BrowserRouter >
          <Switch>
            {/* SW-ADD-2/3/2022 */}
            <Route
              exact={true}
              path={`/patient_info/:patientID/:studentID/:facultyID/:startDate/:endDate/:university`}
              component={PatientInfoItems}
            />
            <Route
              path={`/patient_info/:patientID/:studentID/:facultyID/:startDate/:endDate/:university/over_view/:overView`}
              component={Overview301}
            />

            <Route
              path={`/patient_info/:patientID/:studentID/:facultyID/:startDate/:endDate/:university/medical_records`}
              component={MedicalRecord}
            />

            <Route
              path={"/patient_info/:patientID/:studentID/:facultyID/:startDate/:endDate/:university/nurse_info"}
              component={NurseInfo}
            />
            <Route
              path={"/patient_info/:patientID/:studentID/:facultyID/:startDate/:endDate/:university/nurse_diagnosis"}
              component={NurseDiagnosis}
            />
            <Route
              path={"/patient_info/:patientID/:studentID/:facultyID/:startDate/:endDate/:university/doctor_instruction"}
              component={DoctorInstruction}
            />
            <Route
              path={"/patient_info/:patientID/:studentID/:facultyID/:startDate/:endDate/:university/surgery_record/all"}
              component={SurgeryRecord}
            />
            <Route
              path={"/patient_info/:patientID/:studentID/:facultyID/:startDate/:endDate/:university/josan_record/all"}
              component={JosanRecord}
            />
            <Route
              path={"/patient_info/:patientID/:studentID/:facultyID/:startDate/:endDate/:university/:testData"}
              component={TestData}
            />
            {/* SW add 2021/3/3 */}
            <Route
              path={"/create-new/patient-information-overview"}
              component={CreatePatientInformationOverview}
            />
            <Route
              path={"/create-new/patient-information-medical-records"}
              component={CreatePatientMedicalRecords}
            />
            <Route
              path={"/create-new/patient-information-test-data"}
              component={CreatePatientTestData}
            />
            <Route
              path={"/create-new/nursing-information"}
              component={CreateNursingInformation}
            />
            <Route
              path={"/create-new/nursing-diagnosis"}
              component={CreateNursingDiagnosis}
            />
            <Route
              path={"/create-new/patient-information-doctor-instruction"}
              component={CreatePatientDoctorInstruction}
            />
            <Route
              path={"/create-new/manager-test-data-items"}
              component={CreateManagerTestDataItems}
            />
            <Route
              path={"/create-new/patient-information-midwifery-record"}
              component={CreatePatientMidwiferyRecord}
            />
            <Route
              path={"/create-new/patient-information-surgery-record"}
              component={CreatePatientSurgeryRecord}
            />
            <Route
              path={"/create-new/patient-pdf-manager"}
              component={PdfManagerIndex}
            />
          </Switch>
        </BrowserRouter>
      </Container>
    );
  }
}

export default PatientInfoView;

