import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import "../../../assets/styles/AllChartView.scss";

interface Props extends RouteComponentProps<{ facultyID: string }> {}
interface State {
  charts: {
    studentID: string;
    studentName: string;
		university: string;
		startDate: string;
		endDate: string;
  }[];
}

class AllChartView extends React.Component<Props, State> {
  private student_id : string | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      charts: []
    };

    this.goToChartView = this.goToChartView.bind(this);
    const query = new URLSearchParams(this.props.location.search);
    this.student_id = query.get('student_id');
  }

  public goToChartView(studentID: string) {
      this.props.history.push(
        `/chart_view_for_faculty/${this.props.match.params.facultyID}/${studentID}`);
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/chart_pdf/fetch_all?student_id=${this.student_id}`;
    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log(result);
        this.setState({
          charts: result
        });
      })
      .catch(console.error);
  }

  public render() {
    const chartRows = this.state.charts.map((item, i) => (
      <tr
        key={i}
        onClick={this.goToChartView.bind(
          this,
          item.studentID
        )}
      >
        <td>{item.studentName}</td>
        <td>{item.university}</td>
				<td>{`${item.startDate}〜${item.endDate}`}</td>
      </tr>
    ));

    return (
      <Row className="m-5">
        <Col md={1} />
        <Col md={10}>
          <Row md={8} className="ml-5">
            <Col>
							<h2 className="text-center">受け持ち学生の関連図一覧</h2>
            </Col>
          </Row>
          <Row md={8}>
            <Col>
              <Table id="all_nursing_plans" className="m-5" bordered hover>
                <thead className="thead-dark">
                  <tr>
                    <th>氏名</th>
                    <th>所属</th>
                    <th>実習期間</th>
                  </tr>
                </thead>
                <tbody>{chartRows}</tbody>
              </Table>
            </Col>
          </Row>
        </Col>
        <Col md={1} />
      </Row>
    );
  }
}

export default withRouter(AllChartView);
