import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container, Form, Col, Row, Button, Alert } from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
import { TimePicker } from "../common/TimePickerComponent";
import { LoadingSpriner } from "../common/CircularSpinner";
import RouteDataContext from "../../helpers/RouteDataContext";
import {
  onKeyPress,
  convertToHHMM,
  convertHoursTime,
  ACTION,
} from "../common/function";
import CSVReaderImport from "../common/CSVReaderImport";
import Link from "@material-ui/core/Link";
import { MedicalRecordData, MedicalRecordForm } from "../models/Types";
import MedicalRecordTable from "../patient_info/medical_record/MedicalRecordTable";
import { ButtonSpriner } from "../common/CircularSpinner";
import "../../assets/styles/IndexFaculty.scss";

interface Props extends RouteComponentProps<{}> { }
interface State {
  medicalRecords: MedicalRecordData[];
  formValues: MedicalRecordForm;
  errors: boolean;
  errorsCSV: boolean;
  isSuccess: string;
  hoursTime: any;
  isLoading: boolean;
  isLoadingCSV: boolean;
}

class CreatePatientMedicalRecords extends React.Component<Props, State> {
  static contextType = RouteDataContext;
  constructor(props: Props) {
    super(props);

    this.state = {
      medicalRecords: [],
      formValues: {} as MedicalRecordForm,
      errors: false,
      errorsCSV: false,
      isLoading: false,
      isLoadingCSV: false,
      isSuccess: "",
      hoursTime: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { location } = this.props;
    const { data } = location.state as any;
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/medical_record?patient_id=${data.patientID}&student_id=${data.studentID}&faculty_id=${data.facultyID}`;

    fetch(url, { mode: "cors" })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            medicalRecords: result,
          });
        },
        (error) => {
          console.log("API Error");
        }
      );
  };

  // editMedicalRecord
  setFormValues = (medicalRecordId: number) => {
    const medicalRecord = this.state.medicalRecords.find(
      (record) => record.medicalRecordId === medicalRecordId
    ) as any;

    const mappingData = {
      medicalRecordId: medicalRecord.medicalRecordId,
      patientID: medicalRecord.patientID,
      dateTime: medicalRecord.dateTime,
      dayTime: Math.floor(medicalRecord.dateConverted / 1440),
      hoursTime: convertToHHMM(medicalRecord.dateConverted % 1440) as any,
      author: medicalRecord.author,
      title: medicalRecord.title,
      subTitle: medicalRecord.subTitle,
      message: medicalRecord.message,
    };

    this.setState({
      hoursTime: convertHoursTime(mappingData.hoursTime as any) as any,
      formValues: mappingData,
    });
    window.scrollTo(0, 0);
  };

  onVisibleMedicalRecord = (medicalRecordId: number, visibleFlag: boolean) => {
    const medicalRecord = this.state.medicalRecords.find(
      (record) => record.medicalRecordId === medicalRecordId
    ) as any;

    const params = {
      medicalRecordId: medicalRecord.medicalRecordId,
      patient_id: medicalRecord.patientID,
      visibleFlag: visibleFlag,
    };
    const route = "patient_info/medical_record/visible";
    this.fetchAPI(params, route, "", ACTION.VISIBLE);
  };

  // Create or Update medical Record
  onSubmitMedicalRecord = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { formValues } = this.state;
    const { location } = this.props;
    const { data } = location.state as any;
    const successMsg = formValues.medicalRecordId
      ? "登録完了しました。"
      : "更新しました。";

    const convertToMinutes =
      moment(this.state.hoursTime).hour() * 60 * 60 +
      moment(this.state.hoursTime).minute() * 60;

    const hoursTime = this.state.hoursTime ? convertToMinutes : 0;
    const dayTime = (formValues.dayTime ? formValues.dayTime : 0) * 24 * 60 * 60;

    const medicalRecordsForm = {
      patient_id: data.patientID,
      data: [
        {
          medicalRecordId: formValues.medicalRecordId || "",
          author: formValues.author,
          title: formValues.title,
          message: formValues.message,
          subtitle: formValues.subTitle,
          date_time: Number(dayTime) + Number(hoursTime),
        },
      ],
    };

    const route = "patient_info/medical_record/create_or_update";
    console.log('medicalRecordsForm:', medicalRecordsForm);
    this.fetchAPI(
      medicalRecordsForm,
      route,
      successMsg,
      formValues.medicalRecordId ? ACTION.UPDATE : ACTION.CREATE
    );
  };

  fetchAPI = (
    medicalRecordsForm: any,
    route: string,
    successMsg: string,
    action?: string
  ) => {
    const url = `${process.env.REACT_APP_API_URL}/api/${route}`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify(medicalRecordsForm);

    fetch(url, { method, mode, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const newData = medicalRecordsForm.data;
          switch (action) {
            case ACTION.CREATE:
              this.fetchData();
              break;
            case ACTION.CSV:
              this.fetchData();
            case ACTION.UPDATE:
              this.fetchData();
              break;
            default:
              this.setMedicalRecords(
                newData[0].medicalRecordId,
                action as any,
                newData[0],
                newData[0].visibleFlag
              );
              break;
          }
          this.setSuccessMessage(successMsg);
          this.onResetForm();
        } else {
          this.setState({ errors: true, formValues: {} as MedicalRecordForm });
        }
      })
      .catch(() => {
        this.setError();
      });
  };

  // upload CSV for create medical record
  onHandleCSV = (csvData: any, fileInfo: any) => {
    const { location } = this.props;
    const { data } = location.state as any;

    if (fileInfo.name) {
      const regex = /^.+\.(csv)$/g;
      const checkType = regex.test(fileInfo.name);
      if (checkType) {
        const newData = {
          patient_id: data.patientID,
          data: csvData,
        };

        this.setState({
          isLoadingCSV: true,
        });
        const successMsg = "データの登録が完了しました。";
        const route = "patient_info/medical_record/create_or_update?mode=csv";
        this.fetchAPI(newData, route, successMsg, ACTION.CSV);
      } else {
        this.setError();
      }
    }
  };

  onResetForm = () => {
    this.setState({
      formValues: {} as MedicalRecordForm,
      hoursTime: null,
    });
  };

  // edit medical records
  setMedicalRecords = (
    medicalRecordId: number,
    action: string,
    formData?: any,
    visibleFlag: boolean = false
  ) => {
    const { medicalRecords } = this.state;
    let medicalRecordsNew;
    switch (action) {
      case "DELETE":
        medicalRecordsNew = medicalRecords.filter(
          (medicalRecord) => medicalRecord.medicalRecordId !== medicalRecordId
        );
        break;
      case "VISIBLE":
        medicalRecordsNew = medicalRecords.map((medicalRecord) => {
          const visibleByID =
            medicalRecord.medicalRecordId === medicalRecordId
              ? visibleFlag
              : medicalRecord.visibleFlag;
          return { ...medicalRecord, visibleFlag: visibleByID };
        });
        break;
      case "UPDATE":
        medicalRecordsNew = medicalRecords.map((medicalRecord) => {
          const newData =
            medicalRecord.medicalRecordId === medicalRecordId
              ? this.state.formValues
              : {};
          return {
            ...medicalRecord,
            ...newData,
            dateConverted: (formData.date_time / 60) as any,
          };
        });
        break;

      default:
        break;
    }

    if (medicalRecordsNew) {
      this.setState({
        medicalRecords: medicalRecordsNew,
      });
    }
  };

  // set field for event onchange input
  setField = (field: string, value: string) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [field]: value,
      },
    });
  };

  setSuccessMessage = (successMsg: string) => {
    this.setState({
      isSuccess: successMsg,
      isLoadingCSV: false,
    });
    setTimeout(() => {
      this.setState({
        isSuccess: "",
      });
    }, 2000);
  };

  setError = () => {
    this.setState({
      errorsCSV: true,
      isLoadingCSV: false,
    });
    setTimeout(() => {
      this.setState({
        errorsCSV: false,
      });
    }, 2000);
  };

  render() {
    const medicalRecordRows = this.state.medicalRecords.map((item, i) => (
      <MedicalRecordTable
        key={i}
        medicalRecordData={item}
        isShowAction={true}
        isSuccess={this.state.isSuccess}
        setSuccessMessage={this.setSuccessMessage}
        setMedicalRecords={this.setMedicalRecords}
        setFormValues={this.setFormValues}
        onVisibleMedicalRecord={this.onVisibleMedicalRecord}
      />
    ));

    console.log(this.state.medicalRecords);

    return (
      <Container fluid>
        <Row>
          <Col md={9} className="mt-5" style={{ margin: "auto" }}>
            <h2>診療記録入力</h2>

            <Form className="mt-5" onSubmit={this.onSubmitMedicalRecord}>
              <Row>
                <Col md={6}>
                  <Form.Group as={Row} controlId="fg-1">
                    <Form.Label
                      column
                      md={3}
                      className="font-weight-bold text-left"
                    >
                      著者名：
                    </Form.Label>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        value={this.state.formValues.author || ""}
                        onChange={(e) =>
                          this.setField("author", e.target.value)
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group as={Row} controlId="fg-1">
                    <Col md={8}>
                      <Form.Group as={Row} controlId="fg-1">
                        <Form.Label column md={6} className="font-weight-bold">
                          経過日時：
                        </Form.Label>
                        <Col md={6} className="d-flex">
                          <Form.Control
                            style={{ width: "80px" }}
                            type="text"
                            value={this.state.formValues.dayTime || ""}
                            onChange={(e) =>
                              this.setField("dayTime", e.target.value)
                            }
                            onKeyPress={onKeyPress}
                          />
                          <span className="ml-1 mt-1">日目</span>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={4} style={{ marginTop: "-11px" }}>
                      <TimePicker
                        value={this.state.hoursTime || null}
                        onChange={(time: any) =>
                          this.setState({
                            hoursTime: time,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={10}>
                  <Form.Group as={Row} className="text-left" controlId="fg-1">
                    <Form.Label column md={2} className="font-weight-bold">
                      タイトル：
                    </Form.Label>
                    <Col md={7} style={{ marginLeft: "-15px" }}>
                      <Form.Control
                        type="text"
                        value={this.state.formValues.title || ""}
                        onChange={(e) => this.setField("title", e.target.value)}
                      />
                    </Col>
                    <Form.Label column md={3} className="font-weight-bold">
                      サブタイトル：
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Control
                    type="text"
                    value={this.state.formValues.subTitle || ""}
                    onChange={(e) => this.setField("subTitle", e.target.value)}
                  />
                </Col>
              </Row>
              <Form.Group as={Row} className="mb-5">
                <Col md={2} className="text-left">
                  <Form.Label className="font-weight-bold">
                    メッセージ:
                  </Form.Label>
                </Col>
                <Col md={12}>
                  <Form.Control
                    as="textarea"
                    value={this.state.formValues.message || ""}
                    rows={5}
                    onChange={(e) => this.setField("message", e.target.value)}
                  />
                </Col>
              </Form.Group>
              <StyledButton>
                <ButtonSpriner isLoading={this.state.isLoading} label="登録" />
                <Button variant="outline-primary" onClick={this.onResetForm}>
                  クリア
                </Button>
              </StyledButton>
              {this.state.errors && (
                <Alert className="mt-3" variant="danger">
                  医療記録の入力に失敗しました
                </Alert>
              )}

              {this.state.isSuccess && (
                <Row className="ml-4">
                  <Col md={9} className="mt-5" style={{ margin: "auto" }}>
                    <Alert variant="success">{this.state.isSuccess}</Alert>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </Row>

        <Row className="ml-4">
          {this.state.medicalRecords.length > 0 && (
            <Col md={12} className="mt-3">
              {this.state.isLoadingCSV && <LoadingSpriner />}
              {this.state.errorsCSV && (
                <Alert className="mt-3" variant="danger">
                  データの登録に失敗しました。
                </Alert>
              )}
            </Col>
          )}
          <Col md={9} className="mt-5" style={{ margin: "auto" }}>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <CSVReaderImport
                handleForce={this.onHandleCSV}
                className="react-csv-input"
              />
              <Link
                href={`${process.env.REACT_APP_API_URL}/api/patient_info/csv-sample-download?fileName=[サンプル]診療記録入力.csv`}
                style={{
                  cursor: "pointer",
                  color: "#007bff",
                  textDecoration: "none",
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
                color="primary"
              >
                CSVテンプレート
              </Link>
            </div>
            {medicalRecordRows}
          </Col>
        </Row>

      </Container>
    );
  }
}

const StyledButton = styled.div`
  margin-top: 40px;
  .btn {
    width: 125px;
    margin-right: 20px;
  }
`;

export default withRouter(CreatePatientMedicalRecords);
