import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import NurseDiagnosisItem from "./NurseDiagnosisItem";
import { NursingDiagnosisPatientType } from "../../models/Types";
import "../../../assets/styles/NurseDiagnosis.scss";
import Form from "react-bootstrap/Form";
import { checkUserId } from "../../../services/AuthService";
import { USER_TYPE } from "../../../services/AppLocalHelper";

interface Props
  extends RouteComponentProps<{
    patientID: string;
    studentID: string;
    facultyID: string;
    startDate: string;
  }> { }

interface State {
  nursingDiagnosisArray: NursingDiagnosisPatientType[];
  isStudent: boolean;
}

class NurseDiagnosis extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID === "supervisor-faculty" ? this.props.match.params.facultyID : this.props.match.params.studentID;
    if (!checkUserId(userId, this.props.match.params.studentID === "supervisor-faculty" ? USER_TYPE.TEACHER : USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }

    this.state = {
      nursingDiagnosisArray: [],
      isStudent: true,
    }
    this.loadNurseDiagnosis = this.loadNurseDiagnosis.bind(this);
    this.checkStudent = this.checkStudent.bind(this)
  }

  public loadNurseDiagnosis() {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/get_nurse_diagnosis?patient_id=${this.props.match.params.patientID}&student_id=${this.props.match.params.studentID}&faculty_id=${this.props.match.params.facultyID}&start_date=${this.props.match.params.startDate}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          nursingDiagnosisArray: result,
        });
        // console.log("🚀 ~ file: NurseDiagnosis.tsx ~ line 40 ~ NurseDiagnosis ~ .then ~ result", result)
      })
      .catch(console.error);
  }

  public componentDidMount() {
    this.checkStudent()
    this.loadNurseDiagnosis();
  }

  public checkStudent() {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/control/is_student?student_id=${this.props.match.params.studentID}&faculty_id=${this.props.match.params.facultyID}`;

    fetch(url, { mode: "cors" })
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isStudent: result
          });
        },
        error => {
          console.log("API Error");
        }
      );
  }

  public render() {

    const nursingDiagnosisRow = (this.state.isStudent ? this.state.nursingDiagnosisArray.filter((item) => item.visibleFlag === false) : this.state.nursingDiagnosisArray)
      .map((item, i) => (
        <tr key={item.index}>
          <td>{`No.${i + 1}`}</td>
          <td>{item.date}</td>
          <td>{item.status}</td>
          <td id="nursing_diagnosis" className="p-0">
            <div>
              <Form.Row>
                <div className="fl-left w-10"> 診断名： </div>
                <div className="fl-left w-75 text-ip">
                  <pre className="text-left">
                    {item.diagnosisName}
                  </pre>
                  {/* <Form.Control
                    className="m-0 sw-bgr-fff"
                    required
                    type=""
                    value={item.diagnosisName}
                    disabled
                  /> */}
                </div>
              </Form.Row>
            </div>
            <div>
              <Form.Row>
                <div className="fl-left w-10"> O： </div>
                <div className="fl-left w-75 text-ip">
                  <pre className="text-left">
                    {item.o == null ? '' : item.o}
                  </pre>
                  {/* <Form.Control
                    className="m-0 sw-bgr-fff"
                    required
                    type="text"
                    value={item.o == null ? '' : item.o}
                    disabled
                  /> */}
                </div>
              </Form.Row>
            </div>
            <div>
              <Form.Row>
                <div className="fl-left w-10"> T： </div>
                <div className="fl-left w-75 text-ip">
                  <pre className="text-left">
                    {item.t == null ? '' : item.t}
                  </pre>
                  {/* <Form.Control
                    className="m-0 sw-bgr-fff"
                    required
                    type="text"
                    value={item.t == null ? '' : item.t}
                    disabled
                  /> */}
                </div>
              </Form.Row>
            </div>
            <div>
              <Form.Row>
                <div className="fl-left w-10"> E： </div>
                <div className="fl-left w-75 text-ip">
                  <pre className="text-left">
                    {item.p == null ? '' : item.p}
                  </pre>
                  {/* <Form.Control
                    className="m-0 sw-bgr-fff"
                    required
                    type="text"
                    value={item.p == null ? '' : item.p}
                    disabled
                  /> */}
                </div>
              </Form.Row>
            </div>

          </td>
        </tr>
      ));

    return (
      <Row className="ml-4">
        <Col md={2} />
        <Col md={8}>
          <Table className="nurse_diagnosis-sw" bordered>
            <thead>
              <tr>
                <th className="number">No. </th>
                <th className="diagnosis_date">診断年月日</th>
                <th className="status">状況</th>
                <th className="diagnosis">看護診断/共同問題</th>
              </tr>
            </thead>
            <tbody>
              {nursingDiagnosisRow}
            </tbody>
          </Table>
        </Col>
        <Col md={2} />
      </Row>
    );
  }
}

export default NurseDiagnosis;
