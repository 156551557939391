import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
// import "../../assets/styles/AllNursingDiagnosisView.scss";

interface Props extends RouteComponentProps<{ facultyID: string }> {}
interface State {
  nursingDiagnosisList: {
    studentID: string;
    studentName: string;
    university: string;
    startDate: string;
    updatedAt: string;
  }[];
}

class AllNursingDiagnosisView extends React.Component<Props, State> {
  private student_id : string | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      nursingDiagnosisList: [],
    };

    this.goToNursingDiagnosisForFaculty = this.goToNursingDiagnosisForFaculty.bind(this);
    const query = new URLSearchParams(this.props.location.search);
    this.student_id = query.get('student_id');
  }

  public goToNursingDiagnosisForFaculty(studentID: string) {
    this.props.history.push(`/nursing_diagnosis/faculty/${studentID}?facultyID=${this.props.match.params.facultyID}`);
  }

  public componentDidMount() {
    const url = `${process.env.REACT_APP_API_URL}/api/nursing_diagnosis/faculty/all_list?student_id=${this.student_id}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          nursingDiagnosisList: result,
        });
      })
      .catch(console.error);
  }

  public render() {
    const nursingDiagnosisRow = this.state.nursingDiagnosisList.map(
      (item, i) => (
        <tr
          key={i}
          onClick={this.goToNursingDiagnosisForFaculty.bind(
            this,
            item.studentID
          )}
        >
          <td>{item.studentID}</td>
          <td>{item.studentName}</td>
          <td>{item.university}</td>
          <td>{item.startDate}</td>
          <td>{item.updatedAt}</td>
        </tr>
      )
    );

    return (
      <Row className="m-5 d-flex flex-column align-items-center">
        <h2 className="mb-4 text-center">実習学生の看護診断一覧</h2>
        <Table id="all_nursing_plans_here" className="" bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>学籍番号</th>
              <th>学生氏名</th>
              <th>施設名</th>
              <th>実習開始日</th>
              <th>学生更新日時</th>
            </tr>
          </thead>
          <tbody>{nursingDiagnosisRow}</tbody>
        </Table>
      </Row>
    );
  }
}

export default withRouter(AllNursingDiagnosisView);
