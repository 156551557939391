
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import MedicalRecordItem from "./MedicalRecordItem";
import "../../../assets/styles/MedicalRecord.scss";
// import { json } from "./medical_record_seed";
import { MedicalRecordData } from "../../models/Types";
import { checkUserId } from "../../../services/AuthService";
import { USER_TYPE } from "../../../services/AppLocalHelper";

interface Props
  extends RouteComponentProps<{
    patientID: string;
    studentID: string;
    facultyID: string;
    startDate: string;
  }> { }
interface State {
  medicalRecords: MedicalRecordData[];
  isStudent: boolean;
}

class MedicalRecord extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.studentID === "supervisor-faculty" ? this.props.match.params.facultyID : this.props.match.params.studentID;
    if (!checkUserId(userId, this.props.match.params.studentID === "supervisor-faculty" ? USER_TYPE.TEACHER : USER_TYPE.STUDENT)) {
      this.props.history.push('/')
    }

    this.state = {
      medicalRecords: [],
      isStudent: true,
    };

    this.checkStudent = this.checkStudent.bind(this)
  }

  public componentDidMount() {
    this.checkStudent()

    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/medical_record?patient_id=${this.props.match.params.patientID}&student_id=${this.props.match.params.studentID}&faculty_id=${this.props.match.params.facultyID}&start_date=${this.props.match.params.startDate}`;

    fetch(url, { mode: "cors" })
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            medicalRecords: result
          });
        },
        error => {
          console.log("API Error");
        }
      );
  }

  public checkStudent() {
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/control/is_student?student_id=${this.props.match.params.studentID}&faculty_id=${this.props.match.params.facultyID}`;

    fetch(url, { mode: "cors" })
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isStudent: result
          });
        },
        error => {
          console.log("API Error");
        }
      );
  }
  public render() {
    console.log("🚀 ~ file: MedicalRecord.tsx ~ line 79 ~ MedicalRecord ~ render ~ this.state.medicalRecords", this.state.medicalRecords)
    const medicalRecordRows = (this.state.isStudent ? this.state.medicalRecords.filter((item) => item.visibleFlag === false) : this.state.medicalRecords)
      .map((item, i) => (
        // <MedicalRecordItem key={i} medicalRecordData={item} />
      
        <>
         {(i === 0 || item !== null && (i > 0 && item.dateTime + item.author + item.title !== this.state.medicalRecords[i - 1].dateTime + this.state.medicalRecords[i - 1].author + this.state.medicalRecords[i - 1].title)) && (
            <tr key={i}>
              <td className="title text-left" colSpan={2}>
                {item.author !== null && item.author.includes('妊娠') ? '' : item.dateTime}&nbsp;{' '}
                {item.author}
                {item.title === '' || item.title === '&emsp;'
                  ? ''
                  : `「${item.title}」`}
              </td>
            </tr>
          ) }

          {/* <tr key={i}>
            <td className="title text-left" colSpan={2}>
              {item.author.includes("妊娠")? "": item.dateTime}&nbsp; {item.author}{item.title === "" ||item.title === "&emsp;"  ? "" : `「${item.title}」`}
            </td>
          </tr> */}


          <tr key={i + 1}>
            <td className="left">
              {item.subTitle === "&emsp;"  ? ""  : item.subTitle}
            </td>
            <td className="text-left">
              <span>
                <pre>
                  {item.message}
                </pre>
              </span>
            </td>
          </tr>
        </>

      ));

    return (
      <Row className="ml-4">

        <Col md={12}>
          <h2 className="mb-4">診療記録</h2>
          <Table className="" bordered>

            <tbody>{medicalRecordRows}</tbody>
          </Table>
        </Col>

      </Row>
    );
  }

}

export default MedicalRecord;
