import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { ActionPlanRowType } from "../../models/Types";
import Button from "react-bootstrap/Button";


interface SelectStartHourProps {
  onStartHourChanged: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  startHour: number | undefined;
}
interface SelectStartMinuteProps {
  onStartMinuteChanged: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  startMinute: number | undefined;
}
interface SelectEndHourProps {
  onEndHourChanged: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  endHour: number | undefined;
}
interface SelectEndMinuteProps {
  onEndMinuteChanged: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  endMinute: number | undefined;
}

interface Props extends ActionPlanRowType {
  onClickUp: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCLickDown: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isShowUpButton: boolean;
  isShowDownButton: boolean;
}

interface State { }

class SelectStartHour extends React.Component<SelectStartHourProps, State> {
  public render() {
    return (
      <Form.Control
        as="select"
        className=" "
        defaultValue={7}
        value={this.props.startHour}
        onChange={this.props.onStartHourChanged}
      >
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
        <option value={11}>11</option>
        <option value={12}>12</option>
        <option value={13}>13</option>
        <option value={14}>14</option>
        <option value={15}>15</option>
        <option value={16}>16</option>
        <option value={17}>17</option>
        <option value={18}>18</option>
        <option value={19}>19</option>
      </Form.Control>
    );
  }
}

class SelectStartMinute extends React.Component<SelectStartMinuteProps, State> {
  public render() {
    return (
      <Form.Control
        as="select"
        className=" "
        defaultValue={0}
        value={this.props.startMinute}
        onChange={this.props.onStartMinuteChanged}
      >
        <option value={0}>00</option>
        <option value={5}>05</option>
        <option value={10}>10</option>
        <option value={15}>15</option>
        <option value={20}>20</option>
        <option value={25}>25</option>
        <option value={30}>30</option>
        <option value={35}>35</option>
        <option value={40}>40</option>
        <option value={45}>45</option>
        <option value={50}>50</option>
        <option value={55}>55</option>
      </Form.Control>
    );
  }
}

class SelectEndHour extends React.Component<SelectEndHourProps, State> {
  public render() {
    return (
      <Form.Control
        as="select"
        className=" "
        defaultValue={7}
        value={this.props.endHour}
        onChange={this.props.onEndHourChanged}
      >
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
        <option value={11}>11</option>
        <option value={12}>12</option>
        <option value={13}>13</option>
        <option value={14}>14</option>
        <option value={15}>15</option>
        <option value={16}>16</option>
        <option value={17}>17</option>
        <option value={18}>18</option>
        <option value={19}>19</option>
      </Form.Control>
    );
  }
}

class SelectEndMinute extends React.Component<SelectEndMinuteProps, State> {
  public render() {
    return (
      <Form.Control
        as="select"
        className=" "
        // defaultValue={0}
        value={this.props.endMinute}
        onChange={this.props.onEndMinuteChanged}
      >
        <option value={0}>00</option>
        <option value={5}>05</option>
        <option value={10}>10</option>
        <option value={15}>15</option>
        <option value={20}>20</option>
        <option value={25}>25</option>
        <option value={30}>30</option>
        <option value={35}>35</option>
        <option value={40}>40</option>
        <option value={45}>45</option>
        <option value={50}>50</option>
        <option value={55}>55</option>
      </Form.Control>
    );
  }
}

export default class ActionPlanRow extends React.Component<Props, State> {
  public render() {

    return (

      <Form inline>
        <Form.Group className="mt-4 ">
          <SelectStartHour
            startHour={this.props.startHour}
            onStartHourChanged={this.props.onStartHourChanged}
          
          />
          <Form.Label className="ml-2 mr-2 ">:</Form.Label>
          <SelectStartMinute
            onStartMinuteChanged={this.props.onStartMinuteChanged}
            startMinute={this.props.startMinute}
            
          />
          <Form.Label className="ml-3 mr-3 ">〜</Form.Label>
          <SelectEndHour
            endHour={this.props.endHour}
            onEndHourChanged={this.props.onEndHourChanged}
            
          />
          <Form.Label className="ml-2 mr-2 ">:</Form.Label>
          <SelectEndMinute
            endMinute={this.props.endMinute}
            onEndMinuteChanged={this.props.onEndMinuteChanged}
           
          />
          <Form.Control
            className="ml-4 action_plan_text "
            type="text"
            defaultValue={this.props.plan}
            onChange={this.props.onPlanChanged}
          />


          {this.props.isShowUpButton && (<Button
            className="btn-up"
            variant="secondary"
            size="lg"
            type="submit"
            onClick={this.props.onClickUp}
          >
            <i className="far fa-arrow-alt-circle-up"></i>
          </Button>)}


          {this.props.isShowDownButton && (<Button
            className="btn-up"
            variant="secondary"
            size="lg"
            type="submit"
            onClick={this.props.onCLickDown}
          >
            <i className="far fa-arrow-alt-circle-down"></i>
          </Button>)}
        </Form.Group>

      </Form>

    );
  }
}
