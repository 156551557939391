import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import _ from "lodash";

type Props = {
  dataRow: any;
};

const OverviewTableRow: React.FunctionComponent<Props> = ({ dataRow }) => {
  const [renderCol, setRenderCol] = useState("");

  useEffect(() => {
    leftDataNew();
  }, [dataRow]);

  const leftDataNew = () => {
    let result: any = [];
    const mr_time = dataRow.mr_time;
    dataRow.mr_value.map((item: any, k: number) => {
      // tổng số cột
      // nếu độ dài của mảng arrTemp = tổng số cột thì next row mới
      const pdata = item.pdata.sort(function(a:any,b:any) {
        return (a.mr_time == null || b.mr_time == null) ||  parseInt(a.mr_time.replace(":00:00", "")) - parseInt(b.mr_time.replace(":00:00", ""))
      });
      const tmpRowCount = Math.ceil(pdata.length / mr_time.length);
      if (item.isDisplay) {
        for (let i = 0; i < tmpRowCount; i++) {
          // row
          result.push(
            <tr key={Math.random()} data-type={item.base_type_id}>
              <td key={Math.random()}>{item.namea}</td>
              <td key={Math.random()}>{item.nameb}</td>
              <td key={Math.random()}>{item.namec}</td>
            </tr>
          );
        }
      }
    });
    setTimeout(() => rowSpanFunc(), 1000);
    setRenderCol(result);
  };

  const rowSpanFunc = () => {
    const tableOverview = document.getElementById(
      "overviewXXX"
    ) as HTMLTableElement;
    if (tableOverview == null) return;

    for (let i = 0; i < 2; i++) {
      let headerCell = null;
      // for (let row of tableOverview.rows) {

      for (let j = 0; j < tableOverview.rows.length; j++) {
        // break three line header
        if (j < 2) continue;
        let row = tableOverview.rows[j];
        const firstCell = row.cells[i];

        if (
          headerCell === null ||
          firstCell.innerText !== headerCell.innerText
        ) {
          headerCell = firstCell;
        } else {
          headerCell.rowSpan++;
          firstCell.style.display = "none";
        }
      }
    }
  };

  return (
    <Table
      className="settings__overview patient_info_overview_left float-left"
      id="overviewXXX"
      bordered
      hover
      size="sm"
    >
      <thead>
        <tr>
          <th colSpan={3}>日付</th>
        </tr>
        <tr>
          <th colSpan={3}>時刻</th>
        </tr>
      </thead>
      <tbody>{renderCol && renderCol}</tbody>
    </Table>
  );
};

export default React.memo(OverviewTableRow);
