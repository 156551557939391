import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { LoginFacultyInfo } from "../models/Types";
import { clearUserId, clearUserType, saveUserId, saveUserType, USER_TYPE } from "../../services/AppLocalHelper";

interface Props extends RouteComponentProps { }
interface State extends LoginFacultyInfo {
  isShow: string;
  facultyIdStatus: string;
}

class LoginFaculty extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    clearUserType()
    clearUserId()
    this.state = {
      facultyID: "",
      password: "",
      loginStatus: "",
      isShow: "password",
      facultyIdStatus: "",
    };

    this.onFacultyIDChanged = this.onFacultyIDChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.loginFaculty = this.loginFaculty.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
  }

  public onFacultyIDChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const facultyID: string = e.target.value;
    this.setState({ facultyID: facultyID });
  }

  public onPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const password: string = e.target.value;
    this.setState({ password: password });
  }

  public onChangeType() {
    let currentType: string = "";
    this.state.isShow == "password" ? currentType = "text" : currentType = "password"

    this.setState({
      isShow: currentType
    })
  }

  public loginFaculty(e: React.MouseEvent<HTMLButtonElement>) {

    e.preventDefault();
    let format = /[!@#$%^&*()+=\[\]{};':"\\|,<>\/?]+/;
    if (format.test(this.state.facultyID)) {
      this.setState({ facultyIdStatus: "fail" });
    } else {

      const url = `${process.env.REACT_APP_API_URL}/api/login_faculty`;
      const loginFacultyInfo = {
        faculty_id: this.state.facultyID,
        password: this.state.password,
      };
      const method = "POST";
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const body = JSON.stringify(loginFacultyInfo);

      fetch(url, { method, headers, body })
        .then((res) => res.json())
        .then((result) => {
          if (result.login_status === "success") {
            saveUserType(USER_TYPE.TEACHER)
            saveUserId(this.state.facultyID);
            this.props.history.push(`/index/faculty/${this.state.facultyID}`);
          } else {
            this.setState({ loginStatus: result.login_status });
          }
        })
        .catch(console.error);
    }
  }

  public render() {
    return (
      <div className="mt-5 mb-5">
        <h2 className="clor-lightgreen">教員ログイン</h2>
        <Form className="mt-4">
          <Form.Group as={Row}>
            <Form.Label column md={4} className="font-weight-bold text-right clor-lightgreen">
              教員ID
            </Form.Label>
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder="Enter faculty ID"
                onChange={this.onFacultyIDChanged}
              />
              <Form.Text className="text-muted text-left">
                &nbsp;半角英数字で入力
              </Form.Text>

            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formBasicEmail">
            <Form.Label column md={4} className="font-weight-bold text-right clor-lightgreen">
              パスワード
            </Form.Label>
            <Col md={6}>
              <Form.Control
                type={this.state.isShow}
                placeholder="Enter password"
                onChange={this.onPasswordChanged}
              />
              <Form.Text className="text-muted text-left">
                &nbsp;半角英数字で入力
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formBasicEmail">
            <Form.Label column md={4} className="font-weight-bold text-right clor-lightblue">

            </Form.Label>
            <Col md={6}>
              <input
                type="checkbox"
                className="check-show-password"
                // type="password"
                // placeholder="Enter password"
                onClick={this.onChangeType}
              />
              <Form.Text className="text-muted text-left m-0">
                パスワード表示
              </Form.Text>
              <div className="clear"></div>
            </Col>

          </Form.Group>
          <Row>
            <Col md={1} />
            <Col md={10}>
              <Button
                className="m-2 bg-lightgreen"

                type="submit"
                block
                onClick={this.loginFaculty}
              >
                Login
              </Button>
            </Col>
            <Col md={1} />
          </Row>
        </Form>
        {this.state.loginStatus === "no_user" && (
          <Alert className="" variant="danger">
            教員IDが間違っています
          </Alert>
        )}
        {this.state.loginStatus === "password_incorrect" && (
          <Alert className="" variant="danger">
            パスワードが間違っています
          </Alert>
        )}
        {this.state.loginStatus === "login_prohibited" && (
          <Alert className="" variant="danger">
            ログイン許可がありません
          </Alert>
        )}
        {this.state.facultyIdStatus === "fail" && (
          <Alert className="" variant="danger">
            /\%$#@は使用できません
          </Alert>
        )}
      </div>
    );
  }
}

export default withRouter(LoginFaculty);
