import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface Props {
  key: number;
  activity: string | undefined;
  // result: string | undefined;
  evaluation: string | undefined;
}
interface State {}

class NursingActivityRowForFaculty extends React.Component<Props, State> {
  public render() {
    return (
      <Row className="mt-3 mb-3 p-3 student_input text-muted m-3">
        <Col className="">
          <pre>{this.props.activity}</pre>
        </Col>
        {/* <Col>
          <pre>{this.props.result}</pre>
        </Col> */}
        <Col>
          <pre>{this.props.evaluation}</pre>
        </Col>
      </Row>
    );
  }
}

export default NursingActivityRowForFaculty;
