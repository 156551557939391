import React from "react";
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { UniversityList_2 } from "../models/Types";
// SW fix 2021/3/1
import "../../assets/styles/SWCustom.scss";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import { checkUserId } from "../../services/AuthService";
import { USER_TYPE } from "../../services/AppLocalHelper";
dayjs.extend(isBetween);


// interface Props extends RouteComponentProps {
//   facultyID: string;
// }
interface Props
  extends RouteComponentProps<{
    facultyID: string;
  }> { }
// end fix
interface State {
  universityList: UniversityList_2[];
  yearSelected: string;
  startYear: any;
  endYear: any;
  isFacultySupervisor: boolean;
  affiliationFaculty: string;
}

class InternUniversityList extends React.Component<Props, State> {

  private supervisor: string | null;

  constructor(props: Props) {
    super(props);

    let userId = this.props.match.params.facultyID
    if (!checkUserId(userId, USER_TYPE.TEACHER)) {
      this.props.history.push('/')
    }
   
    this.state = {
      universityList: [],
      yearSelected: 'all',
      startYear: '',
      endYear: '',
      isFacultySupervisor: false,
      affiliationFaculty: '',
    };
    this.getFaculty(userId);
    this.onInternUniversityListRowClicked = this.onInternUniversityListRowClicked.bind(this);
    // SW add 2021/3/2
    this.getType = this.getType.bind(this);
    this.goToStudentList = this.goToStudentList.bind(this)
    this.goToQuesView = this.goToQuesView.bind(this)
    this.goToQuesViewAll = this.goToQuesViewAll.bind(this)
    this.goToStudentListSetting = this.goToStudentListSetting.bind(this)
    this.goToQuesViewSetting = this.goToQuesViewSetting.bind(this)
    this.isChangeYear = this.isChangeYear.bind(this)
    this.changeVisibleFlag =  this.changeVisibleFlag.bind(this)

    const query = new URLSearchParams(this.props.location.search);
    this.supervisor = query.get('supervisor');
  }
  // SW fix 2021/3/2
  public onInternUniversityListRowClicked(
    university: string,
    startDate: string,
    endDate: string,
    internDays: number
  ) {
    const type = this.getType();
    switch (type) {
      case 'patient-information':
        this.props.history.push(`/all_hospital_rooms/supervisor-faculty?university=${university}&startDate=${startDate}&endDate=${endDate}&facultyID=${this.props.match.params.facultyID}`);
        break;
      case 'training-information':
        this.props.history.push(`/faculty/student_list_custom/${university}/${startDate}/${endDate}/${internDays}/${this.props.match.params.facultyID}`);
        break;
      case 'survey-information':
        break;
      case 'setting':
        break;
      default:
        break;
    }

  }
  public getFaculty(facultyID: string){
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info?faculty_id=${facultyID}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
          console.log('getFaculty', result);
     
          this.setState({isFacultySupervisor : result.is_supervisor, affiliationFaculty: result.affiliation_id});
          this.getUniversityList(result.is_supervisor, result.is_subsupervisor);
      })
      .catch(console.error);
  }
  // SW add 2021/3/2
  public goToStudentList(university: string,
    startDate: string,
    endDate: string) {
    this.props.history.push(
      // `/faculty/student_list_self_evaluation/${university}/${startDate}/${endDate}/${this.props.match.params.facultyID}`
      `/faculty/self_evaluation_for_faculty/${university}/${startDate}/${endDate}/${this.props.match.params.facultyID}`
    );
  }
  public goToQuesView(
    university: string,
    startDate: string,
    endDate: string
  ) {
    this.props.history.push(
      `/faculty/questionnaire_result/${university}/${startDate}/${endDate}/${this.props.match.params.facultyID}`
    );
  }
  public goToQuesViewAll() {
    this.props.history.push(
      `/faculty/questionnaire_result/all/null/null/${this.props.match.params.facultyID}`
    );
  }
  public goToStudentListSetting(data: any) {
    // HKB
    this.props.history.push(`/create-new/patient-assignment`, {
      data,
    });
  }
  public goToQuesViewSetting(data: any, universityList: UniversityList_2[]) {
    // HKB
    this.props.history.push(`/create-new/self-evaluation-item`, {
      ...data, universityList
    });
  }

  public getType() {
    // SW fix 2021/3/1
    const query = new URLSearchParams(this.props.location.search)
    const type = query.get("type")
    return type;
  }

  public isChangeYear(event: React.ChangeEvent<HTMLInputElement>) {
    const year: string = event.target.value;
    this.setState({
      yearSelected: year || "all",
      startYear: dayjs(year + "-04-01", "YYYY-MM-DD"),
      endYear: dayjs((Number(year) + 1) + "-03-31", "YYYY-MM-DD"),
    });

  }
  public componentDidMount() {
    // SW fix 2021/3/1

 
  }
  public getUniversityList(is_supervisor: boolean, is_subsupervisor: boolean){
    let is_admin  = is_supervisor || is_subsupervisor ? 1 : 0;
    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info/intern_university_list?faculty_id=${this.props.match.params.facultyID}&is_admin=${is_admin}`;
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          this.setState({
            universityList: result,
          });
        }
      })
      .catch(console.error);
  }

  public changeVisibleFlag ( univerSity: string, startDate: string, endDate: string, internDays: number, visibleFlag: number ) {
    const universitySelected = {
      university: univerSity,
      start_date: startDate,
      end_date: endDate,
      intern_day: internDays,
      visible_flag: visibleFlag,
    };

    const url = `${process.env.REACT_APP_API_URL}/api/faculty_info/update_visibleflag`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(universitySelected);

    fetch(url, { method, headers, body })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          window.location.reload();
          // this.setState({ updateState: "success", showAlert: true });
        } else {
          // this.setState({ updateState: "error" });
          console.log("API Error");
        }
      })
      .catch(console.error);
  }

  public render() {
    // set year
    const year = (new Date()).getFullYear();
    const years = Array.from(new Array(10), (val, index) => index + year - 1);
    const optionsYears = years.map((item, i) => <option key={i} value={item}>{item}</option>);
    //  check supervisor
    const isSupervisor: any = this.supervisor === 'true'
    // check type
    const isRenderAdditionalCol: Boolean = this.getType() === "survey-information"
    const isSettingSelected: Boolean = this.getType() === 'setting'

    // console.log("🚀 ~ file: InternUniversityList.tsx ~ line 166 ~ InternUniversityList ~ render ~ this.state.universityList", this.state.universityList)
    // bind data
    let listUniversity = this.state.yearSelected === 'all' ? this.state.universityList : this.state.universityList.filter(item => dayjs(item.startDate, "YYYY-MM-DD").isBetween(this.state.startYear, this.state.endYear))
    if(!this.state.isFacultySupervisor){
      let affiliation = this.state.affiliationFaculty;
      listUniversity = listUniversity.filter(function (el: any) {
        return el.university == affiliation;
      });
    }
    const universityRow = listUniversity.map((item, i) => (
      <tr
        key={i}
        onClick={this.onInternUniversityListRowClicked.bind(
          this,
          item.university,
          item.startDate,
          item.endDate,
          item.internDays
        )}
      >
        <td>{i + 1}</td>
        <td>{item.university_name}</td>
        <td>{item.studentNum}</td>
        <td>{item.startDate}</td>
        <td>{item.endDate}</td>
        <td>{item.internDays}日目</td>
        {/* SW add 2021/3/2 */}
        {isRenderAdditionalCol && (
          <>
            <td>
              <Button
                className="font-weight-bold"
                variant="outline-success"
                onClick={this.goToStudentList.bind(
                  this,
                  item.university,
                  item.startDate,
                  item.endDate
                )}
              >
                自己評価
              </Button>
            </td>
            <td>
              <Button
                className="font-weight-bold"
                variant="outline-success"
                onClick={this.goToQuesView.bind(
                  this,
                  item.university,
                  item.startDate,
                  item.endDate
                )}
              >
                アンケート結果
              </Button>
            </td>
          </>
        )}
        {isSettingSelected && (
          <>
            <td>
              <Button
                className="font-weight-bold"
                variant="outline-warning"
                onClick={this.goToStudentListSetting.bind(this, item)}
              >
                患者割当
              </Button>
            </td>
            <td>
              <Button
                className="font-weight-bold"
                variant="outline-warning"
                onClick={this.goToQuesViewSetting.bind(this, item, this.state.universityList)}
              >
                自己評価項目
              </Button>
            </td>
            <td 
                className= {item.visibleFlag === 1 ? "btn-bg-gray" : ""}>
              <button 
                className= "btn-visible-flag"
                onClick={() => this.changeVisibleFlag(
                  item.university,
                  item.startDate,
                  item.endDate,
                  item.internDays,
                  item.visibleFlag
                )}
                >
                {item.visibleFlag === 1 ? '表示' : '非表示'}
              </button>
            </td>
          </>
        )}
      </tr>
    ));





    return (
      <div className="ml-3">
        {/* select year */}
        {isSettingSelected && (
          <Form.Control className="select-year" required as="select" onChange={this.isChangeYear}>
            <option value="all">未選択</option>
            {optionsYears}
          </Form.Control>
        )}

        <h2 className="mt-5 mb-3">実習グループ一覧</h2>

        {isRenderAdditionalCol && (
          <div className="quest-left">
            {isSupervisor && (
              <Button
                className="font-weight-bold"
                variant="outline-info"
                onClick={this.goToQuesViewAll}
              >
                旧システムアンケート結果
              </Button>

            )}
          </div>
        )}


        {/* SW fix 2021/3/1 */}
        <Table
          id="index_faculty_student_list"
          className="sw-custom-table"
          bordered
          hover
        >
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>施設名</th>
              <th>学生数</th>
              <th>実習開始日</th>
              <th>実習終了日</th>
              <th>実習日</th>
              {/* SW add 2021/3/2 */}
              {isRenderAdditionalCol && (
                <>
                  <th></th>
                  <th></th>
                </>
              )}
              {isSettingSelected && (
                <>
                  <th></th>
                  <th></th>
                  <th></th>
                </>
              )}
            </tr>
          </thead>
          <tbody>{universityRow}</tbody>
        </Table>
      </div>
    );
  }
}

export default withRouter(InternUniversityList);
