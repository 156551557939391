import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { convertDate } from "../../common/function";
import { MidwiferyRecordType } from "../../models/Types";
import "../../../assets/styles/DoctorInstruction.scss";
import { Button } from "@material-ui/core";

interface Props extends RouteComponentProps<{}> {
  midwiferyRecords: MidwiferyRecordType[];
  setFormValues: Function;
  setSuccessMessage: Function;
  setMidwiferyRecords: Function;
}
interface State {}

class MidwiferyRecordTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  onDelete = (fileInfoID: number, patientID: string) => {
    const confirmDelete = window.confirm("削除してもよろしいですか？");
    const url = `${process.env.REACT_APP_API_URL}/api/patient_info/josan_record/delete`;
    const method = "DELETE";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const mode = "cors";
    const body = JSON.stringify({
      fileInfoID: fileInfoID,
      patientID: patientID,
    });

    console.log(body);
    if (confirmDelete) {
      fetch(url, { method, mode, headers, body })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            this.props.setSuccessMessage("削除しました。");
            this.props.setMidwiferyRecords(fileInfoID, "DELETE");
          }
        })
        .catch(console.error);
      return true;
    } else {
      return false;
    }
  };

  onUpdate = (fileInfoID: number) => {
    this.props.setFormValues(fileInfoID);
  };

  actionItem = [
    {
      label: "編集",
      action: this.onUpdate,
    },
    {
      label: "削除",
      action: this.onDelete,
    },
  ];

  actionHTML = (fileInfoID: number, patientID: string) => {
    console.log(patientID);
    return this.actionItem.map((item) => (
      <Button
        onClick={() => item.action(fileInfoID, patientID)}
        key={item.label}
      >
        {item.label}
      </Button>
    ));
  };

  public render() {
    return (
      <Row className="mt-5">
        <Col md={9} style={{ margin: "auto" }}>
          <h2 className="mb-5">医師の指示</h2>
          <Table responsive bordered hover size="sm">
            <thead className="thead-dark">
              <tr>
                <th id="date">記録種別</th>
                <th id="instruction">記録日時</th>
                <th id="detail">記録名</th>
              </tr>
            </thead>
            <tbody>
              {this.props.midwiferyRecords.map((item, i) => {
                return (
                  <tr key={i}>
                    <td id="instruction" className="text-nowrap">
                      {item.recode_type}
                    </td>
                    <td id="date" className="text-nowrap">
                      {convertDate(parseInt(item.recode_date))}
                    </td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "350px" }}>
                        <pre className="text-left p-2">
                          {item.recode_file_name}
                        </pre>
                      </div>
                      <StyledAction className="float-right">
                        {this.actionHTML(
                          item.fileInfoID as number,
                          item.patientID as string
                        )}
                      </StyledAction>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}

const StyledAction = styled.div`
  button {
    font-size: 1em;
    text-align: left;
    color: blue;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
  }

  button:hover,
  button:focus {
    color: blue;
    background: none;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background: none;
    color: blue;
  }
`;

export default withRouter(MidwiferyRecordTable);
